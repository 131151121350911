// Action Types
const VIDEO_RECORDER = "services/VIDEO_RECORDER";
const VIDEO_RECORDER_SUCCESS = "services/VIDEO_RECORDER_SUCCESS";
const VIDEO_RECORDER_FAIL = "services/VIDEO_RECORDER_FAIL";
const VIDEO_RECORDER_POST = "services/VIDEO_RECORDER_POST";
const VIDEO_RECORDER_POST_SUCCESS = "services/VIDEO_RECORDER_POST_SUCCESS";
const VIDEO_RECORDER_POST_FAIL = "services/VIDEO_RECORDER_POST_FAIL";
const VIDEO_QUESTIONS = "services/VIDEO_QUESTIONS";
const VIDEO_QUESTIONS_SUCCESS = "services/VIDEO_QUESTIONS_SUCCESS";
const VIDEO_QUESTIONS_FAIL = "services/VIDEO_QUESTIONS_FAIL";

// InitialState
const initialState = {
  loading: false,
  loaded: false,
  data: {
    processoseletivo: {
      cargo: "",
    },
    empresa: {
      nome: "",
    },
    perguntas: {},
    video_entrevista: {
      qtde_perguntas: 0,
    },
  },
  questions: {
    loading: false,
    loaded: false,
    data: {
      perguntas: {},
      video_entrevista: {
        qtde_perguntas: 0,
      },
    },
  },
};

// Reducers
export default function videoInterview(state = initialState, action = {}) {
  switch (action.type) {
    case VIDEO_RECORDER:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case VIDEO_RECORDER_SUCCESS:
      return {
        ...state,
        data: action.result,
        hash: action.hash,
        loading: false,
        loaded: true,
      };
    case VIDEO_RECORDER_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error,
      };
    case VIDEO_RECORDER_POST:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case VIDEO_RECORDER_POST_SUCCESS:
      return {
        ...state,
        postResult: action.result,
        loading: false,
        loaded: true,
      };
    case VIDEO_RECORDER_POST_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error.status,
      };
    case VIDEO_QUESTIONS:
      return {
        ...state,
        questions: {
          ...state.questions,
          loaded: false,
          loading: true,
        },
      };
    case VIDEO_QUESTIONS_SUCCESS:
      return {
        ...state,
        questions: {
          ...state.questions,
          data: action.result,
          loaded: true,
          loading: false,
        },
      };
    case VIDEO_QUESTIONS_FAIL:
      return {
        questions: {
          ...state.questions,
          error: action.error.status,
          loaded: false,
          loading: false,
        },
      };
    default:
      return state;
  }
}

// Actions
export function load(path, hash, params, renderTo = "video") {
  return {
    types:
      renderTo === "termos"
        ? [VIDEO_QUESTIONS, VIDEO_QUESTIONS_SUCCESS, VIDEO_QUESTIONS_FAIL]
        : [VIDEO_RECORDER, VIDEO_RECORDER_SUCCESS, VIDEO_RECORDER_FAIL],
    promise: (client) =>
      client.get(
        path,
        {
          params,
        },
        process.env.REACT_APP_ATHENA_URL,
        "app_token",
      ),
    hash: hash,
  };
}

export function post(path, data) {
  return {
    types: [
      VIDEO_RECORDER_POST,
      VIDEO_RECORDER_POST_SUCCESS,
      VIDEO_RECORDER_POST_FAIL,
    ],
    promise: (client) =>
      client.post(
        path,
        { data },
        process.env.REACT_APP_ATHENA_URL,
        "app_token",
      ),
  };
}

function shouldFetch(state) {
  return state.VideoInterview.loading
    ? false
    : state.VideoInterview.loaded
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(path, hash, params, renderTo) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path, hash, params, renderTo));
    }
  };
}
