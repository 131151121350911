import React, { Component } from "react";
import shortid from "shortid";
import intl from "react-intl-universal";
import { Link } from "../../index";
import { map } from "lodash";
import propTypes from "prop-types";

class ListLinks extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { links, group, linkRi } = this.props;

    return links === undefined ? null : (
      <ul className="vg-list-links">
        {map(links, (link) => {
          return (
            <li key={shortid.generate()}>
              <Link
                className={`vg-box-btn ${linkRi === 0 && "vg-btn-disabled"}`}
                moveTo={link}
                params={
                  group === "colaboradores" ? { codigo_da_divisao: linkRi } : {}
                }
              >
                {intl.get(
                  `PROFISSIONAIS.CONTEUDO_BOXES.${group}.LISTA_LINKS.${link}`
                )}
              </Link>
            </li>
          );
        })}
      </ul>
    );
  }
}

ListLinks.propTypes = {
  links: propTypes.array,
  group: propTypes.string,
  linkRi: propTypes.number,
};

export default ListLinks;
