// Action Types
const LOAD_CREATE_GROUP_QUESTION = "services/LOAD_CREATE_GROUP_QUESTION";
const LOAD_CREATE_GROUP_QUESTION_SUCCESS =
  "services/LOAD_CREATE_GROUP_QUESTION_SUCCESS";
const LOAD_CREATE_GROUP_QUESTION_FAIL =
  "services/LOAD_CREATE_GROUP_QUESTION_FAIL";
const POST_CREATE_GROUP_QUESTION = "services/POST_CREATE_GROUP_QUESTION";
const POST_CREATE_GROUP_QUESTION_SUCCESS =
  "services/POST_CREATE_GROUP_QUESTION_SUCCESS";
const POST_CREATE_GROUP_QUESTION_FAIL =
  "services/POST_CREATE_GROUP_QUESTION_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: {},
};

// Reducer
export default function CreateGroupQuestions(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case LOAD_CREATE_GROUP_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CREATE_GROUP_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { load: true, ...action.result },
      };
    case LOAD_CREATE_GROUP_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case POST_CREATE_GROUP_QUESTION:
      return {
        ...state,
        loading: true,
      };
    case POST_CREATE_GROUP_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { post: true, ...action.result },
      };
    case POST_CREATE_GROUP_QUESTION_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [
      LOAD_CREATE_GROUP_QUESTION,
      LOAD_CREATE_GROUP_QUESTION_SUCCESS,
      LOAD_CREATE_GROUP_QUESTION_FAIL,
    ],
    promise: (client) => client.get("painel"),
  };
}

export function post(path, data) {
  return {
    types: [
      POST_CREATE_GROUP_QUESTION,
      POST_CREATE_GROUP_QUESTION_SUCCESS,
      POST_CREATE_GROUP_QUESTION_FAIL,
    ],
    promise: (client) => client.post(path, { data }),
  };
}

function shouldFetch(state) {
  return state.CreateGroupQuestions.loading
    ? false
    : Object.keys(state.CreateGroupQuestions.data).length
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
