import React from "react";
import PropTypes from "prop-types";
import "./Modal.scss";

const Modal = ({ showModal, setShowModal, children }) => {
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    showModal && (
      <div className="vg-modal-box" onClick={closeModal}>
        <div onClick={(e) => e.stopPropagation()}>{children}</div>
      </div>
    )
  );
};

Modal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default Modal;
