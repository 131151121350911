// Action Types
const LOAD_USERSREDE = "services/LOAD_USERSREDE";
const LOAD_USERSREDE_SUCCESS = "services/LOAD_USERSREDE_SUCCESS";
const LOAD_USERSREDE_FAIL = "services/LOAD_USERSREDE_FAIL";
const DEL_USERSREDE = "services/DEL_USERSREDE";
const DEL_USERSREDE_SUCCESS = "services/DEL_USERSREDE_SUCCESS";
const DEL_USERSREDE_FAIL = "services/DEL_USERSREDE_FAIL";
const PUT_USERSREDE = "services/PUT_USERSREDE";
const PUT_USERSREDE_SUCCESS = "services/PUT_USERSREDE_SUCCESS";
const PUT_USERSREDE_FAIL = "services/PUT_USERSREDE_FAIL";
const POST_USERSREDE = "services/POST_USERSREDE";
const POST_USERSREDE_SUCCESS = "services/POST_USERSREDE_SUCCESS";
const POST_USERSREDE_FAIL = "services/POST_USERSREDE_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: {},
  id: "",
};

// Reducer
export default function usersrede(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USERSREDE:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USERSREDE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        id: action.id,
      };
    case LOAD_USERSREDE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case DEL_USERSREDE:
      return {
        ...state,
        loading: true,
      };
    case DEL_USERSREDE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          funcionarios: [
            ...state.data.funcionarios.filter(
              (funcionario) => funcionario.id !== action.funcId
            ),
          ],
        },
      };
    case DEL_USERSREDE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case PUT_USERSREDE:
      return {
        ...state,
        loading: true,
      };
    case PUT_USERSREDE_SUCCESS: {
      let funcList = [
        ...state.data.funcionarios.filter(
          (funcionario) => funcionario.id !== action.funcId
        ),
      ];
      funcList.push({
        email: action.result.email,
        id: action.result.id,
        identidade: action.result.identidade,
        nome: action.result.nome,
        perfil: action.result.perfil,
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          funcionarios: funcList,
        },
      };
    }
    case PUT_USERSREDE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action,
      };
    case POST_USERSREDE:
      return {
        ...state,
        loading: true,
      };
    case POST_USERSREDE_SUCCESS: {
      let funcList = [...state.data.funcionarios];
      if (action.id == state.id) {
        funcList.push({
          email: action.result.email,
          id: action.result.id,
          identidade: action.result.identidade,
          nome: action.result.nome,
          perfil: action.result.perfil,
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        data: {
          funcionarios: funcList,
        },
      };
    }
    case POST_USERSREDE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action,
      };
    default:
      return state;
  }
}

export function load(redeId) {
  return {
    types: [LOAD_USERSREDE, LOAD_USERSREDE_SUCCESS, LOAD_USERSREDE_FAIL],
    promise: (client) => client.get(`funcionarios/da_unidade/${redeId}`),
    id: redeId,
  };
}

export function deleteUser(funcId) {
  return {
    types: [DEL_USERSREDE, DEL_USERSREDE_SUCCESS, DEL_USERSREDE_FAIL],
    promise: (client) => client.del("funcionarios/" + funcId),
    funcId: funcId,
  };
}

export function atualizaUser(funcId, data) {
  return {
    types: [PUT_USERSREDE, PUT_USERSREDE_SUCCESS, PUT_USERSREDE_FAIL],
    promise: (client) => client.put("funcionarios/" + funcId, { data: data }),
    funcId: funcId,
  };
}

export function addUser(data, redeId) {
  return {
    types: [POST_USERSREDE, POST_USERSREDE_SUCCESS, POST_USERSREDE_FAIL],
    promise: (client) => client.post("funcionarios", { data: data }),
    id: redeId,
  };
}

function shouldFetch(state) {
  return state.UsersRedes.loading
    ? false
    : Object.keys(state.UsersRedes.data).length
    ? false
    : true;
}

export function receive(redeId) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(redeId));
    }
  };
}
