import React, { Component } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import { SideBarContent } from "../../index";
import "./SideBar.scss";

class SideBar extends Component {
  render() {
    const { show } = this.props;
    return (
      <Transition in={show} timeout={300}>
        {(state) => <SideBarContent state={state} {...this.props} />}
      </Transition>
    );
  }
}

SideBar.propTypes = {
  show: PropTypes.bool,
};

export default SideBar;
