// Action Types
const LOAD_HIRED = "services/LOAD_HIRED";
const LOAD_HIRED_SUCCESS = "services/LOAD_HIRED_SUCCESS";
const LOAD_HIRED_FAIL = "services/LOAD_HIRED_FAIL";
const BULK = "services/BULK";
const BULK_SUCCESS = "services/BULK_SUCCESS";
const BULK_FAIL = "services/BULK_FAIL";

// InitialState
const initialState = {
  loaded: false,
  data: {
    contratados: [],
  },
  bulkState: {
    error: {},
    loaded: false,
    loading: false,
  },
};

// Reducer
export default function HiredRequest(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_HIRED:
      return {
        ...state,
        loading: true,
      };
    case LOAD_HIRED_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_HIRED_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case BULK:
      return {
        ...state,
        bulkState: {
          loading: true,
          loaded: false,
        },
      };
    case BULK_SUCCESS:
      return {
        ...state,
        bulkState: {
          loading: false,
          loaded: true,
        },
      };
    case BULK_FAIL:
      return {
        ...state,
        bulkState: {
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [LOAD_HIRED, LOAD_HIRED_SUCCESS, LOAD_HIRED_FAIL],
    promise: (client) => client.get(path),
  };
}

export function bulk(path, data) {
  return {
    types: [BULK, BULK_SUCCESS, BULK_FAIL],
    promise: (client) =>
      client.post(path, {
        data: {
          contratados: data,
        },
      }),
  };
}
