import React from "react";
import propTypes from "prop-types";

export default function CollapseDragHeader({
  toggleCollapse,
  children,
  item,
  index,
}) {
  const Children = React.cloneElement(children, { ...item, index });

  return (
    <a
      href="#"
      className="vg-collapse-header"
      onClick={(e) => toggleCollapse(e)}
    >
      {Children}
    </a>
  );
}

CollapseDragHeader.propTypes = {
  toggleCollapse: propTypes.func.isRequired,
  children: propTypes.node.isRequired,
  item: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
};
