import "../Actions.scss";
import {
  ActionDivisions,
  Button,
  Link,
  RichTextEditor,
  WarningTitle,
} from "../../../index";
import React, { Component } from "react";
import {
  post,
  receive as receiveActions,
  update,
} from "../../../../store/modules/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import {
  automaticActionDatalayer,
  virtualPageviewDatalayer,
} from "../../../../utils/dataLayerPush";
import propTypes from "prop-types";
import { receive } from "../../../../store/modules/Divisions";
import { receive as receiveMessage } from "../../../../store/modules/Messages";
import shortid from "shortid";

const mapStateToProps = (state) => ({
  messages: state.Messages.data.mensagens_padrao,
  divisions: state.Divisions.sla,
  accesses: state.Painel.painel.data,
});

export class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      selectMessage: 0,
      selectMessageContent: "",
      isHtml: false,
      currentAction: {},
      actionForm: {
        conteudo: {
          titulo: "",
          corpo: "<p></p>\n",
        },
        divisao_id: 0,
        tipo_acao_id: 1,
        tipo_acao: "enviar_email",
        ativo: true,
      },
      isOpen: false,
      nomeDivisao: "todas-as-divisoes",
      messageTemplate: "valor-indisponivel",
      errorTitle: "",
      errorMessage: "",
    };

    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleActionForm = this.handleActionForm.bind(this);
    this.createAction = this.createAction.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleTitle = this.handleTitle.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  componentDidMount() {
    // Carrega as mensagens padrões
    this.props.receiveMessage();

    // Carrega as divisoes
    this.props.receive("divisoes", { incluir_sla: true });

    virtualPageviewDatalayer(
      "/configuracoes/editar-fases/acao-automatica/mensagem",
      "criar mensagem automatica"
    );
  }

  handleEditorChange(text) {
    const { errorMessage } = this.state;
    if (text.length > 8 && errorMessage.length > 0) {
      this.setState({ errorMessage: "" });
    }
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          corpo: text,
        },
      },
    }));
  }

  handleActionForm(e, type) {
    const targetContent = e.target.value;
    const { errorTitle } = this.state;
    if (targetContent.length > 0 && errorTitle.length > 0) {
      this.setState({ errorTitle: "" });
    }
    if (type === "divisao_id") {
      const textContent = e.target.options[e.target.selectedIndex].text;

      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          [type]: targetContent,
        },
        nomeDivisao: textContent,
      }));
    } else {
      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          conteudo: {
            ...prevState.actionForm.conteudo,
            [type]: targetContent,
          },
        },
      }));
    }
  }

  handleChangeMessage(event) {
    const getMessage = this.props.messages.filter((message) => {
      return message.id.toString() === event.target.value;
    });

    this.setState({
      selectMessage: event.target.value,
      selectMessageContent: getMessage[0]
        ? `<div>${getMessage[0].conteudo}</div>`
        : "<p></p>\n",
      isHtml: getMessage[0] ? getMessage[0].contem_html : false,
      messageTemplate: event.target.options[event.target.selectedIndex].text,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.divisions.divisoes && props.divisions.divisoes.length === 1) {
      if (
        Object.keys(props.currentAction).length &&
        !state.isEditing &&
        props.isOpen
      ) {
        return {
          isEditing: true,
          isOpen: props.isOpen,
          actionForm: {
            conteudo: { ...props.currentAction.conteudo },
            divisao_id: props.currentAction.divisao
              ? props.currentAction.divisao.id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao.id,
            tipo_acao: props.currentAction.tipo_acao.tipo,
            ativo: props.currentAction.ativo,
          },
        };
      } else {
        return {
          actionForm: {
            ...state.actionForm,
            divisao_id: props.divisions.divisoes
              ? props.divisions.divisoes[0].id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao
              ? props.currentAction.tipo_acao.id
              : props.idStep,
          },
        };
      }
    }

    if (
      Object.keys(props.currentAction).length &&
      !state.isEditing &&
      props.isOpen
    ) {
      return {
        isEditing: true,
        isOpen: props.isOpen,
        actionForm: {
          conteudo: { ...props.currentAction.conteudo },
          divisao_id: props.currentAction.divisao
            ? props.currentAction.divisao.id
            : 0,
          tipo_acao_id: props.currentAction.tipo_acao.id,
          tipo_acao: props.currentAction.tipo_acao.tipo,
          ativo: props.currentAction.ativo,
        },
      };
    }

    if (props.isOpen !== state.isOpen) {
      return {
        isEditing: false,
        isOpen: props.isOpen,
        selectMessage: 0,
        actionForm: {
          conteudo: {
            titulo: "",
            corpo: "",
          },
          divisao_id: 0,
          tipo_acao_id: props.idStep,
          tipo_acao: "enviar_email",
          ativo: true,
        },
      };
    }

    return null;
  }

  clearText(text) {
    const cleanText = text
      .replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return cleanText;
  }

  createAction() {
    let postBody = { ...this.state.actionForm };
    const { currentItem } = this.props;
    if (this.state.actionForm.divisao_id == 0) {
      delete postBody.divisao_id;
    }

    automaticActionDatalayer(
      "interaction",
      "enviar-mensagem:clicou:salvar",
      `divisao:${this.clearText(
        this.state.nomeDivisao
      )} - modelo-de-msg:${this.clearText(this.state.messageTemplate)}`,
      this.clearText(currentItem.nome)
    );

    this.props
      .post(`fases/${this.props.currentItem.id}/acoes`, { ...postBody })
      .then(
        () => {
          // Notificando o usuário do sucesso da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_CRIADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          automaticActionDatalayer(
            "nonInteraction",
            "sucesso:acao-automatica-criada",
            "mensagem",
            this.clearText(currentItem.nome)
          );

          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "error"
          );
        }
      );
  }

  updateAction() {
    let postBody = { ...this.state.actionForm };
    if (this.state.actionForm.divisao_id == 0) {
      postBody.divisao_id = "";
    }

    this.props
      .update(
        `fases/${this.props.currentItem.id}/acoes/${this.props.currentAction.id}`,
        { ...postBody }
      )
      .then(
        () => {
          // Notificando o usuário do sucesso da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_ATUALIZADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "error"
          );
        }
      );
  }

  closeModal() {
    const { currentItem, closeActionModal } = this.props;
    const { nomeDivisao, messageTemplate } = this.state;

    automaticActionDatalayer(
      "interaction",
      "enviar-mensagem:clicou:cancelar",
      `divisao:${this.clearText(nomeDivisao)} - modelo-de-msg:${this.clearText(
        messageTemplate
      )}`,
      this.clearText(currentItem.nome)
    );

    closeActionModal();
  }

  handleTitle() {
    const { actionForm } = this.state;
    !actionForm.conteudo.titulo.length
      ? this.setState({ errorTitle: "vg-empty-content-error" })
      : this.setState({ errorTitle: "" });
  }

  handleMessage() {
    const { actionForm } = this.state;
    actionForm.conteudo.corpo.length === 8
      ? this.setState({ errorMessage: "vg-empty-content-error" })
      : this.setState({ errorMessage: "" });
  }

  render() {
    const {
      selectMessageContent,
      isHtml,
      selectMessage,
      actionForm,
      isEditing,
      errorTitle,
      errorMessage,
    } = this.state;
    const { divisions, messages, currentItem, currentAction } = this.props;

    return (
      <div>
        <div className="vg-phases-modal-content">
          <h1>
            {isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_email.TITULO_ATUALIZAR"
                )
              : intl.get("FASES.MODAL_ACTION.TIPO_ACAO.enviar_email.TITULO")}
          </h1>
          <p>
            {intl.get("FASES.MODAL_ACTION.TIPO_ACAO.enviar_email.DESCRICAO")}{" "}
            <strong>{currentItem.nome}</strong>
          </p>
          <div className="vg-row  center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-selects-divisons-tests">
              <ActionDivisions
                divisions={divisions}
                actionForm={actionForm}
                handleActionForm={this.handleActionForm}
                isEditing={!!(isEditing && currentAction.divisao)}
              />
            </div>
            <div className="vg-col-md-10 vg-col-xs-12 vg-form">
              <label className="vg-label">
                <span className="vg-label-text">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_email.LABEL_TITULO"
                  )}
                </span>
                <input
                  type="text"
                  value={actionForm.conteudo.titulo}
                  onChange={(e) => this.handleActionForm(e, "titulo")}
                  onBlur={this.handleTitle}
                  className={`vg-input-text ${errorTitle}`}
                />
                <WarningTitle
                  error={errorTitle}
                  text={intl.get("FASES.MODAL_ACTION.ERRO_GENERICO.TITULO")}
                />
              </label>
            </div>
          </div>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12">
              <RichTextEditor
                changeContent={this.handleEditorChange}
                content={actionForm.conteudo.corpo}
                newContent={selectMessageContent}
                withVariables
                isHtml={isHtml}
                onBlur={this.handleMessage}
                classError={errorMessage}
              />
              <WarningTitle
                error={errorMessage}
                text={intl.get("FASES.MODAL_ACTION.ERRO_GENERICO.MENSAGEM")}
              />
            </div>
          </div>
          {messages.length > 0 && (
            <div className="vg-row center-xs">
              <div className="vg-col-md-10 vg-col-xs-12">
                <p>{intl.getHTML("HOME.CONTEUDO.ENVIAR_MENSAGEM.TEXTO3")}</p>

                <div className="vg-custom-select">
                  <select
                    value={selectMessage}
                    onChange={this.handleChangeMessage}
                    className="vg-select-messages"
                  >
                    <option value={0}>-</option>
                    {map(messages, (message) => {
                      return (
                        <option key={shortid.generate()} value={message.id}>
                          {message.identificacao}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="vg-wrap-buttons">
          <Link onClick={this.closeModal} className="vg-btn-link">
            {intl.get("FASES.MODAL.BOTAO_2")}
          </Link>
          <Button
            onClick={
              this.state.isEditing ? this.updateAction : this.createAction
            }
            disabled={
              actionForm.conteudo.titulo == "" ||
              actionForm.conteudo.corpo == "<p></p>\n" ||
              actionForm.conteudo.corpo == ""
            }
            className="vg-btn-success"
          >
            {this.state.isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_email.BOTAO_EDITAR"
                )
              : intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_email.BOTAO_SALVAR"
                )}
          </Button>
        </div>
      </div>
    );
  }
}

Messages.defaultProps = {
  messages: [],
  divisions: {
    sla: [],
  },
  currentAction: {},
};

Messages.propTypes = {
  receiveMessage: propTypes.func.isRequired,
  update: propTypes.func.isRequired,
  receiveActions: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  messages: propTypes.array.isRequired,
  closeActionModal: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  currentItem: propTypes.object.isRequired,
  showNotifications: propTypes.func.isRequired,
  divisions: propTypes.object.isRequired,
  currentAction: propTypes.object.isRequired,
  idStep: propTypes.number.isRequired,
  accesses: propTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    { receive, receiveMessage, post, receiveActions, update },
    dispatch
  )
)(Messages);
