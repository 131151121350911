// Action Types
const ORDERNATION_LIST = "services/ORDERNATION_LIST";
const FILTER_RATING_UPDATE = "services/FILTER_RATING_UPDATE";
const FILTER_PROCESS_UPDATE = "services/FILTER_PROCESS_UPDATE";
const FILTER_NAME_UPDATE = "services/FILTER_NAME_UPDATE";

// InitialState
const initialState = {
  order: true,
  ordination: [],
  filters: {
    rating: [],
    process: [],
    name: "",
  },
  field: "",
};

// Reducers
export default function VideoInterviewListOrderFilterProcessed(
  state = initialState,
  action = []
) {
  switch (action.type) {
    case ORDERNATION_LIST:
      return {
        ...state,
        order: !state.order,
        ordination: action.ordination,
        field: action.field,
      };
    case FILTER_RATING_UPDATE: {
      return {
        ...state,
        filters: {
          process: state.filters.process,
          name: state.filters.name,
          rating: action.filterRating,
        },
      };
    }
    case FILTER_PROCESS_UPDATE: {
      return {
        ...state,
        filters: {
          rating: state.filters.rating,
          name: state.filters.name,
          process: action.filterProcess,
        },
      };
    }
    case FILTER_NAME_UPDATE: {
      return {
        ...state,
        filters: {
          rating: state.filters.rating,
          process: state.filters.process,
          name: action.name,
        },
      };
    }
    default:
      return state;
  }
}

// Actions
export function loadOrdination(ordination, field) {
  return {
    type: ORDERNATION_LIST,
    ordination,
    field,
  };
}

export function actionUpdateFilterRating(filterRating) {
  return {
    type: FILTER_RATING_UPDATE,
    filterRating,
  };
}

export function actionUpdateFilterProcess(filterProcess) {
  return {
    type: FILTER_PROCESS_UPDATE,
    filterProcess,
  };
}

export function actionUpdateFilterName(name) {
  return {
    type: FILTER_NAME_UPDATE,
    name,
  };
}

function shouldFetch() {
  return true;
}

// Métodos responsáveis pelo dispatch das actions de requisição
export function receiveOrdination(ordination, field) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(loadOrdination(ordination, field));
    }
  };
}

export function updateFilterRating(filterRating) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(actionUpdateFilterRating(filterRating));
    }
  };
}

export function updateFilterProcess(filterProcess) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(actionUpdateFilterProcess(filterProcess));
    }
  };
}

export function updateFilterName(name) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(actionUpdateFilterName(name));
    }
  };
}
