import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../index";
import "./Modal.scss";

class ModalContent extends Component {
  render() {
    const {
      state,
      onClose,
      iconKey,
      contentLabel,
      children,
      small,
      className,
    } = this.props;

    return state !== "exited" ? (
      <div
        className={`vg-modal-wrapper vg-${state} ${
          !contentLabel && !iconKey && "vg-modal-wrapper-titleless"
        } ${className}`}
      >
        <div className="vg-modal-backdrop" onClick={onClose} />
        <div className={`vg-modal ${small && "vg-modal-small"}`}>
          <div className="vg-modal-header">
            {iconKey && <Icon iconKey={iconKey} />}
            <h1>{contentLabel}</h1>
            <button onClick={onClose}>
              <Icon iconKey="close" />
            </button>
          </div>
          <div className="vg-modal-body">{children}</div>
        </div>
      </div>
    ) : null;
  }
}

ModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  small: PropTypes.bool,
  iconKey: PropTypes.string,
  contentLabel: PropTypes.node,
  className: PropTypes.string,
  children: PropTypes.node,
};

ModalContent.defaultProps = {
  small: false,
};

export default ModalContent;
