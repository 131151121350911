import { BigPlayButton, Player } from "video-react";
import {
  Button,
  ErrorHandler,
  //GraphEmotions,
  Highlight,
  Icon,
  Link,
  StepsBullets,
  WordCloud,
} from "../../index";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import moment from "moment";
import { receiveMore } from "../../../store/modules/VideoInterviewListProcessed";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import shortid from "shortid";

const mapStateToProps = (state) => ({
  interviewList: state.VideoInterviewListProcessed.data,
  ordination: state.VideoInterviewListOrderFilterProcessed.ordination,
});

class VideoInternal extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      source: "",
      video: "",
      videoMask: "",
      activeMask: false,
      loaded: false,
      tags: [],
      nextPage: "",
      prevPage: "",
    };

    this.seekPlayer = this.seekPlayer.bind(this);
    // this.changeSource = this.changeSource.bind(this);
    this.getCurrentTime = this.getCurrentTime.bind(this);
    this.pagination = this.pagination.bind(this);
    this.nextVideo = this.nextVideo.bind(this);
    this.prevVideo = this.prevVideo.bind(this);
    this.loadMore = this.loadMore.bind(this);
    this.loadMoreNext = this.loadMoreNext.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;

    const param = location.search
      .replace("?", "")
      .split("&")
      .reduce((acc, item) => {
        const [key, value] = item.split("=");
        acc[key] = value;
        return acc;
      }, {});

    this.pagination(param.listagem_id);
  }

  pagination(id) {
    const { interviewList } = this.props;

    this.setState({
      nextPage: "",
      prevPage: "",
    });

    if (interviewList.participantes) {
      interviewList.participantes.forEach((item, index) => {
        if (item.id === parseInt(id)) {
          let nextItem = index + 1;
          let prevItem = index - 1;
          let itemMore = index + 2;

          this.nextVideo(interviewList.participantes[nextItem]);
          this.prevVideo(interviewList.participantes[prevItem]);
          this.loadMore(interviewList.participantes[itemMore], id);

          return;
        }
      });
    }
  }

  loadMoreNext(id) {
    const { interviewList } = this.props;

    if (interviewList.participantes) {
      interviewList.participantes.forEach((item, index) => {
        if (item.id === parseInt(id)) {
          let nextItem = index + 1;
          this.nextVideo(interviewList.participantes[nextItem]);

          return;
        }
      });
    }
  }

  loadMore(item, id) {
    const { interviewList } = this.props;

    if (!item && interviewList.total_paginas !== interviewList.pagina_atual) {
      const { ordination, interviewList, receiveMore } = this.props;
      const newPage = interviewList.pagina_atual + 1;
      if (ordination.length > 0) {
        ordination[3] = `pagina=${newPage}`;
        const moreItemFilter = receiveMore(
          `video-entrevistas/participantes?${ordination.join("&")}`,
        );
        moreItemFilter.then(() => this.loadMoreNext(id));
        return;
      }
      const moreItem = receiveMore(
        `video-entrevistas/participantes?processo_seletivo_id=${interviewList.processo_seletivo.id}&status[]=processado&limite=10&pagina=${newPage}&ordem=desc`,
      );
      moreItem.then(() => this.loadMoreNext(id));
    }
  }

  nextVideo(item) {
    if (item) {
      const { urlVisitor } = this.props;
      const nextVideoEntrevista = item.video_entrevista_id;
      const nextCandidatoId = item.candidato_id;
      const nextVagaId = item.processo_seletivo_id;
      const nextListagemId = item.id;
      const urlNext = `/video-entrevista/candidato?video_entrevista=${nextVideoEntrevista}&candidato_id=${nextCandidatoId}&vaga_id=${nextVagaId}&listagem_id=${nextListagemId}&urlVisitor=${urlVisitor}`;

      this.setState({
        nextPage: urlNext,
      });

      return;
    }
  }

  prevVideo(item) {
    if (item) {
      const { urlVisitor } = this.props;
      const prevVideoEntrevista = item.video_entrevista_id;
      const prevCandidatoId = item.candidato_id;
      const prevVagaId = item.processo_seletivo_id;
      const prevListagemId = item.id;
      const urlPrev = `/video-entrevista/candidato?video_entrevista=${prevVideoEntrevista}&candidato_id=${prevCandidatoId}&vaga_id=${prevVagaId}&listagem_id=${prevListagemId}&urlVisitor=${urlVisitor}`;

      this.setState({
        prevPage: urlPrev,
      });
    }
  }

  seekPlayer(e, time, anchor = true) {
    e && e.preventDefault();
    this.player.seek(time);
    this.player.play();
    anchor && window.scrollTo(0, 0);
  }

  // changeSource() {
  //   const actualTime = this.getCurrentTime();
  //   this.setState(
  //     prevState => ({
  //       source:
  //         this.state.source === this.state.video || this.state.source == ''
  //           ? this.state.videoMask
  //           : this.state.video,
  //       activeMask: !prevState.activeMask,
  //     }),
  //     () => {
  //       this.player.load();
  //       this.player.seek(actualTime);
  //       this.player.play();
  //     }
  //   );
  // }

  getCurrentTime() {
    const { player } = this.player.getState();
    const currentTime = player.currentTime;

    return currentTime;
  }

  static getDerivedStateFromProps(props, state) {
    if (props.analysisVideo.loaded) {
      return {
        video: props.analysisVideo.data.url_video_original,
        tags: props.analysisVideo.data.nuvem_palavras,
        videoMask: props.analysisVideo.data.url_video_processado,
        source: state.activeMask
          ? props.analysisVideo.data.url_video_processado
          : props.analysisVideo.data.url_video_original,
      };
    }

    return null;
  }

  render() {
    const { analysisContent, analysisVideo, changeQuestion, currentQuestion } =
      this.props;
    const { tags, nextPage, prevPage } = this.state;

    return analysisVideo.loaded ? (
      <div className="vg-video-content">
        {prevPage !== "" && (
          <Button
            className="vg-btn-pagination-internal vg-btn-interview-left"
            to={prevPage}
          >
            <Icon iconKey="arrow" />
          </Button>
        )}
        {nextPage !== "" && (
          <Button
            className="vg-btn-pagination-internal vg-btn-interview-right"
            to={nextPage}
          >
            <Icon iconKey="arrow" />
          </Button>
        )}

        <div className="vg-video-player">
          {/* Reconhecimento facial paralizado por tempo indeterminado, não justificando a remoção por enquanto
          <Button
            onClick={this.changeSource}
            className={`vg-toggle-mask ${this.state.activeMask && 'vg-active'}`}
          >
            <Icon iconKey="toggleMask" />
          </Button>
          <GraphEmotions
            serie={analysisVideo.data.grafico_expressoes.expressoes}
            handleClick={this.seekPlayer}
          /> */}
          <Player
            ref={(c) => {
              this.player = c;
            }}
            fluid={false}
            class="video-js"
          >
            <source
              src={this.state.source ? this.state.source : this.state.video}
            />
            <BigPlayButton position="center" />
            {/* <ProgressControl /> */}
          </Player>
        </div>
        <div className="vg-video-controllers">
          <button
            className="vg-button-controller vg-button-prev"
            onClick={() => changeQuestion("prev")}
            disabled={currentQuestion + 1 === 1}
          >
            {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.ANTERIOR")}
          </button>
          <StepsBullets
            actual={currentQuestion + 1}
            total={analysisContent.video_entrevista.perguntas.length}
            theme="orange"
            changeQuestion={changeQuestion}
          />
          <button
            className="vg-button-controller vg-button-next"
            onClick={() => changeQuestion()}
            disabled={
              currentQuestion + 1 ===
              analysisContent.video_entrevista.perguntas.length
            }
          >
            {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.PROXIMA")}
          </button>
        </div>
        <div className="vg-video-details">
          <em>
            {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.GRAVADO")}{" "}
            {moment(
              analysisContent.participante.respostas[currentQuestion]
                .data_resposta,
            ).fromNow()}
          </em>
          <h1>
            {
              analysisContent.video_entrevista.perguntas[currentQuestion]
                .descricao
            }
          </h1>
          <h4>{intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.TERMOS")}</h4>
          <p>{intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.TERMOS_TEXTO")}</p>
          <ul className="vg-tags">
            {map(
              analysisContent.video_entrevista.perguntas[currentQuestion]
                .termos_relevantes,
              (item) => (
                <li key={shortid.generate()}>
                  <span>{item}</span>
                </li>
              ),
            )}
          </ul>
          <h4>{intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.NUVEM")}</h4>
          <WordCloud tags={tags} />
          <h4>{intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.TRANSCRICAO")}</h4>
          <div className="vg-transcript">
            {map(analysisVideo.data.transcricao.sentencas, (item) => {
              return (
                <Link
                  href="#"
                  onClick={(e) => this.seekPlayer(e, item.inicio_seg)}
                  key={shortid.generate()}
                >
                  <Highlight match={item.termos}>{item.sentenca}</Highlight>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    ) : analysisVideo.error && !analysisVideo.loaded ? (
      <div>
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
        <div className="vg-container">
          <ErrorHandler
            text={intl.get("GRAPHS.ERROR_MESSAGE")}
            error={analysisVideo.error}
            retryHandler={this.requestContent}
          />
        </div>
      </div>
    ) : (
      <div className="vg-video-content">
        <div className="animation-loader">
          <Icon iconKey="videoPlaceholder" />
        </div>
        <div className="animation-loader">
          <Icon iconKey="textPlaceholder" />
        </div>
        <div className="animation-loader">
          <Icon iconKey="textPlaceholder" />
        </div>
      </div>
    );
  }
}

VideoInternal.defaultProps = {
  loaded: false,
};

VideoInternal.propTypes = {
  analysisContent: propTypes.object.isRequired,
  analysisVideo: propTypes.object.isRequired,
  changeQuestion: propTypes.func.isRequired,
  currentQuestion: propTypes.number.isRequired,
  location: propTypes.object,
  interviewList: propTypes.object.isRequired,
  ordination: propTypes.array,
  receiveMore: propTypes.func,
  urlVisitor: propTypes.bool,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ receiveMore }, dispatch),
  )(VideoInternal),
);
