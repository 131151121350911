import React, { useEffect } from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import shortid from "shortid";
import delve from "dlv";

import { intlReplace } from "../../../utils/IntlReplace";
import { ErrorHandler, Icon, Link } from "../../index";
import { receive } from "../../../store/modules/Painel";

import "./FastLink.scss";

const FastLink = ({ fastLinks, type, receive, path, admissaoDigital }) => {
  const nonMoveTo = {
    "buscar-profissionais": "/profissionais",
    "acompanhar-vagas": "/vagas",
    "ver-requisicoes-de-vagas": "/vagas/requisicao-de-vagas",
  };

  const fetchFastLinks = () => {
    receive(type, path);
  };

  useEffect(() => {
    fetchFastLinks();
    // eslint-disable-next-line
  }, []);

  if (fastLinks[type].loading) {
    return (
      <ul className="vg-fast-links vg-fast-placeholder">
        <li>
          <Icon iconKey="fastLinksLoader" />
        </li>
        <li>
          <Icon iconKey="fastLinksLoader" />
        </li>
        <li>
          <Icon iconKey="fastLinksLoader" />
        </li>
      </ul>
    );
  }

  if (fastLinks[type].loaded) {
    return (
      <ul className="vg-fast-links">
        {fastLinks[type].data.acessos.links_rapidos &&
          fastLinks[type].data.acessos.links_rapidos.map((fastLink) => {
            // eslint-disable-next-line no-prototype-builtins
            const noMoveToLinks = nonMoveTo.hasOwnProperty(fastLink);

            if (fastLink === "abrir-processo-seletivo") {
              return (
                <li key={`vg-fast-link-${shortid.generate()}`}>
                  <Link href="profissionais-recomendados/introducao-assistente">
                    <Icon iconKey={fastLink} />
                    {intl.get(`INTRO.LINKS_RAPIDOS.${intlReplace(fastLink)}`)}
                  </Link>
                </li>
              );
            }

            {
              /* Criando regra para redirecionar Cliente TIM para outro link */
            }
            if (
              fastLink === "ver-requisicoes-de-vagas" &&
              delve(fastLinks, "painel.data.cliente.id", "") === 5511
            ) {
              return (
                <li key={`vg-fast-link-${shortid.generate()}`}>
                  <Link
                    moveTo="ver-requisicoes-de-vagas"
                    data-testid="link-requisicoes"
                  >
                    <Icon iconKey={fastLink} />
                    {intl.get(`INTRO.LINKS_RAPIDOS.${intlReplace(fastLink)}`)}
                  </Link>
                </li>
              );
            }

            if (noMoveToLinks) {
              return (
                <li key={`vg-fast-link-${shortid.generate()}`}>
                  <Link
                    to={nonMoveTo[fastLink]}
                    data-testid={`link-${fastLink}`}
                  >
                    <Icon iconKey={fastLink} />
                    {intl.get(`INTRO.LINKS_RAPIDOS.${intlReplace(fastLink)}`)}
                  </Link>
                </li>
              );
            }

            return (
              <li key={`vg-fast-link-${shortid.generate()}`}>
                <Link moveTo={fastLink} data-testid={`link-${fastLink}`}>
                  <Icon iconKey={fastLink} />
                  {intl.get(`INTRO.LINKS_RAPIDOS.${intlReplace(fastLink)}`)}
                </Link>
              </li>
            );
          })}
        {admissaoDigital && (
          <li key={`vg-fast-link-${shortid.generate()}`}>
            <Link
              href="/marketplace/admissao-digital"
              target="_self"
              data-testid="link-acompanhar-admissao"
            >
              <Icon iconKey={"briefcase"} aria-hidden />
              {intl.get("INTRO.LINKS_RAPIDOS.ADMISSAO_DIGITAL")}
            </Link>
          </li>
        )}
      </ul>
    );
  }

  if (fastLinks[type].error) {
    return (
      <ErrorHandler
        text={intl.get("INTRO.LINKS_RAPIDOS.ERRO")}
        retryHandler={fetchFastLinks}
      />
    );
  }

  return null;
};

FastLink.propTypes = {
  fastLinks: propTypes.object.isRequired,
  path: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  receive: propTypes.func.isRequired,
  admissaoDigital: propTypes.bool,
};

const mapStateToProps = (state) => ({
  fastLinks: state.Painel,
});

export default connect(mapStateToProps, { receive })(FastLink);
