/* eslint-disable */
import "./BoxesProfessionals.scss";

import { BoxesProfessionalItem, CaptacaoAberta } from "../../index";
import React, { Component } from "react";

import { map } from "lodash";
import propTypes from "prop-types";
import shortid from "shortid";

class BoxesProfessionals extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { boxes } = this.props;

    return (
      <div className="vg-profissionais">
        <div className="vg-row">
          {map(boxes, (item, index) => {
            if (index === "pesquisa_bce" || index === "captacao_aberta") {
              return null;
            }
            return (
              <BoxesProfessionalItem
                group={index}
                content={item}
                key={shortid.generate()}
              />
            );
          })}
        </div>
        {boxes.hasOwnProperty("captacao_aberta") && (
          <CaptacaoAberta
            link={boxes["captacao_aberta"]}
            path="captacao-aberta"
          />
        )}
      </div>
    );
  }
}

BoxesProfessionals.propTypes = {
  boxes: propTypes.object.isRequired,
};

export default BoxesProfessionals;

BoxesProfessionals.propTypes = {
  boxes: propTypes.object.isRequired,
};
