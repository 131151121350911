/* eslint-disable */

const gtm = () => {
  ;(function (w, d, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" })
    var j = d.createElement(s)
    var dl = l != "dataLayer" ? "&l=" + l : ""
    j.async = true
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl
    d.body.appendChild(j)
  })(window, document, "script", "dataLayer", process.env.REACT_APP_GTM_ID)
}

export default gtm
