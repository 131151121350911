import { listToParams } from "../../utils/QueryFunctions";
import { omit, orderBy, capitalize } from "lodash";

const TOGGLEFILTER = "request/TOGGLEFILTER";
const SEARCH = "request/SEARCH";
const SEARCH_SUCCESS = "request/SEARCH_SUCCESS";
const SEARCH_FAIL = "request/SEARCH_FAIL";
const SEARCH_PAGINATE = "request/SEARCH_PAGINATE";
const SEARCH_PAGINATE_SUCCESS = "request/SEARCH_PAGINATE_SUCCESS";
const SEARCH_PAGINATE_FAIL = "request/SEARCH_PAGINATE_FAIL";
const PAGINATION = "request/PAGINATION";
const UPDATEFILTER = "request/UPDATEFILTER";
const UPDATELIST = "request/UPDATELIST";
const LOAD_FACETS = "request/LOAD_FACETS";
const LOAD_FACETS_SUCCESS = "request/LOAD_FACETS_SUCCESS";
const LOAD_FACETS_FAIL = "request/LOAD_FACETS_FAIL";
const SEARCH_FACETS = "request/SEARCH_FACETS";
const SEARCH_FACETS_SUCCESS = "request/SEARCH_FACETS_SUCCESS";
const SEARCH_FACETS_FAIL = "request/SEARCH_FACETS_FAIL";
const CLEAR_FILTER = "request/CLEAR_FILTER";
const UNCHECK_ALL = "request/UNCHECK_ALL";
const UNCHECK = "request/UNCHECK";
const INPUT_HANDLER = "request/INPUT_HANDLER";
const CLEAR_INPUT = "request/CLEAR_INPUT";

const initialState = {
  activedFilters: [],
  filter: {},
  inputs: {},
  results: {
    loaded: false,
    loading: false,
    paginating: false,
    data: {
      filtros_salvos: {
        filtros: {},
      },
    },
    error: false,
  },
  facets: {
    loaded: false,
    loading: true,
    data: {},
    error: false,
    value: {},
  },
  count: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLEFILTER:
      return {
        ...state,
        activedFilters: addOrRemove(state.activedFilters, action.data),
      };
    case UPDATEFILTER:
      return {
        ...state,
        filter: action.filter
          ? omit(action.filter, "pagina")
          : { ...state.filter },
      };
    case UPDATELIST:
      return {
        ...state,
        results: {
          ...state.results,
          data: {
            ...state.results.data,
            processos_seletivos: [...action.data],
          },
        },
      };
    case PAGINATION:
      return {
        ...state,
        count: parseInt(state.count) + 1,
      };
    case SEARCH:
      return {
        ...state,
        results: {
          ...state.results,
          loading: true,
          loaded: false,
          data: {
            ...state.results.data,
          },
          error: false,
        },
        count: 1,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          loading: false,
          loaded: true,
          data: action.result,
          error: false,
        },
        count: 1,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        results: {
          ...state.results,
          loading: false,
          loaded: false,
          error: action.error.status ? action.error.status : 503,
        },
        count: 1,
      };
    case SEARCH_PAGINATE:
      return {
        ...state,
        results: {
          ...state.results,
          paginating: true,
          data: {
            ...state.results.data,
          },
          error: false,
        },
      };
    case SEARCH_PAGINATE_SUCCESS:
      return {
        ...state,
        results: {
          ...state.results,
          paginating: false,
          data: {
            ...state.results.data,
            pagina_atual: action.result.pagina_atual,
            requisicoes: [
              ...state.results.data.requisicoes,
              ...action.result.requisicoes,
            ],
          },
          error: false,
        },
      };
    case SEARCH_PAGINATE_FAIL:
      return {
        ...state,
        results: {
          ...state.results,
          paginating: false,
          error: action.error.status ? action.error.status : 503,
        },
      };
    case LOAD_FACETS:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: true,
          loaded: false,
          data: {
            ...state.facets.data,
          },
          error: false,
        },
      };
    case LOAD_FACETS_SUCCESS:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: false,
          loaded: true,
          data: {
            ...state.facets.data,
            [action.requestType]: mergeValues(
              state.facets.data[action.requestType],
              action.result,
              action.requestType
            ),
          },
          error: false,
        },
      };
    case LOAD_FACETS_FAIL:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: false,
          loaded: false,
          data: {
            ...state.facets.data,
          },
          error: action.error.status ? action.error.status : 503,
        },
      };
    case CLEAR_FILTER:
      return {
        ...state,
        count: 1,
        filter: {},
      };
    case UNCHECK_ALL:
      return {
        ...state,
        filter: omit(state.filter, action.group),
      };
    case UNCHECK:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.group]: state.filter[action.group].filter(
            (el) => el !== action.item
          ),
        },
      };
    case SEARCH_FACETS:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: true,
          loaded: false,
          data: {
            ...state.facets.data,
          },
          error: false,
        },
      };
    case SEARCH_FACETS_SUCCESS:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: false,
          loaded: true,
          data: {
            ...state.facets.data,
            [action.requestType]: mergeValues(
              state.facets.data[action.requestType],
              action.result,
              action.requestType
            ),
          },
          value: {
            ...state.facets.value,
            [action.requestType]: action.value,
          },
          error: false,
        },
      };
    case SEARCH_FACETS_FAIL:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: false,
          loaded: false,
          data: {
            ...state.facets.data,
          },
          error: action.error.status ? action.error.status : 503,
        },
      };
    case INPUT_HANDLER:
      return {
        ...state,
        inputs: {
          ...state.inputs,
          [action.inputType]: action.q,
        },
      };
    case CLEAR_INPUT:
      return {
        ...state,
        inputs: {},
      };
    default:
      return state;
  }
};

// Actions
const addOrRemove = (prevState, value) =>
  prevState.includes(value)
    ? prevState.filter((item) => item !== value)
    : [value, ...prevState];

const orderList = (arr, by, type) => {
  return orderBy(
    arr,
    (o) => {
      return o[by].normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    },
    type
  );
};

const mergeValues = (oldObject = {}, newObject, type) => {
  if (type !== "status") {
    const oldArray = oldObject[type] ? oldObject[type] : [];
    return Object.entries(newObject).reduce((state, [key, col]) => {
      if (Array.isArray(col)) {
        let hash = new Map();
        oldArray.concat(col).forEach((obj) => {
          hash.set(
            obj.id,
            Object.assign(hash.get(obj.id) || {}, {
              id: obj.id,
              nome: capitalize(obj.nome),
            })
          );
        });

        state[key] = orderList(Array.from(hash.values()), "nome", "asc");
      } else {
        state[key] = col;
      }
      return state;
    }, {});
  } else {
    return newObject;
  }
};

export function parseFilter(data) {
  return (dispatch) => {
    dispatch(updateFilter({ ...data }));
    return dispatch(search(`requisicoes?${listToParams({ ...data })}`));
  };
}

export function search(path, paginate = false) {
  return !paginate
    ? {
        types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
        promise: (client) => client.get(path),
      }
    : {
        types: [SEARCH_PAGINATE, SEARCH_PAGINATE_SUCCESS, SEARCH_PAGINATE_FAIL],
        promise: (client) => client.get(path),
      };
}

export const updateFilter = (filter) => {
  return { type: UPDATEFILTER, filter };
};

export const toggleFilter = (data) => ({ type: TOGGLEFILTER, data });

export function pagination() {
  return (dispatch, getState) => {
    dispatch(increasePagination());

    return dispatch(
      search(
        `requisicoes?pagina=${getState().RequestFilter.count}&${listToParams({
          ...getState().RequestFilter.filter,
        })}`,
        true
      )
    );
  };
}

export const increasePagination = () => ({ type: PAGINATION });

export const loadFacets = (path, type, options) => {
  return {
    types: [LOAD_FACETS, LOAD_FACETS_SUCCESS, LOAD_FACETS_FAIL],
    promise: (client) => client.get(path, { params: options }),
    requestType: type,
  };
};

export function clearInitialValues() {
  return {
    type: CLEAR_FILTER,
  };
}

export function load(path) {
  return {
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
    promise: (client) =>
      client.get(path, { params: { config: { sem_filtro: true } } }),
  };
}

export function storeValues(values) {
  return (dispatch) => {
    const reduceFilter = Object.entries(values).reduce((state, [key, col]) => {
      if (col.length) {
        state[key] = col;
      }
      return state;
    }, {});

    dispatch(parseFilter(reduceFilter));
  };
}

export function uncheckAll(group) {
  return {
    type: UNCHECK_ALL,
    group,
  };
}

export function uncheck(group, item) {
  return {
    type: UNCHECK,
    item,
    group,
  };
}

export function inputHandler(type, q) {
  return {
    type: INPUT_HANDLER,
    inputType: type,
    q,
  };
}

export function clearInput(type) {
  return {
    type: INPUT_HANDLER,
    inputType: type,
    q: "",
  };
}

export function clearInputs() {
  return {
    type: CLEAR_INPUT,
  };
}

export function searchFacets(path, value, type, params) {
  return {
    types: [SEARCH_FACETS, SEARCH_FACETS_SUCCESS, SEARCH_FACETS_FAIL],
    promise: (client) => client.get(path, { params }),
    requestType: type,
    value: value,
  };
}

export default reducer;
