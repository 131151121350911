import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { Icon } from "../../../index";
import Modal from "./Modal";

const DeleteModal = ({ cancelChange, deleteTerms, isOpen, setIsOpen }) => {
  return (
    <Modal showModal={isOpen} setShowModal={setIsOpen}>
      <div className="vg-modal-termo-aceite delete-modal">
        <div className="modal-trash-icon">
          <Icon iconKey="trashAlt" addClass="trash-icon" />
        </div>
        <div className="delete-termo">
          <p className="delete-title">
            {intl.get(
              "CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.DELETE_TERMO_TITULO"
            )}
          </p>
          <p className="delete-information">
            {intl.get(
              "CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.DELETE_TERMO_INFORMACAO"
            )}
          </p>
        </div>
        <div className="buttons-configuration-termo-aceite">
          <button
            onClick={cancelChange}
            className="vg-input-button-cancel modal-cancel delete-cancel"
          >
            {intl.get("CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.CANCELAR")}
          </button>
          <button
            onClick={deleteTerms}
            className="vg-input-button-continue delete-continue"
          >
            {intl.get("CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.EXCLUIR")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

DeleteModal.propTypes = {
  cancelChange: PropTypes.func.isRequired,
  deleteTerms: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

export default DeleteModal;
