// Action Types
const LOAD_COUNTING = "domain/LOAD_COUNTING";
const LOAD_COUNTING_SUCCESS = "domain/LOAD_COUNTING_SUCCESS";
const LOAD_COUNTING_FAIL = "domain/LOAD_COUNTING_FAIL";

// InitialState
const initialState = {
  loaded: false,
};

// Reducer
export default function counting(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_COUNTING:
      return {
        ...state,
        loading: true,
      };
    case LOAD_COUNTING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_COUNTING_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_COUNTING, LOAD_COUNTING_SUCCESS, LOAD_COUNTING_FAIL],
    promise: (client) =>
      client.get("sla/modelo_contagem", {}, process.env.REACT_APP_DOMINIOS_URL),
  };
}

function shouldFetch(state) {
  return state.Counting.loading
    ? false
    : state.Counting.data !== undefined
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
