import React from "react";
import intl from "react-intl-universal";
import { Icon } from "../../index";
import Cookie from "js-cookie";
import { toast } from "react-toastify";

export const updateAcceptanceTerm = (payload, url) =>
  fetch(`${process.env.REACT_APP_ATHENA_URL}${url}`, {
    method: "POST",
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      Authorization: `Bearer ${Cookie.get("access_token")}`,
      "Content-Type": "application/json",
      "Accept-Language": "pt-BR",
      "Access-Control-Allow-Origin": window.location.origin,
    },
  });

export const deleteAllTerms = () =>
  fetch(`${process.env.REACT_APP_ATHENA_URL}termo_aceite/disable_terms`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${Cookie.get("access_token")}`,
      "Content-Type": "application/json",
      "Accept-Language": "pt-BR",
      "Access-Control-Allow-Origin": window.location.origin,
    },
  });

export const listAcceptanceTerms = (url) =>
  fetch(`${process.env.REACT_APP_ATHENA_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${Cookie.get("access_token")}`,
      "Content-Type": "application/json",
      "Accept-Language": "pt-BR",
      "Access-Control-Allow-Origin": window.location.origin,
    },
  });

export const showNotifications = (type) => {
  const ToastType = type === "delete" ? "success" : type;
  const capitalizeType = ToastType.charAt(0).toUpperCase() + ToastType.slice(1);
  const title = intl.get(
    `CONFIGURACOES.TERMO_ACEITE.URL.TITLE_${type.toUpperCase()}`,
  );
  const paragraph = intl.get(
    `CONFIGURACOES.TERMO_ACEITE.URL.PARAGRAPH_${type.toUpperCase()}`,
  );

  const Msg = () => (
    <div className="vg-toast">
      <div className="vg-toast-icon">
        <Icon iconKey={`toast${capitalizeType}`} />
      </div>
      <div className="vg-toast-content" style={{ fontSize: "14px" }}>
        <h1>{title}</h1>
        <p>{paragraph}</p>
      </div>
    </div>
  );

  toast(Msg, {
    position: "bottom-right",
    autoClose: 4000,
    closeOnClick: true,
    pauseOnHover: true,
    className: `sr-toast vg-toast-${ToastType}`,
  });
};
