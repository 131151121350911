// Action Types
const LOAD_EMPLOYEES = "services/LOAD_EMPLOYEES";
const LOAD_EMPLOYEES_SUCCESS = "services/LOAD_EMPLOYEES_SUCCESS";
const LOAD_EMPLOYEES_FAIL = "services/LOAD_EMPLOYEES_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: { funcionarios: {} },
};

// Reducer
export default function employees(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_EMPLOYEES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_EMPLOYEES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_EMPLOYEES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Faz a requisição de divisões
export function load(path, params) {
  return {
    types: [LOAD_EMPLOYEES, LOAD_EMPLOYEES_SUCCESS, LOAD_EMPLOYEES_FAIL],
    promise: (client) => client.get(path, params),
  };
}

// Verifica se é necessário fazer a requisição
function shouldFetch(state) {
  return state.Employees.loading
    ? false
    : Object.keys(state.Employees.data.funcionarios).length
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(path, params) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path, params));
    }
  };
}
