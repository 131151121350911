import "./Actions.scss";

import { EditPhasesActionList, ErrorHandler, Icon } from "../../index";
import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/Actions";

const mapStateToProps = (state) => ({
  actions: state.Actions.getActions,
});

export class EditPhasesActions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.loadActions = this.loadActions.bind(this);
  }

  // Método para carregar a lista de fases
  loadActions() {
    const { receive, item } = this.props;

    receive(item.id);
  }

  componentDidMount() {
    // Carrega as ações
    this.loadActions();
  }

  render() {
    const { item, toggleModal, actions, toggleModalPhase } = this.props;
    const currentAction = actions[item.id];

    if (currentAction) {
      return currentAction.loading && !currentAction.loaded ? (
        <ul className="vg-phase-actions">
          <li className="vg-phase-placeholder animation-loader">
            <Icon iconKey="actionLoader" />
          </li>
        </ul>
      ) : currentAction.error && !currentAction.loaded ? (
        <ErrorHandler
          text={intl.get("GRAPHS.ERROR_MESSAGE")}
          error={currentAction.error}
          retryHandler={this.loadPhases}
          item={item}
        />
      ) : (
        <EditPhasesActionList
          actions={currentAction.data}
          item={item}
          toggleModal={toggleModal}
          toggleModalPhase={toggleModalPhase}
        />
      );
    }

    return null;
  }
}

EditPhasesActions.defaultProps = {
  actions: {},
};

EditPhasesActions.propTypes = {
  actions: propTypes.object.isRequired,
  item: propTypes.object.isRequired,
  receive: propTypes.func.isRequired,
  toggleModal: propTypes.func.isRequired,
  toggleModalPhase: propTypes.func.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(EditPhasesActions);
