export function intlReplace(string, options = { toUpper: true }) {
  if (string !== undefined && string !== null) {
    const newString = string.replace(/[.-]/g, "_");
    if (newString) {
      return options.toUpper ? newString.toUpperCase() : newString;
    }
  }

  return string;
}
