// Action Types
const LOAD_VACANCIESSTATUS = "domain/LOAD_VACANCIESSTATUS";
const LOAD_VACANCIESSTATUS_SUCCESS = "domain/LOAD_VACANCIESSTATUS_SUCCESS";
const LOAD_VACANCIESSTATUS_FAIL = "domain/LOAD_VACANCIESSTATUS_FAIL";

const initialState = {
  loading: false,
  loaded: false,
  error: {},
};

// Reducer
export default function vacanciesStatus(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_VACANCIESSTATUS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VACANCIESSTATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_VACANCIESSTATUS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [
      LOAD_VACANCIESSTATUS,
      LOAD_VACANCIESSTATUS_SUCCESS,
      LOAD_VACANCIESSTATUS_FAIL,
    ],
    promise: (client) =>
      client.get(path, {}, process.env.REACT_APP_DOMINIOS_URL),
  };
}

function shouldFetch(state) {
  return !(state.VacanciesStatus.loading || state.VacanciesStatus.data);
}

export function receive(path) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path));
    }
  };
}
