// Action Types
const LOAD_PROFESSIONALS = "services/LOAD_PROFESSIONALS";
const LOAD_PROFESSIONALS_SUCCESS = "services/LOAD_PROFESSIONALS_SUCCESS";
const LOAD_PROFESSIONALS_FAIL = "services/LOAD_PROFESSIONALS_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: { acessos: {} },
};

// Reducer
export default function panel(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PROFESSIONALS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_PROFESSIONALS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_PROFESSIONALS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [
      LOAD_PROFESSIONALS,
      LOAD_PROFESSIONALS_SUCCESS,
      LOAD_PROFESSIONALS_FAIL,
    ],
    promise: (client) => client.get("painel/profissionais"),
  };
}

function shouldFetch(state) {
  return state.Professionals.loading
    ? false
    : Object.keys(state.Professionals.data.acessos).length
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
