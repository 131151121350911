import "./SearchSLA.scss";

import { Field } from "react-final-form";
import { Icon } from "../../index";
import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import { toggleFilter } from "../../../store/modules/VacanciesSearch";

const mapStateToProps = (state) => ({
  activedFilters: state.VacanciesSearch.activedFilters,
});

const SearchSLA = ({ content, inside, toggleFilter, activedFilters }) => (
  <div
    className={`vg-filter ${
      activedFilters.includes("sla") && "vg-filter-open"
    } ${inside && "vg-filter-more"}`}
  >
    <button className="vg-filter-title" onClick={() => toggleFilter("sla")}>
      {intl.get("VAGAS.BUSCA.SLA.TITULO")}
      <Icon iconKey="arrow" />
    </button>
    <div className="vg-menu-filter vg-sub-menu-filter">
      <div className="vg-custom-radio">
        <label className="vg-label">
          <Field name="sla" component="input" type="radio" value={""} />
          <span className="vg-label-text">
            {intl.get("VAGAS.BUSCA.SLA.TODAS_FASES")}
          </span>
        </label>
        {map(content, (sla) => {
          const removeSpecialChars = sla.desc.replace(/[^\w\s]/gi, "");
          return (
            <label className="vg-label" key={removeSpecialChars}>
              <Field
                name="sla"
                component="input"
                type="radio"
                value={sla.key}
                id={sla.key}
              />
              <span className="vg-label-text">
                {intl.get(`VAGAS.BUSCA.SLA.${removeSpecialChars}`)}
              </span>
            </label>
          );
        })}
      </div>
    </div>
  </div>
);

SearchSLA.propTypes = {
  content: propTypes.array.isRequired,
  inside: propTypes.bool,
  toggleFilter: propTypes.func.isRequired,
  activedFilters: propTypes.array.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleFilter }, dispatch),
)(SearchSLA);
