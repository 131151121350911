export function prepareURl(service, params = {}) {
  //eslint-disable-next-line
  const location = window.location.pathname.split(/^./)[1];

  const paramsUrl =
    (location.length ? "&" : "?") +
    Object.keys(params)
      .map(function (prop) {
        return [prop, params[prop]].map(encodeURIComponent).join("=");
      })
      .join("&");

  return `/move-to/${service}${
    location.length
      ? (service.indexOf("?") > 0 ? "&" : "?") + "url_params=" + location
      : ""
  }${Object.keys(params).length === 0 ? "" : paramsUrl}`;
}
