// Action Types
const LOAD_USERSREDES = "services/LOAD_USERSREDES";
const LOAD_USERSREDES_SUCCESS = "services/LOAD_USERSREDES_SUCCESS";
const LOAD_USERSREDES_FAIL = "services/LOAD_USERSREDES_FAIL";
const DEL_USERSREDES = "services/DEL_USERSREDES";
const DEL_USERSREDES_SUCCESS = "services/DEL_USERSREDES_SUCCESS";
const DEL_USERSREDES_FAIL = "services/DEL_USERSREDES_FAIL";
const POST_USERSREDES = "services/POST_USERSREDES";
const POST_USERSREDES_SUCCESS = "services/POST_USERSREDES_SUCCESS";
const POST_USERSREDES_FAIL = "services/POST_USERSREDES_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: [],
};

// Reducer
export default function panel(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_USERSREDES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_USERSREDES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_USERSREDES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case DEL_USERSREDES:
      return {
        ...state,
        loading: true,
      };
    case DEL_USERSREDES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data.map((unidade, index) => {
          if (index === action.indexSelected) {
            return {
              ...unidade,
              funcionarios: unidade.funcionarios.filter(
                (funcionario) => funcionario.id !== action.id
              ),
            };
          }
          return unidade;
        }),
      };
    case DEL_USERSREDES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case POST_USERSREDES:
      return {
        ...state,
        loading: true,
      };
    case POST_USERSREDES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: state.data.map((unidade) => {
          if (unidade.id === action.result.cliente_id) {
            unidade.funcionarios.push(action.result);
          }
          return unidade;
        }),
      };
    case POST_USERSREDES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_USERSREDES, LOAD_USERSREDES_SUCCESS, LOAD_USERSREDES_FAIL],
    promise: (client) => client.get("funcionarios/das_unidades"),
  };
}

export function deleteUser(data, index) {
  return {
    types: [DEL_USERSREDES, DEL_USERSREDES_SUCCESS, DEL_USERSREDES_FAIL],
    promise: (client) => client.del("funcionarios/" + data),
    id: data,
    indexSelected: index,
  };
}

export function addUser(data) {
  return {
    types: [POST_USERSREDES, POST_USERSREDES_SUCCESS, POST_USERSREDES_FAIL],
    promise: (client) => client.post("funcionarios", { data: data }),
  };
}

function shouldFetch(state) {
  return state.UsersRedes.loading
    ? false
    : Object.keys(state.UsersRedes.data).length
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
