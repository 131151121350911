import React, { Component } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { VideoInterviewCommentsListItem } from "../../index";
import "./VideoInterviewCommentsList.scss";

class VideoInterviewCommentsList extends Component {
  listRef = React.createRef();

  scrollToBottom = () => {
    if (this.listRef) {
      this.listRef.scrollTop = this.listRef.scrollHeight;
    }
  };

  shouldComponentUpdate = (nextProps) => {
    if (this.props.items !== nextProps.items) {
      return true;
    }
    return false;
  };

  componentDidUpdate = () => {
    this.scrollToBottom();
  };

  render() {
    const { items } = this.props;
    return (
      <div className="vg-video-interview-comments-list">
        <div className="vg-title">
          <h4>
            {intl.get(
              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.TITULO_LISTA_COMENTARIOS"
            )}
          </h4>
        </div>
        <div
          ref={(el) => {
            this.listRef = el;
          }}
          className="vg-list-container"
        >
          {items.map((item) => (
            <VideoInterviewCommentsListItem key={item.id} item={item} />
          ))}
        </div>
      </div>
    );
  }
}

VideoInterviewCommentsList.propTypes = {
  items: propTypes.array.isRequired,
};

export default VideoInterviewCommentsList;
