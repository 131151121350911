import { Icon, Link, Well } from "../../index";
import React, { Component } from "react";

import moment from "moment";
import intl from "react-intl-universal";
import propTypes from "prop-types";

export default class VideoInterviewLandpage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rendered: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    // Verifica se o usuário já respondeu a vídeo-entrevista e redireciona para a tela correta
    if (
      props.interviewContent.video_entrevista.todos_videos_gravados &&
      !state.rendered
    ) {
      props.history.push("/candidato/video-entrevista/realizada");
      return { rendered: true };
    }

    if (props.currentAnswered != 0 && !state.rendered) {
      props.history.push("/candidato/video-entrevista/questao");
      return { rendered: true };
    }

    return null;
  }

  render() {
    const { interviewContent } = this.props;
    return (
      <div className="vg-container vg-interview-candidate">
        <header>
          <Icon iconKey="videoInterview" />
          <h1>{intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.TITULO")}</h1>
          <p>
            {intl.getHTML(
              interviewContent.empresa.nome
                ? "VIDEO_ENTREVISTA.CANDIDATO.CONVITE.SUBTITULO"
                : "VIDEO_ENTREVISTA.CANDIDATO.CONVITE.SUBTITULO_EMPRESA_CONFIDENCIAL",
              {
                jobName: interviewContent.processoseletivo.cargo,
                company: interviewContent.empresa.nome,
                time: `${moment(interviewContent.data_limite).format(
                  "H:mm"
                )} do dia ${moment(interviewContent.data_limite).format(
                  "DD/MM/YYYY"
                )}`,
              }
            )}
          </p>
        </header>
        <article className="vg-row">
          <div className="vg-col-md-3 vg-col-xs-12">
            <Well
              text={intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.TEXTO_1", {
                value: interviewContent.video_entrevista.qtde_perguntas,
              })}
              icon="wink"
            />
          </div>
          <div className="vg-col-md-3 vg-col-xs-12">
            <Well
              text={intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.TEXTO_2", {
                time: moment
                  .duration(
                    interviewContent.video_entrevista.tempo_estimado,
                    "seconds"
                  )
                  .humanize(),
              })}
              icon="noise"
            />
          </div>
          <div className="vg-col-md-3 vg-col-xs-12">
            <Well
              text={intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.TEXTO_3")}
              icon="wifi"
            />
          </div>
          <div className="vg-col-md-3 vg-col-xs-12">
            <Well
              text={intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.TEXTO_4")}
              icon="battery"
            />
          </div>
        </article>
        <div className="vg-interview-wrap-buttons">
          <div className="vg-inline-buttons">
            <Link
              to="/candidato/video-entrevista/teste"
              className="vg-btn-primary"
            >
              {intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.BOTAO_1")}
            </Link>
            <Link
              href={`https://docs.google.com/forms/d/e/1FAIpQLSf3N2txKTXmcKRIgY6azzcmMGQJ9g7t0vZjGGncCQAEgn8FRA/viewform?usp=pp_url&entry.1213706749=${interviewContent.processoseletivo.id}`}
              className="vg-btn-primary vg-btn-outline"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.BOTAO_2")}
            </Link>
          </div>
          <Link to="/candidato/video-entrevista/termos" className="vg-btn-link">
            {intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.CONVITE.LINK")}
          </Link>
        </div>
      </div>
    );
  }
}

VideoInterviewLandpage.propTypes = {
  interviewContent: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  currentAnswered: propTypes.number.isRequired,
  allAnswered: propTypes.bool.isRequired,
};
