import React, { Component } from "react";
import propTypes from "prop-types";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { load } from "../../../store/modules/ReturnASP";
import { ChangeLanguage, Icon, Link } from "../../index";
import { receive as receivePainel } from "../../../store/modules/Painel";
import "./Footer.scss";

const mapStateToProps = (state) => ({
  returnOk: state.ReturnASP,
  painelData: state.Painel.painel.data,
});

class Footer extends Component {
  constructor(props) {
    super(props);
    this.backToAsp = this.backToAsp.bind(this);
  }

  componentDidMount() {
    receivePainel("painel", "painel");
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.returnOk.status &&
      (window.location.href = "/move-to/retornar-pagina-servico-asp");
  }

  backToAsp() {
    this.props.load("desativar-pagina-servicos");
  }

  render() {
    const { painelData } = this.props;
    const enableBackToAsp =
      painelData && painelData.acessos.rodape
        ? painelData.acessos.rodape.includes("retornar-pagina-servico-asp")
        : false;

    return (
      <footer className="vg-footer">
        <div className="vg-container">
          <p>
            <Icon iconKey="vagas" />©{moment().format("YYYY")}{" "}
            {intl.get("FOOTER.COPYRIGHT")}
            {!this.props.check && (
              <Link to="/termos-de-uso">{intl.get("FOOTER.TERMOS")}</Link>
            )}
          </p>
          {!this.props.check && (
            <ChangeLanguage
              currentLocale={this.props.currentLocale}
              change={this.props.changeLanguage}
            />
          )}
        </div>
        {!this.props.check && enableBackToAsp && window.location.pathname === "/" && (
          <div className="vg-retorno-asp">
            <div className="vg-container">
              <p>
                <Icon iconKey="warning" />
                {intl.getHTML("FOOTER.MENSAGEM_RETORNO_1")}
                <Link onClick={this.backToAsp}>
                  {intl.get("FOOTER.LINK_RETORNO")}
                </Link>
              </p>
            </div>
          </div>
        )}
      </footer>
    );
  }
}

Footer.propTypes = {
  load: propTypes.func.isRequired,
  history: propTypes.object,
  returnOk: propTypes.object,
  changeLanguage: propTypes.func.isRequired,
  currentLocale: propTypes.string.isRequired,
  check: propTypes.any,
  painelData: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ load, receivePainel }, dispatch)
)(Footer);
