import { string, func, bool } from "prop-types";
import React, { useState, useEffect } from "react";
import "./InputGrade.scss";

const InputGrade = ({
  onInputChange,
  value,
  name,
  valideInput,
  showError,
  handleValidateOnBlur,
}) => {
  const [valueInput, setValueInput] = useState("");
  const [error, setError] = useState(false);

  const onHandleInput = (e) => {
    let input = e.target.value;
    const regexDecimalNumber = /^\d*(,\d{0,2})?$/;
    if (regexDecimalNumber.test(input)) {
      setValueInput(input);
      if (e.target !== null) {
        onInputChange(input);
      }
    }
  };

  useEffect(() => {
    setValueInput(value);
  }, [value]);

  useEffect(() => {
    setError(valideInput);
  }, [valideInput]);

  useEffect(() => {
    setError(showError);
  }, [showError]);

  const validateInput = (event) => {
    handleValidateOnBlur();
    if (event.target.value === "") {
      setError(true);
    }
  };

  return (
    <div className={`grid-input-grade ${error ? "error" : ""}`}>
      <input
        name={name}
        type="text"
        value={valueInput}
        onChange={(e) => onHandleInput(e)}
        onBlur={(e) => validateInput(e)}
        className="vg-input-text"
      />
    </div>
  );
};

InputGrade.defaultProps = {
  value: "",
  name: "",
  onInputChange: () => {},
  valideInput: false,
  showError: false,
  handleValidateOnBlur: () => {},
};

InputGrade.propTypes = {
  onInputChange: func,
  value: string,
  name: string,
  valideInput: bool,
  showError: bool,
  handleValidateOnBlur: func,
};

export default InputGrade;
