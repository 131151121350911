import { Icon, Link } from "../../index";

import React from "react";
import intl from "react-intl-universal";

export default function VideoInterviewAnswered() {
  return (
    <div>
      <div className="vg-container vg-interview-candidate">
        <header>
          <Icon iconKey="videoInterviewSuccess" />
          <h2>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.RESPONDIDO.TITULO")}</h2>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.RESPONDIDO.TEXTO")}</p>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.RESPONDIDO.TEXTO_2")}</p>
          <div className="row">
            <Link href="http://vagas.com.br" className="vg-btn">
              {intl.get("VIDEO_ENTREVISTA.CANDIDATO.RESPONDIDO.LINK")}
            </Link>
          </div>
        </header>
      </div>
    </div>
  );
}
