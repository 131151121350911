import "./SelectiveProcessList.scss";

import { Icon, Link } from "../../index";

import React from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";

const LoadMore = ({ loading, carregarMais, text }) => {
  return !loading ? (
    <Link onClick={carregarMais} className="vg-btn-primary vg-btn-block">
      {intl.get(text)}
    </Link>
  ) : (
    <div>
      <div className="vg-vacancy-loader">
        <Icon iconKey="jobLoader" />
      </div>
      <div className="vg-vacancy-loader">
        <Icon iconKey="jobLoader" />
      </div>
    </div>
  );
};

LoadMore.defaultProps = {
  text: "ACOMPANHAMENTO_DE_VAGAS.BOTAO_VER_MAIS",
};

LoadMore.propTypes = {
  carregarMais: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  text: propTypes.string,
};

export default LoadMore;
