import React from "react";
import propTypes from "prop-types";

const SplitText = ({ text }) => {
  let stringSplit = text.includes("\r\n") ? text.split(/\r\n/) : [text];
  const totalLength = stringSplit.length;

  return stringSplit.map((item, key) => {
    return totalLength === key + 1 ? (
      <span key={key}>{item}</span>
    ) : (
      <span key={key}>
        {item}
        <br />
      </span>
    );
  });
};

SplitText.defaultProps = {
  text: "",
};

SplitText.propTypes = {
  text: propTypes.string,
};

export default SplitText;
