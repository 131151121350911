import { applyMiddleware, compose, createStore } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import clientMiddleware from "./middleware/clientMiddleware";
import rootReducer from "./modules";

const logger = createLogger({
  collapsed: true,
  diff: true,
  timestamp: false,
  duration: true,
});

export default function configureStore(initialState, client) {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const getMiddleware = () =>
    process.env.NODE_ENV !== "production"
      ? [clientMiddleware(client), thunk, logger]
      : [clientMiddleware(client), thunk];

  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...getMiddleware())
  )(createStore);
  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    // Enable webpack hot module replacement for reducers
    module.hot.accept("./modules/index", () => {
      console.info("🍭 Reloading Reducers"); // eslint-disable-line no-console
      const nextReducer = require("./modules/index").default;
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}
