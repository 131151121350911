/* eslint-disable */
import propTypes from "prop-types";
import React, { Component } from "react";
import intl from "react-intl-universal";
import { SlaList, Icon, Radio, BoxTitle } from "../../index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive, update } from "../../../store/modules/Divisions";
import { receive as receiveCriteria } from "../../../store/modules/Criteria";
import { receive as receiveCounting } from "../../../store/modules/Counting";
import { receive as receiveHierarchy } from "../../../store/modules/Hierarchy";
import { receive as receivePainel } from "../../../store/modules/Painel";
import { filter } from "lodash";
import "./Sla.scss";
import { Shortcuts } from "./Shortcuts";

const mapStateToProps = (state) => ({
  divisions: state.Divisions.sla,
  criteria: state.Criteria.data,
  hierarchy: state.Hierarchy.data,
  counting: state.Counting.data,
  loaded: state.Divisions.loaded,
  loading: state.Divisions.loading,
  error: state.Divisions.error,
  accesses: state.Painel.painel.data,
  accessesLoad: state.Painel.painel.loaded,
});

class Sla extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchString: "",
      status: "todas",
      tempDivisions: [],
      toggleFilter: false,
      loaded: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleReload = this.handleReload.bind(this);
    this.handleClick = this.handleClick.bind(this);

    this.radios = [
      ["byStatus", "todas", "SLA.FILTER.RADIO_1"],
      ["byStatus", "true", "SLA.FILTER.RADIO_2"],
      ["byStatus", "false", "SLA.FILTER.RADIO_3"],
    ];
  }

  componentDidMount() {
    const {
      receive,
      divisions,
      receiveCounting,
      receiveHierarchy,
      receivePainel,
    } = this.props;
    receive("divisoes", { incluir_sla: true });
    receiveCounting();
    receiveHierarchy();
    receivePainel("painel", "painel");

    this.setState({
      tempDivisions: divisions.divisoes,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.divisions.divisoes !== this.props.divisions.divisoes) {
      this.setState({
        tempDivisions: nextProps.divisions.divisoes,
      });
    }

    if (nextProps.accessesLoad && !this.state.loaded) {
      this.setState({
        loaded: true,
      });

      const request =
        nextProps.accesses.cliente.hasOwnProperty("requisicao_de_vaga");
      const { receiveCriteria } = this.props;
      const urlSla = `sla/criterios${!request ? "?tipo=VAGA" : ""}`;
      receiveCriteria(urlSla);
    }
  }

  handleChange(e) {
    const type = e.target.type;
    const value = e.target.value;

    this.setState((prevState) => ({
      searchString: this.search.value,
      status: type === "radio" ? value : prevState.status,
      toggleFilter: false,
    }));
  }

  handleReload(data, id, receivePath) {
    this.props.update(data, id, receivePath);
  }

  handleClick() {
    this.setState((prevState) => ({
      toggleFilter: !prevState.toggleFilter,
    }));
  }

  render() {
    const { loading, loaded, criteria, counting, hierarchy, error } =
      this.props;
    const { toggleFilter, searchString, status } = this.state;

    let tempDivisions = this.state.tempDivisions;
    let search = searchString.trim().toLowerCase();
    let statusSearch = status;

    if (search.length > 0) {
      tempDivisions = filter(tempDivisions, (obj) => {
        return obj.nome.toLowerCase().match(search);
      });
    }

    if (statusSearch !== "todas" && statusSearch !== "") {
      tempDivisions = filter(
        tempDivisions,
        (obj) =>
          `${obj.sla === undefined ? false : obj.sla.ativo}` === statusSearch
      );
    }

    return (
      <div className="vg-container vg-sla-wrapper">
        <BoxTitle
          title={intl.get("SLA.TITULO_PAGINA")}
          text={intl.get("SLA.SUB_TITULO_PAGINA")}
          secondLevel
        />
        <div className="vg-sla-page">
          <div className="vg-row">
            <div className="vg-col-xs-12 vg-col-md-6 vg-col-md-offset-3">
              <div className="vg-filter">
                <div className="vg-filter-input">
                  <Icon iconKey="search" aria-hidden />
                  <input
                    type="text"
                    onChange={(el) => this.handleChange(el)}
                    value={searchString}
                    name="byName"
                    ref={(c) => {
                      this.search = c;
                    }}
                    placeholder={intl.get("SLA.FILTER.PLACEHOLDER")}
                  />
                </div>
                <div
                  className={`vg-filter-select ${
                    toggleFilter && "vg-filter-active"
                  }`}
                >
                  <h3>Status:</h3>
                  <Radio
                    inputs={this.radios}
                    intialChecked={status}
                    handleRadioCheck={this.handleChange}
                    id="1"
                  />
                </div>
                <button
                  type="button"
                  onClick={this.handleClick}
                  className={`${toggleFilter && "vg-button-active"}`}
                >
                  <Icon
                    iconKey="filter"
                    aria-label={intl.get("SLA.FILTER.BOTAO_STATUS")}
                  />
                </button>
              </div>
            </div>
          </div>
          <SlaList
            divisions={tempDivisions}
            criteria={criteria}
            hierarchy={hierarchy}
            counting={counting}
            loading={loading}
            loaded={loaded}
            error={error}
            reloadDivisions={this.handleReload}
          />
        </div>
        <Shortcuts />
      </div>
    );
  }
}

Sla.defaultProps = {
  type: "",
  loading: false,
  loaded: false,
  divisions: {},
  criteria: [],
  counting: [],
  hierarchy: [],
  accesses: {},
  accessesLoad: false,
};

Sla.propTypes = {
  accesses: propTypes.object,
  accessesLoad: propTypes.bool,
  divisions: propTypes.object.isRequired,
  criteria: propTypes.array.isRequired,
  hierarchy: propTypes.array.isRequired,
  counting: propTypes.array.isRequired,
  receive: propTypes.func.isRequired,
  update: propTypes.func.isRequired,
  receiveCriteria: propTypes.func.isRequired,
  receiveCounting: propTypes.func.isRequired,
  receiveHierarchy: propTypes.func.isRequired,
  receivePainel: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  error: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      receive,
      update,
      receiveCriteria,
      receiveCounting,
      receiveHierarchy,
      receivePainel,
    },
    dispatch
  )
)(Sla);
