import React from "react";
import intl from "react-intl-universal";
import { Link, Icon } from "../../index";
import { array } from "prop-types";

const IntroProfessionals = ({ content }) => {
  return (
    <div className="vg-intro-professionals">
      <ul className="vg-fast-links">
        <li>
          <Link moveTo={content}>
            <Icon iconKey="search" />
            {intl.get("PROFISSIONAIS.INTRODUCAO_PROFISSIONAIS.LABEL_BOTAO")}
          </Link>
        </li>
      </ul>
    </div>
  );
};

IntroProfessionals.propTypes = {
  content: array,
};

export default IntroProfessionals;
