import React, { useRef, useContext } from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { Icon } from "../../../index";
import { uploadPdf, uploadTypes } from "./reducerPdfFile";
import { PdfContext } from "../Pdf";
import "./PdfUpload.scss";

const PdfUpload = (props) => {
  const { requestHttp } = props;
  const txtButtonError = intl.get(
    "CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.SELECT_BUTTON"
  );
  const txtButtonSuccess = intl.get(
    "CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.CHANGE_BUTTON"
  );
  const txtButtonDelete = intl.get(
    "CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.DELETE_BUTTON"
  );
  const txtButtonCancel = intl.get(
    "CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.CANCEL_BUTTON"
  );

  const inputRef = useRef(null);
  const { pdfFile, dispatchPdfFile } = useContext(PdfContext);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const droppedFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const filePdf = files[0];
    if (filePdf) {
      inputRef.current.files = files;
      uploadPdf(filePdf, dispatchPdfFile);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    let inputPdf = inputRef.current.files[0];
    if (inputPdf) uploadPdf(inputPdf, dispatchPdfFile);
  };

  const deleteFile = (e) => {
    e.preventDefault();
    inputRef.current.value = null;
    dispatchPdfFile({ type: "emptyField" });
  };

  const cancelUpload = (e) => {
    e.preventDefault();
    if (requestHttp) {
      requestHttp.abort();
      dispatchPdfFile({ type: "cancelUpload" });
    }
  };

  return (
    <div>
      <form
        className="drag-drop-container"
        onDragOver={dragOver}
        onDragLeave={dragLeave}
        onDrop={droppedFile}
      >
        <div className={pdfFile.uploadStatus}>
          <div className="icon">
            <Icon iconKey="pdf" addClass="vg-icon-pdf pdf-icon" />
          </div>
          <div className="info">
            <p className="info-name">{pdfFile.name}</p>
            <div className="bar">
              <div className="progress-bar-bg-loading">
                <div
                  className={`progress-bar-${pdfFile.uploadStatus}`}
                  style={{ width: `${pdfFile.uploadPercentage}%` }}
                />
              </div>
              {pdfFile.uploadStatus == uploadTypes.VALID_FILE ||
              pdfFile.uploadStatus == uploadTypes.UPLOADED_FILE ? (
                <Icon
                  iconKey="checkCircleFilled"
                  addClass="pdf-icon-success vg-icon-checkCircleFilled"
                />
              ) : pdfFile.uploadStatus == uploadTypes.LOADING ? (
                `${pdfFile.uploadPercentage}%`
              ) : (
                <Icon
                  iconKey="toastError"
                  addClass="pdf-icon-error vg-icon-toastError"
                />
              )}
            </div>
          </div>
        </div>
        {pdfFile.messages.map((message) => {
          return <p key={message}>{message}</p>;
        })}

        {(pdfFile.uploadStatus == uploadTypes.VALID_FILE ||
          pdfFile.uploadStatus == uploadTypes.UPLOADED_FILE) && (
          <button
            aria-label="Delete term"
            className="false-input"
            onClick={deleteFile}
          >
            {txtButtonDelete}
          </button>
        )}
        {pdfFile.uploadStatus != uploadTypes.DELETING &&
        pdfFile.uploadStatus != uploadTypes.LOADING ? (
          <button
            aria-label="Upload term"
            className="false-input"
            onClick={(e) => {
              e.preventDefault();
              inputRef.current.click();
            }}
          >
            {pdfFile.errors.length ||
            pdfFile.uploadStatus === uploadTypes.FAILED
              ? txtButtonError
              : txtButtonSuccess}
          </button>
        ) : (
          pdfFile.enableCancelUpload && (
            <button
              aria-label="Cancel Upload Term"
              className="false-input"
              onClick={cancelUpload}
            >
              {txtButtonCancel}
            </button>
          )
        )}
        <input
          id="file-upload"
          className="input-drag-drop"
          type="file"
          ref={inputRef}
          onChange={onChange}
          accept="application/pdf"
        />
      </form>
    </div>
  );
};

PdfUpload.propTypes = {
  requestHttp: PropTypes.object,
};

export default PdfUpload;
