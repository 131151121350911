import "./FaqItem.scss";

import React, { Component } from "react";
import PropTypes from "prop-types";
import striptags from "striptags";
import intl from "react-intl-universal";
import { H3, P } from "@vagastec/epartner-ui";
import { Link } from "../../index";

export class FaqItem extends Component {
  prepareContent = (postContent) =>
    striptags(postContent).substring(0, 100).concat("...");

  render() {
    const { post } = this.props;

    return (
      <div className="vg-faq-item">
        <H3>{post.titulo}</H3>
        <P>{this.prepareContent(post.conteudo)}</P>
        <Link
          externalLink={post.link}
          textLink={intl.get("HOME.MODAL.AJUDA.FAQ_LINK_LEIA_MAIS")}
        />
      </div>
    );
  }
}

FaqItem.propTypes = {
  post: PropTypes.PropTypes.shape({
    titulo: PropTypes.string.isRequired,
    conteudo: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }).isRequired,
};

export default FaqItem;
