import { Icon, Link } from "../../index";
import propTypes from "prop-types";
import React, { Component } from "react";

import intl from "react-intl-universal";
import { parse } from "../../../utils/QueryFunctions";

class VideoInterviewThanks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      vagaId: "",
    };
  }

  static getDerivedStateFromProps(props) {
    if (props.location) {
      return {
        vagaId: parse(window.location.search),
      };
    }

    return null;
  }

  render() {
    const { vagaId } = this.state;
    return (
      <div>
        <div className="vg-container vg-interview-candidate">
          <header>
            <Icon iconKey="videoInterviewSuccess" />
            <h2>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.OBRIGADO.TITULO")}</h2>
            <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.OBRIGADO.TEXTO")}</p>
            <div className="row">
              <Link
                href={`https://docs.google.com/forms/d/e/1FAIpQLSfp9Nv5qDaj9dqm8_a4cVFBIk7lIZdNUTeeRd6HDae4D0W-eQ/viewform?usp=pp_url&entry.1278979703=${vagaId.vagaId}`}
                className="vg-btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                {intl.get("VIDEO_ENTREVISTA.CANDIDATO.OBRIGADO.LINK_1")}
              </Link>
            </div>
            <div className="row">
              <Link href="http://vagas.com.br" className="vg-btn">
                {intl.get("VIDEO_ENTREVISTA.CANDIDATO.OBRIGADO.LINK_2")}
              </Link>
            </div>
          </header>
        </div>
      </div>
    );
  }
}

VideoInterviewThanks.propTypes = {
  location: propTypes.object,
};

export default VideoInterviewThanks;
