import propTypes from "prop-types";
import React, { Component } from "react";
import { Icon, SlaListTransition } from "../../index";
import intl from "react-intl-universal";
import "./SlaList.scss";

class SlaListItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      isActive: false,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  toggleCollapse(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));
  }

  toggleActive() {
    this.setState((prevState) => ({
      isActive: !prevState.isActive,
    }));
  }

  render() {
    const { division, criteria, counting, hierarchy, reloadDivisions } =
      this.props;
    const { opened } = this.state;

    const slaActive = division.sla === undefined ? false : division.sla.ativo;

    return (
      <div className={`vg-sla-item ${opened && "vg-active"}`}>
        <a
          href="#"
          className="vg-sla-header"
          onClick={(e) => this.toggleCollapse(e)}
        >
          <div className="vg-row middle-xs">
            <div className="vg-col-sm-5 vg-sla-status-wrapper">
              <span
                className={`vg-sla-status ${
                  slaActive ? "vg-sla-enabled" : "vg-sla-disabled"
                }`}
              />
              <h2>{division.nome}</h2>
            </div>
            <div className="vg-col-sm-4 vg-text-center vg-hide-sm">
              <span className="vg-sla-link">
                {slaActive
                  ? intl.get("SLA.ATIVADO")
                  : intl.get("SLA.DESATIVADO")}
              </span>
            </div>
            <div className="vg-col-sm-3 vg-sla-orange">
              <span className="vg-sla-link">
                <span>{intl.get("SLA.CONFIGURAR.LINK")}</span>
                <Icon iconKey="arrow" />
              </span>
            </div>
          </div>
        </a>
        <SlaListTransition
          opened={opened}
          criteria={criteria}
          division={division}
          counting={counting}
          hierarchy={hierarchy}
          slaActive={slaActive}
          active={this.toggleActive}
          reloadDivisions={reloadDivisions}
        />
      </div>
    );
  }
}

SlaListItem.defaultProps = {
  division: {
    sla: {
      ativo: false,
    },
  },
};

SlaListItem.propTypes = {
  division: propTypes.object.isRequired,
  criteria: propTypes.array.isRequired,
  counting: propTypes.array.isRequired,
  hierarchy: propTypes.array.isRequired,
  reloadDivisions: propTypes.func.isRequired,
};

export default SlaListItem;
