import "../Actions.scss";

import { Button, Icon } from "../../../index";
import React, { Component } from "react";
import { getList } from "../../../../store/modules/Actions";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import shortid from "shortid";

const mapStateToProps = (state) => ({
  actionsList: state.Actions.listActions.data,
  loaded: state.Actions.listActions.loaded,
  accesses: state.Painel.painel.data,
});

export class SelectAction extends Component {
  constructor(props) {
    super(props);

    // método responsável pela listagem de ações
    props.getList("tipos-de-acoes");
  }

  render() {
    const { loaded, actionsList, currentItem, goToStep } = this.props;
    return (
      <div>
        <div className="vg-phases-modal-content">
          <h1>{intl.get("FASES.MODAL_ACTION.TITULO")}</h1>
          <p>
            {intl.getHTML("FASES.MODAL_ACTION.TEXTO", {
              division: currentItem.nome,
            })}
          </p>
          <div className="vg-actions-list">
            {loaded && (
              <ul>
                {map(actionsList, (item) => (
                  <li key={shortid.generate()}>
                    <Icon iconKey={item.tipo} />
                    <Button
                      className="vg-btn-outline"
                      onClick={() => goToStep(item.tipo, item.id)}
                    >
                      {intl.get(
                        `FASES.MODAL_ACTION.TIPO_ACAO.${item.tipo}.BOTAO_SELECIONAR`,
                      )}
                    </Button>
                    {intl.get(
                      `FASES.MODAL_ACTION.TIPO_ACAO.${item.tipo}.DESC_CARD`,
                    ) && (
                      <b>
                        {intl.get(
                          `FASES.MODAL_ACTION.TIPO_ACAO.${item.tipo}.DESC_CARD`,
                        )}
                      </b>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    );
  }
}

SelectAction.defaultProps = {
  actionsList: [],
};

SelectAction.propTypes = {
  actionsList: propTypes.array.isRequired,
  getList: propTypes.func.isRequired,
  goToStep: propTypes.func.isRequired,
  currentItem: propTypes.object.isRequired,
  loaded: propTypes.bool,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ getList }, dispatch),
)(SelectAction);
