/**
 *
 * Receives a sub-property name and a nested object and flattens it by one level by replacing
 * the top level property values with the value of the given sub-property
 *
 * Example input object:
 *   obj = {
 *     topLevelProp1: {
 *       nestedProp1: 'the nested property value'
 *     }
 *   }
 * Example output object:
 *
 *    obj = {
 *      topLevelProp1:  'the nested property value'
 *    }
 */

export default (obj, targetProp) => {
  try {
    return Object.entries(obj).reduce((acc, el) => {
      const [propName, propVal] = el;
      acc[propName] = propVal instanceof Object ? propVal[targetProp] : propVal;
      return acc;
    }, {});
  } catch (err) {
    return obj;
  }
};
