import React from "react";
import intl from "react-intl-universal";
import "./TermsPage.scss";

export default function Terms() {
  return (
    <div className="vg-container">
      <div className="vg-row">
        <div className="vg-col-md-offset-1 vg-col-md-10">
          <h1 className="vg-title-1 vg-title-mt">
            {intl.get("TERMOSDEUSO.TITULO_PAGINA")}
          </h1>
          <div className="vg-page-terms">
            <div className="vg-page-terms-header">
              <div className="vg-icon-terms">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Layer_1"
                  viewBox="0 0 346.9 278.6"
                >
                  <g id="computer">
                    <g>
                      <g>
                        <g>
                          <path
                            fill="#f2f2f2"
                            d="M17.5 278.6a2 2 0 0 1-2-2v-14.1c0-1.1.9-2 2-2h327.4a2 2 0 0 1 2 2v14.1a2 2 0 0 1-2 2H17.5z"
                          />
                        </g>
                        <path
                          fill="#d1d1d1"
                          d="M344.9 278.6H17.5a.8.8 0 0 1-.8-.8v-1c0-.5.4-.8.8-.8h327.4c.5 0 .8.4.8.8v1c0 .5-.3.8-.8.8z"
                        />
                        <g>
                          <path
                            fill="#c2c2c2"
                            d="M159.5 270.1a4.8 4.8 0 0 1-4.8-4.8v-4.7h53.1v4.7c0 2.7-2.2 4.8-4.8 4.8h-43.5z"
                          />
                        </g>
                        <g>
                          <g>
                            <path
                              fill="#e0e0e0"
                              d="M203.6 266.5h-44.8c-2 0-3.6-1.6-3.6-3.6v-2.3h52v2.3c0 2-1.6 3.6-3.6 3.6z"
                            />
                          </g>
                        </g>
                        <g>
                          <path fill="#d1d1d1" d="M43.1 75.1h276.1v180H43.1z" />
                          <path
                            fill="#474747"
                            d="M57.5 89.4h247.4v151.3H57.5z"
                          />
                          <path
                            fill="gray"
                            d="M183.4 82c0 1.2-1 2.2-2.2 2.2s-2.2-1-2.2-2.2 1-2.2 2.2-2.2 2.2 1 2.2 2.2z"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                  <g id="Detail" fill="#c8c8c8">
                    <path d="M299.2 21.7a2.3 2.3 0 0 1-1.7-4l8.6-8.6c.9-.9 2.4-.9 3.3 0s.9 2.4 0 3.3l-8.6 8.6c-.4.4-1 .7-1.6.7zm16.9 12.1h-10.6c-1.3 0-2.3-1-2.3-2.3s1-2.3 2.3-2.3h10.6c1.3 0 2.3 1 2.3 2.3.1 1.2-1 2.3-2.3 2.3zM287 15.3c-1.3 0-2.3-1-2.3-2.3V2.3c0-1.3 1-2.3 2.3-2.3s2.3 1 2.3 2.3v10.6c.1 1.4-1 2.4-2.3 2.4z" />
                  </g>
                  <g id="balloon">
                    <path
                      fill="#ce4146"
                      d="M277.7 183.1h-25.3l1.3 43-41.3-43H12.2c-6.8 0-12.2-5.5-12.2-12.2v-131c0-6.8 5.5-12.2 12.2-12.2h265.4c6.8 0 12.2 5.5 12.2 12.2v131c.1 6.7-5.4 12.2-12.1 12.2z"
                    />
                    <path
                      fill="none"
                      stroke="#fff"
                      strokeLinecap="round"
                      strokeMiterlimit="10"
                      strokeWidth="8"
                      d="M162.1 73.2h97.3m-97.3 20h97.3m-97.3 19.9h97.3M162.1 133h64.3"
                    />
                    <g id="check">
                      <path
                        fill="#fff"
                        d="M123.4 128.2l-34.5 30.3a11.7 11.7 0 0 1-15.5 0l-34.5-30.3a12 12 0 0 1-4-8.8V66c0-5.9 4.3-10.8 10.1-11.7l34.5-4.9c1.1-.2 2.2-.2 3.3 0l34.5 4.9c5.8.8 10.1 5.8 10.1 11.7v53.4c0 3.4-1.5 6.6-4 8.8z"
                      />
                      <path
                        fill="#474747"
                        d="M115.3 124.4l-27.9 25.4a9.3 9.3 0 0 1-12.6 0l-27.9-25.5a10 10 0 0 1-3.2-7.4V72.1c0-4.9 3.5-9.1 8.2-9.8l27.9-4.1h2.7l27.9 4.1c4.7.7 8.2 4.9 8.2 9.8V117a10 10 0 0 1-3.3 7.4z"
                      />
                    </g>
                  </g>
                  <path
                    id="check-icon"
                    fill="none"
                    stroke="#7ab84d"
                    strokeMiterlimit="10"
                    strokeWidth="11"
                    d="M64.4 97.3l14.8 12.8 25.4-22.7"
                  />
                </svg>
              </div>
            </div>
            <div className="vg-text-terms" id="link-termos-de-uso">
              {intl.getHTML("TERMOSDEUSO.COUNTEUDO_PAGINA")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
