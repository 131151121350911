import React from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { Icon, Link } from "../../index";
import "./LoadMoreInterviewList.scss";

const LoadMoreItem = ({ loading, carregarMais, text }) => {
  return !loading ? (
    <Link
      onClick={carregarMais}
      className="vg-btn-primary vg-btn-block vg-load-more"
    >
      {intl.get(text)}
    </Link>
  ) : (
    <div>
      <div className="vg-vacancy-loader">
        <Icon iconKey="jobLoader" />
      </div>
      <div className="vg-vacancy-loader">
        <Icon iconKey="jobLoader" />
      </div>
    </div>
  );
};

LoadMoreItem.defaultProps = {
  text: "VIDEO_ENTREVISTA.EMPRESA.LISTA.BOTAO_VER_MAIS",
};

LoadMoreItem.propTypes = {
  carregarMais: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  text: propTypes.string,
};

export default LoadMoreItem;
