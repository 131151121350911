// Action Types
const VIDEO_LIST = "services/VIDEO_LIST";
const VIDEO_LIST_SUCCESS = "services/VIDEO_LIST_SUCCESS";
const VIDEO_LIST_FAIL = "services/VIDEO_LIST_FAIL";
var order = null;
var orderType = null;

// InitialState
const initialState = {
  loading: false,
  loaded: false,
  data: {},
};

// Reducers
export default function videoInterviewList(state = initialState, action = {}) {
  switch (action.type) {
    case VIDEO_LIST:
      return {
        ...state,
        loading: true,
        loaded: false,
        path: action.path,
        order: action.order,
        orderType: action.orderType,
      };
    case VIDEO_LIST_SUCCESS:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
      };
    case VIDEO_LIST_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error.status,
      };
    default:
      return state;
  }
}

// Actions
export function load(path, ordenationType) {
  handleOrdenation(ordenationType);

  return {
    types: [VIDEO_LIST, VIDEO_LIST_SUCCESS, VIDEO_LIST_FAIL],
    promise: (client) => client.get(path),
    path,
    order,
    orderType,
  };
}

function handleOrdenation(ordenationType) {
  // primeiro click em algum item
  if (order == null && orderType == null && ordenationType != null) {
    order = true;
    orderType = ordenationType;

    // próximos clicks no mesmo item
  } else if (
    orderType != null &&
    ordenationType != null &&
    ordenationType == orderType
  ) {
    order = !order;

    // próximo click em outro item
  } else if (
    order != null &&
    orderType != null &&
    ordenationType != null &&
    ordenationType != orderType
  ) {
    order = !order;
    orderType = ordenationType;
  }
}

function shouldFetch() {
  return true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(path, order = {}) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path, order));
    }
  };
}
