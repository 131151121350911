import React from "react";
import intl from "react-intl-universal";

export default function AcceptanceTermInfo() {
  return (
    <div className="vg-row">
      <div className="vg-col-xs-10 vg-notification-box">
        <h1>{intl.get("CONFIGURACOES.TERMO_ACEITE.NOTIFICACAO_BOX.TITULO")}</h1>
        <p>
          {intl.getHTML("CONFIGURACOES.TERMO_ACEITE.NOTIFICACAO_BOX.CONTEUDO")}
        </p>
      </div>
    </div>
  );
}
