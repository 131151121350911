import { EditorState, Modifier } from "draft-js";
import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import intl from "react-intl-universal";
import onClickOutside from "react-onclickoutside";

class CustomOption extends Component {
  constructor(props) {
    super(props);

    this.addStar = this.addStar.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);

    this.state = {
      dropdownIsOpen: false,
    };
  }

  handleClickOutside() {
    this.setState({ dropdownIsOpen: false });
  }

  addStar(e, type) {
    e && e.preventDefault();
    const { editorState, onChange } = this.props;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "MARK",
      "IMMUTABLE",
      {
        type: "MARK",
        text: type.toUpperCase(),
      }
    );

    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const text = Modifier.replaceText(
      contentState,
      editorState.getSelection(),
      `{{${type.toUpperCase()}}}`,
      editorState.getCurrentInlineStyle(),
      entityKey
    );

    onChange(EditorState.push(editorState, text, "insert-characters"));

    this.setState({ dropdownIsOpen: false });
  }

  toggleDropdown() {
    this.setState((prevState) => ({
      dropdownIsOpen: !prevState.dropdownIsOpen,
    }));
  }

  render() {
    const { dropdownIsOpen } = this.state;
    const { isTest, isDatasheet } = this.props;

    return (
      <div
        className="rdw-dropdown-wrapper rdw-block-dropdown"
        aria-label="rdw-dropdown"
        aria-expanded={dropdownIsOpen}
      >
        <a
          className="rdw-dropdown-select-variable"
          onClick={this.toggleDropdown}
        >
          <span>{intl.get("FASES.VARIAVEIS.TITULO")}</span>
          <div
            className={
              !dropdownIsOpen
                ? "rdw-dropdown-carettoopen"
                : "rdw-dropdown-carettoclose"
            }
          />
        </a>
        {dropdownIsOpen && (
          <ul className="rdw-dropdown-optionwrapper rdw-dropdown-custom">
            <li className="rdw-dropdownoption-default">
              <a href="#" onClick={(e) => this.addStar(e, "candidato_nome")}>
                {intl.get("FASES.VARIAVEIS.LISTA.CANDIDATO_NOME")}
              </a>
            </li>
            <li className="rdw-dropdownoption-default">
              <a href="#" onClick={(e) => this.addStar(e, "candidato_email")}>
                {intl.get("FASES.VARIAVEIS.LISTA.CANDIDATO_EMAIL")}
              </a>
            </li>
            <li className="rdw-dropdownoption-default">
              <a href="#" onClick={(e) => this.addStar(e, "vaga_cargo")}>
                {intl.get("FASES.VARIAVEIS.LISTA.VAGA_CARGO")}
              </a>
            </li>
            <li className="rdw-dropdownoption-default">
              <a href="#" onClick={(e) => this.addStar(e, "vaga_codigo")}>
                {intl.get("FASES.VARIAVEIS.LISTA.VAGA_CODIGO")}
              </a>
            </li>
            {isTest && (
              <Fragment>
                <li className="rdw-dropdownoption-default">
                  <a href="#" onClick={(e) => this.addStar(e, "url_teste")}>
                    {intl.get("FASES.VARIAVEIS.LISTA.URL_TESTE")}
                  </a>
                </li>
                <li className="rdw-dropdownoption-default">
                  <a href="#" onClick={(e) => this.addStar(e, "data_limite")}>
                    {intl.get("FASES.VARIAVEIS.LISTA.DATA_LIMITE")}
                  </a>
                </li>
              </Fragment>
            )}
            {isDatasheet && (
              <Fragment>
                <li className="rdw-dropdownoption-default">
                  <a href="#" onClick={(e) => this.addStar(e, "url_ficha")}>
                    {intl.get("FASES.VARIAVEIS.LISTA.URL_FICHA")}
                  </a>
                </li>
                <li className="rdw-dropdownoption-default">
                  <a href="#" onClick={(e) => this.addStar(e, "data_limite")}>
                    {intl.get("FASES.VARIAVEIS.LISTA.DATA_LIMITE")}
                  </a>
                </li>
              </Fragment>
            )}
          </ul>
        )}
      </div>
    );
  }
}

export default onClickOutside(CustomOption);

CustomOption.propTypes = {
  onChange: PropTypes.func,
  editorState: PropTypes.object,
  isTest: PropTypes.bool,
  isDatasheet: PropTypes.bool,
};
