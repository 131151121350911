import decorateComponentWithProps from "decorate-component-with-props";

const findAllPossibleVariables = (content, regex, callback) => {
  const text = content.getText();
  let matchArr, start;

  while ((matchArr = regex.exec(text)) !== null) {
    start = matchArr.index;
    callback(start, start + matchArr[0].length);
  }
};

const generateVariableHighlighterPlugin = ({
  component,
  regex,
  theme,
  secondaryRegex,
  variables = {},
}) => {
  const hasVariables = Object.keys(variables).length > 0;

  return [
    {
      strategy: (contentBlock, callback) =>
        findAllPossibleVariables(contentBlock, regex, callback),
      component: decorateComponentWithProps(component, {
        variables,
        hasVariables,
        secondaryRegex,
        theme,
      }),
    },
  ];
};

export { generateVariableHighlighterPlugin };
