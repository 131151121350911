import React, { useEffect, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import { RadioButton } from "@vagastec/epartner-ui";
import { Divisions, Icon, Upload } from "../../index";
import { receive } from "../../../store/modules/Divisions";
import { acceptanceTermReducer } from "../../../store/modules/AcceptanceTerms";
import Modal from "./Modals/Modal";
import {
  listAcceptanceTerms,
  showNotifications,
  deleteAllTerms,
} from "./functions";

export const TermContext = React.createContext();

const AcceptanceTermList = (props) => {
  const [configuration, setConfiguration] = useState("");
  const path = "divisoes";
  const urlRequest = "termo_aceite";
  const [terms, dispatchTerms] = useReducer(acceptanceTermReducer, []);
  const [isOpen, setIsOpen] = useState(false);
  const [company, setCompany] = useState("");

  useEffect(() => {
    props.receive(path);
  }, []);

  useEffect(() => {
    if (props.listDivisions.length) {
      dispatchTerms({
        type: "loadInitialTerms",
        payload: { divisions: props.listDivisions },
      });
      listAcceptanceTerms(urlRequest).then((response) => {
        if (response.status == 200) {
          response.json().then((termsClient) => {
            dispatchTerms({
              type: "loadTerms",
              payload: { terms: termsClient },
            });
            let termAtual = termsClient.find((termo) => termo.atual);
            initialConfiguration(termAtual);
          });
        } else {
          showNotifications("error");
        }
      });
    }
  }, [props.listDivisions]);

  useEffect(() => {
    if (props.panel && props.panel.cliente) setCompany(props.panel.cliente.id);
  }, [props.panel]);

  const initialConfiguration = (termAtual) => {
    if (props.listDivisions.length == 1) {
      setConfiguration("empresa");
    } else if (!termAtual) {
      setConfiguration("");
    } else {
      let initialConfiguration = termAtual.divisao_id ? "divisao" : "empresa";
      setConfiguration(initialConfiguration);
    }
  };

  useEffect(
    (configuration) => {
      upload(configuration);
    },
    [configuration]
  );

  function upload() {
    if (configuration == "empresa") {
      return <Upload id={0} />;
    } else if (configuration == "divisao") {
      return <Divisions />;
    }
  }

  function configurationOptions(options) {
    if (props.listDivisions.length > 1) {
      return options.map((option) => {
        return (
          <div key={option.value} className="vg-radio-button">
            <RadioButton
              key={option.value}
              currentChecked={configuration}
              label={option.label}
              name="answer"
              onClick={changeConfiguration}
              onChange={() => {}}
              value={option.value}
            />
          </div>
        );
      });
    }
  }

  const changeConfiguration = (e) => {
    let value = e.target.value;
    if (configuration === value) {
      return;
    } else if (configuration === "") {
      setConfiguration(value);
    } else {
      configurationIsChanged(value);
    }
  };

  const configurationIsChanged = (value) => {
    let filledTerm = terms.find((e) => e.term != "");
    if (filledTerm) {
      let filledTermId = filledTerm.id;

      // O ultimo termo salvo é de empresa, mas a configuração será alterada para divisão
      let termCompany = value == "divisao" && filledTermId == 0;

      // O ultimo termo salvo é de divisao, mas a configuração será alterada para empresa
      let termsDivision = value == "empresa" && filledTermId != 0;

      if (termCompany || termsDivision) {
        setIsOpen(true);
      }
    } else {
      // A configuração foi alterada, mas nenhum termo foi salvo após esta alteração, portanto os termos da configuração
      // anterior não foram deletados
      keepConfiguration(value);
    }
  };

  const keepConfiguration = (value) => {
    setConfiguration(value);
    listAcceptanceTerms(urlRequest).then((response) => {
      if (response.status == 200) {
        response.json().then((termsClient) => {
          dispatchTerms({
            type: "loadTerms",
            payload: { terms: termsClient },
          });
        });
      }
    });
  };

  const cancelChange = () => {
    setIsOpen(false);
  };

  const deleteTerms = () => {
    deleteAllTerms(company).then((response) => {
      const newConfiguration =
        configuration === "empresa" ? "divisao" : "empresa";
      if (response.status === 200) {
        dispatchTerms({
          type: "changeConfiguration",
          payload: { configuration: newConfiguration },
        });
        showNotifications("delete");
        setConfiguration(newConfiguration);
        setIsOpen(false);
      } else {
        showNotifications("error");
      }
    });
  };

  const options = [
    {
      value: "empresa",
      label: intl.get(
        "CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.OPCAO_EMPRESA_LABEL"
      ),
    },
    {
      value: "divisao",
      label: intl.get(
        "CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.OPCAO_DIVISOES_LABEL"
      ),
    },
  ];

  return (
    <div className="vg-row">
      <div className="vg-col-xs-10 vg-option-box">
        <h1>{intl.get("CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.TITULO")}</h1>
        <p>{intl.get("CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.SUB_TITULO")}</p>
        {configurationOptions(options)}
        <TermContext.Provider value={{ terms, dispatchTerms, company }}>
          {upload(configuration)}
        </TermContext.Provider>
        <Modal showModal={isOpen} setShowModal={setIsOpen}>
          <div className="vg-modal-termo-aceite">
            <div className="modal-trash-icon">
              <Icon iconKey="trashAlt" addClass="trash-icon" />
            </div>
            <p>
              {configuration &&
                intl.get(
                  `CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.DELETE_TERMO_${configuration.toUpperCase()}`
                )}
            </p>
            <div className="buttons-configuration-termo-aceite">
              <button
                onClick={cancelChange}
                className="vg-input-button-cancel modal-cancel"
              >
                {intl.get("CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.CANCELAR")}
              </button>
              <button
                onClick={deleteTerms}
                className="vg-input-button-continue"
              >
                {intl.get("CONFIGURACOES.TERMO_ACEITE.OPCAO_BOX.CONTINUAR")}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

AcceptanceTermList.defaultProps = {
  listDivisions: [],
  panel: {},
};

AcceptanceTermList.propTypes = {
  receive: PropTypes.func.isRequired,
  listDivisions: PropTypes.array.isRequired,
  panel: PropTypes.object,
};

const mapStateToProps = (state) => ({
  listDivisions: state.Divisions.sla && state.Divisions.sla.divisoes,
  loaded: state.Divisions.loaded,
  loading: state.Divisions.loading,
  panel: state.Painel.painel.data,
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(AcceptanceTermList);
