import React, { Component } from "react";
import shortid from "shortid";
import intl from "react-intl-universal";
import { Link } from "../../index";
import { map } from "lodash";
import propTypes from "prop-types";

class LinkTriagem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { links, group, linkRi } = this.props;

    return links == undefined ? null : (
      <div className="vg-list-btn">
        {map(links, (link) => {
          return (
            <Link
              key={shortid.generate()}
              className={`vg-box-btn ${linkRi === 0 && "vg-btn-disabled"}`}
              moveTo={link}
              params={
                group === "colaboradores" ? { codigo_da_divisao: linkRi } : {}
              }
            >
              {intl.getHTML(`PROFISSIONAIS.CONTEUDO_BOXES.${group}.${link}`)}
            </Link>
          );
        })}
      </div>
    );
  }
}
LinkTriagem.defaultProps = {
  links: [],
};

LinkTriagem.propTypes = {
  links: propTypes.array.isRequired,
  group: propTypes.string.isRequired,
  linkRi: propTypes.number,
};

export default LinkTriagem;
