import "./BoxGrade.scss";

import React, { useEffect, useState } from "react";
import { Link, SelectGrade, Icon, InputGrade } from "../../../index";
import { object, string, func, array } from "prop-types";
import intl from "react-intl-universal";

const BoxGrade = ({
  id,
  onDeleteGrid,
  selectPhases,
  onUpdateFormGrade,
  dataSelect,
  formGrid,
}) => {
  const [form, setValues] = useState(formGrid);
  const [showErrorGradeMin, setShowErrorGradeMin] = useState(false);
  const [showErrorGradeInit, setShowErrorGradeInit] = useState(false);

  const [mensageError, setMensageError] = useState("");
  const [showMensageErrorOpe, setShowMensageErrorOpe] = useState(false);
  const [showMensageErrorGradeInit, setShowMensageErrorGradeInit] =
    useState(false);

  const [isValidGrade, setIsValidGrade] = useState(false);
  const [isValidOpe, setIsValidOpe] = useState(false);

  const updateInitGrade = (initGrade) => {
    form.nota_inicial = initGrade;
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const updateSelectFase = (id) => {
    form.fase_destino_id = id;
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const updateSelectOperator = (operator) => {
    form.operador_inicial = operator;
    validateGradeInit();
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  useEffect(() => {
    if (isValidGrade && isValidOpe) {
      form.validated = true;
      setShowMensageErrorOpe(false);
      setShowMensageErrorGradeInit(false);
    } else {
      form.validated = false;
    }
    onUpdateFormGrade(form);
  }, [form]);

  useEffect(() => {
    if (form.isEdit) {
      validateGradeInit();
      validateOperator();
    }
  }, []);

  const handleDeleteGrid = () => {
    onDeleteGrid(id);
  };

  const validateOperator = () => {
    if (form.operador_inicial === "") {
      setShowErrorGradeInit(true);
      setMensageError(`${intl.get("FASES.GRADE.MESSAGE_ERROR.OPE_INIT")}`);
      setIsValidOpe(false);
      setShowMensageErrorOpe(true);
      form.validated = false;
    } else {
      setShowErrorGradeInit(false);
      setIsValidOpe(true);
      setShowMensageErrorOpe(false);
    }

    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const validateGradeInit = () => {
    const verify = /,/g;
    let nota_init = form.nota_inicial;

    if (verify.test(form.nota_inicial)) {
      nota_init = form.nota_inicial.replace(",", ".");
    }

    if (nota_init !== "") {
      nota_init = Number(nota_init);
    }
    validateOperator();
    if (form.nota_inicial === "") {
      setMensageError(intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_INIT.VAZIO"));
      setShowErrorGradeMin(true);
      setShowMensageErrorGradeInit(true);
      setIsValidGrade(false);
      form.validated = false;
    } else if (
      nota_init > form.maxGrade ||
      (nota_init === form.maxGrade && form.operador_inicial === ">")
    ) {
      setMensageError(
        `${intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_INIT.MAIOR_MAX")} ${
          form.maxGrade
        }`
      );
      setShowErrorGradeMin(true);
      setShowMensageErrorGradeInit(true);
      setIsValidGrade(false);
      form.validated = false;
    } else {
      setShowErrorGradeMin(false);
      setIsValidGrade(true);
      setShowMensageErrorGradeInit(false);
    }

    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  return (
    <div className="vg-row grid-grade">
      <div className="vg-col-md-12 vg-col-xs-12 vg-form">
        <Link className="icon-trash" onClick={handleDeleteGrid}>
          <Icon iconKey="trash_gray" />
        </Link>
        <div className="vg-row grid-form">
          <div className="vg-col-md-12 vg-col-xs-12">
            <div className="div-reset-grade">
              <label className="label-grade">
                {intl.get("FASES.GRADE.CANDIDATOS")}
              </label>
              <div className="box-select-grade">
                <SelectGrade
                  name="operador_inicial"
                  onChangeSelect={updateSelectOperator}
                  data={dataSelect}
                  selected={form.operador_inicial}
                  handleValidateOnBlur={validateOperator}
                  showError={showErrorGradeInit}
                />
              </div>

              <div className="box-input-grade">
                <InputGrade
                  name="nota_inicial"
                  value={form.nota_inicial}
                  onInputChange={updateInitGrade}
                  handleValidateOnBlur={validateGradeInit}
                  showError={showErrorGradeMin}
                />
              </div>

              <label className="label-grade">
                {intl.get("FASES.GRADE.MUDAR_FASE")}
              </label>
              <div className="box-select-grade">
                {selectPhases.length > 0 && (
                  <SelectGrade
                    name="fase_id"
                    data={selectPhases}
                    onChangeSelect={updateSelectFase}
                    selected={form.fase_destino_id}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMensageErrorOpe || showMensageErrorGradeInit ? (
        <div className="vg-col-md-12 vg-col-xs-12 box-error">
          {mensageError}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

BoxGrade.defaultProps = {
  input: "",
  labelInput: "",
  onUpdateFormGrade: () => {},
  formGrid: {},
};

BoxGrade.propTypes = {
  input: string,
  labelInput: string,
  labelSelect: string,
  id: string.isRequired,
  onDeleteGrid: func.isRequired,
  selectPhases: array.isRequired,
  dataSelect: array.isRequired,
  onUpdateFormGrade: func,
  formGrid: object,
};

export default BoxGrade;
