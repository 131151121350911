import React, { Component } from "react";
import {
  /*IconStatus*/ Icon,
  Button,
  Modal,
  UsersRedesRemove,
  UserRedesForm,
} from "../../index";
import { map } from "lodash";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/UsersRede";
import "./UsersRedesListItem.scss";
import { Loader } from "../../index";
import propTypes from "prop-types";

class UsersListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isOpenEdit: false,
      titleModal: intl.get("UNIDADES_REDES.MODAL_EXCLUIR.BOTAO_EXCLUIR"),
      titleModalEdit: intl.get("UNIDADES_REDES.LINK_HEADER_EDITAR"),
      funcionarioID: false,
      funcionarioNome: "",
      funcionarioIdentidade: "",
      funcionarioEmail: "",
      funcionarioPerfil: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalEdit = this.toggleModalEdit.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.setState(
          {
            isOpen: false,
            isOpenEdit: false,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll"),
        );
      }

      document.getElementsByTagName("body")[0].classList.remove("vg-no-scroll");
    });

    const { receive, rede } = this.props;
    receive(rede.id);
  }

  componentDidUpdate(prevProps) {
    const { receive, rede } = this.props;
    if (prevProps.rede.id != rede.id) {
      receive(rede.id);
    }
  }

  toggleModal(funcionarioID) {
    this.setState({
      isOpen: !this.state.isOpen,
      funcionarioID: funcionarioID,
    });

    const bodyClass = document.getElementsByTagName("body")[0].classList;

    if (!this.state.isOpen) {
      bodyClass.add("vg-no-scroll");
    } else {
      bodyClass.remove("vg-no-scroll");
    }
  }

  toggleModalEdit(
    funcionarioID,
    funcionarioNome,
    funcionarioIdentidade,
    funcionarioEmail,
    funcionarioPerfil,
  ) {
    this.setState({
      isOpenEdit: !this.state.isOpenEdit,
      funcionarioID: funcionarioID,
      funcionarioNome: funcionarioNome,
      funcionarioIdentidade: funcionarioIdentidade,
      funcionarioEmail: funcionarioEmail,
      funcionarioPerfil: funcionarioPerfil,
    });

    const bodyClass = document.getElementsByTagName("body")[0].classList;

    if (!this.state.isOpenEdit) {
      bodyClass.add("vg-no-scroll");
    } else {
      bodyClass.remove("vg-no-scroll");
    }
  }

  render() {
    if (this.props.loaded) {
      if (!this.props.UsersRede.length) {
        return (
          <div>
            <h3>{intl.get("UNIDADES_REDES.LISTAGEM_VAZIA")}</h3>
          </div>
        );
      }
      return (
        <div>
          <ul>
            {map(this.props.UsersRede, (funcionario) => (
              <li
                data-id={funcionario.id}
                key={funcionario.id}
                className="vg-content-box vg-container-user-infos"
              >
                {/*<IconStatus statusColor="green" />*/}
                <div className="vg-content-user">
                  <div className="vg-content-user-infos">
                    <p className="vg-content-user-infos-name">
                      {funcionario.nome}
                    </p>
                    <span className="vg-content-user-infos-created">
                      <span className="vg-content-user-infos-id">
                        <Icon iconKey="userRegular" />
                        {funcionario.identidade}
                      </span>
                      <span className="vg-content-user-infos-email">
                        <Icon iconKey="enviar_email" />
                        {funcionario.email}
                      </span>
                    </span>
                  </div>
                  <div className="vg-content-user-type">
                    {funcionario.perfil}
                  </div>
                  <a
                    className="vg-link-edit"
                    onClick={() =>
                      this.toggleModalEdit(
                        funcionario.id,
                        funcionario.nome,
                        funcionario.identidade,
                        funcionario.email,
                        funcionario.perfil,
                      )
                    }
                  >
                    {intl.get("UNIDADES_REDES.LISTAGEM.BOTAO_EDITAR")}
                  </a>
                  <Button
                    onClick={() => this.toggleModal(funcionario.id)}
                    className="vg-btn-danger vg-btn-outline"
                  >
                    {intl.get("UNIDADES_REDES.LISTAGEM.BOTAO_EXCLUIR")}
                  </Button>
                </div>
              </li>
            ))}
          </ul>
          <Modal
            contentLabel={this.state.titleModal}
            show={this.state.isOpen}
            onClose={this.toggleModal}
          >
            <UsersRedesRemove
              funcionarioID={this.state.funcionarioID}
              onClose={this.toggleModal}
              redeId={this.props.rede.id}
            />
          </Modal>
          <Modal
            contentLabel={this.state.titleModalEdit}
            show={this.state.isOpenEdit}
            onClose={this.toggleModalEdit}
          >
            <UserRedesForm
              onClose={this.toggleModalEdit}
              funcionarioID={this.state.funcionarioID}
              funcionarioNome={this.state.funcionarioNome}
              funcionarioIdentidade={this.state.funcionarioIdentidade}
              funcionarioEmail={this.state.funcionarioEmail}
              funcionarioPerfil={this.state.funcionarioPerfil}
              rede={this.props.rede}
            />
          </Modal>
        </div>
      );
    }
    return <Loader />;
  }
}

const mapStateToProps = (state) => ({
  UsersRede: state.UsersRede.data.funcionarios,
  loaded: state.UsersRede.loaded,
});

UsersListItem.defaultProps = {
  UsersRede: [],
  loaded: false,
};

UsersListItem.propTypes = {
  indexSelected: propTypes.number.isRequired,
  rede: propTypes.object.isRequired,
  UsersRede: propTypes.array.isRequired,
  receive: propTypes.func.isRequired,
  loaded: propTypes.bool.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch),
)(UsersListItem);
