import intl from "react-intl-universal";

export const uploadTypes = {
  DELETING: "delete",
  ERROR: "error",
  FAILED: "failed",
  LOADING: "loading",
  UPLOADED_FILE: "concluded",
  VALID_FILE: "success",
};

const typeNotSupported = "type_not_supported";
const sizeNotSupported = "max_size_limit_exceeded";
const fileMaxSize = Math.pow(10, 8);

export function reducerPdfFile(state, { type, payload }) {
  switch (type) {
    case "initialState":
      return {
        ...state,
        enableSaving: false,
        division: payload.divisionId,
        initialName: payload.name,
        initialUrl: payload.url,
        name: payload.name,
        saved: payload.saved,
        termId: payload.termId,
        url: payload.url,
      };
    case "newFile":
      return newFile(state, payload);
    case "deleteFile":
      return deleteFile(state, payload);
    case "emptyField":
      return emptyField(state);
    case "disableSaving":
      return {
        ...state,
        enableSaving: false,
      };
    case "enableSaving":
      return {
        ...state,
        enableSaving: true,
      };
    case "disableCancelUpload":
      return {
        ...state,
        enableCancelUpload: false,
        enableSaving: false,
      };
    case "uploadStatus":
      return upload(state, payload);
    case "submit":
      return {
        ...state,
        shouldSubmit: payload.submit,
      };
    case "cancelUpload":
      return {
        ...state,
        name: state.initialName,
        url: state.initialUrl,
        uploadStatus: uploadTypes.SUCCESS,
      };
    default:
      return state;
  }
}

function newFile(state, payload) {
  const msgSuccess = intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.SUCCESS");
  const { errorsList, messages } = listErrors(
    payload.file.type,
    payload.file.size
  );

  let msgPdfUpload = [];
  let status = uploadTypes.VALID_FILE;

  if (errorsList.length) {
    status = uploadTypes.ERROR;
    msgPdfUpload.push(messages);
  } else {
    msgPdfUpload.push(msgSuccess);
  }

  return {
    ...state,
    errors: errorsList,
    file: payload.file,
    fileBase64: payload.fileBase64,
    messages: msgPdfUpload,
    name: payload.file.name,
    uploadStatus: status,
    url: "",
    saved: false,
  };
}

const listErrors = (type, size) => {
  const textSize = intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.SIZE_ERROR");
  const textType = intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.TYPE_ERROR");

  let errorsList = [];
  let messages = [];

  if (type != "application/pdf") {
    errorsList.push(typeNotSupported);
    messages.push(textType);
  }

  if (size > fileMaxSize) {
    errorsList.push(sizeNotSupported);
    messages.push(textSize);
  }
  return { errorsList, messages };
};

function emptyField(state) {
  const hasSavedTerm = state.initialName.length ? true : false;

  return {
    ...state,
    errors: [],
    file: "",
    name: state.initialName,
    fileBase64: "",
    uploadStatus: uploadTypes.UPLOADED_FILE,
    termId: state.termId,
    url: state.initialUrl,
    saved: hasSavedTerm,
    enableCancelUpload: false,
  };
}

function upload(state, payload) {
  const uploadMessagesTypes = {
    CONCLUDED: intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.CONCLUDED"),
    FAILED: intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.FAILED"),
    LOADING: intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.LOADING"),
  };

  const enableCancel = payload.uploadStatus === uploadTypes.LOADING;
  const messages = [uploadMessagesTypes[payload.uploadStatus.toUpperCase()]];

  return {
    ...state,
    enableCancelUpload: enableCancel,
    enableSaving: false,
    messages: messages,
    uploadPercentage: payload.uploadPercentage,
    uploadStatus: payload.uploadStatus,
  };
}

export async function uploadPdf(file, dispatchPdfFile) {
  const fileBase64 = await toBase64(file);
  dispatchPdfFile({
    type: "newFile",
    payload: { file: file, fileBase64: fileBase64 },
  });
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function deleteFile(state) {
  const message = intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.DELETING");

  return {
    ...state,
    enableCancelUpload: false,
    enableSaving: true,
    errors: [],
    file: "",
    fileBase64: "",
    messages: [message],
    name: "",
    uploadPercentage: 100,
    saved: false,
    uploadStatus: uploadTypes.DELETING,
    url: "",
    shouldSubmit: true,
  };
}
