import React from "react";
import { node, bool, string } from "prop-types";
import { Transition } from "react-transition-group";
import "./Alert.scss";

const Alert = ({ children, animate, altClass }) => (
  <Transition
    in={animate}
    timeout={300}
    mountOnEnter={true}
    unmountOnExit={true}
  >
    {(state) => (
      <div className={`vg-alert-animate-${state} vg-alert ${altClass}`}>
        {children}
      </div>
    )}
  </Transition>
);

Alert.defaultProps = {
  altClass: "",
};

Alert.propTypes = {
  message: node,
  animate: bool,
  children: string,
  altClass: string,
};

export default Alert;
