import React, { useEffect } from "react";
import propTypes from "prop-types";
import { GraphCandidateV25 } from "../../index";
import "./Graphs.scss";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Professionals";

const mapStateToProps = (state) => ({
  professionals: state.Professionals.data.acessos,
  loaded: state.Professionals.loaded,
  loading: state.Professionals.loading,
});


const GraphV25 = ({ receive, loaded, loading }) => {
  useEffect(() => {
    receive();
  }, []);

  return !loading && loaded ? (
    <GraphCandidateV25 />
  ) : null;
};

GraphV25.propTypes = {
  receive: propTypes.func.isRequired,
  professionals: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
};

export default connect(mapStateToProps, { receive })(GraphV25);
