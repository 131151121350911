import "./CollapseDrag.scss";

import React, { Component } from "react";

import { Transition } from "react-transition-group";
import propTypes from "prop-types";

const phases = {
  entered: {
    opacity: 1,
    maxHeight: "5000px",
    paddingTop: "30px",
    paddingBottom: "30px",
    transform: "translate3d(0, 0, 0)",
  },
  entering: {
    height: 0,
    maxHeight: 0,
    opacity: 0,
    paddingTop: "0",
    paddingBottom: "0",
  },
  exited: {
    height: 0,
    maxHeight: 0,
    opacity: 0,
    paddingTop: "0",
    paddingBottom: "0",
  },
  exiting: {
    height: 0,
    maxHeight: 0,
    opacity: 0,
    paddingTop: "0",
    paddingBottom: "0",
  },
};

export class CollapseDragContent extends Component {
  render() {
    const { opened, children, item } = this.props;
    const Children = React.cloneElement(children, { item: item });
    return (
      <Transition in={opened} appear timeout={200} unmountOnExit mountOnEnter>
        {(status) => (
          <div
            className={"vg-animation vg-collapse-content"}
            style={phases[status]}
          >
            {Children}
          </div>
        )}
      </Transition>
    );
  }
}

CollapseDragContent.propTypes = {
  opened: propTypes.bool.isRequired,
  item: propTypes.object.isRequired,
  children: propTypes.node.isRequired,
};

export default CollapseDragContent;
