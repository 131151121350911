// Action Types
const LOAD_VACANCIES_REQUEST_STATUS = "services/LOAD_VACANCIES_REQUEST_STATUS";
const LOAD_VACANCIES_REQUEST_STATUS_SUCCESS =
  "services/LOAD_VACANCIES_REQUEST_STATUS_SUCCESS";
const LOAD_VACANCIES_REQUEST_STATUS_FAIL =
  "services/LOAD_VACANCIES_REQUEST_STATUS_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: {},
};

// Reducer
export default function VacanciesRequestStatus(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case LOAD_VACANCIES_REQUEST_STATUS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_VACANCIES_REQUEST_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_VACANCIES_REQUEST_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [
      LOAD_VACANCIES_REQUEST_STATUS,
      LOAD_VACANCIES_REQUEST_STATUS_SUCCESS,
      LOAD_VACANCIES_REQUEST_STATUS_FAIL,
    ],
    promise: (client) => client.get(path),
  };
}
