import propTypes from "prop-types";
import React, { Component } from "react";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { map } from "lodash";
import shortid from "shortid";
import { Icon, FormatDate, SplitText } from "../../index";
import "./FluxoStatus.scss";

class RequisitionStatusList extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { listStatus } = this.props;
    return map(listStatus, (status, index) => {
      const total = listStatus.length;
      return (
        <li
          className={`vg-requisition-status-item ${status.status_aprovacao}`}
          key={shortid.generate()}
          style={{ zIndex: `${total - index}` }}
        >
          {status.status_aprovacao != "aprovacao_pendente" && (
            <Icon iconKey={status.status_aprovacao} />
          )}
          <span className="vg-status-desc">
            {intl.get("REQUISICAO_DE_VAGAS.MODAL.STATUS_APROVACAO.TITULO")}
          </span>
          <p className="vg-status-name">
            {status.nome_aprovador}
            <span> ({status.email_aprovador})</span>
          </p>
          {status.status_aprovacao === "aguardando_aprovacao" ? (
            <span className="vg-status-date">
              {intl.get(
                `REQUISICAO_DE_VAGAS.MODAL.STATUS_APROVACAO.${intlReplace(
                  status.status_aprovacao,
                )}`,
              )}
            </span>
          ) : status.status_aprovacao === "aprovada_por_este_aprovador" ? (
            <span>
              <span className="vg-status-date">
                {intl.get(
                  "REQUISICAO_DE_VAGAS.MODAL.STATUS_APROVACAO.PUBLICADA",
                )}

                <FormatDate
                  date={status.data_avaliacao}
                  time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                />
              </span>
              <span className="vg-status-justification">
                {!!status.justificativa && (
                  <SplitText text={status.justificativa} />
                )}
              </span>
            </span>
          ) : (
            status.status_aprovacao === "reprovada_por_este_aprovador" && (
              <span>
                <span className="vg-status-date">
                  {intl.get(
                    `REQUISICAO_DE_VAGAS.MODAL.STATUS_APROVACAO.${intlReplace(
                      status.status_aprovacao,
                    )}`,
                  )}
                  <FormatDate
                    date={status.data_avaliacao}
                    time={"REQUISICAO_DE_VAGAS.MODAL.CRIACAO.HORA"}
                  />
                </span>
                {!!status.justificativa && (
                  <span className="vg-status-justification">
                    <b>
                      {intl.get("REQUISICAO_DE_VAGAS.MODAL.CANCELADA.MOTIVO")}{" "}
                    </b>
                    {!!status.justificativa && (
                      <SplitText text={status.justificativa} />
                    )}
                  </span>
                )}
              </span>
            )
          )}
        </li>
      );
    });
  }
}
RequisitionStatusList.propTypes = {
  listStatus: propTypes.array,
};

export default RequisitionStatusList;
