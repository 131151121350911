export const acceptanceTermReducer = (state, action) => {
  switch (action.type) {
    case "updateTerm":
      return updateTerm(state, action.payload);
    case "changeConfiguration":
      return changeConfiguration(state, action.payload);
    case "loadTerms":
      return loadTerms(state, action.payload);
    case "loadInitialTerms":
      return loadInitialTerms(action.payload);
    default:
      throw new Error("actionNotFound");
  }
};

const updateTerm = (state, { divisionId, term, companyId, type, termId }) => {
  const newTerm = term == "" ? "" : nameFile(companyId, term);
  const index = state.findIndex((division) => division.id == divisionId);
  const newState = [...state];

  newState[index] = {
    ...newState[index],
    termId: termId,
    term: newTerm,
    termUrl: term,
    type: type,
  };

  return newState;
};

const changeConfiguration = (state, { configuration }) => {
  let newState = [...state];
  if (configuration == "empresa") {
    deleteDivisionTerms(newState);
  } else {
    deleteCompanyTerm(newState);
  }
  return newState;
};

const deleteDivisionTerms = (newState) => {
  return newState.map((item) => {
    if (item.id != 0) {
      item.term = "";
      item.termUrl = "";
      item.type = "";
      item.termId = "";
    }
  });
};

const deleteCompanyTerm = (newState) => {
  let termCompany = newState.find((item) => item.id == 0);
  termCompany.term = "";
  termCompany.termUrl = "";
  termCompany.type = "";
  termCompany.termId = "";

  return termCompany;
};

const loadTerms = (state, { terms }) => {
  const newState = [...state];

  terms.forEach((term) => {
    let divisionId = term.divisao_id || "";
    let index = newState.findIndex((division) =>
      term.divisao_id ? division.id == divisionId : division.id == 0
    );
    if (term.termo) {
      newState[index].term = nameFile(term.empresa_id, term.termo);
      newState[index].termUrl = term.termo;
      newState[index].type = term.tipo;
      newState[index].termId = term.id;
    }
  });
  return newState;
};

const loadInitialTerms = ({ divisions }) => {
  const divisionTerms = divisions.map((division) => ({
    name: division.nome,
    id: division.id,
    term: "",
    termUrl: "",
    type: "",
  }));
  const companyTerms = [
    ...divisionTerms,
    { name: "company", id: 0, term: "", termUrl: "", type: "" },
  ];

  return companyTerms;
};

const nameFile = (companyId, term) => {
  let initialName = term;
  if (initialName) {
    let nameIndex = initialName.search(`-${companyId}-`);

    if (nameIndex == -1) {
      return term;
    } else {
      let lengthCompany = companyId.toString().length + 1;

      let initialSlice = nameIndex + lengthCompany + 1;

      return initialName.slice(initialSlice);
    }
  }
};
