import React, { useState, useEffect } from "react";
import { string, func, array, bool } from "prop-types";
import "./SelectGrade.scss";

const SelectGrade = ({
  onChangeSelect,
  data,
  selected,
  name,
  valideSelect,
  showError,
  handleValidateOnBlur,
}) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState(false);

  const handleChange = (event) => {
    onChangeSelect(event.target.value);
    setValue(event.target.value);
  };

  useEffect(() => {
    setValue(selected);
  }, [selected]);

  useEffect(() => {
    setError(valideSelect);
  }, [valideSelect]);

  useEffect(() => {
    setError(showError);
  }, [showError]);

  const validateSelect = (event) => {
    handleValidateOnBlur();
    setError(false);
    if (event.target.value === "") {
      setError(true);
    }
  };

  return (
    <div
      className={`vg-custom-select vg-custom-select-grade ${
        error ? "error" : ""
      }`}
    >
      <select
        name={name}
        value={value}
        onChange={(e) => handleChange(e)}
        onBlur={(e) => validateSelect(e)}
      >
        {data.length > 0
          ? data.map((item) => (
              <option key={item.id} value={item.id}>
                {item.nome}
              </option>
            ))
          : ""}
      </select>
    </div>
  );
};

SelectGrade.defaultProps = {
  selected: "",
  data: [],
  name: "",
  onChangeSelect: () => {},
  valideSelect: false,
  showError: false,
  handleValidateOnBlur: () => {},
};

SelectGrade.propTypes = {
  onChangeSelect: func,
  selected: string,
  data: array.isRequired,
  name: string,
  valideSelect: bool,
  showError: bool,
  handleValidateOnBlur: func,
};

export default SelectGrade;
