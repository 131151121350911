import propTypes from "prop-types";
import React, { Component } from "react";
import { Button, Icon } from "../../index";
import onClickOutside from "react-onclickoutside";
import "./DropDownMenu.scss";
import Context from "./context";

class DropDownMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false,
    };
  }

  handleClickOutside = () => {
    // Evita re-render do componente, caso já esteja fechado
    if (this.state.isToggleOn) this.setState({ isToggleOn: false });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  };

  render() {
    const {
      cssClasses,
      iconLeft,
      iconLeftClass,
      label,
      iconRight = "dots",
    } = this.props;

    return (
      <div
        className={`vg-dropdown-menu ${this.state.isToggleOn && "vg-active"}`}
      >
        <Button
          className={`${cssClasses.join(" ")} vg-dropdown-button`}
          onClick={this.toggleMenu}
          aria-label="menu da vaga"
          data-testid="menu da vaga"
        >
          {iconLeft && <Icon iconKey={iconLeft} addClass={iconLeftClass} />}
          {label && <span className="vg-dropdown-button-label"> {label} </span>}
          <Icon iconKey={iconRight} />
        </Button>

        <Context.Provider value={{ closeSelf: this.handleClickOutside }}>
          {this.props.children}
        </Context.Provider>
      </div>
    );
  }
}

DropDownMenu.defaultProps = {
  cssClasses: ["vg-btn-primary", "vg-btn-outline"],
};

DropDownMenu.propTypes = {
  cssClasses: propTypes.array,
  children: propTypes.any,
  iconLeft: propTypes.string,
  iconLeftClass: propTypes.string,
  iconRight: propTypes.string,
  label: propTypes.string,
};

export default onClickOutside(DropDownMenu);
