import React, { Component } from "react";
import propTypes from "prop-types";
import { Button, InputNumber, Checkbox, Radio, Toast } from "../../index";
import { map, find } from "lodash";
import shortid from "shortid";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive, post } from "../../../store/modules/Sla";
import { toast } from "react-toastify";
import "./SlaList.scss";

const mapStateToProps = (state) => ({
  sla: state.Sla.data.sla,
});

class SlaListContent extends Component {
  constructor(props) {
    super(props);
    const { division } = props;

    // Estado do componente
    this.state = {
      criterio: division.sla !== undefined ? division.sla.criterio_id : 1,
      defaultValueInputNumber: 15,
      modo_contagem:
        division.sla !== undefined ? division.sla.modelo_contagem_id : 1,
      minValueInputNumber: 1,
      controlLevel: division.sla !== undefined ? division.sla.tipo : null,
      checked: props.slaActive,
      // Descomentar os trechos comentados quando for possível customizar o SLA na nova publicação
      // slaType:
      //   division.sla !== undefined
      //     ? division.sla.customizado_por_vaga
      //       ? 'sla-customizado'
      //       : 'padrao'
      //     : null,
      slaType: "padrao",
    };

    this.radios = [
      ["controlLevel", "padrao", "SLA.CONFIGURAR.RADIO_6"],
      ["controlLevel", "hierarquia", "SLA.CONFIGURAR.RADIO_2"],
    ];

    this.radios_2 = [["controlLevel", "padrao", "SLA.CONFIGURAR.RADIO_6"]];

    // Bind dos métodos
    this.handleClick = this.handleClick.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.handleRadios = this.handleRadios.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleSlaType = this.handleSlaType.bind(this);
  }

  // Método utilizado para exibir os feedbacks dos usuários
  showNotifications(message) {
    toast(<Toast message={message} type="success" />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: "sr-toast vg-toast-success",
    });
  }

  // Método utilizado para efetuar o POST na API
  handleClick() {
    const { post, division, reloadDivisions, slaActive } = this.props;
    const inputs = this.parent.querySelectorAll('input[type="text"]');

    let values;

    if (this.state.controlLevel === "hierarquia") {
      values = [...inputs].map((input) => ({
        id: shortid.generate(),
        hierarquia_id: input.name,
        dias: input.value,
      }));
    }

    var data = {
      divisao_id: parseInt(division.id),
      criterio_id: parseInt(this.state.criterio),
      modelo_contagem_id: parseInt(this.state.modo_contagem),
      ativo: this.state.checked,
      tipo: this.state.controlLevel,
      customizado_por_vaga: this.state.slaType === "padrao" ? false : true,
    };

    Object.assign(
      data,
      this.state.controlLevel === "padrao"
        ? {
            dias_padrao: data.customizado_por_vaga
              ? 0
              : Number(inputs[0].value),
          }
        : { niveis: values },
    );

    post(data).then((success) => {
      reloadDivisions(success, division.id, "sla");

      if (!this.state.checked && !slaActive) {
        return false;
      }

      this.showNotifications(
        intl.getHTML(
          `SLA.NOTIFICACOES.${
            this.state.checked === slaActive
              ? "ATUALIZADO"
              : this.state.checked && !slaActive
              ? "ATIVADO"
              : "DESATIVADO"
          }`,
          { divisao: division.nome },
        ),
      );
    });
  }

  // Método utilizado para habilitar e desabilitar o SLA
  handleCheck() {
    this.setState(
      (prevState) => ({
        checked: !prevState.checked,
      }),
      () => {
        !this.state.checked && this.handleClick();
      },
    );
  }

  handleRadios(e) {
    this.setState({
      controlLevel: e.target.value,
    });
  }

  handleSelect(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSlaType(e) {
    this.setState({ slaType: e.target.value });
  }

  render() {
    const { checked, controlLevel, slaType } = this.state;
    const { slaActive, criteria, counting, hierarchy, division } = this.props;

    return (
      <div
        className="vg-sla-content-setting vg-row"
        ref={(c) => {
          this.parent = c;
        }}
      >
        <div className="vg-col-xs-12 vg-col-md-10 vg-col-md-offset-1">
          <div className="vg-row middle-xs">
            <div className="vg-col-xs-12 vg-col-sm-8">
              <h3>{intl.get("SLA.CONFIGURAR.TITULO_1")}</h3>
            </div>
            <div className="vg-col-sm-4 vg-text-right">
              <Checkbox
                name="activeSla"
                initial={slaActive}
                checked={checked}
                onCheck={this.handleCheck}
              />
            </div>
          </div>
          <div className={`vg-row middle-xs ${!checked && "vg-disabled"}`}>
            <div className="vg-col-xs-12 vg-col-sm-8">
              <h3>{intl.get("SLA.CONFIGURAR.TITULO_2")}</h3>
            </div>
            <div className="vg-col-xs-12 vg-col-sm-4">
              <div className="vg-custom-select">
                <select
                  value={this.state.criterio}
                  onChange={this.handleSelect}
                  name="criterio"
                >
                  {map(criteria, (option) => (
                    <option value={option.id} key={shortid.generate()}>
                      {option.descricao}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={`vg-row middle-xs ${!checked && "vg-disabled"}`}>
            <div className="vg-col-xs-12 vg-col-sm-8">
              <h3>{intl.get("SLA.CONFIGURAR.TITULO_3")}</h3>
            </div>
            <div className="vg-col-xs-12 vg-col-sm-4">
              <div className="vg-custom-select">
                <select
                  value={this.state.modo_contagem}
                  onChange={this.handleSelect}
                  name="modo_contagem"
                >
                  {map(counting, (option) => (
                    <option value={option.id} key={shortid.generate()}>
                      {option.descricao}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className={`vg-row ${!checked && "vg-disabled"}`}>
            <div className="vg-col-xs-12">
              <h3>{intl.get("SLA.CONFIGURAR.TITULO_5")}</h3>
              <p className="vg-subtitle-sla">
                {intl.get("SLA.CONFIGURAR.SUBTITULO_5")}
              </p>
              <div className="vg-sla-box-setting">
                <div className="vg-custom-radio vg-custom-radio-sla">
                  <label className="vg-label vg-label-sla">
                    <input
                      type="radio"
                      value="padrao"
                      name="sla"
                      // checked={slaType === 'padrao'}
                      checked
                      onChange={this.handleSlaType}
                    />
                    <span className="vg-label-text">
                      {intl.get("SLA.CONFIGURAR.RADIO_3")}
                    </span>
                  </label>
                  {/* <label className="vg-label vg-label-sla">
                    <input
                      type="radio"
                      value="sla-customizado"
                      name="sla"
                      checked={slaType === 'sla-customizado'}
                      onChange={this.handleSlaType}
                    />
                    <span className="vg-label-text">
                      {intl.get('SLA.CONFIGURAR.RADIO_4')}
                    </span>
                  </label> */}
                </div>
              </div>
            </div>
          </div>
          {slaType === "padrao" ? (
            <div className={`vg-row ${!checked && "vg-disabled"}`}>
              <div className="vg-col-xs-12">
                <h3>{intl.get("SLA.CONFIGURAR.TITULO_4")}</h3>
                <div className="vg-sla-box-setting">
                  <Radio
                    inputs={this.radios}
                    intialChecked={controlLevel}
                    handleRadioCheck={this.handleRadios}
                    id={this.props.division.id}
                  />

                  {controlLevel === "padrao" ? (
                    <div className="vg-sla-box-type">
                      <InputNumber
                        name="0"
                        defaultValue={
                          division.sla !== undefined
                            ? slaType === "padrao" &&
                              division.sla.dias_padrao === 0
                              ? this.state.defaultValueInputNumber
                              : division.sla.dias_padrao !== 0
                              ? division.sla.dias_padrao
                              : this.state.defaultValueInputNumber
                            : this.state.defaultValueInputNumber
                        }
                        min={this.state.minValueInputNumber}
                        i18nLabelSuffix="SLA.INPUT_NUMBER"
                      />
                    </div>
                  ) : null}

                  {controlLevel === "hierarquia" ? (
                    <div className="vg-sla-box-type vg-third">
                      {map(hierarchy, (input) => {
                        const item =
                          division.sla !== undefined
                            ? find(
                                division.sla.niveis,
                                (item) => item.hierarquia_id === input.id,
                              )
                            : false;

                        return (
                          <InputNumber
                            name={input.id}
                            defaultValue={
                              item
                                ? item.dias
                                : this.state.defaultValueInputNumber
                            }
                            min={this.state.minValueInputNumber}
                            i18nLabelPrefix={input.descricao}
                            i18nLabelSuffix="SLA.INPUT_NUMBER"
                            key={shortid.generate()}
                          />
                        );
                      })}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : null}
          {slaType === "sla-customizado" ? (
            <div className={`vg-row ${!checked && "vg-disabled"}`}>
              <div className="vg-col-xs-12">
                <h3>{intl.get("SLA.CONFIGURAR.TITULO_4")}</h3>
                <div className="vg-sla-box-setting">
                  <Radio
                    inputs={this.radios_2}
                    intialChecked={controlLevel}
                    handleRadioCheck={this.handleRadios}
                    id={this.props.division.id}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <Button
            onClick={this.handleClick}
            disabled={!controlLevel}
            className="vg-btn-success"
          >
            {intl.get("SLA.CONFIGURAR.BOTAO")}
          </Button>
        </div>
      </div>
    );
  }
}

SlaListContent.defaultProps = {
  division: {},
};

SlaListContent.propTypes = {
  division: propTypes.object.isRequired,
  active: propTypes.func.isRequired,
  reloadDivisions: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  slaActive: propTypes.bool.isRequired,
  criteria: propTypes.array.isRequired,
  counting: propTypes.array.isRequired,
  hierarchy: propTypes.array.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, post }, dispatch),
)(SlaListContent);
