import React, { Component } from "react";
import intl from "react-intl-universal";
import { Button, Icon, FilterSelect, FiveStars } from "../../index";
import {
  updateFilterRating,
  updateFilterProcess,
  updateFilterName,
} from "../../../store/modules/VideoInterviewListOrderFilterProcessed";
import { receiveWithFilter } from "../../../store/modules/VideoInterviewListProcessed";
import { receiveWithFilterNotProcessed } from "../../../store/modules/VideoInterviewListNotProcessed";
import { Form, Field, FormSpy } from "react-final-form";
import { debounce, isEqual } from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./SearchInterview.scss";
import propTypes from "prop-types";

const mapStateToProps = (state) => ({
  filterRating: state.VideoInterviewListOrderFilterProcessed.filters.rating,
  filterProcess: state.VideoInterviewListOrderFilterProcessed.filters.process,
  filterName: state.VideoInterviewListOrderFilterProcessed.filters.name,
  interviewList: state.VideoInterviewListProcessed.data,
});

class SearchInterview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: false,
      values: {},
    };

    this.toggleFilter = this.toggleFilter.bind(this);
    this.handleSearchForm = this.handleSearchForm.bind(this);
    this.submit = debounce(() => {
      this.handleFilter();
    }, 800);
    this.handleFilter = this.handleFilter.bind(this);
  }

  toggleFilter() {
    this.setState((prevState) => ({ isToggleOn: !prevState.isToggleOn }));
  }
  handleSearchForm(values) {
    const valuesForm = values.values;
    const valuesFormEmpty = !Object.keys(valuesForm).length;
    const { updateFilterRating, updateFilterProcess, updateFilterName } =
      this.props;
    if (valuesForm.rating) {
      const equalRating = isEqual(valuesForm.rating, this.state.values.rating);
      if (!equalRating) {
        this.setState({
          values: valuesForm,
        });
        updateFilterRating(valuesForm.rating);
        this.submit();
      }
    }
    if (valuesForm.process) {
      const equalProcess = isEqual(
        valuesForm.process,
        this.state.values.process,
      );
      if (!equalProcess) {
        this.setState({
          values: valuesForm,
        });
        updateFilterProcess(valuesForm.process);
        this.submit();
      }
    }
    if (valuesForm.name || valuesFormEmpty) {
      const equalName = isEqual(valuesForm.name, this.state.values.name);
      if (!equalName || valuesFormEmpty) {
        this.setState({
          values: valuesForm,
        });
        updateFilterName(valuesForm.name);
        this.submit();
      }
    }
  }

  handleFilter() {
    const {
      filterProcess,
      filterRating,
      filterName,
      receiveWithFilter,
      receiveWithFilterNotProcessed,
      interviewList,
      video_entrevista,
    } = this.props;

    let options = [
      `processo_seletivo_id=${interviewList.processo_seletivo.id}`,
      "limite=10",
      "pagina=1",
      "ordem=desc",
    ];

    if (filterName) {
      options.push(`candidato_nome=${filterName}`);
    }

    if (video_entrevista && video_entrevista != 0) {
      options.push("video_entrevista_id=" + video_entrevista);
    }

    if (filterProcess.includes("convite_pendente")) {
      options.push("status[]=convite_pendente");
    }
    if (filterProcess.includes("prazo_expirado")) {
      options.push("status[]=prazo_expirado");
    }
    if (filterProcess.includes("entrevista_realizada")) {
      options.push("status[]=processado");
    }
    if (filterProcess.includes("entrevista_incompleta")) {
      options.push("status[]=entrevista_incompleta");
    }

    if (filterRating[0]) {
      options.push(`pontuacao[]=${filterRating.join("&pontuacao[]=")}`);
    }

    receiveWithFilter(
      `video-entrevistas/participantes?${options.join("&")}&processados=true`,
    );
    receiveWithFilterNotProcessed(
      `video-entrevistas/participantes?${options.join("&")}&processados=false`,
    );
  }

  render() {
    const { isToggleOn } = this.state;

    return (
      <Form
        onSubmit={({ values }) => this.handleSearchForm(values)}
        render={() => (
          <div className="vg-filter-partipants">
            <div className="vg-filter-partipants-container">
              <div className="vg-input-search">
                <Icon iconKey="search" />
                <Field
                  name="name"
                  id="name"
                  component="input"
                  placeholder={intl.get("VIDEO_ENTREVISTA.EMPRESA.BUSCA.INPUT")}
                />
              </div>
              <Button className="vg-open-filter" onClick={this.toggleFilter}>
                <Icon iconKey="filter" />
              </Button>
              <div
                className={`vg-all-filter ${isToggleOn && "vg-filter-open"}`}
              >
                <div className="vg-filter">
                  <FilterSelect
                    title={intl.get(
                      "VIDEO_ENTREVISTA.EMPRESA.BUSCA.AVALIACAO.LABEL",
                    )}
                  >
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="rating"
                            component="input"
                            type="checkbox"
                            value="5"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <FiveStars stars="5" />
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="rating"
                            component="input"
                            type="checkbox"
                            value="4"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <FiveStars stars="4" />
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="rating"
                            component="input"
                            type="checkbox"
                            value="3"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <FiveStars stars="3" />
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="rating"
                            component="input"
                            type="checkbox"
                            value="2"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <FiveStars clss stars="2" />
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="rating"
                            component="input"
                            type="checkbox"
                            value="1"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <FiveStars stars="1" />
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="rating"
                            component="input"
                            type="checkbox"
                            value="0"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.BUSCA.AVALIACAO.SEM_AVALIACAO",
                          )}
                        </span>
                      </label>
                    </div>
                  </FilterSelect>
                </div>
                <div className="vg-filter">
                  <FilterSelect
                    title={intl.get(
                      "VIDEO_ENTREVISTA.EMPRESA.BUSCA.STATUS.LABEL",
                    )}
                  >
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="process"
                            component="input"
                            type="checkbox"
                            value="entrevista_realizada"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.BUSCA.STATUS.ENTREVISTA_REALIZADA",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="process"
                            component="input"
                            type="checkbox"
                            value="entrevista_incompleta"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.BUSCA.STATUS.ENTREVISTA_INCOMPLETA",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="process"
                            component="input"
                            type="checkbox"
                            value="convite_pendente"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.BUSCA.STATUS.CONVITE_PENDENTE",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-check">
                      <label className="vg-label">
                        <span className="vg-input-checkbox">
                          <Field
                            name="process"
                            component="input"
                            type="checkbox"
                            value="prazo_expirado"
                          />
                          <span className="vg-input-checkbox-inner" />
                        </span>
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.BUSCA.STATUS.CONVITE_EXPIRADO",
                          )}
                        </span>
                      </label>
                    </div>
                  </FilterSelect>
                </div>
              </div>
            </div>
            <FormSpy
              onChange={(values) => this.handleSearchForm(values)}
              subscription={{ values: true }}
            />
          </div>
        )}
      />
    );
  }
}

SearchInterview.propTypes = {
  filterRating: propTypes.array,
  filterProcess: propTypes.array,
  filterName: propTypes.node,
  updateFilterRating: propTypes.func,
  updateFilterProcess: propTypes.func,
  updateFilterName: propTypes.func,
  receiveWithFilter: propTypes.func.isRequired,
  receiveWithFilterNotProcessed: propTypes.func.isRequired,
  interviewList: propTypes.object.isRequired,
  video_entrevista: propTypes.string,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      receiveWithFilter,
      receiveWithFilterNotProcessed,
      updateFilterRating,
      updateFilterProcess,
      updateFilterName,
    },
    dispatch,
  ),
)(SearchInterview);
