import React, { useState, Fragment } from "react";
import { Modal, Toast } from "../../index";
import propTypes from "prop-types";
import { P, Button, InputText, RadioButton } from "@vagastec/epartner-ui";
import ApiClient from "../../../utils/ApiClient";
import { toast } from "react-toastify";
import intl from "react-intl-universal";

import {
  VacancieLabel,
  AccordionContent,
  ButtonAccordion,
  BodyModal,
  VacanciesLoad,
  ButtonContainer,
  FormStyle,
} from "./VincularVagaStyle.js";

const VacanciesLoadComponente = () => {
  return (
    <VacanciesLoad>
      <svg xmlns="http://www.w3.org/2000/svg" width="252" height="80">
        <g fill="none" fillRule="evenodd">
          <path
            fill="#C8C8C8"
            fillRule="nonzero"
            d="M8 0C3.589 0 0 3.59 0 8s3.589 8 8 8c4.41 0 8-3.59 8-8s-3.59-8-8-8zm0 13.714A5.72 5.72 0 0 1 2.286 8 5.72 5.72 0 0 1 8 2.286 5.72 5.72 0 0 1 13.714 8 5.72 5.72 0 0 1 8 13.714z"
          />
          <rect width="198" height="16" x="24" fill="#E6E6E6" rx="8" />
          <path
            fill="#C8C8C8"
            fillRule="nonzero"
            d="M8 32c-4.411 0-8 3.59-8 8s3.589 8 8 8c4.41 0 8-3.59 8-8s-3.59-8-8-8zm0 13.714A5.72 5.72 0 0 1 2.286 40 5.72 5.72 0 0 1 8 34.286 5.72 5.72 0 0 1 13.714 40 5.72 5.72 0 0 1 8 45.714z"
          />
          <rect
            width="168"
            height="16"
            x="24"
            y="32"
            fill="#C8C8C8"
            opacity=".6"
            rx="8"
          />
          <path
            fill="#C8C8C8"
            fillRule="nonzero"
            d="M8 64c-4.411 0-8 3.59-8 8s3.589 8 8 8c4.41 0 8-3.59 8-8s-3.59-8-8-8zm0 13.714A5.72 5.72 0 0 1 2.286 72 5.72 5.72 0 0 1 8 66.286 5.72 5.72 0 0 1 13.714 72 5.72 5.72 0 0 1 8 77.714z"
          />
          <rect
            width="228"
            height="16"
            x="24"
            y="64"
            fill="#C8C8C8"
            opacity=".6"
            rx="8"
          />
        </g>
      </svg>
    </VacanciesLoad>
  );
};

const VincularVaga = ({
  toggleModalVeicular,
  showModalVeicular,
  requisitionId,
}) => {
  const [queryForNewVacancy, setQueryForNewVacancy] = useState("");
  const [querySearchForAttachment, setQuerySearchForAttachment] = useState("");

  const [listForAttachment, setListForAttachment] = useState({
    firstLoading: false,
    error: false,
    vacancyList: [],
    pagina_atual: 0,
    total_paginas: 0,
  });

  const [listForNewVacancy, setListForNewVacancy] = useState({
    firstLoading: false,
    error: false,
    vacancyList: [],
    pagina_atual: 0,
    total_paginas: 0,
  });

  const [showOtherVacancies, setShowOtherVacanies] = useState(false);

  const [paginating, setPagination] = useState(false);

  const [notFound, setNotFound] = useState(false);

  const getVacancies = (url, value = "", pag = 1) => {
    const setter =
      url === "sugestoes/cargos" ? setListForNewVacancy : setListForAttachment;

    /* Verifica qual lista será carregada e se ela está vazia para exibir o primeiro loader */
    if (
      url === "sugestoes/cargos" &&
      listForNewVacancy.vacancyList.length <= 0
    ) {
      setter((data) => ({
        ...data,
        firstLoading: true,
        error: false,
      }));
    }
    /* Verifica qual lista será carregada e se ela está vazia para exibir o primeiro loader */
    if (
      url === "sem_requisicao_vaga" &&
      listForAttachment.vacancyList.length <= 0
    ) {
      setter((data) => ({
        ...data,
        firstLoading: true,
        error: false,
      }));
    }

    if (value !== "" && pag === 1) {
      setter((data) => ({
        ...data,
        firstLoading: true,
        error: false,
        vacancyList: [],
      }));
    }
    if (pag > 1) setPagination(true);

    const client = new ApiClient();

    const params = {
      pagina: pag,
      tamanho: 10,
    };

    if (value) params.q = value;

    return client
      .get(
        url,
        {
          params,
        },
        process.env.REACT_APP_HESTIA
      )
      .then(({ data, pagina_atual, total_paginas }) => {
        if (data.length <= 0) {
          setNotFound(true);
        } else {
          setNotFound(false);
        }
        setPagination(false);

        setter((listState) => ({
          firstLoading: false,
          error: false,
          vacancyList:
            pag === 1 ? [...data] : [...listState.vacancyList, ...data],
          pagina_atual,
          total_paginas,
        }));
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setter({
          firstLoading: false,
          error: true,
        });
      });
  };

  const anexar = () => {
    new ApiClient()
      .post(
        "vincula_requisicao",
        {
          params: {
            vinculacao_requisicao_id: requisitionId,
            vinculacao_vaga_id: itemSelecionado.vacancieId,
          },
        },
        process.env.REACT_APP_HESTIA
      )
      .then((data) => {
        data.status === 200 &&
          (showNotifications(
            intl.getHTML("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.VAGA_VINCULADA", {
              vaga: itemSelecionado.cargo,
              id: itemSelecionado.vacancieId,
            }),
            "success"
          ),
          toggleModalVeicular());
      });
  };

  const [itemSelecionado, setItem] = useState({
    type: "",
    isSelected: false,
    vacancieId: 0,
    cargo: "",
  });

  const [checked, setChecked] = useState(-1);

  const getSelected = (item, type) => {
    setChecked(item.id);
    type === "showListForNewVacanies" && setShowOtherVacanies(true);
    type === "hideListForNewVacanies" && setShowOtherVacanies(false),
      // eslint-disable-next-line no-unused-vars
      setItem((data) => ({ isSelected: false }));
    if (item.id >= 1) {
      setItem({
        type: type,
        isSelected: true,
        vacancieId: item.id,
        cargo: item.cargo,
      });
    }
  };

  const onSubmitForNewVacancy = (e, url) => {
    e.preventDefault();
    getVacancies(url, queryForNewVacancy);
  };

  const onSubmitForAttachment = (e, url) => {
    e.preventDefault();
    getVacancies(url, querySearchForAttachment);
  };

  // Método utilizado para exibir os feedbacks dos usuários
  const showNotifications = (message, type = "success") => {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  };

  const redirecionar = () => {
    if (itemSelecionado.vacancieId === 1) {
      window.location.href = `/vagas/processos-seletivos/novo-processo?requisicao=${requisitionId}`;
    } else {
      window.location.href = `/vagas/processos-seletivos/novo-processo?requisicao=${requisitionId}&vaga-id=${itemSelecionado.vacancieId}`;
    }
  };

  const createItem = (item, type) => {
    return (
      <VacancieLabel key={item.id}>
        <RadioButton
          name="vaga"
          label={`${item?.titulo || item?.cargo} - v${
            item?.id_legado || item?.id
          } (${item?.vaga_modelo ? "vaga-modelo" : "vaga recorrente"}) `}
          value={item.id}
          currentChecked={checked}
          onChange={() => getSelected(item, type)}
        ></RadioButton>
      </VacancieLabel>
    );
  };

  return (
    <Modal
      show={showModalVeicular}
      onClose={toggleModalVeicular}
      small
      contentLabel={intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.TITULO")}
    >
      <BodyModal>
        <P>{intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.SUB_TITULO")}</P>

        <ButtonAccordion>
          {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.TAB_BUTTON_1")}
        </ButtonAccordion>
        <AccordionContent>
          <form>
            <VacancieLabel>
              <RadioButton
                name="vaga"
                label={intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.DO_ZERO")}
                value={1}
                currentChecked={checked}
                onChange={() =>
                  getSelected({ id: 1 }, "hideListForNewVacanies")
                }
              ></RadioButton>
            </VacancieLabel>

            <VacancieLabel>
              <RadioButton
                name="vaga"
                label={intl.get(
                  "REQUISICAO_DE_VAGAS.MODAL_VINCULAR.DE_UMA_VAGA"
                )}
                value={0}
                currentChecked={checked}
                onChange={() =>
                  getSelected({ id: 0 }, "showListForNewVacanies")
                }
              ></RadioButton>
            </VacancieLabel>
          </form>
          {showOtherVacancies && (
            <Fragment>
              <P>{intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.PESQUISAR")}</P>
              <FormStyle
                onSubmit={(e) => onSubmitForNewVacancy(e, "sugestoes/cargos")}
              >
                <InputText
                  icon={{ name: "search" }}
                  name="q"
                  component="input"
                  type="text"
                  placeholder={intl.get(
                    "REQUISICAO_DE_VAGAS.MODAL_VINCULAR.PLACEHOLDER_INPUT"
                  )}
                  onChange={(e) => setQueryForNewVacancy(e.target.value)}
                />
              </FormStyle>
              {listForNewVacancy.error && (
                <P>
                  {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.NAO_CARREGOU")}
                </P>
              )}
              {!listForNewVacancy.firstLoading && notFound && (
                <P>
                  {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.NAO_ENCONTROU")}
                </P>
              )}
              {listForNewVacancy.vacancyList &&
                listForNewVacancy.vacancyList.length >= 0 &&
                listForNewVacancy.vacancyList.map((item) =>
                  createItem(item, "criar")
                )}
              {listForNewVacancy.firstLoading && <VacanciesLoadComponente />}

              {!listForNewVacancy.firstLoading &&
                listForNewVacancy.pagina_atual <
                  listForNewVacancy.total_paginas && (
                  <Button
                    onClick={() =>
                      getVacancies(
                        "sugestoes/cargos",
                        queryForNewVacancy,
                        listForNewVacancy.pagina_atual + 1
                      )
                    }
                    kind="aux"
                    outline
                  >
                    {paginating && (
                      <box-icon name="loader-circle" animation="spin" />
                    )}
                    {intl.get(
                      "REQUISICAO_DE_VAGAS.MODAL_VINCULAR.BOTAO_CARREGAR_MAIS"
                    )}
                  </Button>
                )}
            </Fragment>
          )}
        </AccordionContent>

        <ButtonAccordion>
          {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.TAB_BUTTON_2")}
        </ButtonAccordion>

        <AccordionContent>
          <FormStyle
            onSubmit={(e) => onSubmitForAttachment(e, "sem_requisicao_vaga")}
          >
            <P>{intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.PESQUISAR")}</P>

            <InputText
              icon={{ name: "search" }}
              name="q"
              component="input"
              type="text"
              placeholder={intl.get(
                "REQUISICAO_DE_VAGAS.MODAL_VINCULAR.PLACEHOLDER_INPUT"
              )}
              onChange={(e) => setQuerySearchForAttachment(e.target.value)}
              required
            />
          </FormStyle>
          {listForAttachment.error && (
            <P>{intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.NAO_CARREGOU")}</P>
          )}
          {!listForAttachment.firstLoading && notFound && (
            <P>
              {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.NAO_ENCONTROU")}
            </P>
          )}
          {listForAttachment.vacancyList &&
            listForAttachment.vacancyList.length >= 0 &&
            listForAttachment.vacancyList.map((item) =>
              createItem(item, "vincular")
            )}
          {listForAttachment.firstLoading && <VacanciesLoadComponente />}
          {!listForAttachment.firstLoading &&
            listForAttachment.pagina_atual <
              listForAttachment.total_paginas && (
              <Button
                onClick={() =>
                  getVacancies(
                    "sem_requisicao_vaga",
                    querySearchForAttachment,
                    listForAttachment.pagina_atual + 1
                  )
                }
                kind="aux"
                outline
              >
                {paginating && (
                  <box-icon name="loader-circle" animation="spin" />
                )}
                {intl.get(
                  "REQUISICAO_DE_VAGAS.MODAL_VINCULAR.BOTAO_CARREGAR_MAIS"
                )}
              </Button>
            )}
        </AccordionContent>
        <ButtonContainer>
          <Button kind="aux" onClick={() => toggleModalVeicular()} outline>
            {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.CANCELAR")}
          </Button>
          <Button
            onClick={() =>
              itemSelecionado.type === "vincular" ? anexar() : redirecionar()
            }
            disabled={!itemSelecionado.isSelected}
          >
            {intl.get("REQUISICAO_DE_VAGAS.MODAL_VINCULAR.CONTINUAR")}
          </Button>
        </ButtonContainer>
      </BodyModal>
    </Modal>
  );
};

VincularVaga.propTypes = {
  showModalVeicular: propTypes.bool,
  toggleModalVeicular: propTypes.func,
  requisitionId: propTypes.number,
};

export default VincularVaga;
