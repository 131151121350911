import { useEffect, useReducer } from "react";
import { uploadTypes, reducerPdfFile } from "./reducerPdfFile";

export const usePdfFile = () => {
  const initialState = {
    enableCancelUpload: false,
    enableSaving: false,
    errors: [],
    division: "",
    file: "",
    fileBase64: "",
    initialName: "",
    initialUrl: "",
    messages: [],
    name: "",
    size: 0,
    shouldSubmit: false,
    termId: "",
    uploadPercentage: 100,
    uploadStatus: uploadTypes.UPLOADED_FILE,
    url: "",
    saved: false,
  };
  const [pdfFile, dispatchPdfFile] = useReducer(reducerPdfFile, initialState);

  useEffect(() => {
    allowSaving(pdfFile, dispatchPdfFile);
  }, [pdfFile.file]);

  return { pdfFile, dispatchPdfFile };
};

const allowSaving = (pdfFile, dispatchPdfFile) => {
  if (
    pdfFile.errors.length ||
    pdfFile.saved ||
    pdfFile.uploadStatus == uploadTypes.LOADING
  ) {
    dispatchPdfFile({ type: "disableSaving" });
  } else {
    dispatchPdfFile({ type: "enableSaving" });
  }
};
