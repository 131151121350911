import React from "react";
import "./NotFoundPage.scss";

export default function NotFoundPage() {
  return (
    <div className="vg-container">
      <h1>404</h1>
      <p>Sorry, the page you requested could not be found.</p>
    </div>
  );
}
