import Cookie from "js-cookie";
import { Icon } from "../components";
import React from "react";
import { forEach } from "lodash";
import history from "../utils/history";
import intl from "react-intl-universal";
import { prepareURl } from "../utils/MakeRoutes";
import superagent from "superagent";
import { toast } from "react-toastify";

const methods = ["get", "post", "put", "patch", "del"];
const LOCALES = {
  _enUS: "en",
  _ptBR: "pt-BR",
  _esALT: "es",
};

const defaultRequestOptions = (token_name) => {
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${Cookie.get(token_name)}`,
      "Accept-Language": `${LOCALES[Cookie.get("IntegracaoLoginIdioma")]}`,
      "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_CONTROL,
    },
  };
};

export default class ApiClient {
  constructor() {
    forEach(
      methods,
      (method) =>
        (this[method] = (
          path,
          options = {},
          domain = process.env.REACT_APP_ATHENA_URL,
          token = "access_token"
        ) =>
          new Promise((resolve, reject) => {
            const request = superagent[method](`${domain}${path}`);

            request.set(defaultRequestOptions(token).headers);

            if (options && options.params) {
              request.query(options.params);
            }

            if (options && options.data) {
              request.send(options && options.data);
            }

            request
              .then(({ body, status } = {}) => {
                return resolve(body === null ? { status: status } : body);
              })
              .catch((err) => {
                if (err.status === 401) {
                  process.env.NODE_ENV === "development"
                    ? history.push("/fake-login")
                    : this.handleRedirect();
                }
                return reject(err);
              });
          }))
    );
  }

  redirect() {
    window.location =
      process.env.NODE_ENV === "development"
        ? "/fake-login"
        : prepareURl("finalizar-sessao");
  }

  handleRedirect() {
    if (history.location.hash !== "#isRedirect") {
      toast(
        <div className="vg-toast">
          <div className="vg-toast-icon">
            <Icon iconKey="toastError" />
          </div>
          <div className="vg-toast-content">
            {intl.getHTML("REDIRECIONAMENTO.TEXTO")}
          </div>
        </div>,
        {
          position: "bottom-right",
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          className: "sr-toast vg-toast-error",
          onClose: () => this.redirect(),
        }
      );

      history.push({ hash: "isRedirect" });
    }
  }

  empty() {}
}
