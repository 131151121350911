import propTypes from "prop-types";
import React, { Component } from "react";
import { Button, Icon } from "../../index";
import intl from "react-intl-universal";
import "./InputNumber.scss";

class InputNumber extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.defaultValue,
    };

    this.handleChange = this.handleChange.bind(this);
    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
  }

  handleChange(e) {
    const onlyNumber = /^[0-9\b]+$/;

    onlyNumber.test(e.target.value) &&
      this.setState({ value: parseInt(e.target.value) });

    (e.target.value === "" || e.target.value < this.props.min) &&
      this.setState({ value: this.props.min });
  }

  increase() {
    this.setState({ value: this.state.value + 1 });
  }

  decrease() {
    this.setState({
      value:
        this.state.value <= this.props.min
          ? this.state.value
          : this.state.value - 1,
    });
  }

  render() {
    const { name, i18nLabelSuffix, i18nLabelPrefix, min } = this.props;
    const { value } = this.state;

    return (
      <div className="vg-input-number">
        {i18nLabelPrefix && (
          <label
            htmlFor={name}
            className="vg-input-number-prefix vg-input-number-label-text"
          >
            {i18nLabelPrefix}
          </label>
        )}
        <Button className="vg-input-number-decrease" onClick={this.decrease}>
          <Icon iconKey="minor" />
        </Button>
        <input
          type="text"
          pattern="[0-9]"
          onChange={this.handleChange}
          value={value}
          name={name}
          id={name}
          min={min}
        />
        <Button className="vg-input-number-increase" onClick={this.increase}>
          <Icon iconKey="plus" />
        </Button>
        {i18nLabelSuffix && (
          <label
            htmlFor={name}
            className="vg-input-number-suffix vg-input-number-label-text"
          >
            {intl.get(i18nLabelSuffix, { value })}
          </label>
        )}
      </div>
    );
  }
}

InputNumber.defaultProps = {
  defaultValue: 0,
  name: "",
  min: 0,
};

InputNumber.propTypes = {
  defaultValue: propTypes.number.isRequired,
  name: propTypes.any.isRequired,
  i18nLabelSuffix: propTypes.string,
  i18nLabelPrefix: propTypes.string,
  min: propTypes.number,
};

export default InputNumber;
