// Action Types
const LOAD_PAINEL = "services/LOAD_PAINEL";
const LOAD_PAINEL_SUCCESS = "services/LOAD_PAINEL_SUCCESS";
const LOAD_PAINEL_FAIL = "services/LOAD_PAINEL_FAIL";

// InitialState
const initialState = {
  painel: {
    loaded: false,
    loading: false,
    data: {
      funcionario: {},
      cliente: {},
      acessos: {},
      feature_flags: [],
    },
  },
  vagas: {
    loaded: false,
    loading: false,
    data: { acessos: {} },
  },
  requisicoes: {
    loaded: false,
    loading: false,
    data: { acessos: {} },
  },
};

// Reducers
export default function panel(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_PAINEL:
      return {
        ...state,
        [action.receiveType]: {
          loading: true,
          error: false,
        },
      };
    case LOAD_PAINEL_SUCCESS:
      return {
        ...state,
        [action.receiveType]: {
          data: action.result,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    case LOAD_PAINEL_FAIL:
      return {
        ...state,
        [action.receiveType]: {
          loading: false,
          loaded: false,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

// Actions
export function load(type, path, token = "access_token") {
  return {
    types: [LOAD_PAINEL, LOAD_PAINEL_SUCCESS, LOAD_PAINEL_FAIL],
    promise: (client) =>
      client.get(path, {}, process.env.REACT_APP_ATHENA_URL, token),
    receiveType: type,
  };
}

function shouldFetch(state, type) {
  return state.Painel[type].loading
    ? false
    : Object.keys(state.Painel[type].data.acessos).length
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(type, path, token = "access_token") {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), type)) {
      return dispatch(load(type, path, token));
    }
  };
}
