import React, { useState, useEffect } from "react";
import ApiClient from "../../../utils/ApiClient";
import intl from "react-intl-universal";
import {
  styled,
  Container,
  device,
  BoxShadow,
  Button,
} from "@vagastec/epartner-ui";
import { AnimatedSelect } from "../SelectCreatable";

const Form = styled.form`
  background: ${({ theme }) => theme.colors.white};
  padding: 16px 0;

  div {
    min-width: calc(25% - 38px);
  }
`;

const ContainerFlex = styled((props) => <Container {...props} />)`
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.font.regular};
  justify-content: space-between;

  > div {
    margin-bottom: 16px;
    width: 100%;
  }

  ${device.desktop`
    flex-direction: row;

    > div {
      margin-bottom: 0;
      margin-right: 16px;
    }
  `}
`;

const StyledButton = styled.button`
  font-weight: bold;
  min-width: 188px;
`;

const SearchBar = () => {
  const [jobInputValue, setJobInputValue] = useState("");
  const [locationInputValue, setLocationInputValue] = useState("");
  const [keywordsInputValue, setKeywordsInputValue] = useState("");
  const [positions, setPositions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const fetchSuggestions = (value) => {
    const client = new ApiClient();
    return client
      .get(
        "busca_cidade",
        { params: { cidade: value } },
        process.env.REACT_APP_API_PESQUISA
      )
      .then(({ cidades }) => {
        return cidades.map((value) => {
          return {
            descricao: value.nome,
            id: value.nome,
          };
        });
      });
  };

  useEffect(() => {
    setIsDisabled(
      !(
        (positions && positions.length) ||
        (locations && locations.length) ||
        (keywords && keywords.length)
      )
    );
  }, [positions, locations, keywords]);

  const redirect = (state) => {
    const query = encodeURIComponent(JSON.stringify(state));

    window.location = `${
      process.env.NODE_ENV === "development"
        ? `${process.env.REACT_APP_ACCESS_CONTROL}:5001`
        : process.env.REACT_APP_ACCESS_CONTROL
    }/profissionais/pesquisa?q=${query}`;
  };

  return (
    <BoxShadow>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          redirect({ keywords, positions, locations });
        }}
      >
        <ContainerFlex>
          <AnimatedSelect
            type={"creatable"}
            inputId="cargos"
            icon={{ name: "briefcase", type: "solid" }}
            placeholder={intl.get("BUSCA_PROFISSIONAIS.CARGOS")}
            value={positions}
            setValue={setPositions}
            inputValue={jobInputValue}
            setInputValue={setJobInputValue}
            aria-label="pesquisar candidatos por cargo"
          />
          <AnimatedSelect
            type={"async"}
            loadOptions={fetchSuggestions}
            inputId="cidades"
            icon={{ name: "map", type: "solid" }}
            placeholder={intl.get("BUSCA_PROFISSIONAIS.LOCALIZACAO")}
            value={locations}
            setValue={setLocations}
            inputValue={locationInputValue}
            setInputValue={setLocationInputValue}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.descricao}
            loadingMessage={() => intl.get("BUSCA_PROFISSIONAIS.CARREGANDO")}
            noOptionsMessage={() =>
              intl.get("BUSCA_PROFISSIONAIS.SEM_RESULTADOS")
            }
            aria-label="pesquisar candidatos por localização"
          />
          <AnimatedSelect
            type={"creatable"}
            inputId="palavra_chave"
            icon={{ name: "hash", type: "solid" }}
            placeholder={intl.get("BUSCA_PROFISSIONAIS.PALAVRA_CHAVE")}
            value={keywords}
            setValue={setKeywords}
            inputValue={keywordsInputValue}
            setInputValue={setKeywordsInputValue}
            aria-label="pesquisar candidatos por palavra-chave"
          />
          <StyledButton
            as={Button}
            kind="primary"
            scale="small"
            disabled={isDisabled}
            data-testid="button-buscar-profissionais"
          >
            <span>{intl.get("BUSCA_PROFISSIONAIS.BOTAO")}</span>
          </StyledButton>
        </ContainerFlex>
      </Form>
    </BoxShadow>
  );
};

export default SearchBar;
