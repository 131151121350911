/* eslint-disable no-unused-vars */
import React from "react";
import AsyncSelect from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";
import { array, string, func, object, oneOf } from "prop-types";
import { styled } from "@vagastec/epartner-ui";

const Container = styled.div`
  position: relative;

  > box-icon {
    height: 18px;
    left: 14px;
    position: absolute;
    top: 12px;
    width: 18px;
    z-index: 1;
  }
`;

const animatedComponents = makeAnimated();

const components = {
  ...animatedComponents,
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

// ref: https://react-select.com/styles#style-object
const customStyles = (warning) => ({
  option: (provided) => ({
    ...provided,
    fontSize: "16px",
  }),

  control: (provided, state) => {
    return {
      ...provided,
      borderColor: warning ? "red" : "#c8c8c8",
      borderRadius: "2px",
      fontSize: "16px",
      padding: "2px",
      paddingLeft: "30px",
      boxShadow: state.isFocused ? "0 0 3px 1px #00adef" : "none",
      minHeight: "40px",

      "&:hover": {
        borderColor: warning ? "red" : "#c8c8c8",
      },
    };
  },
  multiValue: (provided) => {
    return {
      ...provided,
      fontSize: "18px",
    };
  },
  placeholder: (provided) => {
    return {
      ...provided,
      color: "#c8c8c8",
      fontSize: "16px",
    };
  },
});

export function AnimatedSelect({
  data = [],
  defaultValue = [],
  loadOptions,
  inputValue,
  setInputValue,
  value,
  setValue,
  label,
  warning,
  getOptionLabel,
  getOptionValue,
  loadingMessage,
  noOptionsMessage,
  icon,
  type,
  ...rest
}) {
  function handleChange(v) {
    setValue(v);
  }

  function handleInputChange(iV) {
    setInputValue(iV);
  }

  function patternIsValid(string) {
    return rest.pattern.test(string);
  }

  function handleKeyDown(event) {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        event.preventDefault();
        if (rest.pattern && !patternIsValid(inputValue)) return;

        setInputValue("");
        Array.isArray(value)
          ? setValue([...value, createOption(inputValue)])
          : setValue([createOption(inputValue)]);
        break;
      default:
        break;
    }
  }
  return (
    <Container>
      <box-icon {...icon} aria-hidden></box-icon>
      {type === "async" ? (
        <AsyncSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          onChange={handleChange}
          onInputChange={handleInputChange}
          value={value}
          styles={customStyles(warning)}
          defaultValue={defaultValue}
          loadOptions={loadOptions}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          loadingMessage={loadingMessage}
          noOptionsMessage={noOptionsMessage}
          {...rest}
        />
      ) : null}
      {type === "async-creatable" ? (
        <AsyncCreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          onChange={handleChange}
          onInputChange={handleInputChange}
          value={value}
          styles={customStyles(warning)}
          defaultValue={defaultValue}
          loadOptions={loadOptions}
          getOptionLabel={getOptionLabel}
          getOptionValue={getOptionValue}
          loadingMessage={loadingMessage}
          noOptionsMessage={noOptionsMessage}
          {...rest}
        />
      ) : null}
      {type === "creatable" ? (
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isClearable
          isMulti
          menuIsOpen={false}
          onKeyDown={handleKeyDown}
          onChange={handleChange}
          onInputChange={handleInputChange}
          value={value}
          styles={customStyles(warning)}
          defaultValue={defaultValue}
          {...rest}
        />
      ) : null}
    </Container>
  );
}

AnimatedSelect.propTypes = {
  data: array,
  loadOptions: func,
  defaultValue: array,
  inputValue: string,
  setInputValue: func,
  value: array,
  setValue: func,
  label: string,
  warning: string,
  getOptionLabel: func,
  getOptionValue: func,
  loadingMessage: func,
  noOptionsMessage: func,
  icon: object,
  isCreatable: func,
  type: oneOf(["async", "creatable", "async-creatable"]),
};
