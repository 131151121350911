// Action Types
const LOAD_MESSAGES = "services/LOAD_MESSAGES";
const LOAD_MESSAGES_SUCCESS = "services/LOAD_MESSAGES_SUCCESS";
const LOAD_MESSAGES_FAIL = "services/LOAD_MESSAGES_FAIL";
const POST = "services/POST";
const POST_SUCCESS = "services/POST_SUCCESS";
const POST_FAIL = "services/POST_FAIL";

// InitialState
const initialState = {
  loaded: false,
  data: {},
  postState: {
    error: {},
    loaded: false,
    loading: false,
  },
};

// Reducer
export default function messages(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_MESSAGES:
      return {
        ...state,
        loading: true,
        data: {},
      };
    case LOAD_MESSAGES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_MESSAGES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case POST:
      return {
        ...state,
        postState: {
          loading: true,
          loaded: false,
        },
      };
    case POST_SUCCESS:
      return {
        ...state,
        postState: {
          loading: false,
          loaded: true,
        },
      };
    case POST_FAIL:
      return {
        ...state,
        postState: {
          loading: false,
          loaded: true,
          error: action.error,
        },
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_MESSAGES, LOAD_MESSAGES_SUCCESS, LOAD_MESSAGES_FAIL],
    promise: (client) => client.get("mensagens-padrao"),
  };
}

export function post(path, data) {
  return {
    types: [POST, POST_SUCCESS, POST_FAIL],
    promise: (client) =>
      client.post(path, {
        data: data,
      }),
  };
}

function shouldFetch(state) {
  return state.Messages.loading
    ? false
    : state.Messages.data.mensagens_padrao
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
