import React from "react";
import propTypes from "prop-types";
import { Icon } from "../../index";
import { intlReplace } from "../../../utils/IntlReplace";
import { underscoreReplace } from "../../../utils/underscoreReplace";
import intl from "react-intl-universal";
import "./Status.scss";

const Status = ({ status = {}, vagaId }) => {
  const reqStatus = status.status;

  return (
    <p
      className={`vg-request-status vg-request-status-${underscoreReplace(
        reqStatus,
      )}`}
      title={
        status.motivo_cancelamento_outros
          ? status.motivo_cancelamento_outros
          : ""
      }
    >
      <Icon iconKey={reqStatus} />
      <span>
        {reqStatus === "cancelada" && status.motivo_cancelamento_descricao
          ? status.motivo_cancelamento_descricao
          : intl.get(`REQUISICOES_VAGAS.STATUS.${intlReplace(reqStatus)}`)}
      </span>
      {status.responsavel_aprovacao && (
        <span className="vg-request-responsible-approval">
          {status.responsavel_aprovacao}
        </span>
      )}
      {!!vagaId && reqStatus === "aprovada_com_vaga" && (
        <span className="vg-request-status-code">V{vagaId}</span>
      )}
    </p>
  );
};

Status.propTypes = {
  status: propTypes.object,
  vagaId: propTypes.number,
};

export default Status;
