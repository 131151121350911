// Action Types
const OPEN_MODAL_SHARE_VIDEO_INTERVIEW = "modals/share_video_interview/open";
const CLOSE_MODAL_SHARE_VIDEO_INTERVIEW = "modals/share_video_interview/close";
const POST_SHARE_VIDEO_INTERVIEW = "services/POST_SHARE_VIDEO_INTERVIEW";
const POST_SHARE_VIDEO_INTERVIEW_SUCCESS =
  "services/POST_SHARE_VIDEO_INTERVIEW_SUCCESS";
const POST_SHARE_VIDEO_INTERVIEW_FAIL =
  "services/OST_SHARE_VIDEO_INTERVIEW_FAIL";

// InitialState
const initialState = {
  isOpen: false,
  selectedIds: [],
  loading: false,
};

// Reducers
export default function ModalShareVideos(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_MODAL_SHARE_VIDEO_INTERVIEW:
      return {
        ...state,
        selectedIds: action.selectedIds,
        isOpen: true,
      };
    case CLOSE_MODAL_SHARE_VIDEO_INTERVIEW:
      return {
        ...state,
        selectedIds: [],
        isOpen: false,
      };
    case POST_SHARE_VIDEO_INTERVIEW:
      return {
        ...state,
        loading: true,
      };
    case POST_SHARE_VIDEO_INTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case POST_SHARE_VIDEO_INTERVIEW_FAIL:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
}

export function openModal(el, selectedIds) {
  !!el && el.preventDefault();
  return {
    type: OPEN_MODAL_SHARE_VIDEO_INTERVIEW,
    selectedIds,
  };
}

export function closeModal() {
  return {
    type: CLOSE_MODAL_SHARE_VIDEO_INTERVIEW,
  };
}

export function post(path, data) {
  return {
    types: [
      POST_SHARE_VIDEO_INTERVIEW,
      POST_SHARE_VIDEO_INTERVIEW_SUCCESS,
      POST_SHARE_VIDEO_INTERVIEW_FAIL,
    ],
    promise: (client) => client.post(path, { data }),
  };
}
