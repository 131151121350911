import React from "react";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import shortid from "shortid";

const ActionDivisions = ({
  divisions,
  actionForm,
  handleActionForm,
  textSelectDivisons,
}) => {
  return divisions.divisoes && divisions.divisoes.length > 0 ? (
    <div className="vg-row center-xs">
      <div className="vg-col-md-12 vg-col-xs-12 vg-form">
        <label className="vg-label">
          <span className="vg-label-text">
            {intl.get("FASES.MODAL_ACTION.TIPO_ACAO.DIVISAO")}
          </span>
          <div className="vg-custom-select">
            <select
              value={actionForm.divisao_id}
              onChange={(e) => handleActionForm(e, "divisao_id")}
              className="vg-select-messages"
            >
              <option value={0}>
                {textSelectDivisons
                  ? textSelectDivisons
                  : intl.get("FASES.MODAL_ACTION.TIPO_ACAO.TODAS")}
              </option>
              {divisions.divisoes &&
                map(divisions.divisoes, (division) => {
                  return (
                    <option key={shortid.generate()} value={division.id}>
                      {division.nome}
                    </option>
                  );
                })}
            </select>
          </div>
        </label>
      </div>
    </div>
  ) : (
    false
  );
};

ActionDivisions.defaultProps = {
  divisions: {
    sla: [],
  },
  actionForm: {
    conteudo: {
      titulo: "",
      corpo: "<p></p>\n",
    },
    divisao_id: 0,
    tipo_acao_id: 1,
  },
  textSelectDivisons: "",
};

ActionDivisions.propTypes = {
  divisions: propTypes.object.isRequired,
  handleActionForm: propTypes.func.isRequired,
  actionForm: propTypes.object.isRequired,
  textSelectDivisons: propTypes.bool,
};

export default ActionDivisions;
