import { Icon, Link } from "../../index";
import React, { Component } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryClipContainer,
  VictoryLabel,
  VictoryScatter,
  VictoryZoomContainer,
} from "victory";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import moment from "moment";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/Graphs";

const mapStateToProps = (state) => ({
  candidateGraph: state.Graphs.candidatos_novos_x_mes,
  loading: state.Graphs.candidatos_novos_x_mes.loading,
  loaded: state.Graphs.candidatos_novos_x_mes.loaded,
  error: state.Graphs.candidatos_novos_x_mes.error,
});

class GraphCandidateV25 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      zoomDomain: {},
    };

    this.createGraph = this.createGraph.bind(this);
    this.fetchGraph = this.fetchGraph.bind(this);
  }

  componentDidMount() {
    this.fetchGraph();

    //eslint-disable-next-line
    this.props.candidateGraph.data.serie.length &&
      this.createGraph(this.props.candidateGraph.data.serie);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.candidateGraph.data.serie !==
      this.props.candidateGraph.data.serie
    ) {
      this.createGraph(nextProps.candidateGraph.data.serie);
    }
  }

  fetchGraph() {
    const { receive } = this.props;
    receive("candidatos_novos_x_mes");
  }

  createGraph(array) {
    const result = array.reduce(
      ((hash) => {
        return (r, a) => {
          var key = a.x;
          if (!hash[key]) {
            hash[key] = { x: a.x, y: a.y };
            r.push(hash[key]);
          } else {
            hash[key].y += a.y;
          }
          return r;
        };
      })(Object.create(null)),
      []
    );

    if (result.length === 1) {
      const lastDate = moment(result[0].x, "YYYY/MM/DD").subtract(1, "month");
      result.unshift({ x: lastDate, y: 0 });
    }

    this.setState({
      zoomDomain:
        result.length <= 5 ? {} : { x: [result.length - 5, result.length] },
    });

    map(result, (item) => {
      const parsedX = moment(item.x, "YYYY/MM/DD").format("MMM/YY");
      const parsedY = item.y;

      this.setState((prevState) => ({
        graphData: [...prevState.graphData, { x: parsedX, y: parsedY }],
      }));
    });
  }

  render() {
    const { loading, loaded, error } = this.props;

    return loading && !loaded ? (
      <div className="vg-graphs-placeholder vg-graphs-line-placeholder vg-graph-v25">
        <Icon iconKey="graphLine" />
      </div>
    ) : !loading && loaded ? (
      <div className="vg-graph-v25">
        <h2>
          {intl.get("GRAPHS.CANDIDATE.TITLE")}
        </h2>
        <div className="vg-graph-candidate">
          <svg style={{ position: "absolute", left: "-9999px" }}>
            <defs>
              <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
                <stop
                  offset="0%"
                  style={{ stopColor: "rgb(255,186,53)", stopOpacity: 1 }}
                />
                <stop
                  offset="100%"
                  style={{ stopColor: "rgb(250,121,110)", stopOpacity: 1 }}
                />
              </linearGradient>
            </defs>
          </svg>
          <VictoryChart
            width={500}
            height={150}
            padding={{ top: 20, bottom: 40, left: 30, right: 30 }}
            containerComponent={
              <VictoryZoomContainer
                brushDimension="x"
                brushDomain={this.state.selectedDomain}
                clipContainerComponent={
                  <VictoryClipContainer
                    clipPadding={{ top: 10, bottom: 50, left: 50, right: 50 }}
                    clipWidth={500}
                    translateX={0}
                  />
                }
                allowZoom={false}
                zoomDomain={this.state.zoomDomain}
              />
            }
          >
            <VictoryAxis
              fixLabelOverlap={true}
              padding={0}
              offsetY={37}
              style={{
                tickLabels: {
                  fill: "#797979",
                  textTransform: "capitalize",
                  fontSize: 17,
                },
                axis: { stroke: 0, fill: "#f2f2f2" },
              }}
            />

            <VictoryArea
              interpolation="cardinal"
              style={{
                data: {
                  fill: "#f2f2f2",
                  stroke: "url(#lgrad)",
                  strokeWidth: 4,
                  strokeLinecap: "round",
                },
                labels: { fill: "#797979", fontSize: 17 },
              }}
              data={this.state.graphData}
              labels={(d) =>
                intl.get("GRAPHS.CANDIDATE.GRAPH_LABEL", { num: d.y })
              }
              labelComponent={<VictoryLabel renderInPortal dy={-20} />}
              padding={{ left: 20 }}
            />
            <VictoryScatter
              interpolation="cardinal"
              data={this.state.graphData}
              size={7}
              style={{
                data: { fill: "#fff", stroke: "#ff830f", strokeWidth: 3 },
              }}
            />
          </VictoryChart>
        </div>
      </div>
    ) : (
      <div className="vg-error-handler-wrapper vg-graph-v25">
        <div className="vg-error-handler">
          <Icon iconKey="spark" />
          <h2>{error}</h2>
          <p>{intl.get("GRAPHS.ERROR_MESSAGE")}</p>
          <Link
            onClick={this.fetchGraph}
            className="vg-btn-primary vg-btn-outline vg-btn-block"
          >
            {intl.get("GRAPHS.BOTAO")}
          </Link>
        </div>
      </div>
    );
  }
}

GraphCandidateV25.propTypes = {
  candidateGraph: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  receive: propTypes.func.isRequired,
  error: propTypes.number,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(GraphCandidateV25);
