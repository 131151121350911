import React from "react";
import intl from "react-intl-universal";
import { TermContext } from "./AcceptanceTermList";
import {
  AccordionProvider,
  Accordion,
  AccordionHeader,
  AccordionPanel,
} from "./accordion";
import { Upload, Icon } from "../../index";

const Divisions = () => {
  const { terms } = React.useContext(TermContext);
  const emptyTerm = intl.get("CONFIGURACOES.TERMO_ACEITE.DIVISIONS.EMPTY_TERM");

  return (
    <div className="divisions">
      <AccordionProvider>
        <Accordion allowAllHidden>
          {terms
            .filter((term) => term.id !== 0)
            .map((division) => (
              <React.Fragment key={division.id}>
                <AccordionHeader>
                  <b className="accordion-information">{division.name}</b>
                  <p className="term-name">{` ${
                    division.term == "" ? emptyTerm : division.term
                  } `}</p>
                  <Icon
                    iconKey="down_arrow"
                    addClass="accordion-icon vg-icon-down_arrow"
                  />
                </AccordionHeader>
                <AccordionPanel>
                  <Upload id={division.id} />
                </AccordionPanel>
              </React.Fragment>
            ))}
        </Accordion>
      </AccordionProvider>
    </div>
  );
};

export default Divisions;
