import { Button, SearchListTop, SearchSLA } from "../../index";
import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import { toggleFilter } from "../../../store/modules/VacanciesSearch";

const mapStateToProps = (state) => ({
  totalSelected: state.VacanciesSearch.filter["responsavel_id"]
    ? Object.values(state.VacanciesSearch.filter["responsavel_id"]).filter(
        (it) => it,
      ).length
    : 0 + state.VacanciesSearch.filter["divisao_veiculacao_id"]
    ? Object.values(
        state.VacanciesSearch.filter["divisao_veiculacao_id"],
      ).filter((it) => it).length
    : 0,
  activedFilters: state.VacanciesSearch.activedFilters,
});

class SearchOtherFilters extends Component {
  handleClickOutside() {
    const { toggleFilter, activedFilters } = this.props;
    activedFilters.includes("other-filters") && toggleFilter("other-filters");
  }

  render() {
    const { facets, totalSelected, toggleFilter, activedFilters } = this.props;

    return (
      <div className="vg-other-filters">
        <Button
          className="vg-btn-block"
          onClick={() => toggleFilter("other-filters")}
        >
          {intl.get("VAGAS.BUSCA.MAISOPCOES", { value: totalSelected })}
        </Button>
        <div
          className={`vg-wrap-other-filters ${
            activedFilters.includes("other-filters") && "vg-active"
          }`}
        >
          <SearchListTop
            content={facets["divisoes_veiculacao"]}
            type="divisao_veiculacao_id"
            inside
          />
          <SearchListTop
            content={facets["responsaveis"]}
            type="responsavel_id"
            inside
          />
          <SearchSLA content={facets["slas"]} inside />
        </div>
      </div>
    );
  }
}

SearchOtherFilters.propTypes = {
  facets: propTypes.object.isRequired,
  totalSelected: propTypes.number.isRequired,
  toggleFilter: propTypes.func.isRequired,
  activedFilters: propTypes.array.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleFilter }, dispatch),
)(onClickOutside(SearchOtherFilters));
