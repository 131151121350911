import { BoxTitle } from "../../index";
import { ZiggeoRecorder } from "react-ziggeo";

import React, { Component } from "react";

export default class VideoInterviewCandidateFake extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verified: false,
    };

    this.handleCallback = this.handleCallback.bind(this);
  }

  handleCallback() {
    this.setState({
      verified: true,
    });
  }

  render() {
    return (
      <div>
        <BoxTitle
          title="Ambiente de testes"
          text="Testes e ajustes para gravação do video"
          secondLevel
        />
        <div className="vg-container vg-interview-candidate">
          <div className="vg-interview-wrap-buttons">
            <ZiggeoRecorder
              className="vg-video-wrapper"
              apiKey={process.env.REACT_APP_ZIGGEO_API_KEY}
              responsive
              flip-camera
              simulate={false}
              skipinitial
              picksnapshots={false}
              title="Gravação teste para ambiente de QA"
              description="Uma descrição do video"
              theme="space"
              stretch={true}
              timelimit={1}
              countdown={3}
              allowupload={false}
              localplayback={true}
              allowcustomupload={false}
              skipinitialonrerecord
              rerecordable={false}
              audiobitrate={320}
            />
          </div>
        </div>
      </div>
    );
  }
}
