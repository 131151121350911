import { Button, Link, Modal } from "../../index";

import React from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { map, filter } from "lodash";

export default function EditPhasesModalRemove({
  isOpen,
  toggleModal,
  formChange,
  selectState,
  removePhase,
  phasesList,
  formState,
}) {
  return (
    <Modal
      show={isOpen}
      small
      onClose={() => toggleModal(null, false, "remove")}
      contentLabel={intl.get("FASES.MODAL_REMOVE.MODAL_HEADER")}
    >
      <div className="vg-phases-modal">
        <div className="vg-phases-modal-content">
          <h1>{intl.get("FASES.MODAL_REMOVE.TITULO")}</h1>
          <p>
            {intl.getHTML("FASES.MODAL_REMOVE.TEXTO", {
              phase: formState.nome,
            })}
          </p>
          <div className="vg-custom-select" style={{ display: "block" }}>
            <select
              value={selectState}
              onChange={(e) => formChange(e)}
              name="tipo_pergunta"
            >
              <option value={0}>-</option>
              {map(
                filter(phasesList, (item) => item.id != formState.id),
                (item) => {
                  return (
                    <option value={item.id} key={item.id}>
                      {item.nome} ({item.sigla})
                    </option>
                  );
                }
              )}
            </select>
          </div>
          <div className="vg-phases-modal-inside-buttons">
            <Button
              className="vg-btn-danger"
              onClick={removePhase}
              disabled={selectState == 0}
            >
              {intl.get("FASES.MODAL_REMOVE.BOTAO1")}
            </Button>
            <Link
              onClick={() => toggleModal(null, false, "remove")}
              className="vg-btn-link"
            >
              {intl.get("FASES.MODAL_REMOVE.BOTAO2")}
            </Link>
            <p>{intl.getHTML("FASES.MODAL_REMOVE.INFO")}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
}

EditPhasesModalRemove.defaultProps = {
  isOpen: false,
};

EditPhasesModalRemove.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggleModal: propTypes.func.isRequired,
  formChange: propTypes.func.isRequired,
  removePhase: propTypes.func.isRequired,
  selectState: propTypes.any.isRequired,
  phasesList: propTypes.array.isRequired,
  formState: propTypes.object.isRequired,
};
