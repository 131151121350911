import "./Actions.scss";

import {
  ActionMessage,
  ActionTags,
  Modal,
  SelectAction,
  Steps,
  Tests,
  Filters,
  Datasheet,
} from "../../index";
import React, { Component } from "react";

import propTypes from "prop-types";
import shortid from "shortid";

export class EditPhasesActionModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevStep: "",
      currentStep: "select_action",
      isOpen: false,
      idStep: 0,
    };

    this.goToStep = this.goToStep.bind(this);
    this.comeBack = this.comeBack.bind(this);
  }

  goToStep(step, id) {
    this.setState((prevState) => ({
      currentStep: step,
      prevStep: prevState.currentStep,
      idStep: id,
    }));
  }

  comeBack() {
    this.setState({
      currentStep: this.state.prevStep,
    });
  }

  static getDerivedStateFromProps(props) {
    if (props.currentAction.tipo_acao) {
      return {
        currentStep: props.currentAction.tipo_acao.tipo,
      };
    }

    if (!props.isOpen) {
      return {
        currentStep: "select_action",
      };
    }

    return null;
  }

  render() {
    const { isOpen, closeActionModal } = this.props;
    const { currentStep, prevStep, idStep } = this.state;

    return (
      <Modal show={isOpen} onClose={closeActionModal}>
        <div className="vg-phases-modal vg-action-create">
          <Steps
            goToStep={this.goToStep}
            comeBack={this.comeBack}
            current={currentStep}
            prevStep={prevStep}
            idStep={idStep}
            steps={[
              <SelectAction
                key={shortid.generate()}
                {...this.props}
                name="select_action"
              />,
              <ActionMessage
                key={shortid.generate()}
                {...this.props}
                name="enviar_email"
              />,
              <ActionTags
                key={shortid.generate()}
                {...this.props}
                name="carimbar_candidato"
              />,
              <Tests
                key={shortid.generate()}
                {...this.props}
                name="enviar_teste"
              />,
              <Datasheet
                key={shortid.generate()}
                {...this.props}
                name="enviar_ficha"
              />,
              <Filters
                key={shortid.generate()}
                {...this.props}
                name="triar_candidatos_redes"
              />,
            ]}
          />
        </div>
      </Modal>
    );
  }
}

EditPhasesActionModal.defaultProps = {
  currentAction: {},
};

EditPhasesActionModal.propTypes = {
  closeActionModal: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  currentItem: propTypes.object.isRequired,
  showNotifications: propTypes.func.isRequired,
  currentAction: propTypes.object.isRequired,
};

export default EditPhasesActionModal;
