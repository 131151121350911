import React, { Component } from "react";
import AvatarEditor from "react-avatar-editor";
import Dropzone from "react-dropzone";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { toast } from "react-toastify";
import "./Avatar.scss";
import default_avatar from "../../../assets/default_avatar.png";
import { Icon, Modal, RangeInput, Toast } from "../../index";
import { deleteAvatar, receive, update } from "../../../store/modules/Avatar";

const mapStateToProps = (state) => ({
  avatar: state.Avatar.data.avatar_url,
  loading: state.Avatar.loading,
  loaded: state.Avatar.loaded,
  error: state.Avatar.error,
});

export class Avatar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      avatarImage:
        props.avatar || props.avatar === "" ? props.avatar : default_avatar,
      canvasImage:
        props.avatar || props.avatar === "" ? props.avatar : default_avatar,
      scale: 1.2,
      isOpen: false,
      openStep: "landing",
      rotate: 0,
      isUploading: false,
    };

    this.setEditorRef = (editor) => (this.editor = editor);
    this.onClickSave = this.onClickSave.bind(this);
    this.handleNewImage = this.handleNewImage.bind(this);
    this.handleScale = this.handleScale.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.rotateRight = this.rotateRight.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    const { receive, avatar } = this.props;
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.setState(
          {
            isOpen: false,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll")
        );
      }
    });

    // Solicita o carregamento da imagem do avatar
    receive();

    this.setState({
      avatarImage: avatar || avatar === "" ? avatar : default_avatar,
      canvasImage: avatar || avatar === "" ? avatar : default_avatar,
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.isUploading) {
      return {
        avatarImage:
          props.avatar || props.avatar === "" ? props.avatar : default_avatar,
        canvasImage:
          props.avatar || props.avatar === "" ? props.avatar : default_avatar,
      };
    }
    return null;
  }

  toggleModal(el) {
    el && el.preventDefault();
    this.setState(
      {
        isOpen: !this.state.isOpen,
      },
      () =>
        document
          .getElementsByTagName("body")[0]
          .classList.toggle("vg-no-scroll")
    );
  }

  onClickSave() {
    if (this.editor) {
      const canvas = this.editor.getImageScaledToCanvas().toDataURL();
      const data = {
        imagem: canvas,
      };

      this.props.update(data);

      this.setState(
        {
          avatarImage: canvas,
          isUploading: false,
        },
        () => {
          this.toggleModal();
          toast(
            <Toast
              message={intl.getHTML("AVATAR.AVATAR_ATUALIZADO")}
              type="success"
            />,
            {
              position: "bottom-right",
              autoClose: 4000,
              closeOnClick: true,
              pauseOnHover: true,
              className: "sr-toast vg-toast-success",
            }
          );
        }
      );
    }
  }

  handleDelete() {
    this.props.deleteAvatar();
    toast(
      <Toast message={intl.getHTML("AVATAR.AVATAR_REMOVIDO")} type="success" />,
      {
        position: "bottom-right",
        autoClose: 4000,
        closeOnClick: true,
        pauseOnHover: true,
        className: "sr-toast vg-toast-success",
      }
    );
    this.toggleModal();
  }

  handleNewImage(dropped) {
    this.setState({
      canvasImage: dropped.target ? dropped.target.files[0] : dropped[0],
      isUploading: true,
    });
  }

  handleScale(e) {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  }

  rotateRight(e) {
    e.preventDefault();
    this.setState({
      rotate: this.state.rotate + 90,
    });
  }

  render() {
    const { avatarImage, canvasImage } = this.state;
    const { alt, loading, loaded } = this.props;

    return loading && !loaded ? (
      <div className="vg-avatar-wrapper vg-avatar-placeholder">
        <div className="vg-avatar">
          <img src={default_avatar} alt={alt} />
        </div>
      </div>
    ) : (
      <div className="vg-avatar-wrapper">
        <a
          className="vg-avatar"
          onClick={this.toggleModal}
          aria-label={intl.get("AVATAR.INSERIR_AVATAR")}
        >
          <img src={avatarImage} alt={alt} />
        </a>

        <Modal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          contentLabel={intl.get("AVATAR.MODAL_TITLE")}
        >
          <p>
            {intl.get("AVATAR.MODAL_TEXTO")}{" "}
            <em>
              {avatarImage !== default_avatar
                ? intl.get("AVATAR.ATUALIZAR_IMAGEM")
                : intl.get("AVATAR.SELECIONAR_IMAGEM")}
            </em>
            .
          </p>
          <div className="vg-avatar-editor">
            <Dropzone
              onDropAccepted={this.handleNewImage}
              accept="image/jpeg, image/png"
              disableClick
              style={{ width: "300px", height: "300px" }}
            >
              <AvatarEditor
                ref={this.setEditorRef}
                image={canvasImage}
                scale={parseFloat(this.state.scale)}
                width={200}
                height={200}
                border={50}
                borderRadius={140}
                color={[245, 245, 245, 0.8]}
                rotate={parseFloat(this.state.rotate)}
                className="vg-avatar-wrapper"
              />
            </Dropzone>
            <div className="vg-avatar-toolbar">
              <label className="vg-avatar-zoom-label">
                {intl.get("AVATAR.ZOOM")} -
                <div className="vg-avatar-zoom">
                  <RangeInput
                    onRange={this.handleScale}
                    defaultRange={this.state.scale}
                  />
                </div>
                +
              </label>
              <label>
                {intl.get("AVATAR.GIRAR")}
                <button onClick={this.rotateRight}>
                  <Icon iconKey="rotateRight" />
                </button>
              </label>
            </div>
            <div className="vg-avatar-wrap-buttons">
              <Dropzone
                onDropAccepted={this.handleNewImage}
                accept="image/jpeg, image/png"
                preventDropOnDocument
                style={{ width: "100%", height: "36px" }}
              >
                <button
                  className="vg-btn-primary vg-btn-outline vg-btn-block"
                  type="button"
                >
                  {avatarImage !== default_avatar
                    ? intl.get("AVATAR.ATUALIZAR_IMAGEM")
                    : intl.get("AVATAR.SELECIONAR_IMAGEM")}
                </button>
              </Dropzone>
              {avatarImage !== default_avatar && (
                <button
                  className="vg-btn-danger vg-btn-outline vg-btn-block"
                  type="button"
                  onClick={this.handleDelete}
                >
                  {intl.get("AVATAR.REMOVER")}
                </button>
              )}
            </div>
          </div>
          <div className="vg-wrap-buttons">
            <button
              className="vg-btn-success"
              type="button"
              onClick={this.onClickSave}
            >
              {intl.get("AVATAR.SALVAR")}
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

Avatar.defaultProps = {
  avatar: "",
  loading: false,
  loaded: false,
};

Avatar.propTypes = {
  update: propTypes.func.isRequired,
  deleteAvatar: propTypes.func,
  receive: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  loaded: propTypes.bool.isRequired,
  alt: propTypes.string,
  avatar: propTypes.string,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, update, deleteAvatar }, dispatch)
)(Avatar);
