import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import {
  DragDropPdf,
  ButtonCancel,
  FilledInputDragDrop,
  PdfUpload,
} from "../../index";
import { TermContext } from "./AcceptanceTermList";
import { showNotifications } from "./functions";
import Cookie from "js-cookie";
import { usePdfFile } from "./PdfUpload/usePdfFile";
import { uploadTypes } from "./PdfUpload/reducerPdfFile";

export const PdfContext = React.createContext();

const Pdf = (props) => {
  const { id } = props;
  const urlRequest = "termo_aceite/create_pdf";
  const { terms, dispatchTerms, company } = React.useContext(TermContext);
  const [requestHttp, setRequestHttp] = useState({});
  const { pdfFile, dispatchPdfFile } = usePdfFile();
  const [payload, setPayload] = useState({
    file: "",
    file_name: "",
    division: "",
    type: "pdf",
    termId: "",
  });

  useEffect(() => {
    const divisionTerm = terms.find((element) => element.id == id);

    if (divisionTerm) {
      const pdfPayload = {
        divisionId: divisionTerm.id,
        name: "",
        url: "",
        termId: "",
        saved: false,
      };
      if (divisionTerm.type == "pdf") pdfPayload.termId = divisionTerm.termId;
      if (divisionTerm.term != "") {
        pdfPayload.name = divisionTerm.term;
        pdfPayload.url = divisionTerm.termUrl;
        pdfPayload.saved = true;
      }
      dispatchPdfFile({ type: "initialState", payload: pdfPayload });
    }
  }, [terms]);

  useEffect(() => {
    if (pdfFile.file != "") {
      setPayload((oldState) => ({
        ...oldState,
        file: pdfFile.fileBase64,
        file_name: pdfFile.file.name,
        division: pdfFile.division,
        termId: pdfFile.termId,
      }));
    } else {
      // payload inicial, quando não há um termo salvo
      setPayload((oldState) => ({
        ...oldState,
        file: "",
        file_name: "",
        division: pdfFile.division,
        termId: pdfFile.termId,
      }));
    }
  }, [pdfFile]);

  useEffect(() => {
    if (!pdfFile.shouldSubmit) return;
    submit();
    dispatchPdfFile({ type: "submit", payload: { submit: false } });
  }, [pdfFile.shouldSubmit]);

  const submit = (e) => {
    if (e) e.preventDefault();
    if (pdfFile.uploadStatus != uploadTypes.DELETING) {
      dispatchPdfFile({
        type: "uploadStatus",
        payload: { uploadPercentage: 0, uploadStatus: uploadTypes.LOADING },
      });
    }
    if (pdfFile.enableSaving) {
      const connection = new XMLHttpRequest();
      connection.withCredentials = true;

      setRequestHttp(connection);

      connection.upload.onprogress = function (onprogressEvent) {
        const loadingPercentage = Math.ceil(
          (onprogressEvent.loaded / onprogressEvent.total) * 100,
        );
        if (onprogressEvent.loaded / onprogressEvent.total != 1) {
          dispatchPdfFile({
            type: "uploadStatus",
            payload: {
              uploadPercentage: loadingPercentage,
              uploadStatus: uploadTypes.LOADING,
            },
          });
        } else {
          dispatchPdfFile({ type: "disableCancelUpload" });
        }
      };

      connection.onload = function () {
        if (connection.status === 200) {
          const termoAceite = connection.response;
          let responseTerm = termoAceite.termo ? termoAceite.termo : "";
          dispatchTerms({
            type: "updateTerm",
            payload: {
              divisionId: termoAceite.divisao_id || 0,
              term: responseTerm,
              companyId: company,
              type: "pdf",
              termId: termoAceite.id,
            },
          });
          responseTerm === ""
            ? showNotifications(uploadTypes.DELETING)
            : showNotifications(uploadTypes.VALID_FILE);
          dispatchPdfFile({
            type: "uploadStatus",
            payload: {
              uploadPercentage: 100,
              uploadStatus: uploadTypes.UPLOADED_FILE,
            },
          });
        } else {
          dispatchPdfFile({
            type: "uploadStatus",
            payload: {
              uploadPercentage: 100,
              uploadStatus: uploadTypes.FAILED,
            },
          });
          showNotifications("error");
        }
      };

      connection.open(
        "POST",
        `${process.env.REACT_APP_ATHENA_URL}${urlRequest}`,
      );

      connection.setRequestHeader(
        "Authorization",
        `Bearer ${Cookie.get("access_token")}`,
      );
      connection.setRequestHeader("Content-type", "application/json");
      connection.setRequestHeader("Accept-Language", "pt-BR");
      connection.setRequestHeader(
        "Access-Control-Allow-Origin",
        window.location.origin,
      );

      connection.responseType = "json";

      connection.send(JSON.stringify(payload));
    } else {
      dispatchPdfFile({
        type: "uploadStatus",
        payload: { uploadPercentage: 100, uploadStatus: uploadTypes.FAILED },
      });
      showNotifications("error");
    }
  };

  function inputPdf() {
    if (pdfFile.uploadStatus != uploadTypes.DELETING && pdfFile.file === "") {
      return <DragDropPdf />;
    } else {
      return <PdfUpload requestHttp={requestHttp} />;
    }
  }

  return (
    <PdfContext.Provider value={{ pdfFile, dispatchPdfFile }}>
      {pdfFile.saved ? (
        <FilledInputDragDrop submit={submit} setPayload={setPayload} />
      ) : (
        inputPdf()
      )}

      <div className="vg-input-box-flex">
        <ButtonCancel
          pdf={{
            dispatchPdfFile: dispatchPdfFile,
            uploadStatus: pdfFile.uploadStatus,
          }}
        />
        <button
          className={`vg-input-button ${
            pdfFile.enableSaving ? "enabled" : "disabled"
          }`}
          onClick={submit}
          disabled={!pdfFile.enableSaving}
        >
          {intl.get("CONFIGURACOES.TERMO_ACEITE.URL.ENVIAR")}
        </button>
      </div>
    </PdfContext.Provider>
  );
};

Pdf.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Pdf;
