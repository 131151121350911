import { array, string, number } from "prop-types";
import React from "react";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { headerDatalayer } from "../../../utils/dataLayerPush";
import { Link } from "../../index";
import "./SubMenu.scss";

const SubMenu = ({ menuItens, label, userId, clientId }) => {
  const eventGTM = (label) => {
    headerDatalayer(
      "interaction",
      label.replace(/\s/g, "-").toLowerCase(),
      userId
    );
  };

  return (
    <ul className="vg-submenu">
      {menuItens.map((item, index) => {
        if (item === "requisicao-de-vagas" && clientId === 5511) {
          return (
            <li key={index}>
              <Link
                moveTo="ver-requisicoes-de-vagas"
                onClick={() => eventGTM(item)}
                data-testid="link-requisicoes-vaga"
              >
                {intl.get(`NAVEGACAO.SUBMENUVAGAS.${intlReplace(item)}`)}
              </Link>
            </li>
          );
        }

        return (
          <li key={index}>
            <Link
              to={`/${label}/${item != "vagas" ? item : ""}`}
              onClick={() => eventGTM(item)}
              data-testid={`link-menu-${item}`}
            >
              {intl.get(`NAVEGACAO.SUBMENUVAGAS.${intlReplace(item)}`)}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

SubMenu.propTypes = {
  menuItens: array.isRequired,
  label: string,
  userId: number.isRequired,
  clientId: number.isRequired,
};

export default SubMenu;
