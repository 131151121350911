import propTypes from "prop-types";
import React, { Component } from "react";
import intl from "react-intl-universal";
import { Tabs, BoxTitle } from "../../index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Settings";

const mapStateToProps = (state) => ({
  settings: state.Settings.data,
  loaded: state.Settings.loaded,
  loading: state.Settings.loading,
});

class Configuration extends Component {
  componentDidMount() {
    const { receive } = this.props;
    receive();
  }

  render() {
    const { settings, loaded, loading } = this.props;

    return (
      <div className="vg-settings-page">
        <BoxTitle
          title={intl.get("CONFIGURACOES.TITULO_PAGINA")}
          text={intl.get("CONFIGURACOES.SUB_TITULO_PAGINA")}
        />
        <div className="vg-container">
          <div className="vg-row">
            <div className="vg-col-xs-12">
              <Tabs tabs={settings} loaded={loaded} loading={loading} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Configuration.defaultProps = {
  type: "",
  loading: false,
};

Configuration.propTypes = {
  settings: propTypes.object.isRequired,
  receive: propTypes.func.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch),
)(Configuration);
