import React from "react";
import "./Vacancies.scss";
import {
  BoxTitle,
  FastLink,
  SelectiveProcessList,
  SelectiveProcessResult,
  VacanciesSearch,
} from "../../index";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";

const mapStateToProps = (state) => ({
  loaded: state.VacanciesSearch.facets.loaded,
  filters: state.VacanciesSearch.facets.data.filtros_salvos.filtros
    ? state.VacanciesSearch.facets.data.filtros_salvos.filtros
    : {},
  featureFlags: state.Painel.painel.data
    ? state.Painel.painel.data.feature_flags
    : [],
});

const Vacancies = ({ loaded, filters, featureFlags }) => {
  return (
    <div className="vg-vacancies-page">
      <BoxTitle
        title={intl.get("ACOMPANHAMENTO_DE_VAGAS.BOX_TITLE.TITULO")}
        text={intl.getHTML("ACOMPANHAMENTO_DE_VAGAS.BOX_TITLE.TEXTO")}
      />
      <div className="vg-container">
        <section className="vg-row">
          <div className="vg-col-md-12 vg-col-xs-12">
            <FastLink type="vagas" path="painel/vagas" />
          </div>
        </section>
        <VacanciesSearch featureFlags={featureFlags} />
        {loaded &&
          (Object.keys(filters).length == 0 ? (
            <section className="vg-row">
              <div className="vg-col-md-12 vg-col-xs-12">
                <SelectiveProcessList
                  path="processos-seletivos?apenas_do_funcionario=true"
                  type="yours"
                  title={intl.get("ACOMPANHAMENTO_DE_VAGAS.MINHAS_VAGAS")}
                  subtitle={intl.get(
                    "ACOMPANHAMENTO_DE_VAGAS.SUAS_VAGAS_SUB_TIT"
                  )}
                />
                <SelectiveProcessList
                  path="processos-seletivos?do_funcionario=nao_incluir&tamanho_pagina=10"
                  type="all"
                  paginate
                  title={intl.getHTML("ACOMPANHAMENTO_DE_VAGAS.TODAS_VAGAS")}
                  subtitle={intl.get(
                    "ACOMPANHAMENTO_DE_VAGAS.TODAS_VAGAS_SUB_TIT"
                  )}
                />
              </div>
            </section>
          ) : (
            <SelectiveProcessResult type="search" />
          ))}
      </div>
    </div>
  );
};

Vacancies.propTypes = {
  loaded: propTypes.bool.isRequired,
  filters: propTypes.object.isRequired,
  featureFlags: propTypes.array,
};

export default connect(mapStateToProps)(Vacancies);
