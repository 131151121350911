import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { AcceptanceTermInfo, AcceptanceTermList } from "../../index";
import "./AcceptanceTerm.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { receive } from "../../../store/modules/Painel";
function AcceptanceTerm(props) {
  return (
    <div className="vg-acceptance-term-page">
      <div className="vg-box-title">
        <h1>{intl.get("CONFIGURACOES.TERMO_ACEITE.TITULO_PAGINA")}</h1>
        <p>{intl.getHTML("CONFIGURACOES.TERMO_ACEITE.SUB_TITULO")}</p>
      </div>
      <div className="vg-container">
        <AcceptanceTermInfo />
        {props.funcionarioAdmin && <AcceptanceTermList />}
      </div>
    </div>
  );
}

AcceptanceTerm.defaultProps = {
  funcionarioAdmin: false,
};

AcceptanceTerm.propTypes = {
  funcionarioAdmin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  funcionarioAdmin:
    state.Painel.painel.data &&
    state.Painel.painel.data.funcionario &&
    state.Painel.painel.data.funcionario.admin,
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(AcceptanceTerm);
