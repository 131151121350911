/* eslint-disable */
import propTypes from "prop-types";
import React, { useState } from "react";
import intl from "react-intl-universal";
import {
  Icon,
  ListLinks,
  LinkTriagem,
  SelectRi,
  Alert,
  Link,
} from "../../index";

const BoxesProfessionalItem = ({ content, group }) => {
  const [linkRi, setLinkRi] = useState(0);

  // Método criado para obter o código da divisão
  const getId = (id) => setLinkRi(parseInt(id));

  return (
    <div className="vg-col-md vg-col-xs-12">
      <div className={`vg-professionals-box vg-box-${group}`}>
        <div className="vg-icon-box">
          <Icon iconKey={`svg-box-${group}`} aria-hidden />
        </div>
        <div className="vg-professionals-box-content">
          <h2 className="vg-professionals-box-title">
            {intl.get(`PROFISSIONAIS.CONTEUDO_BOXES.${group}.TITULO_BOX`)}
          </h2>
          <p className="vg-professionals-box-description">
            {intl.get(`PROFISSIONAIS.CONTEUDO_BOXES.${group}.DESCRICAO_BOX`)}
          </p>

          {group === "banco_de_talentos" && (
            <Link
              className="vg-btn-success vg-btn-block"
              href={`${
                process.env.NODE_ENV === "development"
                  ? `${process.env.REACT_APP_ACCESS_CONTROL}:5001`
                  : process.env.REACT_APP_ACCESS_CONTROL
              }/profissionais/banco-de-talentos`}
            >
              {intl.getHTML(`PROFISSIONAIS.CONTEUDO_BOXES.${group}.BOTAO`)}
            </Link>
          )}

          {content.hasOwnProperty("sem_acesso") ? (
            <Alert animate={true}>
              {intl.get(`PROFISSIONAIS.CONTEUDO_BOXES.${group}.sem_acesso`)}
            </Alert>
          ) : content.hasOwnProperty("contato_comercial") ? (
            <Alert animate={true}>
              {intl.get(
                `PROFISSIONAIS.CONTEUDO_BOXES.${group}.contato_comercial`
              )}
            </Alert>
          ) : (
            <div>
              {group === "colaboradores" && (
                <SelectRi
                  getId={getId}
                  path={"/divisoes?tipo_divisao=recrutamento_interno"}
                />
              )}
              <LinkTriagem
                linkRi={linkRi}
                links={content.triagem}
                group={group}
              />
            </div>
          )}
        </div>
        <ListLinks linkRi={linkRi} links={content.links} group={group} />
      </div>
    </div>
  );
};

BoxesProfessionalItem.defaultProps = {
  content: {},
};

BoxesProfessionalItem.propTypes = {
  content: propTypes.object.isRequired,
  group: propTypes.string.isRequired,
};

export default BoxesProfessionalItem;
