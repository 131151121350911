import { useEffect } from "react";

export const useBeamer = (permissions) => {
  useEffect(() => {
    if (permissions) {
      let responsavel_conta = permissions.cliente.responsavel_conta;
      window.beamer_config = {
        product_id: process.env.REACT_APP_BEAMER_KEY,
        user_id: `${permissions.funcionario.id}`,
        user_email: `${permissions.funcionario.email}`,
        user_firstname: `${permissions.funcionario.nome}`,
        user_lastname: "",
        is_admin: `${permissions.funcionario.admin}`,
        company_name: `${permissions.cliente.nome}`,
        plan: "",
        id_vagas: "",
        company_id: `${permissions.cliente.id}`,
        csm: responsavel_conta && `${responsavel_conta.nome}`,
        id_crm: "",
        bottom: 90,
        selector: "beamerButton",
        filter_by_url: true,
      };
      const scriptBeamer = document.createElement("script");
      scriptBeamer.src =
        process.env.REACT_APP_BEAMER_URL + "/js/beamer-embed.js";
      scriptBeamer.async = true;
      document.body.appendChild(scriptBeamer);
    }
  }, [permissions]);
};
