import { Icon } from "../../index";
import React, { Component } from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";

class InterviewEmpty extends Component {
  render() {
    const { visitor } = this.props;

    return (
      <div className="vg-empty">
        <Icon iconKey="videoOff" />
        <h2>
          {intl.get("VIDEO_ENTREVISTA.EMPRESA.LISTA.RESPONDIDO_VAZIO.TITULO")}
        </h2>
        <p>
          {intl.get(
            `VIDEO_ENTREVISTA.EMPRESA.LISTA.RESPONDIDO_VAZIO.${
              !visitor ? "SUB_TITULO" : "SUB_TITULO_VISITOR"
            }`
          )}
        </p>
      </div>
    );
  }
}

InterviewEmpty.propTypes = {
  visitor: propTypes.bool,
};

export default InterviewEmpty;
