// Action Types
const VIDEO_GROUP = "services/VIDEO_GROUP";
const VIDEO_GROUP_SUCCESS = "services/VIDEO_GROUP_SUCCESS";
const VIDEO_GROUP_FAIL = "services/VIDEO_GROUP_FAIL";
const VIDEO_UPDATE_GROUP = "services/VIDEO_GROUP_UPDATE";
const VIDEO_UPDATE_GROUP_SUCCESS = "services/VIDEO_GROUP_UPDATE_SUCCESS";
const VIDEO_UPDATE_GROUP_FAIL = "services/VIDEO_GROUP_UPDATE_FAIL";

// InitialState
const initialState = {
  loading: false,
  loaded: false,
  loadingMore: false,
  data: {},
};

// Reducers
export default function videoGroupInterview(state = initialState, action = {}) {
  switch (action.type) {
    case VIDEO_GROUP:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case VIDEO_GROUP_SUCCESS:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
      };
    case VIDEO_GROUP_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error.status,
      };
    case VIDEO_UPDATE_GROUP:
      return {
        ...state,
        loadingMore: true,
      };
    case VIDEO_UPDATE_GROUP_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          pagina_atual: action.result.pagina_atual,
          participantes: newParticipantes(state, action),
        },
        loadingMore: false,
      };
    }
    case VIDEO_UPDATE_GROUP_FAIL:
      return {
        loadingMore: false,
        error: action.error.status,
      };
    default:
      return state;
  }
}

// Actions
export function load(path) {
  return {
    types: [VIDEO_GROUP, VIDEO_GROUP_SUCCESS, VIDEO_GROUP_FAIL],
    promise: (client) => client.get(path),
  };
}
export function loadMore(path) {
  return {
    types: [
      VIDEO_UPDATE_GROUP,
      VIDEO_UPDATE_GROUP_SUCCESS,
      VIDEO_UPDATE_GROUP_FAIL,
    ],
    promise: (client) => client.get(path),
  };
}

function shouldFetch() {
  return true;
}

function newParticipantes(state, action) {
  let newParticipantes = state.data.participantes;
  action.result.participantes.map((item) => {
    newParticipantes.push(item);
  });

  return newParticipantes;
}
// Método responsável pelo dispatch das actions de requisição
export function receiveGroup(path) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path));
    }
  };
}

export function receiveGroupMore(path) {
  return (dispatch) => {
    return dispatch(loadMore(path));
  };
}
