import React, { Component } from "react";
import { includes, map } from "lodash";

import { Icon, DynamicLabel, InfiniteScroll } from "../../index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import {
  toggleFilter,
  uncheckAll,
  uncheck,
  searchFacets,
  clearInput,
} from "../../../store/modules/RequestFilter";
import InputFilter from "./InputFilter";
import FilterItem from "./FilterItem";

const mapStateToProps = (state, props) => ({
  totalSelected: state.RequestFilter.filter[props.type]
    ? Object.values(state.RequestFilter.filter[props.type]).filter((it) => it)
        .length
    : 0,
  activedFilters: state.RequestFilter.activedFilters,
  filters: state.RequestFilter.filter[props.type],
  value: state.RequestFilter.facets.value[props.type.split("_id")[0]]
    ? state.RequestFilter.facets.value[props.type.split("_id")[0]]
    : "",
  total: state.RequestFilter.facets.data[props.type.split("_id")[0]]
    ? state.RequestFilter.facets.data[props.type.split("_id")[0]].total_paginas
    : 1,
});

class FilterListTop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      totalList: 5,
      filtered: [],
      filtering: false,
    };

    this.filter = this.filter.bind(this);
    this.loading = this.loading.bind(this);
    this.paginateItems = this.paginateItems.bind(this);
    this.handleUncheck = this.handleUncheck.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    if (props.content.length !== state.items.length) {
      return {
        items: props.content,
      };
    }

    return null;
  }

  prepareString(value = "") {
    return value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  filter(value) {
    if (value) {
      const { content } = this.props;

      const filtered = content.filter((obj) =>
        includes(this.prepareString(obj.nome), this.prepareString(value)),
      );

      this.setState({
        filtered: filtered,
        noResults: !filtered.length,
        filtering: true,
      });
    } else {
      this.setState({
        filtering: false,
        noResults: false,
      });
    }
  }

  loading(bool) {
    this.setState({
      loading: bool,
    });
  }

  handleClickOutside() {
    const { toggleFilter, type, activedFilters } = this.props;
    activedFilters.includes(type) && toggleFilter(type);
  }

  paginateItems(count) {
    const { type, searchFacets, value } = this.props;
    const parsedType = type.split("_id")[0];
    this.loading(true);

    return new Promise((resolve) => {
      searchFacets(`/requisicoes/${parsedType}`, value, parsedType, {
        nome: value,
        pagina: count,
        tamanho: 100,
      }).then(() => {
        this.filter(value);
        resolve(this.loading(false));
      });
    });
  }

  handleUncheck() {
    const { uncheckAll, type, clearInput } = this.props;
    clearInput(type);
    uncheckAll(type);
  }

  render() {
    const {
      type,
      totalSelected,
      inside,
      activedFilters,
      toggleFilter,
      content,
      filters,
      uncheck,
      total,
      value,
    } = this.props;

    const { items, totalList, loading, filtered, noResults, filtering } =
      this.state;

    return (
      <div
        className={`vg-filter ${
          activedFilters.includes(type) && "vg-filter-open"
        } ${inside && "vg-filter-more"}`}
      >
        <button
          className="vg-filter-title"
          onClick={() => toggleFilter(type)}
          type="button"
        >
          {intl.get(`REQUISICOES_VAGAS.${intlReplace(type)}.TITULO`)}:
          <span className="vg-filter-smart-placeholder">
            {intl.getHTML("VAGAS.BUSCA.ITEM", { value: totalSelected })}
          </span>
          <Icon iconKey="arrow" />
        </button>
        <div className="vg-menu-filter">
          {!!filters.length && content.length > totalList && (
            <div className="vg-filter-selected">
              <label className="vg-label-title">
                {intl.get("REQUISICOES_VAGAS.SELECIONADOS")}
                <button onClick={() => this.handleUncheck()}>
                  {intl.get("REQUISICOES_VAGAS.LIMPAR")}
                </button>
              </label>
              <div className="vg-filter-scroll">
                {map(filters, (item) => (
                  <div className="vg-custom-check" key={`selected-${item}`}>
                    <label className="vg-label">
                      <span className="vg-input-checkbox">
                        <input
                          type="checkbox"
                          defaultChecked="true"
                          value={item}
                          name={`selected-${type}`}
                          onChange={() => uncheck(type, item)}
                        />
                        <span className="vg-input-checkbox-inner" />
                      </span>
                      <DynamicLabel item={item} items={content} />
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
          <InputFilter
            content={content.length}
            filter={this.filter}
            type={type}
            loading={this.loading}
          />
          <div className={`${loading && "vg-filter-loading"}`}>
            <InfiniteScroll
              moreItems={this.paginateItems}
              total={total}
              value={value}
            >
              <FilterItem
                items={items}
                filtered={filtered}
                filtering={filtering}
                type={type}
              />
              {noResults && (
                <div className="vg-custom-check">
                  <label className="vg-label">
                    <span className="vg-label-text">Sem resultados</span>
                  </label>
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    );
  }
}

FilterListTop.defaultProps = {
  totalSelected: 0,
  filters: [],
  content: [],
  value: "",
  number: 1,
};

FilterListTop.propTypes = {
  clickOutside: propTypes.func,
  toggleFilter: propTypes.func.isRequired,
  uncheckAll: propTypes.func.isRequired,
  clearInput: propTypes.func.isRequired,
  uncheck: propTypes.func.isRequired,
  searchFacets: propTypes.func.isRequired,
  content: propTypes.array,
  activedFilters: propTypes.array.isRequired,
  filters: propTypes.array,
  type: propTypes.string.isRequired,
  inside: propTypes.bool,
  insideLabel: propTypes.string,
  totalSelected: propTypes.number.isRequired,
  value: propTypes.string,
  total: propTypes.number,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    { toggleFilter, uncheckAll, uncheck, searchFacets, clearInput },
    dispatch,
  ),
)(onClickOutside(FilterListTop));
