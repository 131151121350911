import "./SelectiveProcessList.scss";
import {
  Icon,
  Link,
  LoadMore,
  SelectiveProcessListItem,
  ModalSorting,
} from "../../index";
import React from "react";
import { receive } from "../../../store/modules/Painel";
import { toggleModalTriagem } from "../../../store/modules/SelectSorting";
import {
  clearInitialValues,
  hideVacancy,
  pagination,
  updateVacancy,
  updateVacancyStatus,
} from "../../../store/modules/VacanciesSearch";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import flattenProps from "../../../utils/flatten-props";
import { intlReplace } from "../../../utils/IntlReplace";
import { receive as receiveVacanciesStatus } from "../../../store/modules/VacanciesStatus";
import { requestUpdateVacancy } from "../../../store/modules/Vacancies";
import { useEffect } from "react";

const emptyLocation = { cidade: "", estado: "", pais: "", descricao: "" };

const mapStateToProps = (state) => ({
  loaded: state.VacanciesSearch.search.loaded,
  loading: state.VacanciesSearch.search.loading,
  paginating: state.VacanciesSearch.search.paginating,
  error: state.VacanciesSearch.search.error,
  results: state.VacanciesSearch.search.data,
  vacanciesStatus: state.VacanciesStatus.data,
  count: state.VacanciesSearch.count,
  filters: state.form,
  sortingAccess: state.Painel.painel.data
    ? state.Painel.painel.data.cliente.acesso_triagem_1
    : [],
  sortingDefault: state.Painel.painel.data
    ? state.Painel.painel.data.funcionario.configuracoes.triagem_padrao
    : [],
  isOpenTriagem: state.SelectSorting.modal,
  idTriagem: state.SelectSorting.id ? state.SelectSorting.id : null,
  featureFlags: state.Painel.painel.data
    ? state.Painel.painel.data.feature_flags
    : [],
  requestUpdateVacancy: requestUpdateVacancy,
  onUpdateStatusActions: [],
});

const SelectiveProcessResult = ({
  clearInitialValues,
  updateVacancyStatus,
  receive,
  receiveVacanciesStatus,
  results,
  type,
  isHiddenVacancies,
  pagination,
  count,
  updateVacancy,
  hideVacancy,
  loading,
  loaded,
  paginating,
  sortingAccess,
  isOpenTriagem,
  toggleModalTriagem,
  idTriagem,
  sortingDefault,
  vacanciesStatus,
  featureFlags,
  onUpdateStatusActions,
}) => {
  function clear(el) {
    el.preventDefault();
    clearInitialValues();
  }

  function updateVacanciesTempList(vacancyId, updatedValue) {
    updateVacancyStatus(vacancyId, updatedValue);
  }

  useEffect(() => {
    receive("painel", "painel");
    receiveVacanciesStatus("status_vagas");
  }, []);

  let newVacancyStatusOn = false;

  if (featureFlags) {
    newVacancyStatusOn = featureFlags.includes("novo_status_vaga");
  }

  if (loading) {
    return (
      <div className="row">
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
        <div className="vg-vacancy-loader">
          <Icon iconKey="jobLoader" />
        </div>
        <div className="vg-vacancy-loader">
          <Icon iconKey="jobLoader" />
        </div>
      </div>
    );
  }

  return loaded ? (
    <div className="vg-row">
      <div className="vg-col-md-12 vg-col-xs-12">
        {isHiddenVacancies ? (
          <></>
        ) : (
          <h2 className="vg-title-1 vg-title-vacancies">
            {intl.get("VAGAS.BUSCA.RESULTADO_BUSCA_TITULO")}
            <p className="vg-sub-tit">
              {intl.getHTML("VAGAS.BUSCA.RESULTADO_BUSCA_SUB_TITULO", {
                results: results.total ? results.total : 0,
              })}
              <Link
                href="#"
                onClick={clear}
                className="vg-btn-link"
                data-testid="link-limpar-busca"
              >
                {intl.get("VAGAS.BUSCA.LIMPAR")}
              </Link>
            </p>
          </h2>
        )}
        <ul className="vg-list-wrap">
          {map(results.processos_seletivos, (obj) => (
            <SelectiveProcessListItem
              key={obj.id}
              id={obj.id}
              expirationDate={obj.data_de_expiracao}
              openDate={obj.data_de_abertura}
              creationDate={obj.data_de_criacao}
              limiteDate={obj.data_limite_veiculacao}
              vacancy={obj.cargo}
              pcd={obj.exclusiva_pcd}
              phases={obj.fases}
              totalAfterSorting={obj.total_candidaturas_apos_ultima_triagem}
              status={obj.status && obj.status.replace(/[._]/g, "-")}
              currentStatus={
                obj.status_atual && intlReplace(obj.status_atual.descricao)
              }
              availableStatus={vacanciesStatus}
              acoes={obj.acoes}
              buttons={obj.acoes.botoes}
              menu={obj.acoes.menu}
              total={obj.total_candidatos}
              employee={obj.responsavel.id}
              positions={obj.posicoes}
              isIntern={obj.fechamento_com_candidatos_internos}
              sla={obj.sla}
              type={type}
              responsavel={obj.responsavel}
              grupo={obj.grupo}
              divisao={obj.divisao_veiculacao}
              updateVacancy={updateVacancy}
              test={obj.teste}
              vacancyType={obj.tipo_processo}
              hideVacancy={hideVacancy}
              sortingDefault={sortingDefault}
              sortingAccess={sortingAccess}
              openingDateSituation={obj.situacao_data_veiculacao}
              location={
                obj.localizacao
                  ? flattenProps(obj.localizacao, "nome")
                  : emptyLocation
              }
              requestUpdateVacancy={requestUpdateVacancy}
              onUpdateStatusActions={onUpdateStatusActions}
              visualizations={obj.total_visualizacoes}
              newVacancyStatusOn={newVacancyStatusOn}
              updateVacanciesTempList={updateVacanciesTempList}
              featureFlags={featureFlags}
              vagaInteligente={obj.vaga_inteligente_criada}
              hideVeiculacao={obj.oculta_alterar_veiculacao}
            />
          ))}
        </ul>
        {(results.total_paginas > count || paginating) && (
          <LoadMore carregarMais={pagination} loading={paginating} />
        )}
        {!!sortingAccess && !!sortingDefault && (
          <ModalSorting
            toggleModalTriagem={toggleModalTriagem}
            isOpenTriagem={isOpenTriagem}
            idTriagem={idTriagem}
          />
        )}
      </div>
    </div>
  ) : null;
};

SelectiveProcessResult.defaultProps = {
  loading: true,
  loaded: false,
  results: {
    processos_seletivos: [],
  },
  totalPagina: 0,
  count: 1,
  vacanciesStatus: [],
};

SelectiveProcessResult.propTypes = {
  results: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
  isHiddenVacancies: propTypes.bool,
  pagination: propTypes.func.isRequired,
  totalPagina: propTypes.number,
  updateVacancy: propTypes.func.isRequired,
  hideVacancy: propTypes.func.isRequired,
  clearInitialValues: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  paginating: propTypes.bool.isRequired,
  loaded: propTypes.bool.isRequired,
  count: propTypes.number.isRequired,
  receive: propTypes.func.isRequired,
  sortingAccess: propTypes.any.isRequired,
  sortingDefault: propTypes.any.isRequired,
  idTriagem: propTypes.number,
  isOpenTriagem: propTypes.bool.isRequired,
  toggleModalTriagem: propTypes.func.isRequired,
  featureFlags: propTypes.array.isRequired,
  requestUpdateVacancy: propTypes.func.isRequired,
  receiveVacanciesStatus: propTypes.func.isRequired,
  vacanciesStatus: propTypes.array.isRequired,
  updateVacancyStatus: propTypes.func,
  onUpdateStatusActions: propTypes.array.isRequired,
};

export default connect(mapStateToProps, {
  pagination,
  hideVacancy,
  updateVacancy,
  clearInitialValues,
  receive,
  toggleModalTriagem,
  receiveVacanciesStatus,
  updateVacancyStatus,
  requestUpdateVacancy,
})(SelectiveProcessResult);
