import React, { useState } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import {
  Icon,
  Tooltip,
  Location,
  ListInfoDropdownContent,
  PostingDateContent,
} from "../../index";
import { styled } from "@vagastec/epartner-ui";
import Dropdown from "../../atoms/Dropdown";
import { underscoreReplace } from "../../../utils/underscoreReplace";
import moment from "moment";

const getLocationSafe = (obj) => obj.cidade || obj.estado || obj.pais || "";
const DropdownWrapper = styled.div`
  position: relative;
  flex-shrink: 0;

  + div {
    margin-left: 8px;
  }
`;

const ListItemInfo = (props) => {
  const {
    divisao,
    expirationDate,
    grupo,
    id,
    limiteDate,
    location,
    openDate,
    openingDateSituation,
    hideVeiculacao,
    status,
    total,
    totalAfterSorting,
    updateVacanciesTempList,
    visualizations,
  } = props;

  const [toggleOpeningInfoDropdown, setToggleOpeningInfoDropdown] =
    useState(false);
  const [toggleOpeningDateDropdown, setToggleOpeningDateDropdown] =
    useState(false);

  return (
    <div className="vg-timeline">
      <Tooltip position="bottom" text={intl.get("VAGAS.DATA.PERIODO")}>
        <div className="vg-list-date">
          <Icon
            iconKey="time"
            addClass={underscoreReplace(openingDateSituation)}
            aria-hidden
          />
          {status !== "agendada" && (
            <span
              onClick={() =>
                setToggleOpeningDateDropdown(!toggleOpeningDateDropdown)
              }
            >
              {moment(openDate).format("DD/MM")}
              {intl.get("VAGAS.DATA.ATE")}
              {moment(expirationDate).format("DD/MM/YYYY")}
            </span>
          )}
          {status === "agendada" && (
            <span>
              {intl.get("VAGAS.DATA.APARTIR")}
              {moment(openDate).format("DD/MM/YYYY")}
            </span>
          )}
          {!hideVeiculacao && (
            <button
              style={{
                borderWidth: "0px",
                backgroundColor: "#fff",
              }}
              onClick={() =>
                setToggleOpeningDateDropdown(!toggleOpeningDateDropdown)
              }
              aria-label={intl.get(
                "VAGAS.ACOES.BOTOES.EDITAR_PERIODO_INSCRICOES"
              )}
            >
              <Icon
                aria-hidden
                iconKey="arrow"
                addClass={`vg-icon-opening-date-situation
              vg-icon-opening-date-situation${
                toggleOpeningDateDropdown ? "-up" : ""
              }
            `}
              />
            </button>
          )}
        </div>
      </Tooltip>
      <Tooltip
        position="bottom"
        text={location.descricao || getLocationSafe(location)}
      >
        <Location label={getLocationSafe(location)} />
      </Tooltip>
      <div className="vg-text-info">
        <button
          onClick={() =>
            setToggleOpeningInfoDropdown(!toggleOpeningInfoDropdown)
          }
        >
          <Icon iconKey="info" addClass="vg-icon-info" />
          {intl.get("VAGAS.INFO.LABEL")}
          <Icon
            iconKey="arrow"
            addClass={`vg-icon-opening-info-situation
            vg-icon-opening-info-situation${
              toggleOpeningInfoDropdown ? "-up" : ""
            }
          `}
          />
        </button>
      </div>
      {toggleOpeningInfoDropdown && (
        <DropdownWrapper>
          <Dropdown
            setShowDropdown={setToggleOpeningInfoDropdown}
            label={intl.get("VAGAS.INFO.TITULO")}
            align="left"
            closeTabIndex={0}
            alignAdjustment="-38px"
            className="vg-dropdown-info"
            removeArrow
          >
            <ListInfoDropdownContent
              id={id}
              location={location}
              divisao={divisao}
              grupo={grupo}
              visualizations={visualizations}
              totalAfterSorting={totalAfterSorting}
              total={total}
            />
          </Dropdown>
        </DropdownWrapper>
      )}
      {toggleOpeningDateDropdown && !hideVeiculacao && (
        <DropdownWrapper>
          <Dropdown
            setShowDropdown={setToggleOpeningDateDropdown}
            label={intl.get("VAGAS.EDICAO_DATA_VEICULACAO.TITULO")}
            align="left"
            closeTabIndex={0}
            alignAdjustment="-360px"
            removeArrow
          >
            <PostingDateContent
              openingDate={openDate}
              expirationDate={expirationDate}
              selectiveProcessId={id}
              openingDateSituation={openingDateSituation}
              updateVacanciesTempList={updateVacanciesTempList}
              limiteDate={limiteDate}
            />
          </Dropdown>
        </DropdownWrapper>
      )}
    </div>
  );
};

ListItemInfo.propTypes = {
  divisao: propTypes.string,
  expirationDate: propTypes.string.isRequired,
  grupo: propTypes.string,
  hideVeiculacao: propTypes.bool,
  id: propTypes.number.isRequired,
  limiteDate: propTypes.string,
  location: propTypes.shape({
    cidade: propTypes.string,
    estado: propTypes.string,
    pais: propTypes.string,
    descricao: propTypes.string,
  }),
  openDate: propTypes.string.isRequired,
  openingDateSituation: propTypes.string.isRequired,
  total: propTypes.number,
  totalAfterSorting: propTypes.number,
  status: propTypes.string,
  updateVacanciesTempList: propTypes.func,
  visualizations: propTypes.number,
};

export default ListItemInfo;
