import React from "react";
import { bool, string, object, func } from "prop-types";
import Highlight from "react-highlighter";
import { Icon } from "../../index";
import intl from "react-intl-universal";
import "./AutoSuggest.scss";

const AutoSuggestList = ({ candidates, inputValue, include }) => {
  return candidates.candidatos.length ? (
    <div className="vg-autosuggest-list">
      <ul>
        {candidates.candidatos.map((candidate) => {
          return (
            <li
              className="vg-autosuggest-list-item"
              key={`auto-suggest-${candidate.id}`}
              onClick={() =>
                include({
                  id: candidate.id,
                  nome: candidate.nome,
                  fase_atual_nome: candidate.fase_atual_nome,
                })
              }
            >
              <Icon iconKey="candidate" />
              <span className="vg-autosuggest-candidate-name">
                <Highlight search={inputValue}>{candidate.nome}</Highlight>
                <em>{candidate.fase_atual_nome}</em>
              </span>
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <div className="vg-autosuggest-list">
      <p>
        {intl.get("HOME.CONTEUDO.INDICAR_CANDIDATOS.ERRO.NAO_TEM_CANDIDATOS")}
      </p>
    </div>
  );
};

AutoSuggestList.defaultProps = {
  candidates: {},
  searching: false,
  inputValue: "",
  loaded: false,
};

AutoSuggestList.propTypes = {
  candidates: object.isRequired,
  searching: bool.isRequired,
  inputValue: string.isRequired,
  include: func.isRequired,
  loaded: bool,
};

export default AutoSuggestList;
