// Action Types
const ACTIONS = "services/ACTIONS";
const ACTIONS_SUCCESS = "services/ACTIONS_SUCCESS";
const ACTIONS_FAIL = "services/ACTIONS_FAIL";
const ACTION_POST = "services/ACTION_POST";
const ACTION_POST_SUCCESS = "services/ACTION_POST_SUCCESS";
const ACTION_POST_FAIL = "services/ACTION_POST_FAIL";
const ACTION_UPDATE = "services/ACTION_UPDATE";
const ACTION_UPDATE_SUCCESS = "services/ACTION_UPDATE_SUCCESS";
const ACTION_UPDATE_FAIL = "services/ACTION_UPDATE_FAIL";
const ACTION_REMOVE = "services/ACTION_REMOVE";
const ACTION_REMOVE_SUCCESS = "services/ACTION_REMOVE_SUCCESS";
const ACTION_REMOVE_FAIL = "services/ACTION_REMOVE_FAIL";
const ACTION_LIST = "services/ACTION_LIST";
const ACTION_LIST_SUCCESS = "services/ACTION_LIST_SUCCESS";
const ACTION_LIST_FAIL = "services/ACTION_LIST_FAIL";

// InitialState
const initialState = {
  getActions: {},
  listActions: {
    data: [],
  },
};

// Reducers
export default function Actions(state = initialState, action = {}) {
  switch (action.type) {
    case ACTIONS:
      return {
        ...state,
        getActions: {
          ...state.getActions,
          [action.phaseId]: {
            loading: true,
            loaded: false,
            data: [],
          },
        },
      };
    case ACTIONS_SUCCESS:
      return {
        ...state,
        getActions: {
          ...state.getActions,
          [action.phaseId]: {
            loading: false,
            loaded: true,
            data: action.result,
          },
        },
      };
    case ACTIONS_FAIL:
      return {
        getActions: {
          ...state.getActions,
          [action.phaseId]: {
            error: action.error,
            loading: false,
            loaded: true,
          },
        },
      };
    case ACTION_POST:
      return {
        ...state,
        postResult: {
          loading: true,
          loaded: false,
        },
      };
    case ACTION_POST_SUCCESS:
      return {
        ...state,
        postResult: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case ACTION_POST_FAIL:
      return {
        ...state,
        postResult: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case ACTION_UPDATE:
      return {
        ...state,
        updateResult: {
          loading: true,
          loaded: false,
        },
      };
    case ACTION_UPDATE_SUCCESS:
      return {
        ...state,
        updateResult: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case ACTION_UPDATE_FAIL:
      return {
        ...state,
        updateResult: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case ACTION_REMOVE:
      return {
        ...state,
        removeResult: {
          loading: true,
          loaded: false,
        },
      };
    case ACTION_REMOVE_SUCCESS:
      return {
        ...state,
        removeResult: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case ACTION_REMOVE_FAIL:
      return {
        ...state,
        removeResult: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case ACTION_LIST:
      return {
        ...state,
        listActions: {
          loading: true,
          loaded: false,
        },
      };
    case ACTION_LIST_SUCCESS:
      return {
        ...state,
        listActions: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case ACTION_LIST_FAIL:
      return {
        ...state,
        listActions: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    default:
      return state;
  }
}

// Actions
export function load(phaseId) {
  return {
    types: [ACTIONS, ACTIONS_SUCCESS, ACTIONS_FAIL],
    promise: (client) => client.get(`fases/${phaseId}/acoes`),
    phaseId,
  };
}

export function post(path, data) {
  return {
    types: [ACTION_POST, ACTION_POST_SUCCESS, ACTION_POST_FAIL],
    promise: (client) => client.post(path, { data }),
  };
}

export function update(path, data) {
  return {
    types: [ACTION_UPDATE, ACTION_UPDATE_SUCCESS, ACTION_UPDATE_FAIL],
    promise: (client) => client.put(path, { data }),
  };
}

export function remove(path) {
  return {
    types: [ACTION_REMOVE, ACTION_REMOVE_SUCCESS, ACTION_REMOVE_FAIL],
    promise: (client) => client.del(path),
  };
}

export function getList(path) {
  return {
    types: [ACTION_LIST, ACTION_LIST_SUCCESS, ACTION_LIST_FAIL],
    promise: (client) => client.get(path),
  };
}

function shouldFetch(state) {
  return state.loading ? false : state.data ? false : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(phaseId) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(phaseId));
    }
  };
}
