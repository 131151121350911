// Action Types
const LOAD_HIERARCHY = "domain/LOAD_HIERARCHY";
const LOAD_HIERARCHY_SUCCESS = "domain/LOAD_HIERARCHY_SUCCESS";
const LOAD_HIERARCHY_FAIL = "domain/LOAD_HIERARCHY_FAIL";

// InitialState
const initialState = {
  loaded: false,
};

// Reducer
export default function counting(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_HIERARCHY:
      return {
        ...state,
        loading: true,
      };
    case LOAD_HIERARCHY_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_HIERARCHY_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_HIERARCHY, LOAD_HIERARCHY_SUCCESS, LOAD_HIERARCHY_FAIL],
    promise: (client) =>
      client.get("niveis_hierarquicos", {}, process.env.REACT_APP_DOMINIOS_URL),
  };
}

function shouldFetch(state) {
  return state.Hierarchy.loading
    ? false
    : state.Hierarchy.data !== undefined
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
