import React from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";

const ButtonCancel = (props) => {
  const { url, pdf } = props;

  const onClick = (e) => {
    e.preventDefault();
    url && url.setTermUrl(url.initialTerm);

    pdf && pdf.dispatchPdfFile({ type: "emptyField" });
  };

  const isButtonDisabled = () => {
    return (
      (url && url.termUrl == url.initialTerm) ||
      (pdf && pdf.uploadStatus == "concluded")
    );
  };

  return (
    <button
      className={`vg-input-button-cancel ${
        isButtonDisabled() ? "disabled" : "enabled"
      }`}
      onClick={onClick}
      disabled={isButtonDisabled()}
    >
      {intl.get("CONFIGURACOES.TERMO_ACEITE.URL.CANCELAR")}
    </button>
  );
};

ButtonCancel.propTypes = {
  url: PropTypes.shape({
    initialTerm: PropTypes.string,
    setTermUrl: PropTypes.func,
    termUrl: PropTypes.string,
  }),
  pdf: PropTypes.shape({
    dispatchPdfFile: PropTypes.func,
    uploadStatus: PropTypes.string,
  }),
};

export default ButtonCancel;
