import React from "react";
import { createRoot } from "react-dom/client";
import { Route, Router } from "react-router-dom";
import { ThemeProvider, defaultTheme } from "@vagastec/epartner-ui";
import { Provider } from "react-redux";
import { App, IconsList } from "../src/components/index";
import ApiClient from "../src/utils/ApiClient";
import configureStore from "../src/store/configureStore";
import history from "../src/utils/history";
import "../src/styles/application.scss";
import "../src/loader/loader";

const client = new ApiClient();
const ReduxStore = configureStore(window.REDUX_INITIAL_DATA, client);
const rootElement = document.getElementById("root");
const iconsListElement = document.getElementById("iconsList");

if (rootElement) {
  const container = createRoot(rootElement);
  container.render(
    <Provider store={ReduxStore}>
      <ThemeProvider theme={defaultTheme}>
        <Router basename={process.env.PUBLIC_URL} history={history}>
          <Route path="/" component={App} />
        </Router>
      </ThemeProvider>
    </Provider>
  );
}
if (iconsListElement) {
  const containerIconList = createRoot(iconsListElement);
  containerIconList.render(
    <Router history={history}>
      <Route path="/icones" component={IconsList} />
    </Router>
  );
}
if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept();
}
