// Action Types
const LOAD_CREDITS = "services/LOAD_CREDITS";
const LOAD_CREDITS_SUCCESS = "services/LOAD_CREDITS_SUCCESS";
const LOAD_CREDITS_FAIL = "services/LOAD_CREDITS_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  disponiveis: 0,
};

// Reducer
export default function Credits(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CREDITS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CREDITS_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
        disponiveis: action.result.disponiveis,
      };
    case LOAD_CREDITS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [LOAD_CREDITS, LOAD_CREDITS_SUCCESS, LOAD_CREDITS_FAIL],
    promise: (client) => client.get(path),
  };
}
