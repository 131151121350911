import "./BoxTitle.scss";

import React, { Component } from "react";

import { Link } from "../../index";
import PropTypes from "prop-types";

class BoxTitle extends Component {
  render() {
    const { title, text, secondLevel, inlineLink, children } = this.props;
    return (
      <div className={`vg-box-title ${secondLevel && "vg-box-title-second"}  `}>
        <h1>{title}</h1>
        <p>
          {text}{" "}
          {inlineLink &&
            (inlineLink.to ? (
              <Link to={inlineLink.to}>{inlineLink.text}</Link>
            ) : (
              <Link
                className="vg-link"
                moveTo={inlineLink.moveTo}
                params={inlineLink.params ? inlineLink.params : {}}
              >
                {inlineLink.text}
              </Link>
            ))}
        </p>
        {children}
      </div>
    );
  }
}

BoxTitle.defaultProps = {
  title: "",
  text: "",
  secondLevel: false,
};

BoxTitle.propTypes = {
  title: PropTypes.any,
  text: PropTypes.any,
  secondLevel: PropTypes.bool,
  inlineLink: PropTypes.object,
  children: PropTypes.node,
};

export default BoxTitle;
