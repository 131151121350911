import React from "react";
import { Route, Switch } from "react-router-dom";
import Cookie from "js-cookie";

import {
  AcceptanceTerm,
  Auth,
  Configuration,
  EditPhases,
  HiddenVacancies,
  HomePage,
  NotFoundPage,
  Professionals,
  Sla,
  Terms,
  UsersRedes,
  Vacancies,
  VideoInterview,
  VideoInterviewAnswered,
  VideoInterviewCandidate,
  VideoInterviewCandidateQuestion,
  VideoInterviewCandidateTest,
  VideoInterviewCandidateFake,
  VideoInterviewQuestions,
  VideoInterviewTerms,
  VideoInterviewThanks,
  VideoMain,
} from "../../index";

import VacanciesRequests from "../VacanciesRequests";
import LandingPageTriagem from "../LandingPageTriagem";

export const Routers = () => {
  const isVisitor = () => {
    return !!(
      (window.location.pathname.match(/video-entrevista\/candidato/g) ||
        Cookie.get("compartilhamento_token")) &&
      Cookie.get("convidado_token")
    );
  };

  const urlVisitor = !!window.location.pathname.match(
    /convidado\/assiste\/video-entrevista/g
  );

  const getToken = () => {
    return Cookie.get("convidado_token");
  };

  return (
    <Switch location={window.location}>
      <Route exact path="/" component={() => <HomePage />} />
      <Route exact path="/termos-de-uso" component={Terms} />
      <Route exact path="/landing-page-nova-triagem" component={LandingPageTriagem} />
      <Route exact path="/vagas" component={Vacancies} />
      <Route exact path="/vagas/vagas-ocultas" component={HiddenVacancies} />
      {process.env.NODE_ENV === "development" && (
        <Route exact path="/fake-login" component={Auth} />
      )}
      <Route
        exact
        path="/vagas/requisicao-de-vagas"
        component={VacanciesRequests}
      />
      <Route exact path="/configuracoes/redes" component={UsersRedes} />
      <Route exact path="/configuracoes/sla" component={Sla} />
      <Route exact path="/configuracoes/editar-fases" component={EditPhases} />
      <Route
        exact
        path="/configuracoes/termo-aceite"
        component={AcceptanceTerm}
      />
      <Route exact path="/configuracoes" component={Configuration} />
      <Route exact path="/profissionais" component={Professionals} />
      <Route
        exact
        path="/criar-perguntas"
        component={VideoInterviewQuestions}
      />
      <Route
        exact
        path="/video-entrevista/candidato"
        render={(props) => (
          <VideoMain {...props} visitor={isVisitor()} tokenShare={getToken()} />
        )}
      />
      <Route
        exact
        path="/video-entrevista/:id"
        render={(props) => (
          <VideoInterview {...props} urlVisitor={urlVisitor} />
        )}
      />
      <Route
        exact
        path="/candidato/video-entrevista/teste"
        component={VideoInterviewCandidateTest}
      />
      <Route
        exact
        path="/candidato/video-entrevista/questao"
        component={VideoInterviewCandidateQuestion}
      />
      <Route
        exact
        path="/candidato/video-entrevista/obrigado"
        component={VideoInterviewThanks}
      />
      <Route
        exact
        path="/candidato/video-entrevista/realizada"
        component={VideoInterviewAnswered}
      />
      <Route
        exact
        path="/candidato/grava/video-entrevista"
        component={VideoInterviewCandidate}
      />
      <Route
        exact
        path="/candidato/video-entrevista/termos"
        component={VideoInterviewTerms}
      />
      <Route
        exact
        path="/convidado/assiste/video-entrevista/:token"
        render={(props) => (
          <VideoInterview
            {...props}
            visitor={isVisitor()}
            tokenShare={getToken()}
            urlVisitor={urlVisitor}
          />
        )}
      />
      {process.env.NODE_ENV !== "production" && (
        <Route
          exact
          path="/candidato/video-entrevista/fake"
          component={VideoInterviewCandidateFake}
        />
      )}
      <Route path="**" component={NotFoundPage} />
    </Switch>
  );
};
