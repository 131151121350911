import "./Header.scss";

import { Icon } from "../../index";
import React from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";

export default function EditPhasesHeader(item) {
  return (
    <div className="vg-phase-header">
      <button className="vg-phase-header-drag">
        <Icon iconKey="drag" />
      </button>
      <div className="vg-phase-header-index">{item.index + 1}</div>
      <h3 className="vg-phase-header-title">
        {item.nome} <small>- {item.sigla}</small>
      </h3>
      <div className="vg-phase-header-orange">
        <span>
          <span className="vg-link-text">
            {intl.get("SLA.CONFIGURAR.LINK")}
          </span>
          <Icon iconKey="arrow" />
        </span>
      </div>
    </div>
  );
}

EditPhasesHeader.defaultProps = {
  item: {},
};

EditPhasesHeader.propTypes = {
  item: propTypes.object.isRequired,
};
