import propTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import {
  openModal,
  closeModal,
} from "../../../store/modules/ModalShareVideoInterview";
import { Button, Modal, ModalShareVideo } from "../../index";
import "./BoxShare.scss";

const mapStateToProps = (state) => ({
  isModalOpen: state.ModalShareVideoInterview.isOpen,
  selectedIds: state.ModalShareVideoInterview.selectedIds,
});

class BoxShare extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { participantes, selectedIds, isModalOpen, openModal, closeModal } =
      this.props;
    const modalTitleSuffix =
      selectedIds > 1 ? "TITULO_UNICO" : "TITULO_MULTIPLOS";
    return (
      <React.Fragment>
        {!!participantes.length && (
          <div className="vg-box-share">
            <Button
              className="vg-btn-primary vg-btn-outline"
              onClick={() => {
                openModal(null, participantes);
              }}
            >
              {intl.getHTML(
                "VIDEO_ENTREVISTA.EMPRESA.LISTA.COMPARTILHAR.COMPARTILHAR"
              )}
            </Button>
            <p>
              <span className="vg-text-share">
                {intl.getHTML(
                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.COMPARTILHAR.VIDEOS",
                  {
                    participantes: participantes.length,
                  }
                )}
              </span>
              {intl.getHTML(
                "VIDEO_ENTREVISTA.EMPRESA.LISTA.COMPARTILHAR.SELECIONADAS",
                {
                  participantes: participantes.length,
                }
              )}
            </p>
          </div>
        )}
        <Modal
          contentLabel={intl.get(
            `VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.${modalTitleSuffix}`,
            {
              n: participantes.length,
            }
          )}
          show={isModalOpen}
          onClose={() => {
            closeModal();
          }}
        >
          <ModalShareVideo closeModal={closeModal} />
        </Modal>
      </React.Fragment>
    );
  }
}

BoxShare.propTypes = {
  participantes: propTypes.array.isRequired,
  selectedIds: propTypes.array.isRequired,
  isModalOpen: propTypes.bool.isRequired,
  openModal: propTypes.func.isRequired,
  closeModal: propTypes.func.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      openModal,
      closeModal,
    },
    dispatch
  )
)(BoxShare);
