import { Link, VideoInterviewTermsI18n } from "../../index";

import React from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";

export default function VideoInterviewTerms({ history }) {
  return (
    <div className="vg-container vg-interview-candidate">
      <header>
        <h2>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.TERMOS.TITULO")}</h2>
        <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.TERMOS.SUBTITULO")}</p>
      </header>
      <article className="vg-row center-xs">
        <div className="vg-col-xs-12 vg-col-sm-8 vg-interview-terms">
          <div>
            <VideoInterviewTermsI18n />
          </div>
        </div>
      </article>
      <div className="vg-interview-wrap-buttons">
        <Link onClick={() => history.goBack()} className="vg-btn-primary">
          {intl.get("VIDEO_ENTREVISTA.CANDIDATO.TERMOS.LINK")}
        </Link>
      </div>
    </div>
  );
}

VideoInterviewTerms.propTypes = {
  history: propTypes.object.isRequired,
};
