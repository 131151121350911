import React from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import { requestUpdateVacancy } from "../../../store/modules/Vacancies";
import { HiddenVacanciesListItemHeader } from "../../index";
import { underscoreReplace } from "../../../utils/underscoreReplace";
import "./HiddenVacanciesList.scss";

const mapStateToProps = () => ({
  requestUpdateVacancy: requestUpdateVacancy,
});

const HiddenVacanciesListItem = (props) => {
  const { status, requestUpdateVacancy, limiteDate, updateVacanciesTempList } =
    props;

  return (
    <li className={`vg-list-item vg-${underscoreReplace(status)}`}>
      <HiddenVacanciesListItemHeader
        requestUpdateVacancy={requestUpdateVacancy}
        updateVacanciesTempList={updateVacanciesTempList}
        limiteDate={limiteDate}
        {...props}
      />
    </li>
  );
};

HiddenVacanciesListItem.defaultProps = {
  positions: {},
  buttons: [],
  isIntern: false,
  total: 0,
  status: "",
};

HiddenVacanciesListItem.propTypes = {
  id: propTypes.number.isRequired,
  buttons: propTypes.array.isRequired,
  positions: propTypes.object,
  totalAfterSorting: propTypes.number,
  status: propTypes.string,
  vacancy: propTypes.string.isRequired,
  requestUpdateVacancy: propTypes.any,
  updateVacanciesTempList: propTypes.func,
  limiteDate: propTypes.string,
};

export default connect(mapStateToProps, {
  requestUpdateVacancy,
})(HiddenVacanciesListItem);
