import "./VacanciesRequests.scss";

import {
  BoxTitle,
  ErrorHandler,
  FastLink,
  Icon,
  LoadMore,
  RequestFilter,
  SelectiveProcessState,
  VacanciesRequestList,
} from "../../index";
import React, { useEffect } from "react";
import {
  clearInitialValues,
  load,
  loadFacets,
  pagination,
  parseFilter,
  toggleFilter,
  updateFilter,
  clearInputs,
} from "../../../store/modules/RequestFilter";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";

const mapStateToProps = (state) => ({
  requestList: state.RequestFilter.results,
  facets: state.RequestFilter.facets.data,
  loadingFilter: state.RequestFilter.facets.loading,
  activedFilters: state.RequestFilter.activedFilters,
  filter: state.RequestFilter.filter,
  paginating: state.RequestFilter.results.paginating,
  count: state.RequestFilter.count,
});

const VacanciesRequests = ({
  requestList,
  facets,
  loadingFilter,
  activedFilters,
  parseFilter,
  toggleFilter,
  filter,
  paginating,
  pagination,
  count,
  load,
  loadFacets,
  clearInitialValues,
  clearInputs,
  updateFilter,
}) => {
  useEffect(() => {
    load("requisicoes").then((success) => {
      const { filtros } = success.filtros_salvos;
      mapAllFilters(filtros, ["status", "requisitantes", "responsaveis"]);

      updateFilter(filtros);
    });
  }, []);

  const mapAllFilters = (savedFilters = {}, facets) => {
    facets.map((facet) => {
      if (facet === "requisitantes" || facet === "responsaveis") {
        const parseName = `${facet}_id`;

        // eslint-disable-next-line no-prototype-builtins
        if (savedFilters.hasOwnProperty(parseName)) {
          loadFacets(`requisicoes/${facet}`, facet, {
            "ids[]": savedFilters[parseName],
          });
        }
      }

      loadFacets(`requisicoes/${facet}`, facet, { tamanho: 100 });
    });
  };

  const clear = () => {
    clearInitialValues();
    clearInputs();
  };

  return (
    <div className="vg-vacancies-page">
      <div className="vg-container">
        <BoxTitle
          title={intl.get("REQUISICAO_DE_VAGAS.INTRODUCAO.TITULO")}
          text={intl.get("REQUISICAO_DE_VAGAS.INTRODUCAO.SUB_TITULO")}
          secondLevel
        />
        <section className="vg-row">
          <div className="vg-col-md-12 vg-col-xs-12">
            <FastLink type="requisicoes" path="painel/vagas/requisicoes" />
          </div>
        </section>

        {!!loadingFilter && !!requestList.loading ? (
          <div className="vg-search-wrapper">
            <div className="vg-search-loader">
              <Icon iconKey="searchLoader" />
            </div>
          </div>
        ) : (
          <div className="vg-search-wrapper vg-search-requisition">
            {requestList.data.acompanhar_requisicao ? (
              <div>
                <RequestFilter
                  facets={facets}
                  activedFilters={activedFilters}
                  submitContent={parseFilter}
                  toggleFilter={toggleFilter}
                  initialValues={filter}
                />
                {!!Object.keys(filter).length && requestList.loaded && (
                  <h2 className="vg-title-1 vg-title-vacancies">
                    {intl.get("VAGAS.BUSCA.RESULTADO_BUSCA_TITULO")}
                    <p className="vg-sub-tit">
                      {intl.getHTML(
                        "REQUISICOES_VAGAS.RESULTADO_BUSCA_SUB_TITULO",
                        {
                          results: requestList.data.total
                            ? requestList.data.total
                            : 0,
                        }
                      )}
                      <button onClick={() => clear()} className="vg-btn">
                        {intl.get("VAGAS.BUSCA.LIMPAR")}
                      </button>
                    </p>
                  </h2>
                )}
                {!!Object.keys(filter).length && requestList.loading && (
                  <div className="vg-title-loader">
                    <Icon iconKey="titleLoader" />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        )}

        {requestList.loading ? (
          <div>
            <div className="vg-vacancy-loader">
              <Icon iconKey="jobLoader" />
            </div>
            <div className="vg-vacancy-loader">
              <Icon iconKey="jobLoader" />
            </div>
          </div>
        ) : requestList.error ? (
          <ErrorHandler
            text={intl.getHTML("MENSAGEM_DE_ERRO.REQUISICAO_NAO_CARREGADA")}
            retryHandler={() => load("requisicoes")}
          />
        ) : requestList.data.total === 0 ? (
          <SelectiveProcessState>
            <p>
              <Icon iconKey="file" />
              <span className="vg-icon-border"></span>
              {intl.get("SERVICOS.NAO_HA_REQUISICAO_PUBLICADAS.TITULO")}
            </p>
          </SelectiveProcessState>
        ) : (
          <div>
            <section className="vg-row">
              <div className="vg-col-md-12 vg-col-xs-12">
                <VacanciesRequestList list={requestList} />
              </div>
            </section>
            {requestList.data.acompanhar_requisicao &&
              (requestList.data.total_paginas > count || paginating) && (
                <LoadMore
                  carregarMais={pagination}
                  loading={paginating}
                  text="REQUISICAO_DE_VAGAS.VER_MAIS"
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
};

VacanciesRequests.defaultProps = {
  filter: {},
};

VacanciesRequests.propTypes = {
  load: propTypes.func.isRequired,
  loadFacets: propTypes.func.isRequired,
  requestList: propTypes.object.isRequired,
  facets: propTypes.object.isRequired,
  activedFilters: propTypes.array.isRequired,
  parseFilter: propTypes.func.isRequired,
  pagination: propTypes.func.isRequired,
  clearInputs: propTypes.func.isRequired,
  updateFilter: propTypes.func.isRequired,
  paginating: propTypes.bool.isRequired,
  loadingFilter: propTypes.bool.isRequired,
  toggleFilter: propTypes.func.isRequired,
  clearInitialValues: propTypes.func.isRequired,
  filter: propTypes.object,
  count: propTypes.number.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      load,
      loadFacets,
      parseFilter,
      toggleFilter,
      clearInitialValues,
      pagination,
      updateFilter,
      clearInputs,
    },
    dispatch
  )
)(VacanciesRequests);
