import { array, string, func, object, number, node, bool } from "prop-types";
import React from "react";
import Slider from "react-slick";
import { Icon, Link, Tooltip } from "../../index";
import intl from "react-intl-universal";
import "./Phases.scss";

function CustomArrow(props) {
  const { className, style, onClick, ariaLabel } = props;
  return (
    <button
      className={className}
      style={{ ...style }}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <Icon iconKey="arrow" aria-hidden />
    </button>
  );
}

CustomArrow.defaultProps = {
  className: "",
  style: {},
};

CustomArrow.propTypes = {
  className: string.isRequired,
  style: object.isRequired,
  onClick: func,
  ariaLabel: string,
};

const Phases = (props) => {
  const { id, phases, totalAfterSorting, sortingDefault } = props;
  const firstPhase = phases.length && phases[0];
  const moveToSorting =
    sortingDefault === "triagem_1"
      ? "ver-candidaturas-triagem1"
      : "ver-candidaturas-triagem2";

  const reactSlickSettings = {
    dots: false,
    infinite: false,
    focusOnSelect: true,
    variableWidth: true,
    nextArrow: (
      <CustomArrow ariaLabel={intl.get("VAGAS.ACOES.BOTOES.PROXIMA_FASE")} />
    ),
    prevArrow: (
      <CustomArrow ariaLabel={intl.get("VAGAS.ACOES.BOTOES.FASE_ANTERIOR")} />
    ),
  };

  return (
    <div className="vg-phases-list-container">
      {!!firstPhase && (
        <Link
          moveTo={moveToSorting}
          params={{ codigo_da_vaga: id, codigo_da_fase: firstPhase.id }}
          className={`vg-first-phase vg-phase ${
            firstPhase.atual ? "vg-active" : ""
          }`}
          data-testid={`link-fase-${firstPhase.nome}`}
        >
          <span className="vg-phase-title">{firstPhase.nome}:</span>
          <span className="vg-phase-value">{firstPhase.total_candidatos}</span>
          <Tooltip
            position="right"
            text={intl.getHTML("VAGAS.NOVO.NOVAS_CANDIDATURAS")}
          >
            <span className="vg-phase-total">
              ({totalAfterSorting}{" "}
              {intl.getHTML("VAGAS.NOVO.NOVOS_CVS", {
                total: totalAfterSorting,
              })}
              )
            </span>
          </Tooltip>
        </Link>
      )}

      <div className="vg-phases-list">
        <Slider {...reactSlickSettings}>
          {phases?.map(
            (phaseItem) =>
              phaseItem !== firstPhase && (
                <Link
                  moveTo={moveToSorting}
                  params={{
                    codigo_da_vaga: id,
                    codigo_da_fase: phaseItem.id,
                  }}
                  className={`vg-phase ${phaseItem.atual ? "vg-active" : ""}`}
                  key={phaseItem.id}
                  data-testid={`link-fase-${phaseItem.nome}`}
                >
                  <span className="vg-phase-title">{phaseItem.nome}:</span>
                  <span className="vg-phase-value">
                    {phaseItem.total_candidatos}
                  </span>
                </Link>
              )
          )}
        </Slider>
      </div>
      {props.children}
    </div>
  );
};

Phases.propTypes = {
  phases: array.isRequired,
  totalAfterSorting: number.isRequired,
  className: string,
  id: number,
  children: node,
  sortingDefault: string,
  triagemV3: bool.isRequired,
};

export default Phases;
