import propTypes from "prop-types";
import React from "react";
import "./ErrorMessage.scss";

const ErrorMessage = ({ message }) => (
  <div className="vg-error-message">
    <p>{message}</p>
  </div>
);

ErrorMessage.propTypes = {
  message: propTypes.any.isRequired,
};

export default ErrorMessage;
