import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Icon, Toast } from "../../index";
import intl from "react-intl-universal";
import { P, Checkbox } from "@vagastec/epartner-ui";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import moment from "moment";
import ApiClient from "../../../utils/ApiClient";
import "./PostingDateContent.scss";

const PostingDateContent = ({
  openingDate,
  expirationDate,
  selectiveProcessId,
  openingDateSituation,
  updateVacanciesTempList,
  limiteDate,
}) => {
  const defaultDateFormat = "DD/MM/YYYY";
  const dateMaskFormat = "99/99/9999";
  const today = moment().format("YYYY/MM/DD");
  const yesterday = moment().subtract(1, "days");
  const tomorrow = moment().add(1, "days");
  const validationErrorTypes = [
    "ACIMA_SESSENTA_DIAS",
    "ACIMA_DA_DATA_LIMITE",
    "ACIMA_DA_QUANT_MAX_DIAS",
    "DATA_EXPIRACAO_MAIOR_QUE_PERMITIDO",
    "ULTRAPASSOU_DATA_EXPIRACAO",
    "DATA_ABERTURA_MENOR_QUE_PERMITIDO",
    "DATA_ATUAL_ULTRAPASSOU_PRAZO_LIMITE",
    "PERIODO_VEICULACAO_ULTRAPASSOU_LIMITE",
    "ULTRAPASSOU_DATA_ABERTURA_LIMITE",
    "DATA_ABERTURA_MAIOR_QUE_DATA_EXPIRACAO",
    "DATA_INVALIDA",
  ];
  const isValidDate = (date, format = defaultDateFormat) =>
    moment(date, format).isValid();
  const formatDate = (date, format = defaultDateFormat) =>
    moment(date).format(format);

  const showNotifications = (message, type = "success") => {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  };

  const [openingDateState, setOpeningDateState] = useState(
    formatDate(openingDate)
  );
  const [expirationDateState, setExpirationDateState] = useState(
    formatDate(expirationDate)
  );
  const [isExpirationDateDisabled, setIsExpirationDateDisabled] =
    useState(false);
  const [isValidPostingPeriodState, setIsValidPostingPeriodState] =
    useState(true);
  const [errorMessageState, setErrorMessageState] = useState([]);
  const [limiteDateValidate, setLimiteDate] = useState("");
  const formatedLimiteDate = moment(limiteDateValidate).format("YYYY/MM/DD");
  const [statusDate, setStatusDate] = useState("");
  const [isUpdatingDateState, setIsUpdatingDateState] = useState(false);

  const onBlurOpeningDateHandler = (event) => {
    const editedDate = event.target.value;

    if (isValidDate(editedDate)) {
      setOpeningDateState(editedDate);
    }
  };

  const onBlurExpirationDateHandler = (event) => {
    const editedDate = event.target.value;

    if (isValidDate(editedDate)) {
      setExpirationDateState(editedDate);
    }
  };

  const toggleCloseApplicationHandler = () => {
    setIsExpirationDateDisabled(!isExpirationDateDisabled);

    isExpirationDateDisabled
      ? setExpirationDateState(formatDate(tomorrow))
      : setExpirationDateState(formatDate(yesterday));
  };

  const updateOpeningDate = () => {
    setErrorMessageState(undefined);
    setIsValidPostingPeriodState(true);
    setIsUpdatingDateState(true);

    if (isValidDate(expirationDateState) && isValidDate(openingDateState)) {
      new ApiClient()
        .put(
          `/processos-seletivos/${selectiveProcessId}`,
          {
            data: {
              data_de_abertura: openingDateState,
              data_de_expiracao: expirationDateState,
            },
          },
          process.env.REACT_APP_ATHENA_URL
        )
        .then((data) => {
          if (data) {
            handleUpdateSuccess(),
              updateVacanciesTempList(selectiveProcessId, {
                data_de_abertura: moment(openingDateState, "DD/MM/YYYY"),
                data_de_expiracao: moment(expirationDateState, "DD/MM/YYYY"),
                situacao_data_veiculacao: data.situacao_data_veiculacao,
                sla: data.sla,
              });
          }
        })
        .catch((error) => handleUpdateError(error.response.body));
    }
  };

  const handleUpdateSuccess = () => {
    setIsUpdatingDateState(false);
    setIsValidPostingPeriodState(true);
    setErrorMessageState(undefined);

    showNotifications(
      intl.getHTML("VAGAS.EDICAO_DATA_VEICULACAO.MENSAGENS.SUCESSO"),
      "success"
    );
  };

  useEffect(() => {
    if (limiteDate) {
      setLimiteDate(limiteDate);
    }
  }, [limiteDate]);

  useEffect(() => {
    if (openingDateSituation) {
      setStatusDate(openingDateSituation);
    }
  }, [openingDateSituation]);

  const handleUpdateError = (error) => {
    const errorType = error.type && error.type.toUpperCase();
    setIsUpdatingDateState(false);
    setErrorMessageState("");

    if (validationErrorTypes.includes(errorType)) {
      setIsValidPostingPeriodState(false);

      let maxExpirationDate = "";

      if (errorType === "DATA_EXPIRACAO_MAIOR_QUE_PERMITIDO") {
        const message = error.error.split(" ");
        maxExpirationDate = message[message.length - 1];
        setLimiteDate(maxExpirationDate);
      }

      setErrorMessageState(
        intl.get(`VAGAS.EDICAO_DATA_VEICULACAO.MENSAGENS.ERROS.${errorType}`, {
          date: formatDate(maxExpirationDate),
        })
      );
    } else {
      showNotifications(
        "error",
        intl.get(
          "VAGAS.EDICAO_DATA_VEICULACAO.MENSAGENS.ERROS.FALHA_NA_REQUISICAO"
        )
      );
    }
  };

  return (
    <div className="vg-posting-date-content">
      {statusDate === "expirada" ? (
        <div>
          <label className="vg-label">
            <p className="vg-date-text">
              {intl.get(
                `VAGAS.EDICAO_DATA_VEICULACAO.${openingDateSituation.toUpperCase()}`
              )}
            </p>
          </label>
          <label className="vg-label">
            <span className="input-title">
              {intl.get("VAGAS.EDICAO_DATA_VEICULACAO.DATA_DE_ABERTURA.TITULO")}
            </span>
            <p className="vg-date-text">{openingDateState}</p>
          </label>
          <label className="vg-label">
            <span className="input-title">
              {intl.get(
                "VAGAS.EDICAO_DATA_VEICULACAO.DATA_DE_EXPIRACAO.TITULO"
              )}
            </span>
            <p className="vg-date-text">{expirationDateState}</p>
          </label>
          <label className="vg-label">
            {moment(today).isSameOrBefore(formatedLimiteDate) && (
              <button
                kind="aux"
                className="vg-btn-outline"
                onClick={() => setStatusDate("em_andamento")}
              >
                {intl.get(
                  "VAGAS.EDICAO_DATA_VEICULACAO.BOTOES.REPUBLICAR_VAGA"
                )}
              </button>
            )}
          </label>
        </div>
      ) : (
        <div>
          {isValidPostingPeriodState ? (
            <label className="vg-label">
              <p className="vg-date-text">
                {intl.get(
                  `VAGAS.EDICAO_DATA_VEICULACAO.${openingDateSituation.toUpperCase()}`
                )}
              </p>
            </label>
          ) : (
            <P className="validation-error-message">{errorMessageState}</P>
          )}

          <label className="vg-label">
            <span className="input-title">
              {intl.get("VAGAS.EDICAO_DATA_VEICULACAO.DATA_DE_ABERTURA.TITULO")}
            </span>
            <InputMask
              className="vg-input-text"
              placeholder={intl.get(
                "VAGAS.EDICAO_DATA_VEICULACAO.DATA_DE_ABERTURA.PLACEHOLDER"
              )}
              value={openingDateState}
              mask={dateMaskFormat}
              maskChar={null}
              onBlur={onBlurOpeningDateHandler}
              onChange={(event) => setOpeningDateState(event.target.value)}
            />
            <Icon iconKey="calendar" addClass="posting-date-calendar" />
          </label>

          <label className="vg-label">
            <span className="input-title">
              {intl.get(
                "VAGAS.EDICAO_DATA_VEICULACAO.DATA_DE_EXPIRACAO.TITULO"
              )}
            </span>
            <InputMask
              className="vg-input-text"
              placeholder={intl.get(
                "VAGAS.EDICAO_DATA_VEICULACAO.DATA_DE_EXPIRACAO.PLACEHOLDER"
              )}
              value={expirationDateState}
              mask={dateMaskFormat}
              maskChar={null}
              onBlur={onBlurExpirationDateHandler}
              onChange={(event) => setExpirationDateState(event.target.value)}
              disabled={isExpirationDateDisabled}
            />
            <Icon iconKey="calendar" addClass="posting-date-calendar" />
          </label>
          <label className="vg-label">
            <Checkbox
              label={intl.get(
                "VAGAS.EDICAO_DATA_VEICULACAO.ENCERRAMENTO_INSCRICOES_LABEL"
              )}
              disabled={!isValidPostingPeriodState}
              checked={isExpirationDateDisabled}
              toggle={toggleCloseApplicationHandler}
            />
          </label>
          <label className="vg-label">
            <button
              kind="aux"
              className="vg-btn-outline"
              onClick={() => updateOpeningDate()}
            >
              {isUpdatingDateState
                ? intl.get(
                    "VAGAS.EDICAO_DATA_VEICULACAO.BOTOES.BOTAO_ATUALIZANDO"
                  )
                : intl.get(
                    "VAGAS.EDICAO_DATA_VEICULACAO.BOTOES.BOTAO_ATUALIZAR"
                  )}
            </button>
          </label>
        </div>
      )}
    </div>
  );
};

PostingDateContent.propTypes = {
  openingDate: PropTypes.string.isRequired,
  expirationDate: PropTypes.string.isRequired,
  selectiveProcessId: PropTypes.number.isRequired,
  requestUpdateVacancyDate: PropTypes.func,
  openingDateSituation: PropTypes.string.isRequired,
  updateVacanciesTempList: PropTypes.func,
  limiteDate: PropTypes.string,
};

export default PostingDateContent;
