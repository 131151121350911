import { Icon, Link, StepsBullets, VideoRecorder } from "../../index";
import React, { Component } from "react";
import { load, post } from "../../../store/modules/VideoInterview";

import { CSSTransition } from "react-transition-group";
import Cookie from "js-cookie";
import moment from "moment";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { filter } from "lodash";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { withRouter } from "react-router";

const mapStateToProps = (state) => ({
  interviewContent: state.VideoInterview.questions.data,
  hash: state.VideoInterview.hash,
  loading: state.VideoInterview.questions.loading,
  loaded: state.VideoInterview.questions.loaded,
  error: state.VideoInterview.questions.error,
});

class VideoInterviewCandidateQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verified: false,
      timelimit: 10,
      questionIndex: 0,
      justOneTime: false,
    };

    this.handleCallback = this.handleCallback.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);

    const hash = Cookie.get("videoEntrevistaH", {
      domain: process.env.REACT_APP_DOMAIN_COOKIE,
    });

    const id = Cookie.get("videoEntrevistaId", {
      domain: process.env.REACT_APP_DOMAIN_COOKIE,
    });

    const params = {
      termo_aceito: true,
    };

    this.props.load(
      `video-entrevistas/dados-gravacao/${hash}/${id}`,
      hash,
      params,
      "termos"
    );
  }

  handleCallback(token) {
    const { interviewContent, post, history, hash } = this.props;
    const { questionIndex } = this.state;
    const data = {
      hash: hash,
      video_token: token,
      participante_id: interviewContent.participante_id,
    };

    const ve = interviewContent.video_entrevista;
    const per_id = interviewContent.perguntas[questionIndex].pergunta_id;

    post(`video-entrevistas/${ve.id}/perguntas/${per_id}/respostas`, data).then(
      () => {
        this.setState({
          verified: true,
        });

        if (parseInt(questionIndex) + 1 == ve.qtde_perguntas) {
          history.push(
            `/candidato/video-entrevista/obrigado/?vagaId=v${interviewContent.processoseletivo.id}`
          );
        }
      }
    );
  }

  nextQuestion() {
    this.setState((prevState) => ({
      verified: !prevState.verified,
      questionIndex: parseInt(prevState.questionIndex) + parseInt(1),
    }));
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loaded) {
      const currentAnswered = filter(props.interviewContent.perguntas, {
        gravacao_efetuada: true,
      }).length;

      const allAnswered =
        filter(props.interviewContent.perguntas, { gravacao_efetuada: true })
          .length === props.interviewContent.video_entrevista.qtde_perguntas;

      if (allAnswered && !state.justOneTime) {
        props.history.push("/candidato/video-entrevista/realizada");

        return {
          justOneTime: true,
          verified: true,
        };
      }

      if (
        currentAnswered != state.questionIndex &&
        !allAnswered &&
        !state.justOneTime
      ) {
        return {
          justOneTime: true,
          verified: false,
          questionIndex: currentAnswered,
        };
      }

      return {
        justOneTime: true,
      };
    }

    return null;
  }

  render() {
    const { loaded, error, interviewContent } = this.props;
    const { questionIndex } = this.state;
    let question = interviewContent.perguntas[questionIndex];
    let quantity = interviewContent.video_entrevista.qtde_perguntas;

    return loaded ? (
      <div>
        <div className="vg-container vg-interview-candidate">
          {!this.state.verified && (
            <div>
              <header>
                <StepsBullets
                  actual={parseInt(questionIndex) + parseInt(1)}
                  total={quantity}
                />
                <h2>{question.descricao}</h2>
              </header>
              <VideoRecorder
                timelimit={question.tempo_resposta}
                readtime={question.tempo_leitura}
                loaded={loaded}
                callbackVerified={this.handleCallback}
                localplayback={false}
              />
              <p className="vg-interview-candidate-description">
                {intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.QUESTAO.ALERTA", {
                  value: moment
                    .duration(question.tempo_resposta, "seconds")
                    .humanize(),
                })}
              </p>
            </div>
          )}
          <CSSTransition
            in={this.state.verified}
            timeout={400}
            unmountOnExit
            classNames="vg-show-buttons"
          >
            <div>
              <header>
                <StepsBullets
                  actual={parseInt(questionIndex) + 1}
                  total={quantity}
                />
                <h2>
                  {intl.get("VIDEO_ENTREVISTA.CANDIDATO.QUESTAO.ENVIADO")}
                </h2>
                <div className="vg-fake-player">
                  <Icon iconKey="check-circle" />
                </div>
              </header>
              <div className="vg-interview-wrap-buttons">
                {parseInt(questionIndex) + parseInt(1) <= quantity && (
                  <Link onClick={this.nextQuestion} className="vg-btn-primary">
                    {intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.QUESTAO.BOTAO_1")}
                  </Link>
                )}
              </div>
            </div>
          </CSSTransition>
        </div>
      </div>
    ) : error && !loaded ? (
      <div>
        <div className="vg-container vg-interview-candidate">
          <header>
            <h2>
              Não foi possível carregar, volte novamente ao link do email e
              inicie a videoentrevista novamente.
            </h2>
          </header>
        </div>
      </div>
    ) : (
      <div className="vg-title-loader">
        <Icon iconKey="titleLoader" />
      </div>
    );
  }
}

VideoInterviewCandidateQuestion.defaultProps = {
  interviewContent: {},
  loaded: false,
  hash: "",
};

VideoInterviewCandidateQuestion.propTypes = {
  interviewContent: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  load: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  error: propTypes.number,
  location: propTypes.object.isRequired,
  match: propTypes.object.isRequired,
  hash: propTypes.string.isRequired,
  history: propTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ load, post }, dispatch)
  )(VideoInterviewCandidateQuestion)
);
