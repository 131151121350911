import "./StarRating.scss";

import React, { Component } from "react";
import shortid from "shortid";

import { Icon } from "../../index";
import propTypes from "prop-types";

class StarRating extends Component {
  constructor(props) {
    super(props);

    this.state = {
      rating: props.rating,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.rating !== props.rating) {
      return { rating: props.rating };
    }
    return null;
  }

  render() {
    const { rating } = this.state;
    const nameGenerator = shortid.generate();
    return (
      <div className="vg-rating-star">
        <input
          type="radio"
          id={shortid.generate()}
          name={nameGenerator}
          defaultChecked={rating == 5}
          value="5"
        />
        <label htmlFor="rate-5">
          <Icon iconKey="star" />
        </label>
        <input
          type="radio"
          id={shortid.generate()}
          name={nameGenerator}
          defaultChecked={rating == 4}
          value="4"
        />
        <label htmlFor="rate-4">
          <Icon iconKey="star" />
        </label>
        <input
          type="radio"
          id={shortid.generate()}
          name={nameGenerator}
          defaultChecked={rating == 3}
          value="3"
        />
        <label htmlFor="rate-3">
          <Icon iconKey="star" />
        </label>
        <input
          type="radio"
          id={shortid.generate()}
          name={nameGenerator}
          defaultChecked={rating == 2}
          value="2"
        />
        <label htmlFor="rate-2">
          <Icon iconKey="star" />
        </label>
        <input
          type="radio"
          id={shortid.generate()}
          name={nameGenerator}
          defaultChecked={rating == 1}
          value="1"
        />
        <label htmlFor="rate-1">
          <Icon iconKey="star" />
        </label>
      </div>
    );
  }
}

StarRating.propTypes = {
  rating: propTypes.any.isRequired,
};

export default StarRating;
