import React from "react";
import { bool, func, string, node, oneOf } from "prop-types";
import styled, { css } from "styled-components";
import { FocusOn } from "react-focus-on";
import shortid from "shortid";

import { resetButton, visuallyHidden } from "@vagastec/epartner-ui";

Dropdown.propTypes = {
  /**
   * Define onde será alinhado o Dropdown
   */
  align: oneOf(["left", "right"]),
  /**
   * Alinha o Dropdown ao seu botão de abertura (use valores como: `5px`)
   */
  alignAdjustment: string,
  /**
   * Conteúdo do Dropdown, (<Dropdown>{ children }</Dropdown>)
   */
  children: node.isRequired,
  /**
   * Atribui valor à propriedade tabIndex do botão de fechar
   */
  closeTabIndex: string,
  /**
   * Subtítulo apresentado dentro do Dropdown
   */
  description: string,
  /**
   * É o ID do componente ao qual o subtítulo se refere
   */
  descriptionId: string,
  /**
   * É o valor que especifica se o botão de fechar deve ser exibido
   */
  isCloseButtonHidden: bool,
  /**
   * É o valor que especifica se o subtítulo deve ser exibido
   */
  isDescriptionVisuallyHidden: bool,
  /**
   * É o valor que especifica se o título deve ser exibido
   */
  isLabelVisuallyHidden: bool,
  /**
   * Título apresentado dentro do Dropdown
   */
  label: string,
  /**
   * É o ID do componente ao qual o título se refere
   */
  labelId: string,
  /**
   * É o valor que especifica se a seta do Dropdown deve ser exibida
   */
  removeArrow: bool,
  /**
   * É a função que determina a exibição do Dropdown
   */
  setShowDropdown: func.isRequired,
  /**
   * É o valor que especifica se o Dropdown deve abrir para cima/baixo
   */
  shouldOpenUpwards: bool,
  /**
   * É o valor que especifica se o conteúdo do Dropdown deve ter quebra de linha
   */
  whiteSpaceNormal: bool,
};

Dropdown.defaultProps = {
  align: "left",
  description: null,
  descriptionId: shortid.generate(),
  isCloseButtonHidden: false,
  isDescriptionVisuallyHidden: false,
  isLabelVisuallyHidden: false,
  label: null,
  labelId: shortid.generate(),
  closeTabIndex: "0",
  shouldOpenUpwards: false,
  removeArrow: false,
  whiteSpaceNormal: true,
};

export default function Dropdown({
  align,
  children,
  description,
  descriptionId,
  isCloseButtonHidden,
  isDescriptionVisuallyHidden,
  isLabelVisuallyHidden,
  label,
  labelId,
  setShowDropdown,
  closeTabIndex,
  alignAdjustment,
  shouldOpenUpwards,
  removeArrow,
  whiteSpaceNormal,
  ...rest
}) {
  function handleClose() {
    return setShowDropdown(false);
  }

  return (
    <FocusOn
      onClickOutside={handleClose}
      onEscapeKey={handleClose}
      scrollLock={false}
    >
      <Container
        align={align}
        aria-describedby={descriptionId}
        aria-labelledby={labelId}
        aria-modal="true"
        data-testid="dropdown"
        role="dialog"
        shouldOpenUpwards={shouldOpenUpwards}
        alignAdjustment={alignAdjustment}
        removeArrow={removeArrow}
        whiteSpaceNormal={whiteSpaceNormal}
        {...rest}
      >
        <Header hideHeader={isCloseButtonHidden && isLabelVisuallyHidden}>
          {label && (
            <Label id={labelId} isLabelVisuallyHidden={isLabelVisuallyHidden}>
              {label}
            </Label>
          )}

          <FlexSpacing />
          {!isCloseButtonHidden && (
            <CloseButton onClick={handleClose} tabIndex={closeTabIndex}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="#474747"
              >
                <path d="M19.293 3.293L12 10.586 4.707 3.293 3.293 4.707 10.586 12 3.293 19.293 4.707 20.707 12 13.414 19.293 20.707 20.707 19.293 13.414 12 20.707 4.707z" />
              </svg>
            </CloseButton>
          )}
        </Header>
        {description && (
          <Description
            id={descriptionId}
            isDescriptionVisuallyHidden={isDescriptionVisuallyHidden}
          >
            {description}
          </Description>
        )}
        {children}
      </Container>
    </FocusOn>
  );
}

const Container = styled.div`
  ${({ shouldOpenUpwards }) =>
    shouldOpenUpwards
      ? css`
          bottom: 100%;
          margin-bottom: 10px;
          top: auto;
        `
      : css`
          margin-top: 10px;
        `}

  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #4c4c4c;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 16px;
  position: absolute;
  white-space: ${({ whiteSpaceNormal }) =>
    whiteSpaceNormal ? "normal" : "nowrap"};
  z-index: 30;
  ${({ align }) =>
    align === "left"
      ? css`
          left: ${({ alignAdjustment }) => alignAdjustment};
          margin-right: 16px;
        `
      : css`
          margin-left: 16px;
          right: ${({ alignAdjustment }) =>
            alignAdjustment ? alignAdjustment : "2px"};
        `}

  &::before, &::after {
    border: 9px solid transparent;
    content: "";
    display: ${({ removeArrow }) => (removeArrow ? "none" : "block")};
    position: absolute;
    ${({ align }) =>
      align === "left"
        ? css`
            left: 16px;
          `
        : css`
            right: 16px;
          `}
  }

  ${({ shouldOpenUpwards }) =>
    shouldOpenUpwards
      ? css`
          &::before {
            border-top-color: rgba(0, 0, 0, 0.3);
            bottom: -19px;
          }

          &::after {
            border-top-color: #fff;
            bottom: -18px;
          }
        `
      : css`
          &::before {
            border-bottom-color: rgba(0, 0, 0, 0.3);
            top: -19px;
          }

          &::after {
            border-bottom-color: #fff;
            top: -18px;
          }
        `}
`;

const Header = styled.header`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;

  ${({ hideHeader }) =>
    hideHeader &&
    css`
      ${visuallyHidden};
    `}
`;

const Label = styled.h2`
  color: #4c4c4c;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  margin: 0;
  ${({ isLabelVisuallyHidden }) =>
    isLabelVisuallyHidden &&
    css`
      ${visuallyHidden};
    `}
`;

const Description = styled.p`
  color: #4c4c4c;
  font-size: 16px;
  line-height: 24px;
  ${({ isDescriptionVisuallyHidden }) =>
    isDescriptionVisuallyHidden &&
    css`
      ${visuallyHidden};
    `}
`;

const CloseButton = styled.button`
  ${resetButton};
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;
  margin-top: 5px;
`;

const FlexSpacing = styled.div`
  flex: 1;
`;
