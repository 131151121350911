import "./VacanciesRequestList.scss";

import React from "react";
import { map } from "lodash";
import propTypes from "prop-types";
import shortid from "shortid";

import VacanciesRequestItem from "./VacanciesRequestItem";

const VacanciesRequestList = ({ list }) => (
  <div>
    <ul className="vg-list-wrap-request">
      {map(list.data.requisicoes, (obj) => {
        return (
          <VacanciesRequestItem
            key={shortid.generate()}
            nome={obj.nome}
            dias={obj.quantidade_dias}
            requisitante={obj.requisitante}
            responsavel={obj.responsavel_requisitante.nome}
            status_aprovacao={obj.status_aprovacao}
            botoes={obj.acoes.botoes}
            menu={obj.acoes.menu}
            id={obj.id}
            vagaId={obj.vaga_id}
            area={obj.area}
          />
        );
      })}
    </ul>
  </div>
);

VacanciesRequestList.propTypes = {
  list: propTypes.object,
};

export default VacanciesRequestList;
