import "./CreateQuestion.scss";

import {
  BoxTitle,
  Button,
  Icon,
  Link,
  Modal,
  Toast,
  TokenField,
} from "../../index";
import React, { Component } from "react";
import { filter, map, toLower, trim, debounce } from "lodash";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { post } from "../../../store/modules/CreateGroupQuestions";
import { prepareURl } from "../../../utils/MakeRoutes";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/Painel";
import shortid from "shortid";
import { toast } from "react-toastify";

const mapStateToProps = (state) => ({
  CreateGroupQuestions: state.CreateGroupQuestions.data.CreateGroupQuestion,
  error: state.CreateGroupQuestions.error,
  accesses: state.Painel.painel.data,
});

const KeyCodes = {
  comma: 188,
  enter: 13,
  space: 32,
};
const delimiters = [KeyCodes.enter, KeyCodes.comma, KeyCodes.space];

class VideoInterviewQuestions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      GroupName: "",
      ReadingTime: "120",
      Questions: [],
      QuestionName: "",
      QuestionTime: "120",
      relevantTerms: [],
      nameCheck: false,
      tipo: 0,
      tags: [],
      isEditing: false,
    };

    // Bind dos métodos
    this.handleClick = this.handleClick.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleRadios = this.handleRadios.bind(this);
    this.handleRadiosQuestions = this.handleRadiosQuestions.bind(this);
    this.getGroupName = this.getGroupName.bind(this);
    this.getQuestionName = this.getQuestionName.bind(this);
    this.insertQuestion = this.insertQuestion.bind(this);
    this.getTagDebounce = debounce((tags) => this.getTag(tags), 200);
    this.removeQuestion = this.removeQuestion.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.editQuestion = this.editQuestion.bind(this);
    this.updateQuestion = this.updateQuestion.bind(this);
    this.showNotifications = this.showNotifications.bind(this);

    receive("vaga", "painel");
  }

  // Método utilizado para exibir os feedbacks dos usuários
  showNotifications(message, type = "success") {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  }

  toggleModal(el) {
    el && el.preventDefault();
    this.setState(
      {
        isEditing: false,
        QuestionName: "",
        QuestionTime: "120",
        tags: [],
        tipo: 0,
        isOpen: !this.state.isOpen,
      },
      () =>
        document
          .getElementsByTagName("body")[0]
          .classList.toggle("vg-no-scroll"),
    );
  }

  // Método utilizado para efetuar o POST de criação do grupo de perguntas
  handleClick() {
    const { post } = this.props;

    var data = {
      identificacao: this.state.GroupName,
      tempo_de_leitura: parseInt(this.state.ReadingTime),
      perguntas: this.state.Questions,
    };

    post("video-entrevistas", data).then(
      () => {
        window.location = prepareURl("convite-video-entrevista");
      },
      (error) => {
        if (error.status === 412) {
          this.showNotifications(
            intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.CRIACAO.MENSAGEM_DUPLICADO"),
            "error",
          );
          this.inputGroupName.focus();
        }
      },
    );
  }

  handleSelect(e) {
    this.setState({
      tipo: e.target.value,
    });
  }

  handleRadios(e) {
    this.setState({
      ReadingTime: e.target.value,
    });
  }

  handleRadiosQuestions(e) {
    this.setState({
      QuestionTime: e.target.value,
    });
  }

  getGroupName(e) {
    this.setState(
      {
        GroupName: e.target.value,
      },
      () => {
        this.setState({
          nameCheck:
            this.state.GroupName.length > 0 &&
            trim(this.state.GroupName).length > 0,
        });
      },
    );
  }

  getQuestionName(e) {
    this.setState(
      {
        QuestionName: e.target.value,
      },
      () => {
        this.setState({
          questionCheck:
            this.state.QuestionName.length > 0 &&
            trim(this.state.QuestionName).length > 0,
        });
      },
    );
  }

  insertQuestion() {
    const { QuestionName } = this.state;
    function isEqual(value) {
      const checkingEqual = toLower(value.descricao) === toLower(QuestionName);

      if (checkingEqual) {
        return value;
      }
    }

    const duplicateQuestion = filter(this.state.Questions, isEqual);

    if (duplicateQuestion.length > 0) {
      this.showNotifications(
        intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.CRIACAO.MENSAGEM_ERRO"),
        "error",
      );
      this.toggleModal();
    } else {
      this.setState(
        (prevState) => ({
          isEditing: false,
          Questions: [
            ...prevState.Questions,
            {
              descricao: QuestionName,
              tempo_de_resposta: parseInt(this.state.QuestionTime),
              termos_relevantes: this.state.relevantTerms,
              tipo: parseInt(this.state.tipo),
            },
          ],
        }),
        () => {
          this.toggleModal();
        },
      );
    }
  }

  updateQuestion() {
    let questionsCopy = JSON.parse(JSON.stringify(this.state.Questions));

    //make changes to questions
    questionsCopy[this.state.EditingItem].descricao = this.state.QuestionName;
    questionsCopy[this.state.EditingItem].tempo_de_resposta = parseInt(
      this.state.QuestionTime,
    );
    questionsCopy[this.state.EditingItem].termos_relevantes =
      this.state.relevantTerms;
    questionsCopy[this.state.EditingItem].tipo = parseInt(this.state.tipo);

    this.setState(
      {
        Questions: questionsCopy,
      },
      () => {
        this.toggleModal();
      },
    );
  }

  getTag(tags) {
    this.setState(
      {
        relevantTerms: [],
      },
      () => {
        this.setState((prevState) => ({
          relevantTerms: [
            ...prevState.relevantTerms,
            ...map(tags, (tag) => tag.text),
          ],
        }));
      },
    );
  }

  removeQuestion(el, tag) {
    const array = this.state.Questions.filter(
      (index) => index.descricao !== tag,
    );

    this.setState({
      Questions: array,
    });
  }

  editQuestion(ind) {
    this.toggleModal();
    const obj = this.state.Questions.filter(
      (elem, index, arr) => arr.indexOf(elem) === ind,
    );

    this.setState({
      isEditing: true,
      EditingItem: ind,
      QuestionName: obj[0].descricao,
      QuestionTime: String(obj[0].tempo_de_resposta),
      tags: map(obj[0].termos_relevantes, (item, index) => ({
        id: `tag-${index}`,
        text: item,
      })),
      relevantTerms: obj[0].termos_relevantes,
      tipo: obj[0].tipo,
    });
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.setState(
          {
            isOpen: false,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll"),
        );
      }
    });
  }

  render() {
    return (
      <div className="vg-create-question-page">
        <BoxTitle
          title={intl.get("VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TITULO")}
          text={intl.get("VIDEO_ENTREVISTA.EMPRESA.CRIACAO.SUBTITULO")}
          secondLevel
        />
        <div className="vg-container">
          <div className="vg-row center-md">
            <div className="vg-col-md-8 vg-col-xs-12">
              <div className="vg-form-create-group">
                <div className="vg-item-form-question">
                  <span className="vg-label-title">
                    {intl.get(
                      "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.LABEL_NOME_GRUPO",
                    )}
                  </span>
                  <input
                    onChange={this.getGroupName}
                    className="vg-input-text"
                    type="text"
                    ref={(c) => {
                      this.inputGroupName = c;
                    }}
                  />
                </div>
                <div className="vg-item-form-question">
                  <span className="vg-label-title">
                    {intl.getHTML(
                      "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.LABEL",
                    )}
                  </span>
                  <div className="vg-time-reading">
                    <div className="vg-custom-radio">
                      <label className="vg-label">
                        <input
                          type="radio"
                          value={30}
                          checked={this.state.ReadingTime === "30"}
                          name="tempo_de_leitura"
                          onChange={this.handleRadios}
                        />
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.SEGUNDOS",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-radio">
                      <label className="vg-label">
                        <input
                          type="radio"
                          value={60}
                          checked={this.state.ReadingTime === "60"}
                          name="tempo_de_leitura"
                          onChange={this.handleRadios}
                        />
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.1_MINUTO",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-radio">
                      <label className="vg-label">
                        <input
                          type="radio"
                          value={120}
                          checked={this.state.ReadingTime === "120"}
                          name="tempo_de_leitura"
                          onChange={this.handleRadios}
                        />
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.2_MINUTOS",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-radio">
                      <label className="vg-label">
                        <input
                          type="radio"
                          value={180}
                          checked={this.state.ReadingTime === "180"}
                          name="tempo_de_leitura"
                          onChange={this.handleRadios}
                        />
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.3_MINUTOS",
                          )}
                        </span>
                      </label>
                    </div>
                    <div className="vg-custom-radio">
                      <label className="vg-label">
                        <input
                          type="radio"
                          value={300}
                          checked={this.state.ReadingTime === "300"}
                          name="tempo_de_leitura"
                          onChange={this.handleRadios}
                        />
                        <span className="vg-label-text">
                          {intl.get(
                            "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.5_MINUTOS",
                          )}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="vg-item-form-question">
                  <span className="vg-label-title">
                    {intl.get(
                      "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.LABEL",
                    )}
                  </span>
                  <div className="vg-item-add-question">
                    {this.state.Questions.length > 0 &&
                      map(this.state.Questions, (pergunta, index) => {
                        return (
                          <div
                            key={shortid.generate()}
                            className="vg-question-item"
                          >
                            <p className="vg-question-name">
                              {pergunta.descricao}
                            </p>
                            <div className="vg-question-action">
                              <Link onClick={() => this.editQuestion(index)}>
                                <Icon iconKey="pen" />
                              </Link>
                              <Link
                                onClick={(el) =>
                                  this.removeQuestion(el, pergunta.descricao)
                                }
                              >
                                <Icon iconKey="trash" />
                              </Link>
                            </div>
                          </div>
                        );
                      })}
                    <Link
                      to=""
                      className="vg-btn-link"
                      onClick={(el) => this.toggleModal(el)}
                    >
                      <Icon iconKey="plus" />
                      {intl.get(
                        "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.BOTAO_CRIAR_PERGUNTA",
                      )}
                    </Link>
                  </div>
                </div>
              </div>
              <div className="vg-footer-create">
                <a
                  href="/move-to/convite-video-entrevista"
                  className="vg-btn-link"
                >
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.BOTAO_CANCELAR",
                  )}
                </a>
                <Button
                  onClick={this.handleClick}
                  className="vg-btn-success"
                  disabled={
                    this.state.nameCheck === true &&
                    this.state.Questions.length >= 1
                      ? false
                      : true
                  }
                >
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.BOTAO_CRIAR_GRUPO",
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* Modal */}
        <Modal
          show={this.state.isOpen}
          onClose={this.toggleModal}
          contentLabel="Criar perguntas"
        >
          <div className="vg-row center-md">
            <div className="vg-col-md-9">
              <div className="vg-item-form-question vg-item-padding vg-question-input">
                <span className="vg-label-title">
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.LABEL",
                  )}
                </span>
                <textarea
                  onChange={this.getQuestionName}
                  className="vg-input-text"
                  type="text"
                  value={this.state.QuestionName}
                />
              </div>
              <div className="vg-item-form-question vg-item-padding">
                <span className="vg-label-title">
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.TEMPO",
                  )}
                </span>
                <div className="vg-time-reading">
                  <div className="vg-custom-radio">
                    <label className="vg-label">
                      <input
                        type="radio"
                        checked={this.state.QuestionTime === "60"}
                        value="60"
                        name="tempo_de_resposta"
                        onChange={this.handleRadiosQuestions}
                      />
                      <span className="vg-label-text">
                        {intl.get(
                          "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.1_MINUTO",
                        )}
                      </span>
                    </label>
                  </div>
                  <div className="vg-custom-radio">
                    <label className="vg-label">
                      <input
                        type="radio"
                        checked={this.state.QuestionTime === "120"}
                        value="120"
                        name="tempo_de_resposta"
                        onChange={this.handleRadiosQuestions}
                      />
                      <span className="vg-label-text">
                        {intl.get(
                          "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.2_MINUTOS",
                        )}
                      </span>
                    </label>
                  </div>
                  <div className="vg-custom-radio">
                    <label className="vg-label">
                      <input
                        type="radio"
                        checked={this.state.QuestionTime === "180"}
                        value="180"
                        name="tempo_de_resposta"
                        onChange={this.handleRadiosQuestions}
                      />
                      <span className="vg-label-text">
                        {intl.get(
                          "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.3_MINUTOS",
                        )}
                      </span>
                    </label>
                  </div>
                  <div className="vg-custom-radio">
                    <label className="vg-label">
                      <input
                        type="radio"
                        checked={this.state.QuestionTime === "300"}
                        value="300"
                        name="tempo_de_resposta"
                        onChange={this.handleRadiosQuestions}
                      />
                      <span className="vg-label-text">
                        {intl.get(
                          "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.5_MINUTOS",
                        )}
                      </span>
                    </label>
                  </div>
                  <div className="vg-custom-radio">
                    <label className="vg-label">
                      <input
                        type="radio"
                        checked={this.state.QuestionTime === "600"}
                        value="600"
                        name="tempo_de_resposta"
                        onChange={this.handleRadiosQuestions}
                      />
                      <span className="vg-label-text">
                        {intl.get(
                          "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.TEMPO.10_MINUTOS",
                        )}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div className="vg-item-question vg-item-desc">
                <Icon iconKey="robot" />
                <p>
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.TEXTO",
                  )}
                </p>
              </div>
              <div className="vg-item-form-question vg-item-padding">
                <span className="vg-label-title">
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.TERMOS",
                  )}
                </span>
                <TokenField
                  terms={this.getTagDebounce}
                  delimiters={delimiters}
                  tags={this.state.tags}
                  textInput={intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.TERMOS_INPUT",
                  )}
                />
              </div>
              <div className="vg-item-form-question vg-item-padding">
                <span className="vg-label-title">
                  {intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.TIPO_PERGUNTA",
                  )}
                </span>
                <div className="vg-custom-select">
                  <select
                    value={this.state.tipo}
                    onChange={this.handleSelect}
                    name="tipo_pergunta"
                  >
                    <option value={0}>Selecione um tipo</option>
                    <option value={1}>Comportamento / Soft Skills</option>
                    <option value={2}>Valores</option>
                    <option value={3}>Conhecimentos específicos</option>
                    <option value={4}>Outros</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="vg-wrap-buttons">
            <Link onClick={this.toggleModal} className="vg-btn-link">
              {intl.get(
                "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.BOTAO_CANCELAR",
              )}
            </Link>

            {this.state.isEditing ? (
              <Button
                onClick={this.updateQuestion}
                className="vg-btn-success"
                disabled={
                  this.state.questionCheck === true && this.state.tipo >= 1
                    ? false
                    : true
                }
              >
                {intl.get(
                  "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.BOTAO_ATUALIZAR_PERGUNTA",
                )}
              </Button>
            ) : (
              <Button
                onClick={this.insertQuestion}
                className="vg-btn-success"
                disabled={
                  this.state.questionCheck === true && this.state.tipo >= 1
                    ? false
                    : true
                }
              >
                {intl.get(
                  "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.BOTAO_ADICIONAR_PERGUNTA",
                )}
              </Button>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

VideoInterviewQuestions.defaultProps = {
  accesses: {},
};

VideoInterviewQuestions.propTypes = {
  handleRadios: propTypes.func,
  terms: propTypes.any,
  post: propTypes.func.isRequired,
  accesses: propTypes.object.isRequired,
  error: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ post, receive }, dispatch),
)(VideoInterviewQuestions);
