import React from "react";
import { Icon } from "../../index";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import "./HiddenVacanciesListInfoDropdownContent.scss";

const HiddenVacanciesListInfoDropdownContent = ({
  location,
  divisao,
  totalAfterSorting,
  total,
  visualizations,
}) => {
  return (
    <div>
      <div className="vg-info">
        <p>
          <Icon iconKey="location" />
          {location.descricao || location.pais}
        </p>
        <p>
          <Icon iconKey="buildings" />
          {divisao}
        </p>
      </div>
      <div className="vg-block-totals">
        <div className="vg-col-totals">
          <h4 className="vg-info-numbers">{visualizations || 0}</h4>
          <p className="vg-text-totals">
            {intl.get("VAGAS.INFO.TOTAL_VISUALIZACAO")}
          </p>
        </div>
        <div className="vg-col-totals">
          <h4 className="vg-info-numbers">{total || 0}</h4>
          <p className="vg-text-totals">
            {intl.get("VAGAS.INFO.TOTAL_INSCRITOS")}
          </p>
        </div>
        <div className="vg-col-totals">
          <h4 className="vg-info-numbers">{totalAfterSorting || 0}</h4>
          <p className="vg-text-totals">
            {intl.get("VAGAS.INFO.TOTAL_NOVOS_CANDIDATOS")}
          </p>
        </div>
      </div>
    </div>
  );
};

HiddenVacanciesListInfoDropdownContent.propTypes = {
  location: propTypes.shape({
    cidade: propTypes.string,
    estado: propTypes.string,
    pais: propTypes.string,
    descricao: propTypes.string,
  }),
  grupo: propTypes.string,
  divisao: propTypes.string,
  totalAfterSorting: propTypes.number,
  total: propTypes.number,
  visualizations: propTypes.number,
  id: propTypes.number.isRequired,
};

export default HiddenVacanciesListInfoDropdownContent;
