// Action Types
const LOAD_VACANCIES = "services/LOAD_VACANCIES";
const LOAD_VACANCIES_SUCCESS = "services/LOAD_VACANCIES_SUCCESS";
const LOAD_VACANCIES_FAIL = "services/LOAD_VACANCIES_FAIL";
const RETURN_UPDATE = "services/RETURN_UPDATE";
const RETURN_UPDATE_SUCCESS = "services/RETURN_UPDATE_SUCCESS";
const RETURN_UPDATE_FAIL = "services/RETURN_UPDATE_FAIL";
const RETURN_CURRENT_STATE = "services/RETURN_CURRENT_STATE";

// InitialState
const initialState = {
  home: {
    vacancies: [],
    loaded: false,
    loading: false,
  },
  all: {
    vacancies: [],
    loaded: false,
    loading: false,
  },
  hidden: {
    vacancies: [],
    loaded: false,
    loading: false,
  },
  yours: {
    vacancies: [],
    loaded: false,
    loading: false,
  },
  updateState: {
    error: {},
    loaded: false,
    loading: false,
  },
  updated: {
    loaded: false,
    loading: false,
  },
};

// Reducer
export default function vacancies(
  state = initialState,
  action = { paginate: false }
) {
  switch (action.type) {
    case LOAD_VACANCIES:
      return {
        ...state,
        [action.requestType]: {
          ...state[action.requestType],
          loading: true,
        },
        updateState: {
          error: {},
          loaded: false,
          loading: false,
        },
      };
    case LOAD_VACANCIES_SUCCESS:
      return {
        ...state,
        [action.requestType]: {
          ...state[action.requestType],
          vacancies: action.result.processos_seletivos,
          totalPagina: action.result.total_paginas,
          totalVagas: action.result.total ?? 0,
          loading: false,
          loaded: true,
          error: false,
        },
      };
    case LOAD_VACANCIES_FAIL:
      return {
        ...state,
        [action.requestType]: {
          ...state[action.requestType],
          loading: false,
          loaded: false,
          error: action.error.status ? action.error.status : 503,
        },
      };
    case RETURN_UPDATE:
      return {
        ...state,
        updated: {
          loaded: false,
          loading: true,
          result: {},
        },
      };
    case RETURN_UPDATE_SUCCESS:
      return {
        ...state,
        updated: {
          result: { ...action.result },
          loaded: true,
          loading: false,
        },
      };
    case RETURN_UPDATE_FAIL:
      return {
        ...state,
        updated: {
          error: action.error.status ? action.error.status : 503,
          loaded: false,
          loading: false,
        },
      };
    case RETURN_CURRENT_STATE:
      return {
        ...state,
      };
    default:
      return state;
  }
}

// Actions
export function load(type, path, params = {}) {
  return {
    types: [LOAD_VACANCIES, LOAD_VACANCIES_SUCCESS, LOAD_VACANCIES_FAIL],
    promise: (client) => client.get(path, params),
    requestType: type,
  };
}

export function update(path, data) {
  return {
    types: [LOAD_VACANCIES, LOAD_VACANCIES_SUCCESS, LOAD_VACANCIES_FAIL],
    promise: (client) =>
      client.post(path, {
        data: data,
      }),
  };
}

export function returnUpdated(vacancyId) {
  return {
    types: [RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAIL],
    promise: (client) => client.get(`processos-seletivos/${vacancyId}`),
  };
}

export function changeHiddenStatusRequest(vacancyId, unhideVacancy = false) {
  return {
    types: [RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAIL],
    promise: (client) =>
      client.post(`processos-seletivos/${vacancyId}/ocultar${unhideVacancy ? '?oculta=false' : ''}`),
  };
}

export function requestUpdateVacancy(vacancyId, payload) {
  return {
    types: [RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAIL],
    promise: (client) =>
      client.put(`processos-seletivos/${vacancyId}`, {
        data: {
          status_atual: payload.status && payload.status.id,
          ativador: payload.status && payload.status.nome,
          status_anterior: payload.status_anterior,
          data_de_expiracao: payload.data_de_expiracao,
        },
      }),
  };
}

export function returnTotal() {
  return {
    type: RETURN_CURRENT_STATE,
  };
}
