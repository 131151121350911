import propTypes from "prop-types";
import React, { Component } from "react";
import "./RangeInput.scss";

class RangeInput extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onRange, defaultRange } = this.props;

    return onRange ? (
      <input
        name="scale"
        type="range"
        onChange={onRange}
        min="1"
        max="2"
        step="0.01"
        defaultValue={defaultRange}
      />
    ) : null;
  }
}

RangeInput.defaultProps = {
  defaultRange: 0,
};

RangeInput.propTypes = {
  onRange: propTypes.func.isRequired,
  defaultRange: propTypes.number.isRequired,
};

export default RangeInput;
