import "./WordCloud.scss";

import PropTypes from "prop-types";
import React from "react";
import { TagCloud } from "react-tagcloud";

const WordCloud = ({ tags }) => {
  return (
    <TagCloud
      minSize={10}
      maxSize={32}
      disableRandomColor
      shuffle={false}
      tags={tags}
      renderer={(tag, size) => (
        <span key={tag.value} className={`vg-tag-${size}`}>
          {tag.value}
        </span>
      )}
    />
  );
};

WordCloud.propTypes = {
  tags: PropTypes.array.isRequired,
};

export default WordCloud;
