// Action Types
const VIDEO_LIST_NOT = "services/VIDEO_LIST_NOT";
const VIDEO_LIST_NOT_SUCCESS = "services/VIDEO_LIST_NOT_SUCCESS";
const VIDEO_LIST_NOT_FAIL = "services/VIDEO_LIST_NOT_FAIL";
const VIDEO_UPDATE_NOT = "services/VIDEO_NOT_UPDATE";
const VIDEO_UPDATE_NOT_SUCCESS = "services/VIDEO_NOT_UPDATE_SUCCESS";
const VIDEO_UPDATE_NOT_FAIL = "services/VIDEO_NOT_UPDATE_FAIL";
const VIDEO_LIST_FILTER_NOT_PROCESSED =
  "services/VIDEO_LIST_FILTER_NOT_PROCESSED";
const VIDEO_LIST_FILTER_NOT_PROCESSED_SUCCESS =
  "services/VIDEO_LIST_FILTER_NOT_PROCESSED_SUCCESS";
const VIDEO_LIST_FILTER_NOT_PROCESSED_FAIL =
  "services/VIDEO_LIST_FILTER_NOT_PROCESSED_FAIL";

// InitialState
const initialState = {
  loading: false,
  loaded: false,
  loadingMore: false,
  data: {},
};

// Reducers
export default function videoInterviewListNotProcessed(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case VIDEO_LIST_NOT:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case VIDEO_LIST_NOT_SUCCESS:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
      };
    case VIDEO_LIST_NOT_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error.status,
      };
    case VIDEO_UPDATE_NOT:
      return {
        ...state,
        loadingMore: true,
      };
    case VIDEO_UPDATE_NOT_SUCCESS: {
      let newParticipantes = state.data.participantes;
      action.result.participantes.map((item) => {
        newParticipantes.push(item);
      });
      return {
        ...state,
        data: {
          ...state.data,
          pagina_atual: action.result.pagina_atual,
          participantes: newParticipantes,
        },
        loadingMore: false,
      };
    }
    case VIDEO_UPDATE_NOT_FAIL:
      return {
        loadingMore: false,
        error: action.error.status,
      };
    case VIDEO_LIST_FILTER_NOT_PROCESSED:
      return {
        ...state,
      };
    case VIDEO_LIST_FILTER_NOT_PROCESSED_SUCCESS:
      return {
        ...state,
        data: action.result,
      };
    case VIDEO_LIST_FILTER_NOT_PROCESSED_FAIL:
      return {
        error: action.error.status,
      };
    default:
      return state;
  }
}

// Actions
export function load(path) {
  return {
    types: [VIDEO_LIST_NOT, VIDEO_LIST_NOT_SUCCESS, VIDEO_LIST_NOT_FAIL],
    promise: (client) => client.get(path),
  };
}
export function loadMore(path) {
  return {
    types: [VIDEO_UPDATE_NOT, VIDEO_UPDATE_NOT_SUCCESS, VIDEO_UPDATE_NOT_FAIL],
    promise: (client) => client.get(path),
  };
}

export function loadWithFilter(path) {
  return {
    types: [
      VIDEO_LIST_FILTER_NOT_PROCESSED,
      VIDEO_LIST_FILTER_NOT_PROCESSED_SUCCESS,
      VIDEO_LIST_FILTER_NOT_PROCESSED_FAIL,
    ],
    promise: (client) => client.get(path),
  };
}

function shouldFetch() {
  return true;
}

// Método responsável pelo dispatch das actions de requisição
export function receiveNot(path) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path));
    }
  };
}

export function receiveNotMore(path) {
  return (dispatch) => {
    return dispatch(loadMore(path));
  };
}

export function receiveWithFilterNotProcessed(path) {
  return (dispatch) => {
    return dispatch(loadWithFilter(path));
  };
}
