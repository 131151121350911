import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { NewSelect } from "../../index";
import "./SelectRedes.scss";

const SelectRedes = (props) => {
  const [redes, setRedes] = useState([]);

  useEffect(() => {
    setRedes(props.redesList);
  });

  return (
    <NewSelect
      selectClass="vg-select-redes"
      onChangeNewSelect={props.onChangeSelect}
      selectId="select-redes"
      listItens={redes}
      itemOption="identidade"
    />
  );
};

SelectRedes.propTypes = {
  onChangeSelect: PropTypes.func.isRequired,
  redesList: PropTypes.array.isRequired,
};

export default SelectRedes;
