// Action Types
const OPEN_MODAL_ABOUT_CREDITS = "modals/about_credits/open";
const CLOSE_MODAL_ABOUT_CREDITS = "modals/about_credits/close";
const TOGGLE_MODAL_ABOUT_CREDITS = "modals/about_credits/toggle";

// InitialState
const initialState = {
  isOpen: false,
};

// Reducers
export default function ModalAboutCredits(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN_MODAL_ABOUT_CREDITS:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_MODAL_ABOUT_CREDITS:
      return {
        ...state,
        isOpen: false,
      };
    case TOGGLE_MODAL_ABOUT_CREDITS:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
}

export function toggleModalStatus() {
  return { type: TOGGLE_MODAL_ABOUT_CREDITS };
}
