import React from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { Icon, Link } from "../../index";
import "./Logo.scss";

const Logo = ({ check, visitor }) => {
  const icon = <Icon iconKey={check ? "logo-simple" : "logo"} />;

  return check || visitor ? (
    <div className="vg-logo">{icon}</div>
  ) : (
    <Link to="/" className="vg-logo" aria-label={intl.get("NAVEGACAO.LOGO")}>
      {icon}
    </Link>
  );
};

Logo.propTypes = {
  check: propTypes.any,
  visitor: propTypes.bool.isRequired,
};

export default Logo;
