import React from "react";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import propTypes from "prop-types";

const Label = ({ i18n, type, item }) => {
  return (
    <span className="vg-label-text">
      {i18n
        ? intl.get(`VAGAS.BUSCA.${intlReplace(type)}.${intlReplace(item.desc)}`)
        : item.desc}
      {item.doc_count && (
        <span className="vg-qtd-itens">({item.doc_count})</span>
      )}
    </span>
  );
};

Label.propTypes = {
  type: propTypes.string.isRequired,
  i18n: propTypes.bool,
  item: propTypes.object.isRequired,
};

export default Label;
