/* eslint-disable */
// Action Types
const LOAD_DIVISIONS = "services/LOAD_DIVISIONS";
const LOAD_DIVISIONS_SUCCESS = "services/LOAD_DIVISIONS_SUCCESS";
const LOAD_DIVISIONS_FAIL = "services/LOAD_DIVISIONS_FAIL";
const UPDATE_DIVISIONS = "services/UPDATE_DIVISIONS";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
};

// Reducer
export default function divisions(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_DIVISIONS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_DIVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        [action.receivePath]: action.result,
      };
    case LOAD_DIVISIONS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case UPDATE_DIVISIONS:
      return {
        ...state,
        [action.receivePath]: {
          divisoes: state[action.receivePath].divisoes.map((item) => {
            if (item.id !== action.id) {
              return item;
            }
            return {
              ...item,
              sla: action.data,
            };
          }),
        },
      };
    default:
      return state;
  }
}

// Faz a requisição de divisões
export function load(path, receivePath, params) {
  return {
    types: [LOAD_DIVISIONS, LOAD_DIVISIONS_SUCCESS, LOAD_DIVISIONS_FAIL],
    promise: (client) => client.get(path, { params }),
    receivePath,
  };
}

export function update(data, id, receivePath) {
  return {
    type: UPDATE_DIVISIONS,
    data,
    id,
    receivePath,
  };
}

// Verifica se é necessário fazer a requisição
function shouldFetch(state, receivePath) {
  return state.Divisions.loading
    ? false
    : state.Divisions.hasOwnProperty(receivePath)
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(path, params) {
  const receivePath = path.includes("tipo_divisao=")
    ? path.split("tipo_divisao=")[1]
    : "sla";

  return (dispatch, getState) => {
    if (shouldFetch(getState(), receivePath)) {
      return dispatch(load(path, receivePath, params));
    }
  };
}
