import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../index";
import "./SideBar.scss";

class SideBarContent extends Component {
  render() {
    const { state, onClose, children, className } = this.props;

    return state !== "exited" ? (
      <div className={`vg-sidebar vg-${state} ${className}`}>
        <Icon iconKey="cross" onClick={onClose} />
        {children}
      </div>
    ) : null;
  }
}

SideBarContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  state: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default SideBarContent;
