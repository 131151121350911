import React, { Component } from "react";
import propTypes from "prop-types";
import { Transition } from "react-transition-group";
import { map } from "lodash";

class Steps extends Component {
  render() {
    const { steps, current } = this.props;
    const stepsMap = map(steps, (step, index) => {
      return (
        <Transition
          key={index}
          in={step.props.name === current}
          timeout={200}
          mountOnEnter={true}
          unmountOnExit={true}
        >
          {(state) => (
            <div className={`vg-animate-${state}`}>
              {React.cloneElement(step, { ...this.props })}
            </div>
          )}
        </Transition>
      );
    });

    return <div className="vg-steps">{stepsMap}</div>;
  }
}

Steps.propTypes = {
  steps: propTypes.array.isRequired,
  goToStep: propTypes.func.isRequired,
  current: propTypes.string.isRequired,
};

export default Steps;
