import { Icon } from "../../index";
import React, { Component } from "react";
import propTypes from "prop-types";
import bxs_video from "../../../assets/bxs_video.jpg";

class InterviewImage extends Component {
  render() {
    const { image } = this.props;
    return (
      <div className="vg-img-participant">
        {image.url_thumbnail ? (
          <img
            className="vg-img"
            src={image.url_thumbnail === null ? bxs_video : image.url_thumbnail}
          />
        ) : (
          <Icon iconKey="timeFilled" />
        )}
      </div>
    );
  }
}

InterviewImage.propTypes = {
  image: propTypes.object.isRequired,
};

export default InterviewImage;
