import React from "react";
import { string, object, any, node } from "prop-types";
import { Link as RouterLink, NavLink } from "react-router-dom";
import { prepareURl } from "../../../utils/MakeRoutes";
import { Icon } from "../../index";

import "./Link.scss";

const Link = ({
  to,
  activeClassName,
  moveTo,
  externalLink,
  textLink,
  icon,
  classLink,
  params,
  ...props
}) => {
  if (to) {
    return activeClassName ? (
      <NavLink
        activeClassName={activeClassName}
        to={to}
        className="vg-link"
        {...props}
      />
    ) : (
      <RouterLink to={to} className="vg-link" {...props} />
    );
  } else if (moveTo) {
    return (
      <a href={prepareURl(moveTo, params)} {...props}>
        {icon && <Icon iconKey={icon} />}
        {props.children}
      </a>
    );
  } else if (externalLink) {
    return (
      <a
        href={externalLink}
        className={classLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        {textLink}
        {icon && <Icon iconKey={icon} />}
      </a>
    );
  }

  return (
    <a {...props}>
      {icon && <Icon iconKey={icon} />}
      {props.children}
    </a>
  );
};

Link.defaultProps = {
  moveTo: "",
};

Link.propTypes = {
  activeClassName: string,
  to: string,
  moveTo: any,
  externalLink: string,
  textLink: string,
  icon: string,
  classLink: string,
  params: object,
  children: node,
};

export default Link;
