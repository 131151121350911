import React from "react";
import propTypes from "prop-types";
import "./VideoInterviewListItemSelector.scss";

const VideoInterviewListItemSelector = ({ input, ...props }) => {
  return (
    <div
      className={`vg-checkbox-container ${
        input.checked ? "" : "vg-checkbox-hoverable"
      }`}
    >
      <div className="vg-checkbox-bg" />
      <div className="vg-check-share" {...props}>
        <div className="vg-custom-check">
          <span className="vg-input-checkbox">
            <input
              id={props.id}
              type="checkbox"
              value={input.value}
              {...input}
            />
            <span className="vg-input-checkbox-inner" />
          </span>
        </div>
      </div>
    </div>
  );
};

VideoInterviewListItemSelector.defaultProps = {
  input: {},
};

VideoInterviewListItemSelector.propTypes = {
  id: propTypes.number.isRequired,
  input: propTypes.object,
};

export default VideoInterviewListItemSelector;
