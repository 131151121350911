// Action Types
const VAGAMODELO_LIST_SUCCESS = "services/VAGAMODELO_LIST_SUCCESS";
const VAGAMODELO_LIST_LOAD = "services/VAGAMODELO_LIST_LOAD";
const VAGAMODELO_LIST_FAIL = "services/VAGAMODELO_LIST_FAIL";

// InitialState
const initialState = {
  error: false,
  loading: false,
  data: [],
};

// Reducers
export default function globalVagaModeloAction(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case VAGAMODELO_LIST_LOAD:
      return {
        ...state,
        loading: true,
      };
    case VAGAMODELO_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
      };
    case VAGAMODELO_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadList(path) {
  return {
    types: [
      VAGAMODELO_LIST_LOAD,
      VAGAMODELO_LIST_SUCCESS,
      VAGAMODELO_LIST_FAIL,
    ],
    promise: (client) => client.get(path),
  };
}

export function vagasModeloList(path) {
  return (dispatch) => {
    return dispatch(loadList(path));
  };
}
