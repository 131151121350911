import "./VideoInterviewCandidate.scss";

import { Icon, VideoInterviewError, VideoInterviewLandpage } from "../../index";
import React, { Component } from "react";

import Cookie from "js-cookie";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { filter } from "lodash";
import { parse } from "../../../utils/QueryFunctions";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/VideoInterview";
import { withRouter } from "react-router";

const mapStateToProps = (state) => ({
  interviewContent: state.VideoInterview.data,
  loading: state.VideoInterview.loading,
  loaded: state.VideoInterview.loaded,
  error: state.VideoInterview.error,
});

class VideoInterviewCandidate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allAnswered: false,
      currentAnswered: 0,
    };
  }

  componentDidMount() {
    this.loadContentHandler();
  }

  loadContentHandler() {
    const { h, id } = parse(window.location.search);
    const { receive } = this.props;
    Cookie.set("videoEntrevistaH", h, {
      domain: process.env.REACT_APP_DOMAIN_COOKIE,
    });
    Cookie.set("videoEntrevistaId", id, {
      domain: process.env.REACT_APP_DOMAIN_COOKIE,
    });

    const params = {};

    receive(`video-entrevistas/info-gravacao/${h}/${id}`, h, params);
  }

  static getDerivedStateFromProps(props) {
    if (props.loaded) {
      return {
        currentAnswered: filter(props.interviewContent.perguntas, {
          gravacao_efetuada: true,
        }).length,
        allAnswered:
          filter(props.interviewContent.perguntas, { gravacao_efetuada: true })
            .length === props.interviewContent.video_entrevista.qtde_perguntas,
      };
    }

    return null;
  }

  render() {
    const { loading, loaded, error, interviewContent, history } = this.props;
    const { allAnswered, currentAnswered } = this.state;

    return loading && !loaded ? (
      <div>
        <div className="vg-container vg-interview-candidate">
          <header>
            <Icon iconKey="videoInterview" />
            <div className="vg-title-loader">
              <Icon iconKey="titleLoader" />
            </div>
          </header>
        </div>
      </div>
    ) : error && !loaded ? (
      <VideoInterviewError error={error} />
    ) : (
      <VideoInterviewLandpage
        interviewContent={interviewContent}
        allAnswered={allAnswered}
        history={history}
        currentAnswered={currentAnswered}
      />
    );
  }
}

VideoInterviewCandidate.defaultProps = {
  interviewContent: {},
};

VideoInterviewCandidate.propTypes = {
  interviewContent: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  receive: propTypes.func.isRequired,
  error: propTypes.object,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ receive }, dispatch)
  )(VideoInterviewCandidate)
);
