import { Icon } from "../../index";
import React from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";

const reponseError = (error, validation) =>
  error.response.body.error.split("")[1] === validation;

export default function VideoInterviewError({ error }) {
  return reponseError(error, "#<LinkInvalidoError: Link inválido.>") ? (
    <div>
      <div className="vg-container vg-interview-candidate">
        <header>
          <Icon iconKey="videoInterviewError" />
          <h2>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.LINK_ERRADO.TITULO")}</h2>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.LINK_ERRADO.TEXTO")}</p>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.LINK_ERRADO.TEXTO_2")}</p>
        </header>
      </div>
    </div>
  ) : reponseError(
      error,
      "#<LinkExpiradoError: Link para gravação da videoentrevista expirado>"
    ) ? (
    <div>
      <div className="vg-container vg-interview-candidate">
        <header>
          <Icon iconKey="videoInterviewError" />
          <h2>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.LINK_EXPIRADO.TITULO")}</h2>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.LINK_EXPIRADO.TEXTO")}</p>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.LINK_EXPIRADO.TEXTO_2")}</p>
        </header>
      </div>
    </div>
  ) : (
    <div>
      <div className="vg-container vg-interview-candidate">
        <header>
          <Icon iconKey="videoInterviewError" />
          <h2>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.DATA_EXPIRADA.TITULO")}</h2>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.DATA_EXPIRADA.TEXTO")}</p>
          <p>{intl.get("VIDEO_ENTREVISTA.CANDIDATO.DATA_EXPIRADA.TEXTO_2")}</p>
        </header>
      </div>
    </div>
  );
}

VideoInterviewError.propTypes = {
  error: propTypes.object.isRequired,
};
