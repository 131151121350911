import "./Well.scss";

import React, { Component } from "react";

import { Icon } from "../../index";
import PropTypes from "prop-types";

class Well extends Component {
  render() {
    const { text, icon } = this.props;
    return (
      <div className="vg-well">
        <Icon iconKey={icon} />
        <p>{text}</p>
      </div>
    );
  }
}

Well.defaultProps = {
  text: "",
  icon: false,
};

Well.propTypes = {
  text: PropTypes.any,
  icon: PropTypes.any,
};

export default Well;
