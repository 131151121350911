import propTypes from "prop-types";
import React, { Component } from "react";
import { Button } from "../../index";
import "./Checkbox.scss";

class Checkbox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { checked, onCheck } = this.props;

    return (
      <div className="vg-custom-checkbox">
        <Button
          className={`vg-custom-checkbox-spinner ${checked && "is-checked"}`}
          onClick={onCheck}
        />
      </div>
    );
  }
}

Checkbox.defaultProps = {
  checked: false,
};

Checkbox.propTypes = {
  checked: propTypes.bool.isRequired,
  onCheck: propTypes.func.isRequired,
};

export default Checkbox;
