import React, { useState } from "react";
import { string, node } from "prop-types";

import "./TooltipGrade.scss";

const TooltipGrade = ({ title, text, position, children }) => {
  const [show, setShow] = useState(false);

  const showTooltipNotaDelayed = () => {
    if (this.intv) clearInterval(this.intv);
    setShow(true);
  };

  const showTooltipNota = () => {
    if (this.intv) clearInterval(this.intv);
    this.intv = setTimeout(showTooltipNotaDelayed, 600);
  };

  const hideTooltipNota = () => {
    if (this.intv) clearInterval(this.intv);
    setShow(false);
  };

  return (
    <span
      className="tooltip-grade"
      onMouseOver={showTooltipNota}
      onMouseLeave={hideTooltipNota}
    >
      {show && text && title && (
        <span className={`tooltip-message on-${position ? position : "top"}`}>
          <h3 className="titulo-notas-atencao">{title}</h3>
          <span className="content-tooltip-nota">{text}</span>
        </span>
      )}
      {children}
    </span>
  );
};

TooltipGrade.propTypes = {
  text: node.isRequired,
  position: string,
  title: string,
  children: node.isRequired,
};
TooltipGrade.defaultProps = {
  position: "top",
  title: "",
};

export default TooltipGrade;
