// Action Types
const LOAD_REPORTS = "services/LOAD_REPORTS";
const LOAD_REPORTS_SUCCESS = "services/LOAD_REPORTS_SUCCESS";
const LOAD_REPORTS_FAIL = "services/LOAD_REPORTS_FAIL";

// InitialState
const initialState = {
  loaded: false,
  data: {
    total_candidaturas: 0,
    total_candidaturas_por_status_processo: {
      em_andamento_inscricao_encerrada: 0,
      veiculacao_suspensa: 0,
    },
    total_processos: 0,
    total_processos_por_status: {
      em_andamento_inscricao_encerrada: 0,
      veiculacao_suspensa: 0,
    },
  },
};

// Reducer
export default function reports(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_REPORTS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_REPORTS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_REPORTS, LOAD_REPORTS_SUCCESS, LOAD_REPORTS_FAIL],
    promise: (client) => client.get("relatorio"),
  };
}

function shouldFetch(state) {
  return state.Reports.loading
    ? false
    : state.Reports.data.total_candidaturas > 0
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
