import React from "react";
import PropTypes from "prop-types";
import icons from "../../../utils/icons";
import "./Icon.scss";

const getIcon = (iconKey) => {
  if (icons[iconKey]) {
    return { __html: icons[iconKey] };
  }

  // eslint-disable-next-line
  return console.error(
    `👻 Icon iconKey(${iconKey}) not found. Please add it to the Icon source file(utils/icons.js)`,
  );
};

const Icon = ({ iconKey, el, addClass = "", ...props }) =>
  React.createElement(el, {
    dangerouslySetInnerHTML: getIcon(iconKey),
    ...props,
    className: `vg-icon vg-icon-${iconKey} ${addClass}`,
  });

Icon.propTypes = {
  el: PropTypes.string.isRequired,
  iconKey: PropTypes.string.isRequired,
  addClass: PropTypes.string,
};

Icon.defaultProps = {
  el: "span",
};

export default Icon;
