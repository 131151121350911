import React, { Component } from "react";
import { ErrorHandler, Icon } from "../../index";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import default_avatar from "../../../assets/default_avatar.png";

class VideoUser extends Component {
  render() {
    const { analysisContent, analysisVideo } = this.props;

    return analysisVideo.loaded ? (
      <div className="vg-video-user">
        <div className="vg-rouded-graph">
          <svg x="0" y="0" viewBox="-3 -5 60 60">
            <linearGradient
              id="arotateLeft"
              x2="511.9998"
              y1="-184.614"
              y2="-184.614"
              gradientTransform="matrix(-1 0 0 1 511.9998 400.614)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#ff8003" />
              <stop offset="1" stopColor="#f9564a" />
            </linearGradient>
            <path
              className="circle"
              strokeDasharray={`${parseInt(
                analysisContent.participante.relevancia * 100
              )}, 100`}
              stroke="url(#arotateLeft)"
              d="M15.9,0c8.8,0,15.9,7.1,15.9,15.9s-7.1,15.9-15.9,15.9l0,0C7.1,31.8,0,24.7,0,15.9C0,7.1,7.1,0,15.9,0"
            />
          </svg>
        </div>
        <div className="vg-video-user-photo">
          <img
            alt="Avatar default para candidato"
            src={
              analysisContent?.participante?.foto
                ? analysisContent.participante.foto
                : default_avatar
            }
          />
        </div>
        <div className="vg-video-user-detail">
          <h2>{analysisContent.participante.candidato_nome}</h2>
          <p>
            <Icon iconKey="bolt" />
            {parseInt(analysisContent.participante.relevancia * 100)}
            {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.ADERENCIA")}
          </p>
        </div>
      </div>
    ) : analysisVideo.error && !analysisVideo.loaded ? (
      <div>
        <div className="vg-title-loader">
          <Icon iconKey="titleLoader" />
        </div>
        <div className="vg-container">
          <ErrorHandler
            text={intl.get("GRAPHS.ERROR_MESSAGE")}
            error={analysisVideo.error}
            retryHandler={this.requestContent}
          />
        </div>
      </div>
    ) : (
      <div className="animation-loader">
        <Icon iconKey="userPlaceholder" />
      </div>
    );
  }
}

VideoUser.defaultProps = {
  loaded: false,
};

VideoUser.propTypes = {
  analysisContent: propTypes.object.isRequired,
  analysisVideo: propTypes.object.isRequired,
};

export default VideoUser;
