// Action Types
const LOAD_CRITERIA = "domain/LOAD_CRITERIA";
const LOAD_CRITERIA_SUCCESS = "domain/LOAD_CRITERIA_SUCCESS";
const LOAD_CRITERIA_FAIL = "domain/LOAD_CRITERIA_FAIL";

// InitialState
const initialState = {
  loaded: false,
};

// Reducer
export default function criteria(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CRITERIA:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CRITERIA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_CRITERIA_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [LOAD_CRITERIA, LOAD_CRITERIA_SUCCESS, LOAD_CRITERIA_FAIL],
    promise: (client) =>
      client.get(path, {}, process.env.REACT_APP_DOMINIOS_URL),
  };
}

function shouldFetch(state) {
  return state.Criteria.loading
    ? false
    : state.Criteria.data !== undefined
    ? false
    : true;
}

export function receive(path) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path));
    }
  };
}
