import "./ModalVagas25URL.scss";

import { Modal } from "../../index";
import React, { Component } from "react";

import intl from "react-intl-universal";

export class ModalVagas25URL extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
    this.toggleModal = this.toggleModal.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.toggleModal();
      }
    });
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render() {
    const { isOpen } = this.state;
    return (
      <Modal
        className="modal-vagas25"
        small
        show={isOpen}
        onClose={this.toggleModal}
      >
        <h2>
          {intl.get("HOME.MODAL.VAGAS25_URL.TITULO")}
        </h2>
        <p>{intl.get("HOME.MODAL.VAGAS25_URL.TEXT")}</p>
        <p>{intl.getHTML("HOME.MODAL.VAGAS25_URL.TEXT2")}</p>
      </Modal>
    );
  }
}

export default ModalVagas25URL;
