import React from "react";
import PropTypes from "prop-types";

const getName = (item, items) => {
  const filterItem = items.filter((obj) => obj.key == item);

  return filterItem.length ? filterItem[0].desc : "";
};

const DynamicLabel = ({ item, items }) => {
  return <span className="vg-label-text">{getName(item, items)}</span>;
};

DynamicLabel.propTypes = {
  item: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};

export default DynamicLabel;
