// Action Types
const LOAD_RESPO_UNIDADE = "services/RESPO_UNIDADE";
const LOAD_RESPO_UNIDADE_SUCCESS = "services/RESPO_UNIDADE_SUCCESS";
const LOAD_RESPO_UNIDADE_FAIL = "services/RESPO_UNIDADE_FAIL";

const POST_RESPO_UNIDADE = "services/RESPO_UNIDADE_FAIL";
const POST_RESPO_UNIDADE_SUCCESS = "services/RESPO_UNIDADE_FAIL";
const POST_RESPO_UNIDADE_FAIL = "services/RESPO_UNIDADE_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: { id: "", nome: "", email: "" },
  unidId: 0,
};

// Reducer
export default function UnidResponsable(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_RESPO_UNIDADE:
      return {
        ...state,
        loading: true,
      };
    case LOAD_RESPO_UNIDADE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        unidId: action.unidId,
      };
    case LOAD_RESPO_UNIDADE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case POST_RESPO_UNIDADE:
      return {
        ...state,
        loading: true,
      };
    case POST_RESPO_UNIDADE_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
        unidId: action.unidId,
      };
    case POST_RESPO_UNIDADE_FAIL:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(unidId, identidade) {
  return {
    types: [
      LOAD_RESPO_UNIDADE,
      LOAD_RESPO_UNIDADE_SUCCESS,
      LOAD_RESPO_UNIDADE_FAIL,
    ],
    promise: (client) =>
      client.post(`unidades/${unidId}/responsavel_unidade`, {
        data: { identidade: identidade },
      }),
    unidId,
  };
}

function shouldFetch(state, unidId) {
  if (state.UnidResponsable.unidId === unidId) {
    return state.UnidResponsable.loading
      ? false
      : Object.keys(state.UnidResponsable.data).length
      ? false
      : true;
  } else {
    return true;
  }
}

export function receive(unidId, identidade) {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), unidId)) {
      return dispatch(load(unidId, identidade));
    }
  };
}

export function updateUnidResponsable(unidId, params) {
  return {
    types: [
      POST_RESPO_UNIDADE,
      POST_RESPO_UNIDADE_SUCCESS,
      POST_RESPO_UNIDADE_FAIL,
    ],
    promise: (client) =>
      client.post(`unidades/${unidId}/atualiza_email_responsavel_unidade`, {
        data: params,
      }),
    unidId,
  };
}
