import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./RichTextEditor.scss";

import { ContentState, EditorState, convertToRaw } from "draft-js";
import { CustomOption, Mark } from "../../index";
import React, { Component } from "react";

import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { generateVariableHighlighterPlugin } from "./utils";
import htmlToDraft from "html-to-draftjs";
import propTypes from "prop-types";

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    const contentBlock = htmlToDraft(props.content);

    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );

      this.state = {
        editorState: EditorState.createWithContent(contentState),
        variables: [
          { id: 1, value: "{{CANDIDATO_NOME}}" },
          { id: 2, value: "{{CANDIDATO_EMAIL}}" },
          { id: 3, value: "{{VAGA_CARGO}}" },
          { id: 4, value: "{{VAGA_CODIGO}}" },
          { id: 5, value: "{{URL_TESTE}}" },
          { id: 6, value: "{{URL_FICHA}}" },
          { id: 7, value: "{{DATA_LIMITE}}" },
        ],
      };
    } else {
      this.state = {
        editorState: EditorState.createEmpty(),
        variables: [
          { id: 1, value: "{{CANDIDATO_NOME}}" },
          { id: 2, value: "{{CANDIDATO_EMAIL}}" },
          { id: 3, value: "{{VAGA_CARGO}}" },
          { id: 4, value: "{{VAGA_CODIGO}}" },
          { id: 5, value: "{{URL_TESTE}}" },
          { id: 6, value: "{{URL_FICHA}}" },
          { id: 7, value: "{{DATA_LIMITE}}" },
        ],
      };
    }

    this.onEditorStateChange = this.onEditorStateChange.bind(this);
  }

  _getDecorators() {
    const variableObject = this.state.variables.reduce(
      (acc, n) => ({
        ...acc,
        [n.value]: true,
      }),
      {}
    );

    const highlighterConfig = {
      component: Mark,
      variables: variableObject,
      regex: new RegExp(/{{\s*([^}]+)\s*}}/, "g"),
      theme: {
        valid: "vg-highlight-editor",
        invalid: "vg-highlight-editor-error",
      },
    };

    return generateVariableHighlighterPlugin(highlighterConfig);
  }

  insertBlocksFromHtml(htmlString) {
    const contentBlock = htmlToDraft(htmlString);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    const editorState = EditorState.createWithContent(contentState);

    this.setState(
      {
        editorState,
      },
      () => {
        this.props.changeContent(
          draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
        );
      }
    );
  }

  onEditorStateChange(editorState) {
    this.setState(
      {
        editorState,
      },
      () => {
        this.props.changeContent(
          draftToHtml(convertToRaw(editorState.getCurrentContent()))
        );
      }
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.newContent !== this.props.newContent) {
      this.insertBlocksFromHtml(nextProps.newContent);
    }
  }

  render() {
    const { editorState } = this.state;
    const { withVariables, isTest, isDatasheet, onBlur, classError } =
      this.props;

    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName={`vg-editor-wrapper ${classError}`}
          editorClassName="vg-editor"
          toolbarClassName="vg-editor-toolbar"
          spellCheck={true}
          onEditorStateChange={this.onEditorStateChange}
          onBlur={onBlur}
          toolbarCustomButtons={
            withVariables
              ? [
                  <CustomOption
                    key="customOption"
                    isTest={isTest}
                    isDatasheet={isDatasheet}
                  />,
                ]
              : []
          }
          customDecorators={withVariables ? this._getDecorators() : []}
          localization={{
            locale: "pt",
          }}
          toolbar={{
            options: [
              "inline",
              // 'blockType',
              "fontSize",
              // 'fontFamily',
              "list",
              "textAlign",
              // 'colorPicker',
              "link",
              // 'embedded',
              // 'emoji',
              "history",
              // 'image',
              // 'remove',
            ],
            fontSize: {
              options: [8, 10, 12, 13, 14, 16, 18, 24, 30],
            },
            inline: {
              inDropdown: false,
              options: ["bold", "italic", "underline"],
            },
            list: {
              inDropdown: true,
            },
            textAlign: {
              inDropdown: true,
            },
            link: {
              inDropdown: true,
            },
          }}
        />
      </div>
    );
  }
}

RichTextEditor.defaultProps = {
  isTest: false,
  isDatasheet: false,
};

RichTextEditor.propTypes = {
  content: propTypes.string.isRequired,
  changeContent: propTypes.func.isRequired,
  newContent: propTypes.string.isRequired,
  isHtml: propTypes.bool.isRequired,
  withVariables: propTypes.bool,
  isTest: propTypes.bool,
  isDatasheet: propTypes.bool,
  onBlur: propTypes.func,
  classError: propTypes.string,
};

export default RichTextEditor;
