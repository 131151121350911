import propTypes from "prop-types";
import moment from "moment";
import intl from "react-intl-universal";

const FormatDate = ({ date, time }) =>
  moment(date, "YYYY-MM-DD hh:mm").format(
    `DD/MM/YYYY, [${intl.get(time)}] HH:mm`
  );

FormatDate.propTypes = {
  data: propTypes.string,
  time: propTypes.string,
};

export default FormatDate;
