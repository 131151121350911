import "./CollapseDrag.scss";

import { CollapseDragItem, ErrorHandler } from "../../index";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import React, { Component } from "react";

import { map } from "lodash";
import propTypes from "prop-types";

export class CollapseDrag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      items: [],
    };

    this.onDragStart = this.onDragStart.bind(this);
  }

  static getDerivedStateFromProps(props) {
    if (props.restResult.loaded && !props.restResult.error) {
      return {
        items: props.restResult.data,
        loaded: props.restResult.loaded,
      };
    }

    if (props.restResult.error) {
      return {
        error: props.restResult.error,
        loaded: props.restResult.loaded,
      };
    }

    return null;
  }

  onDragStart() {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
  }

  render() {
    const { loaded, items } = this.state;
    const { errorMessage, retryFetch, headerContent, bodyContent, onDragEnd } =
      this.props;

    return loaded ? (
      <div className="vg-collapse-list">
        <DragDropContext onDragStart={this.onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <div ref={provided.innerRef}>
                {map(items, (item, index) => {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="vg-collapse-space"
                        >
                          <CollapseDragItem
                            key={item.id}
                            headerContent={headerContent}
                            bodyContent={bodyContent}
                            item={item}
                            index={index}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    ) : (
      <ErrorHandler text={errorMessage} retryHandler={retryFetch} />
    );
  }
}

CollapseDrag.propTypes = {
  errorMessage: propTypes.string.isRequired,
  retryFetch: propTypes.func.isRequired,
  headerContent: propTypes.node.isRequired,
  bodyContent: propTypes.node.isRequired,
  restResult: propTypes.object.isRequired,
  onDragEnd: propTypes.func.isRequired,
};

export default CollapseDrag;
