import "./ModalAboutCredits.scss";

import { Modal, Panel } from "../../index";
import React, { Component, Fragment } from "react";
import { toggleModalStatus } from "../../../store/modules/ModalAboutCredits";

import intl from "react-intl-universal";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

const mapStateToProps = (state) => ({
  isOpen: state.ModalAboutCredits.isOpen,
});

export class ModalAboutCredits extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { isOpen, toggleModalStatus, isPunctual } = this.props;

    return (
      <Modal
        className="modal-entenda-creditos"
        iconKey="dollar"
        contentLabel={intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.TITULO")}
        small
        show={isOpen}
        onClose={toggleModalStatus}
      >
        <Panel iconKey="processo-seletivo">
          <h2>
            {intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.PROCESSO_SELETIVO")}
          </h2>
          <p>
            {isPunctual &&
              intl.get(
                "HOME.MODAL.ENTENDA_SEUS_CREDITOS.PROCESSO_SELETIVO_TEXTO.PONTUAL"
              )}
            {!isPunctual &&
              intl.get(
                "HOME.MODAL.ENTENDA_SEUS_CREDITOS.PROCESSO_SELETIVO_TEXTO.RECRUTADOR"
              )}
          </p>
        </Panel>
        <h3>{intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.SEPARADOR")}</h3>
        <Panel iconKey="abrir-curriculos">
          <h2>
            {intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.ABRIR_CURRICULOS")}
          </h2>
          <p>
            {intl.get(
              "HOME.MODAL.ENTENDA_SEUS_CREDITOS.ABRIR_CURRICULOS_TEXTO"
            )}
          </p>
        </Panel>
        {isPunctual && (
          <Fragment>
            <h3>{intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.SEPARADOR")}</h3>
            <Panel iconKey="ampliar-bce">
              <h2>
                {intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.AMPLIAR_BCE")}
              </h2>
              <p>
                {intl.get("HOME.MODAL.ENTENDA_SEUS_CREDITOS.AMPLIAR_BCE_TEXTO")}
              </p>
            </Panel>
          </Fragment>
        )}
      </Modal>
    );
  }
}

ModalAboutCredits.propTypes = {
  isOpen: PropTypes.bool,
  toggleModalStatus: PropTypes.func,
  isPunctual: PropTypes.bool.isRequired,
};

ModalAboutCredits.defaultProps = {
  isPunctual: false,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ toggleModalStatus }, dispatch)
)(ModalAboutCredits);
