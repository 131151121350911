// Action Types
const DATASHEET_LIST_SUCCESS = "services/DATASHEET_LIST_SUCCESS";
const DATASHEET_LIST_LOAD = "services/DATASHEET_LIST_LOAD";
const DATASHEET_LIST_FAIL = "services/DATASHEET_LIST_FAIL";

// InitialState
const initialState = {
  error: false,
  loading: false,
  data: [],
};

// Reducers
export default function globalTestAction(state = initialState, action = {}) {
  switch (action.type) {
    case DATASHEET_LIST_LOAD:
      return {
        ...state,
        loading: true,
      };
    case DATASHEET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.result,
      };
    case DATASHEET_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function loadList(path) {
  return {
    types: [DATASHEET_LIST_LOAD, DATASHEET_LIST_SUCCESS, DATASHEET_LIST_FAIL],
    promise: (client) => client.get(path),
  };
}

export function datasheetList(path) {
  return (dispatch) => {
    return dispatch(loadList(path));
  };
}
