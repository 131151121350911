import React, { useEffect, useState } from "react";
import { array, func, number } from "prop-types";
import { styled } from "@vagastec/epartner-ui";
import { differenceBy } from "lodash";
import intl from "react-intl-universal";

import { AutoSuggestList, Icon } from "../../index";
import { useDebounce } from "../../../utils/hooks";
import ApiClient from "../../../utils/ApiClient";
import "./AutoSuggest.scss";

const LoaderMessage = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  box-icon {
    fill: ${({ theme }) => theme.colors.primary};
  }

  p {
    margin: 0;
  }
`;

const AutoSuggest = ({ totalCandidates, setTotalCandidates, goToStep, id }) => {
  const [inputValue, setInputValue] = useState("");
  const [searching, setSearching] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const debouncedChange = useDebounce(inputValue, 300);
  const [suggestionList, setSuggestionList] = useState({
    candidatos: [],
  });

  useEffect(() => {
    if (debouncedChange) {
      if (inputValue.length >= 2) {
        fetchOptions();
        setSearching(true);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedChange]);

  useEffect(() => {
    if (inputValue.length < 2) {
      setSearching(false);
      setLoaded(false);
      setSuggestionList({ candidatos: [] });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const fetchOptions = () => {
    setLoaded(false);

    const client = new ApiClient();
    client
      .get(`processos-seletivos/${id}/candidatos?nome=${inputValue}`)
      .then((data) => {
        setSearching(false);
        setLoaded(true);
        setSuggestionList({
          ...data,
          candidatos: [...differenceBy(data.candidatos, totalCandidates, "id")],
        });
      })
      .catch(() => goToStep("error-message"));
  };

  const handleInclude = ({ id, nome, fase_atual_nome }) => {
    setTotalCandidates([
      ...totalCandidates,
      {
        id,
        nome,
        fase_atual_nome,
      },
    ]);

    setInputValue("");
    setSearching(false);
    setLoaded(false);
  };

  return (
    <div className="vg-autosuggest-container">
      <input
        type="text"
        className="vg-autosuggest-input"
        onChange={(event) => setInputValue(event.target.value)}
        value={inputValue}
      />
      <Icon iconKey="search" />

      {searching && (
        <div className="vg-autosuggest-list">
          <LoaderMessage>
            <box-icon name="loader-circle" animation="spin"></box-icon>{" "}
            <p>
              {intl.get(
                "HOME.CONTEUDO.INDICAR_CANDIDATOS.CARREGANDO_LISTA_DE_SUGESTOES"
              )}
            </p>
          </LoaderMessage>
        </div>
      )}

      {loaded && (
        <AutoSuggestList
          candidates={suggestionList}
          inputValue={inputValue}
          include={handleInclude}
        />
      )}
    </div>
  );
};

AutoSuggest.defaultProps = {
  searching: false,
  totalCandidates: [],
};

AutoSuggest.propTypes = {
  totalCandidates: array,
  setTotalCandidates: func,
  goToStep: func.isRequired,
  id: number.isRequired,
};

export default AutoSuggest;
