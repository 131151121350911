import React, { Component } from "react";

import { intlReplace } from "../../../utils/IntlReplace";
import shortid from "shortid";
import { map } from "lodash";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import moment from "moment";
import { Field } from "react-final-form";

import "./InterviewListItem.scss";
import {
  Icon,
  StarRating,
  InterviewImage,
  Button,
  ButtonStatus,
  DropDownMenu,
  DropDownButtonList,
  VideoInterviewListItemSelector,
} from "../../index";

const defaultRating = [
  "pontuacao",
  "comunicacao",
  "postura",
  "aderencia",
  "cuidado_video",
];

class InterviewListItem extends Component {
  render() {
    const { item, isPending, urlVisitor } = this.props;

    return (
      <React.Fragment>
        <div className="vg-right-content">
          <InterviewImage image={isPending ? {} : item} />
          {!isPending && !urlVisitor && (
            <Field
              name="checks"
              type="checkbox"
              component={VideoInterviewListItemSelector}
              value={item.id}
              id={item.id}
            ></Field>
          )}
        </div>
        <div className="vg-item-data">
          <div className="vg-item-infos">
            <span className="vg-name">{item.candidato_nome}</span>
            <span>
              {isPending &&
              intlReplace(item.status_exibicao) !== "ENTREVISTA_PROCESSAMENTO"
                ? `${intl.get("VIDEO_ENTREVISTA.EMPRESA.LISTA.PRAZO")} ${moment(
                    item.data_limite,
                  ).format("DD/MM/YYYY")}`
                : `${intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.LISTA.GRAVADO",
                  )} ${moment(item.data_ultima_resposta).format("DD/MM/YYYY")}`}
              {isPending &&
                item.total_perguntas_respondidas > 0 &&
                item.total_perguntas > item.total_perguntas_respondidas &&
                intl.getHTML(
                  "VIDEO_ENTREVISTA.EMPRESA.LISTA.DESCRICAO_STATUS",
                  {
                    total_perguntas_respondidas:
                      item.total_perguntas_respondidas,
                    total_perguntas: item.total_perguntas,
                  },
                )}
            </span>
          </div>
          {isPending ? (
            <div className="vg-interview-status">
              <ButtonStatus status={item.status_exibicao}>
                <Icon iconKey={item.status_exibicao} />
                {intl.get(
                  `VIDEO_ENTREVISTA.EMPRESA.LISTA.${intlReplace(
                    item.status_exibicao,
                  )}`,
                )}
              </ButtonStatus>
            </div>
          ) : (
            <React.Fragment>
              <div className="vg-types">
                {map(defaultRating, (ratingItem) => {
                  const ratingValue = item.avaliacoes["avaliacao_principal"]
                    ? item.avaliacoes["avaliacao_principal"][ratingItem]
                    : 0;
                  return ratingItem === "pontuacao" ? (
                    <div key={shortid.generate()} className="vg-rating">
                      <StarRating rating={ratingValue} />
                    </div>
                  ) : (
                    <div key={shortid.generate()} className="vg-item-analysis">
                      <p className="vg-item-analysis-label">
                        {intl.get(
                          `VIDEO_ENTREVISTA.EMPRESA.LISTA.LEGENDA.${intlReplace(
                            ratingItem,
                          )}`,
                        )}
                        :
                      </p>
                      <p>
                        {intl.getHTML(
                          "VIDEO_ENTREVISTA.EMPRESA.LISTA.ANALISE",
                          {
                            value: ratingValue,
                          },
                        )}
                      </p>
                    </div>
                  );
                })}
              </div>
              <div className="vg-buttons-right">
                <Button
                  className={`vg-btn-success vg-btn-interview ${
                    !urlVisitor ? "" : "visitor"
                  }`}
                  to={
                    "/video-entrevista/candidato?" +
                    parameters(item) +
                    `&urlVisitor=${urlVisitor}`
                  }
                >
                  {intl.get("VIDEO_ENTREVISTA.EMPRESA.LISTA.LINK_VISUALIZAR")}
                </Button>
                <span className="vg-dropdown-right">
                  {!urlVisitor && (
                    <DropDownMenu>
                      <DropDownButtonList
                        vagaId={item.processo_seletivo_id}
                        candidatoId={item.candidato_id}
                        participanteId={item.id}
                        permissions={[
                          "curriculo-contexto-vaga",
                          "compartilhamento-vaga",
                        ]}
                        id={item.video_entrevista_id}
                      />
                    </DropDownMenu>
                  )}
                </span>
              </div>
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}

function parameters(item) {
  let options = [
    `video_entrevista=${item.video_entrevista_id}`,
    `candidato_id=${item.candidato_id}`,
    `listagem_id=${item.id}`,
    `vaga_id=${item.processo_seletivo_id}`,
  ];
  return options.join("&");
}
InterviewListItem.defaultProps = {
  item: {
    video_entrevista_id: "",
    candidato_id: "",
    url_thumbnail: null,
    nome: "",
    relevancia: "",
    data_limite: "",
    analise: [],
  },
  isPending: false,
};

InterviewListItem.propTypes = {
  item: propTypes.object.isRequired,
  vaga_id: propTypes.number,
  isPending: propTypes.bool,
  visitor: propTypes.bool,
  urlVisitor: propTypes.bool,
};

export default InterviewListItem;
