// Recebe arrays e transforma para arrays de string
export const listToParams = (list) => {
  const queryParam = Object.keys(list).reduce((acc, item) => {
    if (
      list[item] === "undefined" ||
      list[item] === null ||
      list[item] === undefined
    ) {
      return acc;
    }

    return Array.isArray(list[item])
      ? list[item].reduce((acm, val) => {
          return acm + `${item}[]=${val}&`;
        }, acc)
      : acc + `${item}=${list[item]}&`;
  }, "");

  return queryParam.slice(0, -1);
};

// Recebe uma URL e retorna um objeto com os valores de search
export const parse = (param) =>
  param
    .slice(1, param.length)
    .split("&")
    .reduce((acc, item) => {
      const [key, value] = item.split("=");
      return { ...acc, [key]: value };
    }, {});
