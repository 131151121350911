// Action Types
const AVATAR = "services/AVATAR";
const AVATAR_SUCCESS = "services/AVATAR_SUCCESS";
const AVATAR_FAIL = "services/AVATAR_FAIL";

// InitialState
const initialState = {
  loading: false,
  loaded: false,
  data: {},
};

// Reducers
export default function avatar(state = initialState, action = {}) {
  switch (action.type) {
    case AVATAR:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case AVATAR_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.result,
        },
        loading: false,
        loaded: true,
      };
    case AVATAR_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

// Actions
export function load() {
  return {
    types: [AVATAR, AVATAR_SUCCESS, AVATAR_FAIL],
    promise: (client) => client.get("imagens"),
  };
}

function shouldFetch(state) {
  return state.Avatar.loading
    ? false
    : state.Avatar.data.avatar_url
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}

export function update(data) {
  return {
    types: [AVATAR, AVATAR_SUCCESS, AVATAR_FAIL],
    promise: (client) => client.post("imagens", { data }),
  };
}

export function deleteAvatar() {
  return {
    types: [AVATAR, AVATAR_SUCCESS, AVATAR_FAIL],
    promise: (client) => client.del("imagens"),
  };
}
