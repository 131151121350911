import React, { Component } from "react";
import onClickOutside from "react-onclickoutside";
import propTypes from "prop-types";
import { Icon } from "../../index";

class FilterSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggleOn: false,
    };

    this.toggleFilter = this.toggleFilter.bind(this);
  }

  toggleFilter() {
    this.setState((prevState) => ({ isToggleOn: !prevState.isToggleOn }));
  }

  handleClickOutside() {
    this.setState({ isToggleOn: false });
  }

  render() {
    const { title, children } = this.props;
    const { isToggleOn } = this.state;

    return (
      <div className={`vg-filter ${isToggleOn && "vg-filter-open"}`}>
        <button
          className="vg-filter-title"
          type="button"
          onClick={this.toggleFilter}
        >
          {title}
          <Icon iconKey="arrow" />
        </button>
        <div className="vg-menu-filter">
          <div className="vg-filter-scroll">{children}</div>
        </div>
      </div>
    );
  }
}

FilterSelect.propTypes = {
  title: propTypes.string.isRequired,
  children: propTypes.node.isRequired,
};

export default onClickOutside(FilterSelect);
