import React from "react";
import { string, array } from "prop-types";

import "./ButtonStatus.scss";

const ButtonStatus = ({ status, children }) => {
  const classNames = `vg-status-list ${status}`;

  return <button className={classNames}>{children}</button>;
};

ButtonStatus.propTypes = {
  status: string,
  children: array,
};

export default ButtonStatus;
