import React, { useState, useEffect, useRef  } from "react";
import intl from "react-intl-universal";
import {
  Button,
  Icon,
  Navigation,
  UserNavigation,
  DashboardButton,
  Link
} from "../../index";
import { toggleHelp } from "../../../store/modules/Help";
import { connect } from "react-redux";
import propTypes, { func } from "prop-types";
import { withRouter } from "react-router";
import Cookie from "js-cookie";
import delve from "dlv";
import { vpv } from "../../../utils/VirtualPageView";

const HeaderCheck = ({ accesses, check, toggleHelp, location, credits }) => {
  const tooltipBalanceRef = useRef(null);

  const isVagas25 = !!(
    accesses?.cliente && accesses?.cliente.cadastro_vagas25
  );

  const isCredits = !!(
    accesses.cliente && accesses.cliente.modelo_creditos_vagas
  );
  const dashboardButton =
    accesses.feature_flags && accesses.feature_flags.includes("link-marketing-place");

  const [balanceOpen, setBalanceOpen] = useState(false);

  const toggleBalance = () => {
    setBalanceOpen(!balanceOpen);
  };
  
  const handleClickOutside = (event) => {
    if (tooltipBalanceRef.current && !tooltipBalanceRef.current.contains(event.target)) {
      setBalanceOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
        document.removeEventListener('click', handleClickOutside, true);
    };
  }, []); 

  return check ||
    (Cookie.get("compartilhamento_token") &&
      Cookie.get("access_token") === null) ? null : (
    <div className={`vg-header-wrapper dash-${dashboardButton}`}>
      <Navigation
        accesses={accesses.acessos.itens_menu}
        clientId={delve(accesses, "cliente.id", 0)}
        userId={accesses.funcionario.id}
        location={location}
        isVagas25={isVagas25}
      />
      <div className="vg-header-right-section list-icons-header">
        {isVagas25 ?
          <Button
            onClick={toggleBalance}
            className="vg-balance"
            aria-label={intl.get("NAVEGACAO.BALANCE")}
          >
            <Icon iconKey="dollar2_circle" aria-hidden />
          </Button> 
        : null }
        <Button
          onClick={toggleHelp}
          className="vg-help"
          aria-label={intl.get("NAVEGACAO.AJUDA")}
        >
          <Icon iconKey="help_circle" aria-hidden />
        </Button>
        <UserNavigation
          navigation={accesses.acessos.itens_menu_usuario}
          username={accesses.funcionario.nome}
          isCredits={isCredits}
          isVagas25={isVagas25}
        />
        {dashboardButton && !isVagas25 && (
          <DashboardButton featureFlags={accesses.feature_flags} />
        )}
        {isVagas25 ?
          <div ref={tooltipBalanceRef} className={balanceOpen ? "vg-tooltip-balance active" : "vg-tooltip-balance"}>
            <span>{intl.get("USERNAVIGATION.SALDO_ATUAL")}</span>
            {credits ? 
              <p>{credits} {intl.get("USERNAVIGATION.CREDITOS_VAGAS")}</p>
            : <span className="vg-no-credit">
                <Icon iconKey="error_circle" aria-hidden />
                {intl.get("USERNAVIGATION.SEM_SALDO")}
              </span>}
            <div className="vg-tooltip-btns">
              <Link moveTo="servicos-contratados" className="vg-btn-outline-light-gray vg-btn-tooltip-servicos">
                <Icon iconKey="category" aria-hidden />
                {intl.get("USERNAVIGATION.SERVICOS_CONTRATADOS")}
              </Link>
              <Link
                onClick={() => vpv("/funil-compras/creditos-menu")}
                moveTo="comprar-creditos-vagas"
                className="vg-btn-outline vg-btn-tooltip-comprar"
              >
                <Icon iconKey="credit_card" aria-hidden />
                {intl.get("USERNAVIGATION.COMPRAR_CREDITOS")}
              </Link>
            </div>
          </div>
        : null }
      </div>
    </div>
  );
};

HeaderCheck.defaultProps = {
  accesses: {
    funcionario: {},
    cliente: {},
    acessos: {},
    feature_flags: [],
  },
};

HeaderCheck.propTypes = {
  accesses: propTypes.object.isRequired,
  check: propTypes.any,
  toggleHelp: propTypes.func,
  location: propTypes.object,
  credits: propTypes.number,
};

export default withRouter(connect(null, { toggleHelp })(HeaderCheck));
