import { ErrorHandler, Icon, Link } from "../../index";
import React, { Component } from "react";

import { VictoryPie } from "victory";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { receive } from "../../../store/modules/Graphs";
import { receive as receiveDivisions } from "../../../store/modules/Divisions";

const mapStateToProps = (state) => ({
  divisions: state.Divisions.sla,
  slaGraph: state.Graphs.sla,
  loading: state.Graphs.sla.loading,
  loaded: state.Graphs.sla.loaded,
  error: state.Graphs.sla.error,
});

class GraphSla extends Component {
  constructor() {
    super();

    this.fetchGraph = this.fetchGraph.bind(this);
    this.isActive = this.isActive.bind(this);

    this.state = {
      slaIsActive: false,
    };
  }

  componentDidMount() {
    const { receiveDivisions, divisions } = this.props;
    this.fetchGraph();
    receiveDivisions("divisoes", { incluir_sla: true });

    divisions.divisoes && divisions.divisoes.find(this.isActive);
  }

  fetchGraph() {
    const { receive } = this.props;
    receive("sla");
  }

  isActive(element) {
    if (element.sla && element.sla.ativo) {
      this.setState({
        slaIsActive: true,
      });
    }
  }

  showButtonConfigSLA(user_config_sla) {
    if (user_config_sla === true) {
      return (
        <Link to="configuracoes/sla" className="vg-btn-success">
          {intl.get("GRAPHS.SLA.BOTAO_PARA_SLA")}
        </Link>
      );
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.divisions.divisoes) {
      nextProps.divisions.divisoes.find(this.isActive);
    }
  }

  render() {
    const { slaGraph, loading, loaded, error } = this.props;
    return loading && !loaded ? (
      <div className="vg-graphs-placeholder">
        <Icon iconKey="graphRounded" />
      </div>
    ) : !loading && loaded ? (
      <div className="vg-graphs-content vg-graphs-sla">
        <h2 className="vg-graphs-title">{intl.get("GRAPHS.SLA.TITLE")}</h2>
        {this.state.slaIsActive ? (
          <div>
            <div className="vg-graph vg-graph-sla">
              <h2>
                {intl.getHTML("GRAPHS.SLA.TOTAL", {
                  value: slaGraph.data.vagas,
                })}
              </h2>
              <svg viewBox="50 50 200 200">
                <filter id="dropshadow" height="130%">
                  <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
                  <feOffset dx="0" dy="2" result="offsetblur" />
                  <feComponentTransfer>
                    <feFuncA type="linear" slope="0.4" />
                  </feComponentTransfer>
                  <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                  </feMerge>
                </filter>
                {slaGraph.data.vagas === 0 ? (
                  <VictoryPie
                    colorScale={["#f2f2f2"]}
                    standalone={false}
                    width={300}
                    height={300}
                    padAngle={2}
                    data={[{ x: "", y: 1 }]}
                    innerRadius={86}
                    labels={() => null}
                  />
                ) : (
                  <VictoryPie
                    colorScale={["#2dac3a", "#ffbb45", "#f9574b"]}
                    animate={{ duration: 2000, easing: "bounce" }}
                    standalone={false}
                    width={300}
                    height={300}
                    padAngle={2}
                    data={slaGraph.data.serie}
                    innerRadius={86}
                    labels={() => null}
                  />
                )}
                <circle
                  cx={150}
                  cy={150}
                  r={86}
                  style={{ filter: "url(#dropshadow)" }}
                />
              </svg>
            </div>
            <div className="vg-graph-label">
              <ul>
                <li>
                  {intl.get("GRAPHS.SLA.LABELS.FIRST")} (
                  {slaGraph.data.serie[0].y})
                </li>
                <li>
                  {intl.get("GRAPHS.SLA.LABELS.SECOND")} (
                  {slaGraph.data.serie[1].y})
                </li>
                <li>
                  {intl.get("GRAPHS.SLA.LABELS.THIRD")} (
                  {slaGraph.data.serie[2].y})
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="vg-graph-empty">
            <Icon iconKey="graph" />
            <div className="vg-graph-label">
              <p>{intl.get("GRAPHS.SLA.SLA_NAO_CONFIGURADO")}</p>
              {this.showButtonConfigSLA(slaGraph.data.user_config_sla)}
            </div>
          </div>
        )}
      </div>
    ) : (
      <ErrorHandler
        text={intl.get("GRAPHS.ERROR_MESSAGE")}
        error={error}
        retryHandler={this.fetchGraph}
      />
    );
  }
}

GraphSla.defaultProps = {
  divisions: {},
};

GraphSla.propTypes = {
  divisions: propTypes.object.isRequired,
  slaGraph: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  receive: propTypes.func.isRequired,
  receiveDivisions: propTypes.func.isRequired,
  error: propTypes.number,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, receiveDivisions }, dispatch)
)(GraphSla);
