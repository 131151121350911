import React, { Component } from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/RedesReducer";
import {
  Link,
  Modal,
  UsersRedesList,
  UserRedesForm,
  SelectRedes,
} from "../../index";

import "./UsersRedes.scss";

class UsersRedes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      indexSelected: 0,
      isOpen: false,
      titleModal: intl.get("UNIDADES_REDES.LINK_HEADER"),
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  onChangeSelect(value) {
    this.setState({
      indexSelected: parseInt(value),
    });
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.setState(
          {
            isOpen: false,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll")
        );
      }

      document.getElementsByTagName("body")[0].classList.remove("vg-no-scroll");
    });

    const { receive } = this.props;
    receive();
  }

  toggleModal() {
    this.setState({
      isOpen: !this.state.isOpen,
    });

    const bodyClass = document.getElementsByTagName("body")[0].classList;

    if (!this.state.isOpen) {
      bodyClass.add("vg-no-scroll");
    } else {
      bodyClass.remove("vg-no-scroll");
    }
  }

  render() {
    return (
      <div className="vg-container">
        <div className="vg-row">
          <div className="vg-col-xs-12">
            <h1 className="vg-title-1 vg-title-mt vg-title-redes">
              {intl.get("UNIDADES_REDES.TITULO_HEADER")}
            </h1>
            <p className="vg-subtitle">
              {intl.get("UNIDADES_REDES.TEXTO_HEADER")}
              <Link to="" onClick={() => this.toggleModal()}>
                {intl.get("UNIDADES_REDES.LINK_HEADER")}
              </Link>
            </p>
            <div className="vg-content-select">
              <span>{intl.get("UNIDADES_REDES.EXIBIR")} </span>
              <SelectRedes
                redesList={this.props.RedesList}
                onChangeSelect={this.onChangeSelect}
              />
            </div>
            {this.props.RedesList.length > 0 ? (
              <UsersRedesList
                rede={this.props.RedesList[this.state.indexSelected]}
                indexSelected={this.state.indexSelected}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        <Modal
          contentLabel={this.state.titleModal}
          show={this.state.isOpen}
          onClose={this.toggleModal}
        >
          <UserRedesForm onClose={this.toggleModal} />
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  RedesList: state.RedesReducer.data,
});

UsersRedes.defaultProps = {
  RedesList: [],
};

UsersRedes.propTypes = {
  receive: PropTypes.func.isRequired,
  RedesList: PropTypes.array.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(UsersRedes);
