import React from "react";
import propTypes from "prop-types";
import shortid from "shortid";
import intl from "react-intl-universal";

const SelectTests = ({ handleSelectTests, actionForm, items, type }) => (
  <div className="vg-row">
    <div className="vg-col-md-10 vg-col-xs-12 vg-form">
      <label className="vg-label">
        <span className="vg-label-text">
          {intl.get(`FASES.MODAL_ACTION.TIPO_ACAO.${type}.TITULO_SELECT`)}
        </span>
      </label>
      <div className="vg-custom-select">
        <select
          value={actionForm.conteudo.adendo_id}
          className="vg-select-messages"
          onChange={handleSelectTests}
        >
          <option value={0}>
            {intl.get(`FASES.MODAL_ACTION.TIPO_ACAO.${type}.SELECIONE`)}
          </option>
          {items.map((item) => (
            <option key={shortid.generate()} value={item.ficha_id}>
              {item.identidade}{" "}
              {type === "enviar_teste" && item.nota_maxima !== null
                ? ` (${item.nota_maxima})`
                : ""}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

SelectTests.defaultProps = {
  actionForm: {
    conteudo: {
      titulo: "",
      corpo: "<p></p>\n",
    },
    divisao_id: 0,
    tipo_acao_id: 1,
  },
};

SelectTests.propTypes = {
  handleSelectTests: propTypes.func.isRequired,
  items: propTypes.array.isRequired,
  actionForm: propTypes.object.isRequired,
  type: propTypes.string.isRequired,
};

export default SelectTests;
