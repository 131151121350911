import React from "react";
import { Toast } from "../../components/index";
import intl from "react-intl-universal";
import { toast } from "react-toastify";
import { omit } from "lodash";

const TOGGLEFILTER = "services/TOGGLEFILTER";
const STORE_VALUE = "services/STORE_VALUE";
const SEARCH = "services/SEARCH";
const SEARCH_SUCCESS = "services/SEARCH_SUCCESS";
const SEARCH_FAIL = "services/SEARCH_FAIL";
const SEARCH_PAGINATE = "services/SEARCH_PAGINATE";
const SEARCH_PAGINATE_SUCCESS = "services/SEARCH_PAGINATE_SUCCESS";
const SEARCH_PAGINATE_FAIL = "services/SEARCH_PAGINATE_FAIL";
const UPDATE = "services/UPDATE";
const UPDATE_SUCCESS = "services/UPDATE_SUCCESS";
const UPDATE_FAIL = "services/UPDATE_FAIL";
const PAGINATION = "services/PAGINATION";
const UPDATEFILTER = "services/UPDATEFILTER";
const UPDATELIST = "services/UPDATELIST";
const LOAD_FACETS = "services/LOAD_FACETS";
const LOAD_FACETS_SUCCESS = "services/LOAD_FACETS_SUCCESS";
const LOAD_FACETS_FAIL = "services/LOAD_FACETS_FAIL";
const CLEAR_FILTER = "services/CLEAR_FILTER";
const UNCHECK_ALL = "services/UNCHECK_ALL";
const UNCHECK = "services/UNCHECK";
const RETURN_UPDATE = "services/RETURN_UPDATE";
const RETURN_UPDATE_SUCCESS = "services/RETURN_UPDATE_SUCCESS";
const RETURN_UPDATE_FAIL = "services/RETURN_UPDATE_FAIL";

const initialState = {
  activedFilters: [],
  filter: {},
  search: {
    loaded: false,
    loading: false,
    paginating: false,
    data: {
      processos_seletivos: [],
      total: 0,
    },
    error: false,
  },
  facets: {
    loaded: false,
    loading: true,
    data: {
      aggs: {},
      filtros_salvos: {
        filtros: {},
      },
    },
    error: false,
  },
  count: 1,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLEFILTER:
      return {
        ...state,
        activedFilters: addOrRemove(state.activedFilters, action.data),
      };
    case UPDATEFILTER:
      return {
        ...state,
        filter: action.filter,
        facets: {
          ...state.facets,
          data: {
            ...state.facets.data,
            filtros_salvos: {
              filtros: { ...action.filter },
            },
          },
        },
      };
    case UPDATELIST:
      return {
        ...state,
        search: {
          ...state.search,
          data: {
            ...state.search.data,
            processos_seletivos: [...action.data],
          },
        },
      };
    case PAGINATION:
      return {
        ...state,
        count: parseInt(state.count) + 1,
      };
    case SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          loading: true,
          loaded: false,
          data: {
            ...state.search.data,
          },
          error: false,
        },
        count: 1,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          loaded: true,
          data: action.result,
          error: false,
        },
        count: 1,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          loaded: false,
          error: action.error.status ? action.error.status : 503,
        },
        count: 1,
      };
    case SEARCH_PAGINATE:
      return {
        ...state,
        search: {
          ...state.search,
          paginating: true,
          data: {
            ...state.search.data,
          },
          error: false,
        },
      };
    case SEARCH_PAGINATE_SUCCESS:
      return {
        ...state,
        search: {
          ...state.search,
          paginating: false,
          data: {
            ...state.search.data,
            pagina_atual: action.result.pagina_atual,
            processos_seletivos: [
              ...state.search.data.processos_seletivos,
              ...action.result.processos_seletivos,
            ],
          },
          error: false,
        },
      };
    case SEARCH_PAGINATE_FAIL:
      return {
        ...state,
        search: {
          ...state.search,
          paginating: false,
          error: action.error.status ? action.error.status : 503,
        },
      };
    case LOAD_FACETS:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: true,
          loaded: false,
          data: {
            ...state.facets.data,
          },
          error: false,
        },
      };
    case LOAD_FACETS_SUCCESS:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: false,
          loaded: true,
          data: {
            ...action.result,
          },
          error: false,
        },
      };
    case LOAD_FACETS_FAIL:
      return {
        ...state,
        facets: {
          ...state.facets,
          loading: false,
          loaded: false,
          data: {
            ...state.facets.data,
          },
          error: action.error.status ? action.error.status : 503,
        },
      };
    case UNCHECK_ALL:
      return {
        ...state,
        filter: omit(state.filter, action.group),
      };
    case UNCHECK:
      return {
        ...state,
        filter: {
          ...state.filter,
          [action.group]: state.filter[action.group].filter(
            (el) => el !== action.item
          ),
        },
      };
    case CLEAR_FILTER:
      return {
        ...state,
        count: 1,
        filter: {},
      };
    case STORE_VALUE:
      return {
        ...state,
        filter: action.values,
      };
    default:
      return state;
  }
};

// Actions
const addOrRemove = (prevState = [], value) =>
  prevState.includes(value)
    ? prevState.filter((item) => item !== value)
    : [value, ...prevState];

const parsing = (data) =>
  Object.entries(data).reduce((state, [key, col]) => {
    if (col.length) {
      state[key] = col;
    }

    return state;
  }, {});

export const parseFilter = (data) => {
  return (dispatch) => {
    const prepareData = {
      config: {
        pagina: 1,
        limite: 10,
        incluir_fases: true,
        ordenar: [
          { campo: "sla", ordem: "asc" },
          { campo: "id", ordem: "desc" },
        ],
      },
      filtros: {
        ...parsing(data),
      },
    };

    dispatch(updateFilter(parsing(data)));

    return dispatch(search("processos-seletivos/procurar", prepareData));
  };
};

export function search(path, data, paginate = false) {
  return !paginate
    ? {
        types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
        promise: (client) => client.post(path, { data }),
        data,
      }
    : {
        types: [SEARCH_PAGINATE, SEARCH_PAGINATE_SUCCESS, SEARCH_PAGINATE_FAIL],
        promise: (client) => client.post(path, { data }),
        data,
      };
}

export const updateFilter = (filter) => ({ type: UPDATEFILTER, filter });

export const toggleFilter = (data) => ({ type: TOGGLEFILTER, data });

export function pagination() {
  return (dispatch, getState) => {
    dispatch(increasePagination());

    return dispatch(
      search(
        "processos-seletivos/procurar",
        {
          filtros: { ...getState().VacanciesSearch.filter },
          config: {
            ...getState().VacanciesSearch.filter.config,
            pagina: getState().VacanciesSearch.count,
            ordenar: [
              { campo: "sla", ordem: "asc" },
              { campo: "id", ordem: "desc" },
            ],
            incluir_fases: true,
          },
        },
        true
      )
    );
  };
}

export const increasePagination = () => ({ type: PAGINATION });

export function hideVacancy(el, id) {
  return (dispatch, getState) => {
    el.preventDefault();
    dispatch(hideRequest(id));

    const { processos_seletivos } = getState().VacanciesSearch.search.data;

    if (processos_seletivos) {
      const toDelete = new Set([id]);
      const newArray = processos_seletivos.filter(
        (obj) => !toDelete.has(obj.id)
      );

      toast(
        <Toast
          message={intl.getHTML("VAGAS.OCULTAR_VAGA", { vaga: id })}
          type="success"
        />,
        {
          position: "bottom-right",
          autoClose: 4000,
          closeOnClick: true,
          pauseOnHover: true,
          className: "sr-toast vg-toast-success",
        }
      );

      return dispatch(updateList(newArray));
    }
  };
}

export function updateVacancyStatus(id, updatedValue) {
  return (dispatch, getState) => {
    const { processos_seletivos } = getState().VacanciesSearch.search.data;

    if (processos_seletivos) {
      const newArray = processos_seletivos.map((obj) => {
        if (obj.id === id) {
          return { ...obj, ...updatedValue };
        } else {
          return obj;
        }
      });
      return dispatch(updateList(newArray));
    }
  };
}

export function returnUpdated(vacancyId) {
  return {
    types: [RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAIL],
    promise: (client) => client.get(`processos-seletivos/${vacancyId}`),
  };
}

export function requestUpdateVacancy(vacancyId, payload) {
  return {
    types: [RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAIL],
    promise: (client) =>
      client.put(`processos-seletivos/${vacancyId}`, {
        data: {
          status_atual: payload.status && payload.status.id,
          ativador: payload.status && payload.status.nome,
          status_anterior: payload.status_anterior,
          data_de_expiracao: payload.data_de_expiracao,
        },
      }),
  };
}

export function updateVacancy() {
  return (dispatch, getState) => {
    const { processos_seletivos } = getState().VacanciesSearch.search.data;
    if (processos_seletivos) {
      const updatedVacancy = getState().Vacancies.updated.result;
      const mapItens = processos_seletivos.map((item) => {
        if (item.id !== updatedVacancy.id) {
          return item;
        }
        return {
          ...updatedVacancy,
        };
      });

      return dispatch(updateList(mapItens));
    }
  };
}

export function hideRequest(vacancyId) {
  return {
    types: [UPDATE, UPDATE_SUCCESS, UPDATE_FAIL],
    promise: (client) =>
      client.post(`processos-seletivos/${vacancyId}/ocultar`),
  };
}

export const updateList = (data) => ({ type: UPDATELIST, data });

export function loadFacets(path) {
  return (dispatch) => {
    dispatch(getFacets(path)).then((success) => {
      success.filtros_salvos.filtros &&
        dispatch(updateFilter(success.filtros_salvos.filtros));
    });
  };
}

export function clearInitialValues() {
  return (dispatch) => {
    dispatch(parseFilter({}));
    dispatch({ type: CLEAR_FILTER });
  };
}

export function getFacets(path) {
  return {
    types: [LOAD_FACETS, LOAD_FACETS_SUCCESS, LOAD_FACETS_FAIL],
    promise: (client) =>
      client.post(path, {
        data: {
          config: { agregacoes: "apenas" },
        },
      }),
  };
}

export function uncheckAll(group) {
  return {
    type: UNCHECK_ALL,
    group,
  };
}

export function uncheck(group, item) {
  return {
    type: UNCHECK,
    item,
    group,
  };
}

export function storeValues(values) {
  return (dispatch) => {
    dispatch({ type: STORE_VALUE, values });
    dispatch(parseFilter(values));
  };
}

export default reducer;
