import React, { Component } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { searchFacets } from "../../../store/modules/RequestFilter";
import { debounce } from "lodash";

class InputFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      q: "",
      error: false,
    };

    this.searchAndFilter = this.searchAndFilter.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.search = debounce((value) => this.searchAndFilter(value), 500);
  }

  searchAndFilter(value) {
    const { filter, loading } = this.props;

    filter(value);
    loading(false);
  }

  handleInput(value) {
    const { loading } = this.props;

    loading(true);

    this.setState(
      {
        q: value,
      },
      () => this.search(value),
    );
  }

  render() {
    const { content } = this.props;
    const { q } = this.state;

    return content > 6 ? (
      <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
        <input
          placeholder={intl.get("VAGAS.BUSCA.LABEL_PESQUISAR")}
          className="vg-input-text"
          type="text"
          name="byName"
          value={q}
          onChange={(el) => this.handleInput(el.target.value)}
        />
      </form>
    ) : null;
  }
}

InputFilter.defaultProps = {
  type: "",
};

InputFilter.propTypes = {
  content: propTypes.number.isRequired,
  filter: propTypes.func.isRequired,
  searchFacets: propTypes.func.isRequired,
  type: propTypes.string,
  loading: propTypes.func.isRequired,
};

export default connect(null, (dispatch) =>
  bindActionCreators({ searchFacets }, dispatch),
)(InputFilter);
