import gtm from "./gtm";

export function automaticActionDatalayer(event, action, label, fase) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    eventCategory: "acao-automatica",
    eventAction: action,
    eventLabel: label,
    eventValue: 0,
    custom: {
      dimensions: {
        cdNomeDaFase: fase,
      },
    },
  });
  gtm();
}

export function virtualPageviewDatalayer(page, title) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "virtualPageview",
    vpvPage: page,
    vpvTitle: title,
  });
  gtm();
}

export function headerDatalayer(event, label, idDoUsuarioEmpresa) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: event,
    eventCategory: "menu",
    eventAction: "cabecalho:clicou",
    eventLabel: label,
    eventValue: 0,
    custom: {
      dimensions: {
        cdIdUsuarioEmpresa: idDoUsuarioEmpresa,
      },
    },
  });
  gtm();
}

export function addVacancyListPageDataLayer({
  companyName,
  companyId,
  userId,
}) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    custom: {
      dimensions: {
        cdNomeEmpresa: companyName,
        cdCodigoEmpresa: companyId,
        cdIdUsuarioEmpresa: userId,
      },
    },
  });
  gtm();
}

export function addOpenPanelLinkDataLayer({
  vacancyId,
  companyName,
  companyId,
}) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "interaction",
    eventCategory: "vagas",
    eventAction: "clicou:painel-da-vaga",
    eventLabel: vacancyId,
    eventValue: 0,
    custom: {
      dimensions: {
        cdNomeEmpresa: companyName,
        cdCodigoEmpresa: companyId,
      },
    },
  });
  gtm();
}
