import { useRef, useEffect, useState } from "react";

function generateId() {
  const alphabet = "zyxwvutsrqponmlkjihgfedcba9876543210";
  let id = "",
    len = 8;
  while (len--) id += alphabet[(Math.random() * 36) | 0];
  return id;
}

export function useAccordion() {
  const accordionRootRef = useRef(null);
  const [headers, setHeaders] = useState();
  const [panels, setPanels] = useState();
  const [ids, setIds] = useState();
  const [activeHeader, setActiveHeader] = useState();
  const [activePanel, setActivePanel] = useState();
  const [allowAllHidden, setAllowAllHidden] = useState(false);

  useEffect(() => {
    updateActivePanel(activeHeader);
  }, [activeHeader]);

  function updateActivePanel(activeHeader) {
    let panel = null;
    if (activeHeader) {
      panel = panels[headers.indexOf(activeHeader)];
    }
    setActivePanel(panel);
  }

  function updateActiveHeader(header) {
    if (allowAllHidden && header.getAttribute("aria-expanded") === "true") {
      header.setAttribute("aria-expanded", "false");
      setActiveHeader(null);
    } else {
      setActiveHeader(header);

      header.setAttribute("aria-expanded", "true");

      headers.map((v) =>
        v !== header ? v.setAttribute("aria-expanded", "false") : null
      );
    }
  }

  function updateHeaderFocus(header) {
    header.focus({ preventScroll: true });
  }

  function handleHeaderKeydown(e) {
    const { code } = e;

    if (["ArrowUp", "ArrowDown"].includes(code)) {
      e.preventDefault();
    }
  }

  function handleHeaderKeyup(e) {
    const { code, target } = e;

    if (["ArrowUp", "ArrowDown"].includes(code)) {
      e.preventDefault();
      const index = headers.indexOf(target);
      const lastHeader = headers.length - 1;
      const nextHeader =
        headers[
          code === "ArrowDown"
            ? index === lastHeader
              ? 0
              : index + 1
            : index === 0
            ? lastHeader
            : index - 1
        ];

      updateHeaderFocus(nextHeader);
    }
  }

  function handleHeaderClick({ target }) {
    updateActiveHeader(target);
  }

  useEffect(() => {
    if (ids) {
      headers.map((header, i) => {
        header.setAttribute("aria-controls", `accordion-panel-${ids[i]}`);
        header.id = `accordion-header-${ids[i]}`;
        header.addEventListener("click", handleHeaderClick);
        header.addEventListener("keydown", handleHeaderKeydown);
        header.addEventListener("keyup", handleHeaderKeyup);
      });

      panels.map((panel, i) => {
        panel.setAttribute("aria-labelledby", `accordion-header-${ids[i]}`);
        panel.id = `accordion-panel-${ids[i]}`;
      });

      if (!allowAllHidden) updateActiveHeader(headers[0]);
    }
  }, [ids]);

  useEffect(() => {
    if (headers) setIds(headers.map(() => generateId()));
  }, [headers]);

  useEffect(() => {
    if (accordionRootRef) {
      setHeaders([
        ...accordionRootRef.current.querySelectorAll(
          ':scope > [data-component="accordion-header"]'
        ),
      ]);
      setPanels([
        ...accordionRootRef.current.querySelectorAll(
          ':scope > [data-component="accordion-panel-container"]'
        ),
      ]);
    }
  }, [accordionRootRef]);

  return {
    accordionRootRef,
    headers,
    activeHeader,
    activePanel,
    setAllowAllHidden,
  };
}
