import PropTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Divisions";
import { map } from "lodash";
import "./Select.scss";

const mapStateToProps = (state) => ({
  listDivisionsRedes: state.Divisions.redes,
  listDivisionsRecrutamento: state.Divisions.recrutamento_interno,
  loaded: state.Divisions.loaded,
  loading: state.Divisions.loading,
});

class Select extends Component {
  constructor(props) {
    super(props);
    this.state = { value: "" };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.onChangeSelect(event.target.value);
  }
  componentDidMount() {
    const { receive, path } = this.props;
    receive(path);
  }
  render() {
    const { listDivisionsRedes, listDivisionsRecrutamento, itemSelect, path } =
      this.props;
    const pathDivision = path.split("tipo_divisao=")[1];

    return (
      <select
        className="vg-select"
        id={itemSelect}
        onChange={this.handleChange}
      >
        <option value="0">Selecione uma divisão</option>
        {map(
          pathDivision === "redes"
            ? listDivisionsRedes.divisoes
            : listDivisionsRecrutamento.divisoes,
          (item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.nome}
              </option>
            );
          }
        )}
      </select>
    );
  }
}

Select.defaultProps = {
  listDivisionsRedes: {
    divisoes: [],
  },
  listDivisionsRecrutamento: {
    divisoes: [],
  },
};

Select.propTypes = {
  onChangeSelect: PropTypes.func.isRequired,
  receive: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  listDivisionsRedes: PropTypes.object,
  listDivisionsRecrutamento: PropTypes.object,
  itemSelect: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(Select);
