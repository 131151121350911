import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import propTypes from "prop-types";
import { map } from "lodash";
import {
  automaticActionDatalayer,
  virtualPageviewDatalayer,
} from "../../../../utils/dataLayerPush";
import { datasheetList } from "../../../../store/modules/GlobalDatasheetAction";
import { receive as painel } from "../../../../store/modules/Painel";
import { receive } from "../../../../store/modules/Divisions";
import {
  post,
  receive as receiveActions,
  update,
} from "../../../../store/modules/Actions";
import { receive as receiveMessage } from "../../../../store/modules/Messages";
import intl from "react-intl-universal";
import shortid from "shortid";

import {
  ActionDivisions,
  SelectTests,
  RichTextEditor,
  Button,
  Link,
  Icon,
  WarningTitle,
} from "../../../index";

export class Tests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      selectMessage: 0,
      selectMessageContent: "",
      actionForm: {
        conteudo: {
          titulo: "",
          corpo: `${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.FICHA_DATA_LIMITE",
            {
              nome: "{{CANDIDATO_NOME}}",
              url: "{{URL_FICHA}}",
              data: "{{DATA_LIMITE}}",
            }
          )}`,
          adendo_id: 0,
          prazo_dias: 1,
        },
        divisao_id: 0,
        tipo_acao_id: 1,
        tipo_acao: "enviar_ficha",
        ativo: true,
      },
      currentAction: {},
      isOpen: false,
      isHtml: false,
      nomeDivisao: "todas-as-divisoes",
      nomeFicha: "",
      valueDeadline: "",
      errorTitle: "",
      errorMessage: "",
    };

    this.handleTitle = this.handleTitle.bind(this);
    this.handleContent = this.handleContent.bind(this);
    this.handleActionForm = this.handleActionForm.bind(this);
    this.handleSelectTests = this.handleSelectTests.bind(this);
    this.createAction = this.createAction.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.handleChangeDay = this.handleChangeDay.bind(this);
    this.handleButtonCountPlus = this.handleButtonCountPlus.bind(this);
    this.handleButtonCountMinor = this.handleButtonCountMinor.bind(this);
    this.setDeadLine = this.setDeadLine.bind(this);
    this.handleOnBlurTitle = this.handleOnBlurTitle.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  componentDidMount() {
    // Carrega as mensagens padrões
    this.props.receiveMessage();

    // Carrega as divisoes
    this.props.receive("divisoes", { incluir_sla: true });

    if (this.props.currentItem.id) {
      this.props
        .datasheetList(`fases/${this.props.currentItem.id}/adendos?tipo=ficha`)
        .then(
          () => {},
          (error) => {
            const status = error.response.status;

            if (status === 404) {
              this.props.showNotifications(
                intl.getHTML("FASES.MODAL_ACTION.TIPO_ACAO.ERRO_FICHA.404"),
                "error"
              );
            }
          }
        );

      virtualPageviewDatalayer(
        "/configuracoes/editar-fases/acao-automatica/ficha",
        "enviar ficha automatica"
      );
    }
  }

  handleChangeMessage(event) {
    const getMessage = this.props.messages.filter((message) => {
      return message.id.toString() === event.target.value;
    });

    this.setState({
      selectMessage: event.target.value,
      selectMessageContent: getMessage[0]
        ? `<div>${getMessage[0].conteudo} <div>${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.FICHA_VARIAVEL",
            { url: "{{URL_FICHA}}", data: "{{DATA_LIMITE}}" }
          )}</div></div>`
        : `<p></p>\n<div>${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.FICHA_DATA_LIMITE",
            { url: "{{URL_FICHA}}", data: "{{DATA_LIMITE}}" }
          )}</div>`,
      isHtml: getMessage[0] ? getMessage[0].contem_html : false,
    });
  }

  handleTitle(e) {
    let { value } = e.target;
    const { errorTitle } = this.state;

    if (value.length > 0 && errorTitle.length > 0) {
      this.setState({ errorTitle: "" });
    }
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          titulo: value,
        },
      },
    }));
  }

  handleContent(text) {
    const { errorMessage } = this.state;
    if (text.length > 8 && errorMessage.length > 0) {
      this.setState({ errorMessage: "" });
    }
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          corpo: text,
        },
      },
    }));
  }

  handleActionForm(e, type) {
    const targetContent = e.target.value;
    const textContent = e.target.children[e.target.selectedIndex].textContent;

    if (type === "divisao_id") {
      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          [type]: targetContent,
        },
        nomeDivisao: textContent,
      }));
    } else {
      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          conteudo: {
            ...prevState.actionForm.conteudo,
            [type]: targetContent,
          },
        },
      }));
    }
  }

  handleSelectTests(el) {
    const { value } = el.target;
    const textContent = el.target.children[el.target.selectedIndex].textContent;
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          adendo_id: parseInt(value, 10),
        },
      },
      nomeFicha: textContent,
    }));
  }

  clearText(text) {
    const cleanText = text
      .replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return cleanText;
  }

  createAction() {
    let postBody = { ...this.state.actionForm };
    const content = this.state.actionForm.conteudo.corpo;
    const { currentItem } = this.props;

    if (this.state.actionForm.divisao_id == 0) {
      delete postBody.divisao_id;
    }

    if (
      !content.includes("{{URL_FICHA}}") &&
      !content.includes("{{DATA_LIMITE}}")
    ) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL_FICHA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{URL_FICHA}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{DATA_LIMITE}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    automaticActionDatalayer(
      "interaction",
      "enviar-ficha:clicou:criar-envio-automatico",
      `divisao:${this.clearText(
        this.state.nomeDivisao
      )} - ficha:${this.clearText(this.state.nomeFicha)}`,
      this.clearText(currentItem.nome)
    );

    this.props
      .post(`fases/${this.props.currentItem.id}/acoes`, { ...postBody })
      .then(
        () => {
          // Notificando o usuário do sucesso da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_CRIADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          automaticActionDatalayer(
            "nonInteraction",
            "sucesso:acao-automatica-criada",
            "ficha",
            this.clearText(currentItem.nome)
          );

          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "error"
          );
        }
      );
  }

  updateAction() {
    let postBody = { ...this.state.actionForm };
    const content = this.state.actionForm.conteudo.corpo;

    if (this.state.actionForm.divisao_id == 0) {
      postBody.divisao_id = "";
    }

    if (
      !content.includes("{{URL_FICHA}}") &&
      !content.includes("{{DATA_LIMITE}}")
    ) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL_FICHA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{URL_FICHA}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL_FICHA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{DATA_LIMITE}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    this.props
      .update(
        `fases/${this.props.currentItem.id}/acoes/${this.props.currentAction.id}`,
        { ...postBody }
      )
      .then(
        () => {
          // Notificando o usuário do sucesso da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_ATUALIZADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "error"
          );
        }
      );
  }

  static getDerivedStateFromProps(props, state) {
    if (props.divisions.divisoes && props.divisions.divisoes.length === 1) {
      if (
        Object.keys(props.currentAction).length &&
        !state.isEditing &&
        props.isOpen
      ) {
        return {
          isEditing: true,
          isOpen: props.isOpen,
          actionForm: {
            conteudo: {
              ...props.currentAction.conteudo,
              prazo_dias: props.currentAction.conteudo.prazo_dias
                ? props.currentAction.conteudo.prazo_dias
                : 1,
            },
            divisao_id: props.currentAction.divisao
              ? props.currentAction.divisao.id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao.id,
            tipo_acao: props.currentAction.tipo_acao.tipo,
            ativo: props.currentAction.ativo,
          },
          valueDeadline: `${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS",
            {
              dias: props.currentAction.conteudo.prazo_dias
                ? props.currentAction.conteudo.prazo_dias
                : 1,
            }
          )}`,
        };
      } else {
        return {
          actionForm: {
            ...state.actionForm,
            divisao_id: props.divisions.divisoes
              ? props.divisions.divisoes[0].id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao
              ? props.currentAction.tipo_acao.id
              : props.idStep,
          },
        };
      }
    }

    if (
      Object.keys(props.currentAction).length &&
      !state.isEditing &&
      props.isOpen
    ) {
      return {
        isEditing: true,
        isOpen: props.isOpen,
        actionForm: {
          conteudo: {
            ...props.currentAction.conteudo,
            prazo_dias: props.currentAction.conteudo.prazo_dias
              ? props.currentAction.conteudo.prazo_dias
              : 1,
          },
          divisao_id: props.currentAction.divisao
            ? props.currentAction.divisao.id
            : 0,
          tipo_acao_id: props.currentAction.tipo_acao.id,
          tipo_acao: props.currentAction.tipo_acao.tipo,
          ativo: props.currentAction.ativo,
        },
        valueDeadline: `${intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS", {
          dias: props.currentAction.conteudo.prazo_dias
            ? props.currentAction.conteudo.prazo_dias
            : 1,
        })}`,
      };
    }

    if (props.isOpen !== state.isOpen) {
      return {
        isEditing: false,
        isOpen: props.isOpen,
        selectMessage: 0,
        actionForm: {
          conteudo: {
            titulo: "",
            corpo: `${intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.FICHA_DATA_LIMITE",
              { url: "{{URL_FICHA}}", data: "{{DATA_LIMITE}}" }
            )}`,
            adendo_id: 0,
            prazo_dias: 1,
          },
          divisao_id: 0,
          tipo_acao_id: props.idStep,
          tipo_acao: "enviar_ficha",
          ativo: true,
        },
      };
    }
    return null;
  }

  setDeadLine(value) {
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          prazo_dias: value,
        },
      },
      valueDeadline: `${intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS", {
        dias: value,
      })}`,
    }));
  }

  handleChangeDay(event) {
    const value = Number(event.target.value.replace(/[^\d/]/g, ""));

    this.setDeadLine(value);
  }

  handleButtonCountPlus() {
    const { actionForm } = this.state;
    const newValue = actionForm.conteudo.prazo_dias
      ? actionForm.conteudo.prazo_dias + 1
      : 1;

    this.setDeadLine(newValue);
  }

  handleButtonCountMinor() {
    const { actionForm } = this.state;
    const newValue =
      actionForm.conteudo.prazo_dias === 1
        ? 1
        : actionForm.conteudo.prazo_dias - 1;

    this.setDeadLine(newValue);
  }

  handleOnBlurTitle() {
    const { actionForm } = this.state;
    !actionForm.conteudo.titulo.length
      ? this.setState({ errorTitle: "vg-empty-content-error" })
      : this.setState({ errorTitle: "" });
  }

  handleMessage() {
    const { actionForm } = this.state;
    actionForm.conteudo.corpo.length === 8
      ? this.setState({ errorMessage: "vg-empty-content-error" })
      : this.setState({ errorMessage: "" });
  }

  render() {
    const {
      selectMessageContent,
      actionForm,
      isHtml,
      selectMessage,
      isEditing,
      valueDeadline,
      errorTitle,
      errorMessage,
    } = this.state;
    const {
      divisions,
      closeActionModal,
      listDatasheet,
      messages,
      currentItem,
      currentAction,
    } = this.props;

    return (
      <div>
        <div className="vg-phases-modal-content vg-test-scroll">
          <h1>
            {isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_ficha.TITULO_ATUALIZAR"
                )
              : intl.get("FASES.MODAL_ACTION.TIPO_ACAO.enviar_ficha.TITULO")}
          </h1>
          <p>
            {intl.get("FASES.MODAL_ACTION.TIPO_ACAO.enviar_ficha.DESCRICAO")}{" "}
            <strong>{currentItem.nome}</strong>
          </p>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-selects-divisons-tests">
              {divisions.divisoes && divisions.divisoes.length > 1 && (
                <div className="vg-select-test">
                  <ActionDivisions
                    divisions={divisions}
                    actionForm={actionForm}
                    handleActionForm={this.handleActionForm}
                    isEditing={!!(isEditing && currentAction.divisao)}
                  />
                </div>
              )}
              <div className="vg-select-test">
                <SelectTests
                  handleSelectTests={this.handleSelectTests}
                  actionForm={actionForm}
                  items={listDatasheet}
                  type="ficha"
                />
              </div>
            </div>
          </div>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-record-deadline">
              <h3>{intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.TITULO")}</h3>
              <p>{intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.TEXTO")}</p>
              <div className="vg-record-deadline-form">
                <Button
                  disabled={actionForm.conteudo.prazo_dias <= 1}
                  className="vg-record-deadline-btn-count"
                  data-type="record-dealine-minor"
                  onClick={this.handleButtonCountMinor}
                >
                  <Icon iconKey="minor_light" data-type="record-dealine-plus" />
                </Button>
                <input
                  type="text"
                  value={valueDeadline}
                  onChange={this.handleChangeDay}
                  placeholder={intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS",
                    { dias: 1 }
                  )}
                  maxLength={7}
                  className="vg-record-deadline-view"
                />
                <Button
                  disabled={actionForm.conteudo.prazo_dias >= 99}
                  className="vg-record-deadline-btn-count"
                  data-type="record-dealine-plus"
                  onClick={this.handleButtonCountPlus}
                >
                  <Icon iconKey="plus_light" data-type="record-dealine-plus" />
                </Button>
              </div>
            </div>
          </div>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-form vg-title-test">
              <label className="vg-label">
                <span className="vg-label-text">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_ficha.LABEL_TITULO"
                  )}
                </span>
                <input
                  type="text"
                  value={actionForm.conteudo.titulo}
                  onChange={(e) => this.handleTitle(e)}
                  onBlur={this.handleOnBlurTitle}
                  className={`vg-input-text ${errorTitle}`}
                />
                <WarningTitle
                  error={errorTitle}
                  text={intl.get("FASES.MODAL_ACTION.ERRO_GENERICO.TITULO")}
                />
              </label>
            </div>
            <div className="vg-col-md-10 vg-col-xs-12">
              <RichTextEditor
                changeContent={(e) => this.handleContent(e)}
                content={actionForm.conteudo.corpo}
                newContent={selectMessageContent}
                withVariables
                isHtml={isHtml}
                isTest={false}
                isDatasheet={true}
                onBlur={this.handleMessage}
                classError={errorMessage}
              />
              <WarningTitle
                error={errorMessage}
                text={intl.get("FASES.MODAL_ACTION.ERRO_GENERICO.MENSAGEM")}
              />
            </div>
          </div>
          {messages.length > 0 && (
            <div className="vg-row center-xs">
              <div className="vg-col-md-10 vg-col-xs-12">
                <p>{intl.getHTML("HOME.CONTEUDO.ENVIAR_MENSAGEM.TEXTO3")}</p>

                <div className="vg-custom-select">
                  <select
                    value={selectMessage}
                    onChange={this.handleChangeMessage}
                    className="vg-select-messages"
                  >
                    <option value={0}>-</option>
                    {map(messages, (message) => {
                      return (
                        <option key={shortid.generate()} value={message.id}>
                          {message.identificacao}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="vg-wrap-buttons">
          <Link onClick={closeActionModal} className="vg-btn-link">
            {intl.get("FASES.MODAL.BOTAO_2")}
          </Link>
          <Button
            onClick={
              this.state.isEditing ? this.updateAction : this.createAction
            }
            disabled={
              actionForm.conteudo.titulo === "" ||
              actionForm.conteudo.corpo === "<p></p>\n" ||
              actionForm.conteudo.corpo === "" ||
              actionForm.conteudo.adendo_id == 0
            }
            className="vg-btn-success"
          >
            {this.state.isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_ficha.BOTAO_EDITAR"
                )
              : intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_ficha.BOTAO_SALVAR"
                )}
          </Button>
        </div>
      </div>
    );
  }
}

Tests.defaultProps = {
  messages: [],
  divisions: {
    sla: [],
  },
  currentAction: {},
};

Tests.propTypes = {
  receiveMessage: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  divisions: propTypes.object.isRequired,
  listDatasheet: propTypes.array.isRequired,
  currentAction: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  closeActionModal: propTypes.func.isRequired,
  datasheetList: propTypes.func.isRequired,
  currentItem: propTypes.object.isRequired,
  painel: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  showNotifications: propTypes.func.isRequired,
  receiveActions: propTypes.func.isRequired,
  idStep: propTypes.number.isRequired,
  messages: propTypes.array.isRequired,
  update: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  divisions: state.Divisions.sla,
  listDatasheet: state.GlobalDatasheetAction.data,
  messages: state.Messages.data.mensagens_padrao,
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      receive,
      datasheetList,
      painel,
      receiveMessage,
      post,
      receiveActions,
      update,
    },
    dispatch
  )
)(Tests);
