import PropTypes from "prop-types";
import React, { Component } from "react";
import { NewSelect, ErrorMessageForm } from "../../index";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/RedesReducer";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
class SelectDivUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      divisions: {},
      divSelected: 0,
    };

    this.onChangeSelectDiv = this.onChangeSelectDiv.bind(this);
    this.onChangeSelectForm = this.onChangeSelectForm.bind(this);

    const { receive } = this.props;
    receive();
  }

  onChangeSelectDiv(value) {
    this.setState({
      divSelected: value,
    });
  }

  onChangeSelectForm(value) {
    const unidId = Number(value.split("_")[0]);
    const selectedDivision = this.state.divisions[this.state.divSelected];
    const unids = selectedDivision.unidades;
    const unidInfo = unids.find((unid) => unid.id[0] === unidId);

    this.props.onChangeSelectForm(value, unidInfo);
  }

  componentDidMount() {
    const { loaded, RedesList } = this.props;
    if (loaded) {
      let arrDiv = [],
        objectArr = [];
      RedesList.map((item) => {
        !objectArr[item.rede]
          ? (objectArr[item.rede] = [])
          : objectArr[item.rede][item.id];
        objectArr[item.rede].push({
          id: [item.id],
          identidade: item.identidade,
          divisao_id: item.divisao_id,
        });
      });
      Object.keys(objectArr).map((itemArr) => {
        arrDiv.push({ nome: itemArr, unidades: objectArr[itemArr] });
      });
      this.setState({
        divisions: arrDiv,
      });
    }
  }

  render() {
    const { message } = this.props;
    const classCustomSelect = message ? "error" : "";
    return (
      <div>
        <fieldset>
          <label>
            {intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_DIVISAO")}
          </label>
          <NewSelect
            selectClass="vg-select-user-redes"
            onChangeNewSelect={this.onChangeSelectDiv}
            selectId="select-redes"
            listItens={this.state.divisions}
            itemOption="nome"
          />
          <ErrorMessageForm />
        </fieldset>
        <fieldset>
          <label>
            {intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_UNIDADE")}
          </label>
          <NewSelect
            selectClass="vg-select-user-redes"
            onChangeNewSelect={this.onChangeSelectForm}
            selectId="select-redes2"
            listItens={
              this.state.divisions[this.state.divSelected]
                ? this.state.divisions[this.state.divSelected].unidades
                : []
            }
            itemOption="identidade"
            valueOption="id"
            dataOption="divisao_id"
            firstOption="Selecione"
            classCustomSelect={classCustomSelect}
          />
          <ErrorMessageForm message={message} />
        </fieldset>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  RedesList: state.RedesReducer.data,
  loaded: state.RedesReducer.loaded,
});

SelectDivUnit.defaultProps = {
  RedesList: [],
  loaded: false,
};

SelectDivUnit.propTypes = {
  receive: PropTypes.func.isRequired,
  RedesList: PropTypes.array.isRequired,
  loaded: PropTypes.bool.isRequired,
  onChangeSelectForm: PropTypes.func.isRequired,
  message: PropTypes.string,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ receive }, dispatch)
  )(SelectDivUnit)
);
