// Action Types
const LOAD_SETTINGS = "services/LOAD_SETTINGS";
const LOAD_SETTINGS_SUCCESS = "services/LOAD_SETTINGS_SUCCESS";
const LOAD_SETTINGS_FAIL = "services/LOAD_SETTINGS_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: { acessos: {} },
};

// Reducer
export default function settings(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SETTINGS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_SETTINGS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_SETTINGS, LOAD_SETTINGS_SUCCESS, LOAD_SETTINGS_FAIL],
    promise: (client) => client.get("painel/configuracoes"),
  };
}

function shouldFetch(state) {
  return state.Settings.loading
    ? false
    : Object.keys(state.Settings.data.acessos).length
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
