import React, { Component } from "react";

import { VictoryPie } from "victory";
import propTypes from "prop-types";

class PercentGraph extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { percentValue, className } = this.props;

    return (
      <div className={className}>
        <h2>{Math.round(percentValue)}%</h2>
        <svg viewBox="50 50 200 200">
          <defs>
            <linearGradient id="lgrad" x1="0%" y1="85%" x2="100%" y2="15%">
              <stop
                offset="0%"
                style={{ stopColor: "rgb(255,186,53)", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "rgb(250,121,110)", stopOpacity: 1 }}
              />
            </linearGradient>
            <filter id="dropshadow" height="130%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
              <feOffset dx="0" dy="2" result="offsetblur" />
              <feComponentTransfer>
                <feFuncA type="linear" slope="0.4" />
              </feComponentTransfer>
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>

          {Math.round(percentValue) === 0 ? (
            <VictoryPie
              animate={{ duration: 1000 }}
              standalone={false}
              width={300}
              height={300}
              data={[
                { x: 1, y: 100 },
                { x: 2, y: 100 - 100 },
              ]}
              innerRadius={86}
              cornerRadius={25}
              style={{
                data: {
                  fill: (d) => {
                    return d.x === 1 ? "#f2f2f2" : "transparent";
                  },
                },
              }}
              labels={() => null}
            />
          ) : (
            <VictoryPie
              animate={{ duration: 1000 }}
              standalone={false}
              width={300}
              height={300}
              data={[
                { x: 1, y: Math.round(percentValue) },
                { x: 2, y: 100 - Math.round(percentValue) },
              ]}
              innerRadius={86}
              cornerRadius={25}
              style={{
                data: {
                  fill: (d) => {
                    return d.x === 1 ? "url(#lgrad)" : "#f2f2f2";
                  },
                },
              }}
              labels={() => null}
            />
          )}
          <circle
            cx={150}
            cy={150}
            r={86}
            style={{ filter: "url(#dropshadow)" }}
          />
        </svg>
      </div>
    );
  }
}

PercentGraph.propTypes = {
  percentValue: propTypes.number.isRequired,
  className: propTypes.string,
};

export default PercentGraph;
