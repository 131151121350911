import propTypes from "prop-types";
import React, { Component } from "react";
import { map } from "lodash";
import intl from "react-intl-universal";
import { Loader, ErrorMessage, SlaListItem } from "../../index";
import "./SlaList.scss";

class SlaList extends Component {
  render() {
    const {
      divisions,
      loaded,
      loading,
      criteria,
      counting,
      hierarchy,
      error,
      reloadDivisions,
    } = this.props;

    return loading ? (
      <Loader />
    ) : !loaded && error !== undefined ? (
      <ErrorMessage
        message={intl.get("MENSAGEM_DE_ERRO.CONFIGURACOES_NAO_CARREGADA")}
      />
    ) : (
      <div>
        {divisions.length ? (
          map(divisions, (division) => {
            return (
              <SlaListItem
                hierarchy={hierarchy}
                division={division}
                counting={counting}
                criteria={criteria}
                key={division.id}
                reloadDivisions={reloadDivisions}
              />
            );
          })
        ) : (
          <ErrorMessage
            message={intl.get("MENSAGEM_DE_ERRO.FILTRO_NAO_ENCONTROU_DIVISAO")}
          />
        )}
      </div>
    );
  }
}

SlaList.defaultProps = {
  loading: false,
  loaded: false,
  error: {},
  divisions: [],
  criteria: [],
  counting: [],
  hierarchy: [],
};

SlaList.propTypes = {
  divisions: propTypes.array.isRequired,
  criteria: propTypes.array.isRequired,
  counting: propTypes.array.isRequired,
  hierarchy: propTypes.array.isRequired,
  loading: propTypes.bool.isRequired,
  reloadDivisions: propTypes.func.isRequired,
  loaded: propTypes.bool.isRequired,
  error: propTypes.object,
};

export default SlaList;
