// Action Types
const VIDEO_ANALYSIS = "services/VIDEO_ANALYSIS";
const VIDEO_ANALYSIS_SUCCESS = "services/VIDEO_ANALYSIS_SUCCESS";
const VIDEO_ANALYSIS_FAIL = "services/VIDEO_ANALYSIS_FAIL";
const VIDEO_POST = "services/VIDEO_POST";
const VIDEO_POST_SUCCESS = "services/VIDEO_POST_SUCCESS";
const VIDEO_POST_FAIL = "services/VIDEO_POST_FAIL";
const VIDEO_GET = "services/VIDEO_GET";
const VIDEO_GET_SUCCESS = "services/VIDEO_GET_SUCCESS";
const VIDEO_GET_FAIL = "services/VIDEO_GET_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  commentLoading: false,
  data: {},
  result: {},
  analysis: {
    loaded: false,
    loading: false,
  },
};

// Reducers
export default function videoAnalysis(state = initialState, action = {}) {
  switch (action.type) {
    case VIDEO_ANALYSIS:
      return {
        ...state,
        data: state.result,
        loading: true,
        loaded: false,
        loadingResult: true,
        loadedResult: false,
      };
    case VIDEO_ANALYSIS_SUCCESS:
      return {
        ...state,
        data: action.result,
        // result: state.result,
        loading: false,
        loaded: true,
        loadingResult: true,
        loadedResult: false,
      };
    case VIDEO_ANALYSIS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error.status,
        loadingResult: false,
        loadedResult: false,
        errorResult: action.error.status,
      };
    case VIDEO_POST:
      return {
        ...state,
        post: {
          ...state.post,
          [action.receiveType]: {
            loading: true,
            loaded: false,
          },
        },
      };
    case VIDEO_POST_SUCCESS:
      return {
        ...state,
        post: {
          ...state.post,
          [action.receiveType]: {
            data: action.result,
            loading: false,
            loaded: true,
          },
        },
      };
    case VIDEO_POST_FAIL:
      return {
        ...state,
        post: {
          ...state.post,
          [action.receiveType]: {
            loading: false,
            loaded: false,
            error: action.error.status,
          },
        },
      };
    case VIDEO_GET:
      return {
        ...state,
        [action.receiveType]: {
          loading: true,
          loaded: false,
        },
      };
    case VIDEO_GET_SUCCESS:
      return {
        ...state,
        [action.receiveType]: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case VIDEO_GET_FAIL:
      return {
        ...state,
        [action.receiveType]: {
          loading: false,
          loaded: false,
          error: action.error.status,
        },
      };

    default:
      return state;
  }
}

// Actions
export function load(videoId, candId, vagaId, token, tokenShare) {
  let query = [];
  candId && query.push("candidato_id=" + candId);
  vagaId && query.push("processo_seletivo_id=" + vagaId);
  tokenShare && query.push("convidado_token=" + tokenShare);

  return {
    types: [VIDEO_ANALYSIS, VIDEO_ANALYSIS_SUCCESS, VIDEO_ANALYSIS_FAIL],
    promise: (client) =>
      client.get(
        `video-entrevistas/${videoId}/participantes?${query.join("&")}`,
        {},
        process.env.REACT_APP_ATHENA_URL,
        token,
      ),
  };
}

export function get(path, type, token = "access_token") {
  return {
    types: [VIDEO_GET, VIDEO_GET_SUCCESS, VIDEO_GET_FAIL],
    promise: (client) =>
      client.get(path, {}, process.env.REACT_APP_ATHENA_URL, token),
    receiveType: type,
  };
}

export function post(path, data, type, token = "access_token") {
  return {
    types: [VIDEO_POST, VIDEO_POST_SUCCESS, VIDEO_POST_FAIL],
    promise: (client) =>
      client.post(path, { data }, process.env.REACT_APP_ATHENA_URL, token),
    receiveType: type,
  };
}

function shouldFetch(state) {
  return state.VideoAnalysis.loading
    ? false
    : Object.keys(state.VideoAnalysis.data).length
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(videoId, candId, vagaId) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(videoId, candId, vagaId));
    }
  };
}
