import "./Steps.scss";

import React, { Component } from "react";

import propTypes from "prop-types";
import shortid from "shortid";
import { times } from "lodash";

class StepsBullets extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(i) {
    this.props.changeQuestion("number", i);
  }
  render() {
    const { total, actual, theme = "green", changeQuestion } = this.props;
    return changeQuestion ? (
      <ul
        className={`vg-steps-bullet ${
          theme === "orange" && "vg-steps-bullet-orange"
        } ${total > 5 && "vg-steps-bullet-reduce"}`}
      >
        {times(total, (i) => (
          <li
            key={shortid.generate()}
            className={i + 1 == actual ? "vg-active" : ""}
          >
            <button
              className="vg-bullets-button"
              onClick={actual !== i + 1 ? () => this.handleClick(i) : null}
              data-total={`/ ${total}`}
            >
              {i + 1}
            </button>
            <span />
          </li>
        ))}
      </ul>
    ) : (
      <ul
        className={`vg-steps-bullet ${
          theme === "orange" && "vg-steps-bullet-orange"
        } ${total > 5 && "vg-steps-bullet-reduce"}`}
      >
        {times(total, (i) => (
          <li
            key={shortid.generate()}
            className={`no-click ${i + 1 == actual ? "vg-active" : ""}`}
            data-total={`/ ${total}`}
          >
            {i + 1} <span />
          </li>
        ))}
      </ul>
    );
  }
}

StepsBullets.propTypes = {
  actual: propTypes.any.isRequired,
  total: propTypes.any.isRequired,
  theme: propTypes.string,
  changeQuestion: propTypes.func,
};

export default StepsBullets;
