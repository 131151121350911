import React from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { VideoInterviewRatingsListItem, FiveStars } from "../../index";
import "./VideoInterviewRatingsList.scss";

const VideoInterviewRatingsList = ({ average, items }) => {
  return (
    <div className="vg-video-interview-ratings-list">
      <div className="vg-title">
        <h4>
          {intl.get("VIDEO_ENTREVISTA.EMPRESA.VIDEO.TITULO_LISTA_AVALIACOES")}
        </h4>
        {average != null && (
          <div className="vg-average-rating">
            <span className="vg-average-rating-text">
              {intl.get(
                "VIDEO_ENTREVISTA.EMPRESA.VIDEO.MEDIA_LISTA_AVALIACOES"
              )}
              <span> {average.toLocaleString("pt-BR")}</span>
            </span>
            <FiveStars stars={average} />
          </div>
        )}
      </div>
      <div className="vg-list-container">
        {items.map((item) => (
          <VideoInterviewRatingsListItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

VideoInterviewRatingsList.propTypes = {
  items: propTypes.array.isRequired,
  average: propTypes.number.isRequired,
};

export default VideoInterviewRatingsList;
