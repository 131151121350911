import propTypes from "prop-types";
import React from "react";
import { Transition } from "react-transition-group";
import { SlaListContent } from "../../index";

const phases = {
  entered: {
    opacity: 1,
    maxHeight: "5000px",
    paddingTop: "30px",
    paddingBottom: "30px",
    transform: "translate3d(0, 0, 0)",
  },
  entering: {
    height: 0,
    maxHeight: 0,
    opacity: 0,
    paddingTop: "0",
    paddingBottom: "0",
  },
  exited: {
    height: 0,
    maxHeight: 0,
    opacity: 0,
    paddingTop: "0",
    paddingBottom: "0",
  },
  exiting: {
    height: 0,
    maxHeight: 0,
    opacity: 0,
    paddingTop: "0",
    paddingBottom: "0",
  },
};

const SlaListTransition = ({
  opened,
  division,
  slaActive,
  active,
  criteria,
  counting,
  hierarchy,
  reloadDivisions,
}) => {
  return (
    <Transition in={opened} appear timeout={200} unmountOnExit mountOnEnter>
      {(status) => (
        <div className={"vg-animation vg-sla-content"} style={phases[status]}>
          <SlaListContent
            criteria={criteria}
            counting={counting}
            active={active}
            division={division}
            slaActive={slaActive}
            hierarchy={hierarchy}
            reloadDivisions={reloadDivisions}
          />
        </div>
      )}
    </Transition>
  );
};

SlaListTransition.defaultProps = {
  opened: false,
  division: {},
  criteria: [],
  counting: [],
  hierarchy: [],
};

SlaListTransition.propTypes = {
  opened: propTypes.bool.isRequired,
  slaActive: propTypes.bool.isRequired,
  division: propTypes.object.isRequired,
  criteria: propTypes.array.isRequired,
  counting: propTypes.array.isRequired,
  hierarchy: propTypes.array.isRequired,
  active: propTypes.func.isRequired,
  reloadDivisions: propTypes.func.isRequired,
};

export default SlaListTransition;
