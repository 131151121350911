import React from "react";
import PropTypes from "prop-types";
import { Icon } from "../../index";
import "./Location.scss";

const Location = ({ label }) => {
  return (
    <div className="vg-atom-location">
      <Icon iconKey="location" aria-hidden />
      <span className="vg-atom-location-label">{label}</span>
    </div>
  );
};

Location.propTypes = {
  label: PropTypes.string.isRequired,
};

export default Location;
