import React from "react";
import propTypes from "prop-types";

const Mark = (props) => {
  const child = props.children[0];
  const hasRegex = !!props.secondaryRegex;
  const variableExists = !!props.variables[child.props.text];
  const theme = props.theme || {};

  // if the user supplied variables, check to see if the variable name exists.
  // if they did not supply variables, check to see if they supplied a secondary regex to match against
  // if they did not then just default to true
  const isValid = props.hasVariables
    ? variableExists
    : hasRegex
    ? props.secondaryRegex.exec(child.props.text)
    : true;

  return <span className={isValid ? theme.valid : theme.invalid}>{child}</span>;
};

export default Mark;

Mark.propTypes = {
  hasVariables: propTypes.bool.isRequired,
  children: propTypes.array.isRequired,
  secondaryRegex: propTypes.string,
  variables: propTypes.object.isRequired,
  theme: propTypes.object.isRequired,
};
