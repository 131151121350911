// Action Types
const LOAD_SLA = "services/LOAD_SLA";
const LOAD_SLA_SUCCESS = "services/LOAD_SLA_SUCCESS";
const LOAD_SLA_FAIL = "services/LOAD_SLA_FAIL";
const POST_SLA = "services/POST_SLA";
const POST_SLA_SUCCESS = "services/POST_SLA_SUCCESS";
const POST_SLA_FAIL = "services/POST_SLA_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: {},
};

// Reducer
export default function sla(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SLA:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { load: true, ...action.result },
      };
    case LOAD_SLA_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case POST_SLA:
      return {
        ...state,
        loading: true,
      };
    case POST_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { post: true, ...action.result },
      };
    case POST_SLA_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load() {
  return {
    types: [LOAD_SLA, LOAD_SLA_SUCCESS, LOAD_SLA_FAIL],
    promise: (client) => client.get("painel"),
  };
}

export function post(data) {
  return {
    types: [POST_SLA, POST_SLA_SUCCESS, POST_SLA_FAIL],
    promise: (client) => client.post("sla", { data }),
  };
}

function shouldFetch(state) {
  return state.Sla.loading
    ? false
    : Object.keys(state.Sla.data).length
    ? false
    : true;
}

export function receive() {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load());
    }
  };
}
