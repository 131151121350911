import { any, string } from "prop-types";

import { Icon } from "../../index";
import React from "react";

const Toast = ({ message, type }) => {
  return (
    <div className="vg-toast">
      <div className="vg-toast-icon">
        {type === "success" && <Icon iconKey="toastSuccess" />}
        {type === "error" && <Icon iconKey="toastError" />}
        {type === "warning" && <Icon iconKey="toastWarning" />}
      </div>
      <div className="vg-toast-content">{message}</div>
    </div>
  );
};

Toast.defaultProps = {
  message: "",
  type: "success",
};

Toast.propTypes = {
  message: any.isRequired,
  type: string.isRequired,
};

export default Toast;
