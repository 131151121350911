import React, { useState } from "react";
import { SetAccordionContext } from "./set-accordion.context";
import { AccordionContext } from "./accordion.context";
import PropTypes from "prop-types";

export function AccordionProvider({ children }) {
  const [state, setState] = useState({});

  return (
    <AccordionContext.Provider value={state}>
      <SetAccordionContext.Provider value={setState}>
        {children}
      </SetAccordionContext.Provider>
    </AccordionContext.Provider>
  );
}

AccordionProvider.propTypes = {
  children: PropTypes.any,
};
