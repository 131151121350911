/* eslint-disable */
import propTypes from "prop-types";
import React, { Fragment, useEffect } from "react";
import intl from "react-intl-universal";
import { connect } from "react-redux";
import { styled, keyframes } from "@vagastec/epartner-ui";

import { SearchBar } from "../../index";
import { receive } from "../../../store/modules/Professionals";
import "./Professionals.scss";
import {
  BoxesProfessionals,
  IntroProfessionals,
  Icon,
  ErrorMessage,
  BoxTitle,
} from "../../index";

const InfiniteFade = keyframes`
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
`;

const Loader = styled.div`
  animation: ${InfiniteFade} 0.4s linear infinite both alternate;
  display: flex;
  width: 100%;

  span {
    height: 326px;
    width: 100%;

    + span {
      margin-left: 16px;
    }
  }
`;

const Professionals = ({ professionals, loaded, loading, receive, painel }) => {
  useEffect(() => {
    receive();
  }, []);

  const featureFlags =
    painel &&
    painel.feature_flags &&
    painel.funcionario.permissoes.dir_acesso_banco_curriculos == 2
      ? painel.feature_flags
      : [];

  return (
    <div className="vg-professional-page">
      <BoxTitle
        title={intl.get("PROFISSIONAIS.TITULO_PAGINA")}
        text={intl.get("PROFISSIONAIS.SUB_TITULO_PAGINA")}
      />
      {loading && (
        <div className="vg-container">
          <Loader>
            <Icon iconKey="employerLoader" />
            <Icon iconKey="employerLoader" />
            <Icon iconKey="employerLoader" />
          </Loader>
        </div>
      )}

      {!loaded && !loading && (
        <div className="vg-container">
          <ErrorMessage
            message={intl.get("MENSAGEM_DE_ERRO.CONFIGURACOES_NAO_CARREGADA")}
          />
        </div>
      )}

      {loaded && (
        <Fragment>
          {featureFlags.includes("pesquisa-simplificada") && <SearchBar />}
          <div className="vg-container">
            <div className="vg-row">
              <div className="vg-col-xs-12">
                {professionals.hasOwnProperty("pesquisa_bce") && (
                  <IntroProfessionals content={professionals["pesquisa_bce"]} />
                )}
                <BoxesProfessionals boxes={professionals} />
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

Professionals.defaultProps = {
  professionals: [],
  type: "",
  loading: false,
};

Professionals.propTypes = {
  professionals: propTypes.object.isRequired,
  receive: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  professionals: state.Professionals.data.acessos,
  loaded: state.Professionals.loaded,
  loading: state.Professionals.loading,
  painel: state.Painel.painel.data,
});

export default connect(mapStateToProps, { receive })(Professionals);
