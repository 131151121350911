import React from "react";
import intl from "react-intl-universal";
import { Link } from "../../index";

export default function VideoInterviewTermsI18n() {
  const { currentLocale } = intl.getInitOptions();
  return currentLocale === "pt-BR" ? (
    <div>
      <p>
        O site www.vagas.com.br, os serviços e o aplicativo (“SITE”) são de
        propriedade exclusiva de VAGAS TECNOLOGIA DE SITE LTDA., com sede na
        Avenida das Nações Unidas, 11.633, 14o andar, Cjs. 143 e 144, Brooklin
        Paulista, na cidade de São Paulo, Estado de São Paulo, CEP 04578-901,
        inscrita no CNPJ/MF sob o n° 03.689.427/0001-37 (“VAGAS”).
      </p>
      <p>
        Através de seu endereço eletrônico www.vagas.com.br, a VAGAS
        disponibiliza aos candidatos, autorização não exclusiva, intransferível
        e revogável do uso do SITE.
      </p>
      <p>
        Ao utilizar o SITE, você declara ter mais de 18 anos de idade ou, caso
        menor de idade, estar acompanhado de representante legal e estar
        legalmente habilitado para aceitar as obrigações previstas nestes Termos
        e Condições de Uso (“Termos de Uso”).
      </p>
      <p>
        A utilização do SITE, ainda que off line, poderá ocorrer de qualquer
        parte do mundo, porém, ressaltamos que, ao utilizar o SITE ou contratar
        os serviços, você, o candidato (“Candidato”) concorda com estes termos e
        condições de boas práticas de uso (“Termo”) e está sujeito à legislação
        brasileira, bem como ao cumprimento integral do seguinte documento:
      </p>
      <p>
        1. Política de Privacidade (
        <Link href="https://www.vagas.com.br/candidatos/politica-privacidade">
          https://www.vagas.com.br/candidatos/politica-privacidade
        </Link>
        ).
      </p>
      <p>
        PORTANTO, LEIA COM ATENÇÃO ESSES DOCUMENTOS PARA CONHECER OS TERMOS E
        CONDIÇÕES QUE VOCÊ, CANDIDATO, ESTARÁ SUJEITO E AS CONDIÇÕES EM QUE SUAS
        INFORMAÇÕES PESSOAIS, SUA IMAGEM E DIREITOS CONEXOS SERÃO ARMAZENADOS,
        UTILIZADOS E PROTEGIDOS NO SITE.
      </p>
      <p>
        AO FORNECER INFORMAÇÕES PESSOAIS OU NAVEGAR NO SITE, VOCÊ, O CANDIDATO,
        ESTARÁ AUTOMATICAMENTE CONCORDANDO COM AS REGRAS DE UTILIZAÇÃO E
        OBRIGAÇÕES AQUI ESTABELECIDAS.
      </p>
      <h3>1. ANUÊNCIA:</h3>
      <p>
        1.1. O presente Termo aplica-se a utilização dos serviços e dos produtos
        VAGAS disponíveis no SITE e tem como objetivo definir as regras a serem
        seguidas pelos usuários do SITE. AO ACEITAR ELETRONICAMENTE O PRESENTE
        TERMO, OS USUÁRIOS ACEITAM TODAS AS CLÁUSULAS A SEGUIR EXPOSTAS E A
        POLÍTICA DE PRIVACIDADE, BEM COMO AUTORIZAM O USO DE SUA IMAGEM, VOZ E
        DIREITOS CONEXOS E ESTARÃO AUTOMATICAMENTE ADERINDO E CONCORDANDO EM SE
        SUBMETEREM INTEGRALMENTE AOS TERMOS E CONDIÇÕES AQUI RELACIONADAS. Desse
        modo, caso haja alguma discordância por parte do usuário, solicitamos
        que não utilize o SITE, sendo que a anuência aos presentes termos poderá
        se dar das seguintes maneiras:
      </p>
      <ul>
        <li>
          clicando na caixa de aceitação dos termos exibida como rota de acesso
          à página exclusiva de serviços;
        </li>
        <li>
          durante o uso efetivo dos serviços oferecidos pelo SITE, inclusive no
          cadastramento de dados pessoais e do currículo do CANDIDATO, será
          considerada como aceitação incondicional dos termos e condições ora
          propostos.
        </li>
      </ul>
      <p>
        1.2. O CANDIDATO, ao aceitar este Termo, AUTORIZA o uso de sua imagem,
        voz e direitos conexos, em vídeos entrevistas realizados e de
        responsabilidade exclusiva de recrutadores. Ainda, o CANDIDATO concorda
        que tais vídeos poderão ser compartilhados pelos recrutadores, sempre
        respeitando as condições e finalidade aqui estabelecidas
      </p>
      <p>
        1.2.1. A presente autorização é concedida a título GRATUITO, pelo prazo
        de vigência deste Termo, abrangendo o uso da imagem, voz e direitos
        conexos acima mencionados em todo território nacional e no exterior.
      </p>
      <p>
        1.2.2. Esta é a expressão da vontade do CANDIDATO que, neste ato,
        declara que autoriza o uso aqui descrito sem que em momento algum nada
        haja a ser reclamado a título de direitos conexos à sua imagem ou
        qualquer outro.
      </p>
      <p>
        1.2.3. O CANDIDATO reconhece que a vídeo entrevista é gravada e que a
        gravação não estará disponível para acesso do CANDIDATO. Caso o
        candidato opte em excluir o vídeo gravado, esta solicitação deverá ser
        efetuada mediante preenchimento e envio de formulário disponível no link{" "}
        <Link href="https://www.vagas.com.br/ajuda-candidatos/contato/">
          https://www.vagas.com.br/ajuda-candidatos/contato/
        </Link>
        . VAGAS excluirá o vídeo em até 48 (quarenta e oito) horas após o
        recebimento, por VAGAS, do formulário preenchido.
      </p>
      <p>
        1.2.3.1. O CANDIDADO reconhece que, caso solicite a exclusão, ele poderá
        ser desclassificado do respectivo processo seletivo.
      </p>
      <p>
        1.3. O CANDIDATO poderá esclarecer dúvidas sobre o presente termo e seus
        dados entrando em contato diretamente com a VAGAS através dos recursos
        disponibilizados pelo SITE.
      </p>
      <p>
        1.3.1. A VAGAS prestará suporte técnico ao CANDIDATO para utilização
        plena do SITE, nos horários e formas padronizadas pela própria VAGAS,
        segundo as regras divulgadas no SITE.
      </p>
      <p>1.4. O CANDIDATO não poderá utilizar os serviços e o SITE caso:</p>
      <p>
        a. não tenha idade legal para oferecer serviços como profissional (menor
        de 14 anos no Brasil);
      </p>
      <p>
        b. não esteja em pleno gozo de sua capacidade para praticar atos na vida
        civil;
      </p>
      <p>
        c. as leis locais de seu país ou qualquer legislação aplicável não
        permitam a utilização de serviços disponibilizados no SITE pelo
        CANDIDATO, por qualquer razão.
      </p>
      <h4>3. OBJETO - USO DO SITE:</h4>
      <p>
        3.1. O SITE tem por finalidade ofertar gratuitamente vagas de emprego de
        Empresas clientes da VAGAS e possibilitar ao CANDIDATO cadastrar seu
        currículo para concorrer à essas vagas.
      </p>
      <p>
        3.2. Aceito pelo CANDIDATO o presente Termo, este passa a ter direito de
        utilizar seu acesso ao SITE para cadastrar seu currículo, utilizar as
        ferramentas disponíveis para busca e monitoramento de vagas abertas por
        empresas anunciantes, e candidatar-se a processos seletivos, observadas
        as regras e o funcionamento do sistema divulgados no SITE.
      </p>
      <p>
        3.3. A VAGAS declara que manterá, segundo seu julgamento e
        possibilidades de mercado, a atualização constante do SITE visando seu
        aperfeiçoamento e adaptação às novas tecnologias disponíveis.
      </p>
      <p>
        3.3. O CANDIDATO, por seu lado, reconhece que a área de tecnologia da
        informação está sempre em constante desenvolvimento, e que a perfeição e
        ausência de defeitos ou riscos é um conceito inexistente nesse setor.
      </p>
      <p>
        3.4. A VAGAS compromete-se a manter os dados cadastrais e de acesso, bem
        como a imagem do CANDIDATO confidenciais nos termos da lei, sendo que as
        informações apostas pelo CANDIDATO no seu currículo serão divulgadas no
        SITE apenas para as empresas cadastradas, segundo o nível de privacidade
        e de visualização determinado pelo próprio CANDIDATO.
      </p>
      <p>
        3.4.1. VAGASpoderá,aseuexclusivocritério,excluirosdados,imagensedemais
        informações apostas pelo CANDIDATO no SITE, sem qualquer aviso prévio
        nesse sentido, na hipótese de o CANDIDATO não acessar o SITE por um
        período superior a 36 (trinta e seis) meses.
      </p>
      <p>
        3.5. O CANDIDATO RECONHECE QUE O CADASTRO DE SEU CURRÍCULO EM PROCESSOS
        DE SELEÇÃO PUBLICADOS NO SITE IMPLICA NA REVELAÇÃO DE TODAS AS
        INFORMAÇÕES APOSTAS NO CURRÍCULO PELO PRÓPRIO CANDIDATO PARA O
        ANUNCIANTE DA RESPECTIVA VAGA.
      </p>
      <p>
        3.5.1. Além das informações do currículo, o CANDIDATO disponibilizará
        outros dados exigidos pelas empresas anunciantes através de testes e
        fichas requeridas para candidatura à vaga anunciada.
      </p>
      <p>
        3.5.2. O CANDIDATO tem ciência de que as informações disponibilizadas no
        SITE poderão ser compartilhadas com as empresas clientes VAGAS, sem
        necessidade de anuência adicional além da aqui prevista.
      </p>
      <p>
        3.5.3. Quando aplicável e requerido pelo ANUNCIANTE da vaga de emprego,
        ao seguir com os procedimentos e aceitação do convite para a vídeo
        entrevista, o CANDIDATO reconhece que deverá declarar que leu, entendeu
        e aceitou as condições previstas neste Termo para participar da vídeo
        entrevista, cujo vídeo será utilizado exclusivamente pelo ANUNCIANTE.
      </p>
      <p>
        3.5.4. O candidato poderá ter mais de um currículo cadastrado no SITE,
        desde que efetue login e senhas diferentes, devendo aceitar o termo de
        uso na primeira vez que realizar o login em ambos os cadastros ou,
        conforme o caso, quando assim solicitado por VAGAS.
      </p>
      <p>
        3.6. Caso o CANDIDATO desative a exibição do seu currículo no SITE, a
        VAGAS reserva-se no direito de manter as informações do currículo em
        seus cadastros pelos prazos previstos na legislação aplicável, sendo que
        os dados do CANDIDATO ficarão inacessíveis para novas empresas e buscas,
        não recebendo o CANDIDATO qualquer e-mail ou informação sobre processos
        seletivos, exceto pelas empresas anunciantes na qual já tenha efetuado
        candidatura ou disponibilizado seu currículo.
      </p>
      <p>
        3.6.1. O CANDIDATO poderá reativar o seu cadastro a qualquer momento,
        desde que opte por isso ao efetuar seu login no SITE e após aceitar a
        versão em vigor deste Termo.
      </p>
      <p>
        3.7. Tendo em vista que os dados são públicos para visualização e
        divulgação, o CANDIDATO está ciente de que o seu currículo e demais
        informações inseridas no SITE poderão ser visualizadas por terceiros,
        inclusive por seu atual empregador. Dessa forma, ao escolher a VAGAS e
        utilizar o SITE, o CANDIDATO sabe e reconhece que há o risco de que seu
        currículo seja acessado por terceiros, sendo certo que o CANDIDATO
        reconhece e concorda expressamente que a VAGAS não poderá ser
        responsabilizada, em hipótese alguma, por eventuais danos sofridos pelo
        CANDIDATO decorrentes da visualização de seu currículo no SITE por seu
        atual empregador ou por quaisquer terceiros.
      </p>
      <p>
        3.9. O uso do SITE pelo CANDIDATO para cadastramento de seu currículo,
        utilização das ferramentas disponíveis para busca e monitoramento de
        vagas abertas por empresas anunciantes, e a candidatura a processos
        seletivos a partir do SITE, será GRATUITO, podendo o CANDIDATO manter
        seu currículo ATIVO no SITE pelo período que desejar, podendo
        interrompê-lo a qualquer momento, podendo desativá-lo sem necessidades
        de autorizações ou comunicações à VAGAS para tanto.
      </p>
      <p>
        3.10. A VAGAS, mediante prévio e claro aviso, poderá oferecer ao
        CANDIDATO serviços e utilidades específicas do SITE, mediante
        remuneração à VAGAS pelo CANDIDATO, o que dependerá sempre de prévia e
        expressa anuência e contratação pelo CANDIDATO, segundo sua livre
        escolha.
      </p>
      <p>
        3.11. É de exclusiva responsabilidade e ônus do CANDIDATO providenciar
        os equipamentos de informática, incluindo os equipamentos necessários
        para a vídeo entrevista quando optar e consentir com os termos desta
        funcionalidade, e a conexão à Internet necessários para o acesso ao
        SITE, não se responsabilizando a VAGAS em nenhuma hipótese pela
        existência, funcionamento e qualidade de tais equipamentos, sistemas e
        conexões.
      </p>
      <p>
        3.12. O CANDIDATO COMPROMETE-SE A ACESSAR E UTILIZAR O SITE
        EXCLUSIVAMENTE PARA FINS LÍCITOS, SEGUNDO O SISTEMA JURÍDICO VIGENTE,
        OBSERVANDO A MELHOR ÉTICA NO USO DE INTERNET E A MAIS ESTRITA BOA FÉ,
        BEM COMO TODAS AS REGRAS DE USO DO SITE NELE DIVULGADAS, RESPONDENDO
        INTEGRALMENTE, COMO ÚNICO RESPONSÁVEL, PELAS INFORMAÇÕES QUE VEÍCULAR
        ATRAVÉS DE SEU CURRÍCULO E DE SEUS DADOS CADASTRAIS.
      </p>
      <p>
        3.13. O CANDIDATO compreende e aceita que a VAGAS poderá suspender ou
        interromper o funcionamento do SITE para fins de manutenção ou por caso
        fortuito ou de força maior, independentemente de qualquer aviso prévio.
      </p>
      <h4>4. SENHAS E SEGURANÇA:</h4>
      <p>
        4.1. O CANDIDATO RECONHECE QUE SUA SENHA DE ACESSO AO SITE É DE USO
        PESSOAL E INSTRANSFERÍVEL, NÃO PODENDO FORNECÊ-LA A TERCEIROS EM NENHUMA
        HIPÓTESE, DEVENDO TOMAR TODAS AS PROVIDÊNCIAS CABÍVEIS PARA GARANTIR SUA
        CONFIDENCIALIDADE, DEVENDO, INCLUSIVE, EFETUAR O LOGOFF PARA FINALIZAR
        SEU ACESSO À SUA PÁGINA DE SERVIÇOS.
      </p>
      <p>
        4.2. O NÍVEL DE SEGURANÇA DA SENHA, CONFORME COMBINAÇÃO DE LETRAS,
        NÚMEROS E CARACTERES É DE EXCLUSIVA RESPONSABILIDADE DO CANDIDATO.
      </p>
      <p>
        4.3. A recuperação de senhas perdidas ou esquecidas dar-se-á conforme as
        normas de segurança divulgadas no SITE.
      </p>
      <p>
        4.4. O CANDIDATO RECONHECE QUE DEVE PROVIDENCIAR SEGURANÇA ADEQUADA NO
        USO DE SEUS EQUIPAMENTOS DE INFORMÁTICA, DEVENDO UTILIZAR, SEMPRE QUE
        POSSÍVEL SISTEMA DE ANTI-VÍRUS ATUALIZADOS, FIREWALLS, OUTROS SISTEMAS
        DE SEGURANÇA ATUALIZADOS E TODAS AS MEDIDAS ACIMA MENCIONADAS, AS QUAIS
        FICARÃO A CARGO DO CANDIDATO.
      </p>
      <p>
        4.5. O CANDIDATO COMPROMETE-SE A RESPONDER INTEGRALMENTE POR QUAISQUER
        CONSEQUÊNCIAS JURÍDICAS OU ADMINISTRATIVAS DECORRENTES DIRETAMENTE DA
        PERDA OU EXTRAVIO DE SUA SENHA OU USO INDEVIDO DE SEU USUÁRIO, DEVIDO A
        UM COMPORTAMENTO SEU CONSIDERADO DOLOSO OU CULPOSO - IMPRUDÊNCIA,
        NEGLIGÊNCIA IMPERÍCIA.
      </p>
      <h4>5. PROCESSOS SELETIVOS E VAGAS ANUNCIADAS:</h4>
      <p>
        5.1. A VAGAS ATUA TÃO SOMENTE COMO UMA FORNECEDORA DE SOLUÇÕES QUE
        APROXIMAM O CANDIDATO E AS EMPRESAS ANUNCIANTES, E NÃO REALIZA, E NÃO
        TEM A PRERROGATIVA LEGAL DE REALIZAR, MONITORAMENTO, FISCALIZAÇÃO,
        EDIÇÃO OU APROVAÇÃO DO CONTEÚDO DAS VAGAS DIVULGADAS PELAS EMPRESAS
        ANUNCIANTES, OU SOBRE A COMUNICAÇÃO ENTRE AS EMPRESAS ANUNCIANTES E O
        CANDIDATO.
      </p>
      <p>
        5.2. A VAGAS NÃO SE RESPONSABILIZA PELO CONTEÚDO DAS VAGAS ANUNCIADAS,
        PELAS COMUNICAÇÕES ESTABELECIDAS ENTRE AS EMPRESAS ANUNCIANTES E OS
        CANDIDATOS, INCLUINDO A VÍDEO ENTREVISTA QUE PODE SER SOLICITADA PELO
        ANUNCIANTE, NEM PELA CONDUÇÃO DOS PROCESSOS SELETIVOS PELAS EMPRESAS
        ANUNCIANTES, QUE SÃO AS ÚNICAS RESPONSÁVEIS PELAS VAGAS, PELOS VÍDEOS,
        PELAS COMUNICAÇÕES E SELEÇÕES QUE ANUNCIAREM E/OU CONDUZIREM, CABENDO AO
        CANDIDATO ZELAR POR SEUS MELHORES INTERESSES, MOTIVO PELO QUAL O
        CANDIDATO RECONHECE E CONCORDA EXPRESSAMENTE QUE A VAGAS NÃO PODERÁ SER
        RESPONSABILIZADA, PERANTE O CANDIDATO OU QUALQUER TERCEIRO, POR PERDAS E
        DANOS DE QUALQUER ESPÉCIE QUE VENHAM A SER CONHECIDAS EM DECORRÊNCIA DE
        TAIS NEGOCIAÇÕES, SEJAM CONTRATUAIS, EXTRACONTRATUAIS OU DE QUALQUER
        OUTRA NATUREZA.
      </p>
      <p>
        5.3. A VAGAS compromete-se a manter o currículo do CANDIDATO cadastrado
        conforme as funcionalidades do SITE, não se responsabilizando, tampouco
        garantindo, a participação efetiva do CANDIDATO em processos seletivos
        (entrevistas), nem por sua contratação.
      </p>
      <p>
        5.4. O CANDIDATO tem ciência de que, com sua anuência, a partir do seu
        cadastro e aceitação do Termo de Uso, poderão ser enviadas mensagens
        para seu e-mail informando sobre vagas e processos seletivos abertos que
        estejam de acordo com o perfil cadastrado no SITE e convites para a
        gravação de vídeos entrevistas, tendo ocorrido a candidatura ou não.
        Além disso, uma vez cadastrado o currículo no ambiente da empresa
        ANUNCIANTE, esta poderá remeter mensagens diretamente ao candidato, sem
        interveniência da VAGAS.
      </p>
      <h4>6. RESPONSABILIDADES:</h4>
      <p>
        6.1 O SITE É OFERECIDO AO CANDIDATO, E É POR ESSE ACEITO, NO ESTADO
        TÉCNICO E DE PERFEIÇÃO EM QUE SE ENCONTRA, RAZÃO PELA QUAL{" "}
        <strong>NÃO RESPONDE A VAGAS POR NENHUMA OUTRA GARANTIA</strong>; NÃO
        RESPONDE A VAGAS, ENTRE OUTRAS HIPÓTESES:
      </p>
      <ol className="upper-roman">
        <li>
          PELA ADEQUAÇÃO DO SITE ÀS NECESSIDADES OU EXPECTATIVAS DO CANDIDATO;
        </li>
        <li>
          PELOS RESULTADOS OU DESEMPENHOS ESPERADOS PELO CANDIDATO AO USAR O
          SITE E AO GRAVAR AS VÍDEOS ENTREVISTAS, QUANDO CONVIDADO;
        </li>
        <li>POR UM USO DO SITE ININTERRUPTO E LIVRE DE ERROS;</li>
        <li>
          POR QUALQUER INFORMAÇÃO OU COMUNICAÇÃO VEICULADA PELAS EMPRESAS
          ANUNCIANTES DE VAGAS E PROCESSOS SELETIVOS;
        </li>
        <li>
          PELA CORREÇÃO E APERFEIÇOAMENTO DOS ERROS ENCONTRADOS PELO CANDIDATO
          NO SITE.
        </li>
      </ol>
      <p>
        6.2. AMBAS AS PARTES DECLARAM TER PLENO CONHECIMENTO DE QUE O USO DE
        QUALQUER SISTEMA DE INFORMÁTICA EM AMBIENTE EXPOSTO À INTERNET ESTÁ
        SUJEITO A ATAQUES DE TERCEIROS, NÃO SE RESPONSABILIZANDO A VAGAS PERANTE
        O CANDIDATO OU TERCEIROS, POR QUAISQUER DANOS CAUSADOS POR INVASÕES NO
        SITE POR HACKERS, OU TERCEIROS COM INTENÇÕES SEMELHANTES.
      </p>
      <h4>7. INTERRUPÇÃO DO USO DO SITE OU DE CONTEÚDO DIVULGADO:</h4>
      <p>
        7.1. Caso a VAGAS tome ciência por si mesma, ou por qualquer terceiro,
        de que qualquer informação veiculada pelo CANDIDATO através do SITE ou
        vídeo entrevista por ele gravado (“Conteúdo“) esteja em desacordo com o
        sistema jurídico vigente, inclusive por conflito com direitos de
        terceiros, o CANDIDATO será comunicado pela VAGAS para que se manifeste
        sobre tais fatos no prazo máximo de 24 (vinte e quatro) horas.
      </p>
      <ol className="upper-roman">
        <li>
          CASO FIQUE CONSTATADA A ILICITUDE OU IRREGULARIDADE DO CONTEÚDO APÓS A
          MANIFESTAÇÃO DO CANDIDATO, A VAGAS PODERÁ EXCLUIR A INFORMAÇÃO, OU ATÉ
          MESMO DESATIVAR SEU CADASTRO E CURRÍCULO DO SITE UNILATERALMENTE,
          INDEPENDENTEMENTE DE AUTORIZAÇÃO DO CANDIDATO, PODENDO FORNECER TODAS
          AS INFORMAÇÕES ÀS AUTORIDADES LEGAIS, NOS TERMOS DA LEI.
        </li>
        <li>
          CASO O CANDIDATO NÃO SE MANIFESTE NO PRAZO DESIGNADO ACIMA, A VAGAS
          PODERÁ SUSPENDER TAL CONTEÚDO UNILATERALMENTE, INDEPENDENTEMENTE DE
          AUTORIZAÇÃO DO CANDIDATO, ATÉ SUA DEVIDA REGULARIZAÇÃO OU MANIFESTAÇÃO
          NO SENTIDO DE DEMONSTRAR A ADEQUAÇÃO DO CONTEÚDO AO SISTEMA JURÍDICO
          VIGENTE.
        </li>
        <li>
          CASO A VAGAS CONSTATE UMA INCONTROVERSA ILICITUDE DE CONTEÚDO QUE
          POSSA CAUSAR DANOS DE QUALQUER ESPÉCIE A TERCEIROS OU ENVOLVA
          LEGISLAÇÃO DE NATUREZA PENAL, PODERÁ SUSPENDÊ-LO, UNILATERALMENTE,
          ANTES DE COMUNICAR O CANDIDATO PARA QUE SE MANIFESTE A RESPEITO, NOS
          TERMOS DO CAPUT.
        </li>
        <li>
          A SUSPENSÃO OU EXCLUSÃO DE CONTEÚDO TAMBÉM SERÁ UNILATERAL E
          INDEPENDENTEMENTE DE QUALQUER COMUNICAÇÃO AO CANDIDATO QUANDO
          DETERMINADO POR ÓRGÃO DO PODER PÚBLICO COMPETENTE.
        </li>
        <li>
          A SUSPENSÃO DE CONTEÚDO AJUSTADA NESSA CLÁUSULA NÃO DARÁ DIREITO À
          NENHUMA ESPÉCIE DE INDENIZAÇÃO, BEM COMO REPARAÇÃO DE DANOS OU
          PREJUÍZOS PELA VAGAS AO CANDIDATO.
        </li>
      </ol>
      <p>
        7.2. A VAGAS RESERVA-SE O DIREITO DE, UMA VEZ CONSTATADO DESCUMPRIMENTO
        PELO CANDIDATO DE QUALQUER OBRIGAÇÃO DECORRENTE DO PRESENTE TERMO,
        DESATIVAR O ACESSO DO CANDIDATO AO SITE, OU MESMO EXCLUIR SEU CADASTRO E
        CURRÍCULO, SEM PREJUÍZO DA POSSIBILIDADE DE REMESSA DE SEUS DADOS
        CADASTRAIS E DE ACESSO ÀS AUTORIDADES LEGAIS COMPETENTES, SEM QUE ISSO
        REPRESENTE LESÃO À SEUS DIREITOS DE PROTEÇÃO DE SEUS DADOS E/OU DE
        PRIVACIDADE.
      </p>
      <h4>8. TOLERÂNCIA:</h4>
      <p>
        8.1. O não exercício, por qualquer das partes, de seus direitos ou
        prerrogativas legais ou contratuais, constituir-se-á sempre em mera
        liberalidade, não constituindo precedente para futuros descumprimentos,
        nem alteração ou extinção das obrigações contratualmente assumidas;
        Também não caracteriza, em nenhuma hipótese, novação, transação,
        compensação ou remissão, nem se constitui em hipóteses de
        surrectio/supressio, podendo tais direitos serem exercidos a qualquer
        tempo, quando conveniente para o seu titular, inclusive para a exigência
        de obrigações vencidas e não cumpridas.
      </p>
      <h4>9. E-MAIL E ARMAZENAMENTO DE E-MAILS:</h4>
      <p>
        9.1. Por opção do CANDIDATO, este poderá autorizar expressamente a VAGAS
        a enviar e-mail pertinente a toda e qualquer comunicação proveniente de
        qualquer uma das empresas cadastradas no SITE ou a proveniente da VAGAS,
        bem como boletins periódicos ou informativos do site, mesmo que estes
        contenham links de outros SITES, sejam ou não estes parceiros comerciais
        da VAGAS, para o endereço de e-mail informado no cadastro do CANDIDATO.
      </p>
      <h4>10. PRIVACIDADE E USO DE INFORMAÇÕES DO CANDIDATO:</h4>
      <p>
        10.1. É política da VAGAS respeitar a privacidade de seus CANDIDATOS.,
        sem sua permissão, excetuando para o fim deste Termo e nas hipóteses de
        tal conduta ser necessária para: (a) proteger o interesse da VAGAS ou de
        terceiros; (b) responder a eventual reclamação de que tal conteúdo viole
        direitos de terceiros; (c) identificar e resolver problemas técnicos;
        (d) cumprir procedimento administrativo, legal, inclusive requerimento
        de autoridade, determinação judicial ou de qualquer órgão regulatório
        competente; (e) fazer cumprir os termos dos serviços ora prestados.
      </p>
      <p>
        10.1.1. O CANDIDATO, ao concordar com os termos aqui previstos, concorda
        que a VAGAS poderá coletar certas informações por meio de tecnologias
        como Tags, cookies, pixels e armazenamento local (como seu browser ou
        dispositivo), que poderão ser utilizadas no SITE, independentemente de
        cadastro do Usuário no SITE.
      </p>
      <p>10.1.1.1. Para fins deste Termo de Uso:</p>
      <p>
        - Tags são ferramentas utilizadas para aferição de cliques em
        determinadas seções e links específicos de websites; e
      </p>
      <p>
        - Cookies são pequenos arquivos de texto que armazenam informação no seu
        computador, celular ou outro dispositivo local.
      </p>
      <p>
        10.1.2. A VAGAS poderá permitir que terceiros, tais como prestadores de
        serviço de publicidade e estatística, coletem informações utilizando
        esse tipo de tecnologia diretamente do SITE. Para isto, o CANDIDATO
        deverá aceitar o presente Termos de Uso, nenhum dado será
        disponibilizado sem a autorização do usuário.
      </p>
      <p>
        10.2. POR OPÇÃO DO CANDIDATO, ESTE AUTORIZA EXPRESSAMENTE A VAGAS, OU
        TERCEIRO POR ELA INDICADO, A UTILIZAR SEUS DADOS CADASTRAIS PARA FINS
        COMERCIAIS DE MARKETING e PUBLICIDADE.
      </p>
      <p>
        10.3. Salvo estipulação em contrário e prévia aceitação do CANDIDATO, a
        VAGAS não armazenará informações pessoais disponíveis em sites de
        terceiros, tais como Twitter, Facebook, Linkedin e outros, para
        compartilhamento ou inclusão em suas bases de dados.
      </p>
      <p>
        10.4. Coletamos informações anônimas de navegação dos CANDIDATOS através
        de sistemas de terceiros autorizados, respeitadas todas as questões de
        segurança, privacidade de dados e legislação aplicável, com o objetivo
        de compreender melhor o perfil de nossa audiência, oferecer uma melhor
        experiência de navegação ou apresentar publicidade mais relevante para
        você. Caso não queira que dados anônimos de navegação sejam utilizados,
        acesse e altere as configurações de privacidade do seu browser.
      </p>
      <h4>11. DIREITO DE PROPRIEDADE:</h4>
      <p>
        11.1. O CANDIDATO reconhece que todo o conteúdo (escrito, falado, imagem
        e som) exibido no sistema VAGAS, exceto os de propriedade exclusiva do
        CANDIDATO, seja da própria VAGAS ou de EMPRESAS PARCEIRAS e estão
        protegidos pela Lei que regulamenta os direitos de imagem, direitos
        autorais, marcas, patentes e demais direitos de propriedade intelectual.
      </p>
      <p>
        11.2. O CANDIDATO compromete-se a não utilizar tal conteúdo sem prévio e
        expresso consentimento da VAGAS ou EMPRESAS PARCEIRAS sob pena de ser
        responsabilizado civil e criminalmente pelas violações que cometer aos
        direitos alheios.
      </p>
      <h4>12. DISPOSIÇÕES GERAIS:</h4>
      <p>
        12.1. O CANDIDATO declara expressamente, por este instrumento, que, nos
        termos do Artigo 46 da Lei no 8.078/1990 (Código de Defesa do
        Consumidor), tomou conhecimento prévio deste Contrato no site da VAGAS,
        tendo, inclusive, a possibilidade de imprimi-lo, e que avaliou, leu,
        entendeu e concordou com todas as disposições e cláusulas aqui
        descritas.
      </p>
      <p>
        12.2. A VAGAS se reserva ao direito de alterar a qualquer tempo os
        termos e condições deste Termo, que será sempre exibido aos CANDIDATOS
        que acessarem este documento através do SITE.
      </p>
      <h4>13. DO FORO:</h4>
      <p>
        13.1. As partes elegem o Foro da Comarca da Capital do Estado de São
        Paulo como competente para dirimir eventuais conflitos originários do
        presente contrato.
      </p>
    </div>
  ) : currentLocale === "en" ? (
    <div>
      <h1>Termos em Inglês</h1>
    </div>
  ) : (
    <div>
      <h1>Termos em espanhol</h1>
    </div>
  );
}
