// Action Types
const LOAD_GROUPSVIDEOINTERVIEW = "services/LOAD_GROUPSVIDEOINTERVIEW";
const LOAD_GROUPSVIDEOINTERVIEW_SUCCESS =
  "services/LOAD_GROUPSVIDEOINTERVIEW_SUCCESS";
const LOAD_GROUPSVIDEOINTERVIEW_FAIL =
  "services/LOAD_GROUPSVIDEOINTERVIEW_FAIL";
const CLEAN = "services/CLEAN";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: {},
};

// Reducer
export default function GroupsVideoInterview(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case LOAD_GROUPSVIDEOINTERVIEW:
      return {
        ...state,
        loading: true,
      };
    case LOAD_GROUPSVIDEOINTERVIEW_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_GROUPSVIDEOINTERVIEW_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case CLEAN:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function load(processo_id, candidato_id) {
  return {
    types: [
      LOAD_GROUPSVIDEOINTERVIEW,
      LOAD_GROUPSVIDEOINTERVIEW_SUCCESS,
      LOAD_GROUPSVIDEOINTERVIEW_FAIL,
    ],
    promise: (client) =>
      client.get(
        `video-entrevistas/grupos?processo_seletivo_id=${processo_id}&candidato_id=${candidato_id}&remover_perguntas=true`
      ),
  };
}

function shouldFetch(state) {
  return state.GroupsVideoInterview.loading
    ? false
    : Object.keys(state.GroupsVideoInterview.data).length
    ? false
    : true;
}

export function receive(processo_id, candidato_id) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(processo_id, candidato_id));
    }
  };
}

export const clean = () => ({
  type: CLEAN,
});
