import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { CloseSelectionProcess } from "@vagastec/close-selection-process";
import { requestUpdateVacancy } from "../../../store/modules/Vacancies";
import { Phases, SlaBar, ListItemHeader, Toast } from "../../index";
import { Link, Icon } from "../../../components/index";
import { underscoreReplace } from "../../../utils/underscoreReplace";
import "./SelectiveProcessList.scss";

const mapStateToProps = () => ({
  requestUpdateVacancy: requestUpdateVacancy,
});

const LOCALES = {
  _enUS: "en",
  _ptBR: "pt-BR",
  _esALT: "es",
};

const SelectiveProcessListItem = (props) => {
  const {
    buttons,
    status,
    id,
    phases,
    vacancy,
    totalAfterSorting,
    requestUpdateVacancy,
    sla,
    limiteDate,
    sortingDefault,
    acoes,
    positions,
    updateVacanciesTempList,
  } = props;

  const [openFlow, setOpenFlow] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [positionTypes, setPositionTypes] = useState(positions);
  const triagemV3 = buttons.includes("triagem-3-0-vaga");

  useEffect(() => {
    // Remove informações armazenadas pela triagem
    const prefixesToRemove = ["ordemRanqueamento", "opcaoMarcacao"];

    prefixesToRemove.forEach((prefix) => {
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);

        if (key.startsWith(prefix)) {
          sessionStorage.removeItem(key);
        }
      }
    });
  }, []);

  const toggleModal = (el, fluxo) => {
    if (el) {
      el.preventDefault();
    }

    setOpenFlow(fluxo);
    setIsModalOpen(true);
  };

  const showNotifications = (message, type = "success") => {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  };

  const updateVacancyAfterApproval = (approved) => {
    setPositionTypes({ ...positionTypes, fechadas: approved });
  };

  const onCloseSelectionProcess = (openFlow) => {
    if (!openFlow) return;
    if (openFlow === "fechar-vaga") {
      updateVacanciesTempList(id, {
        status: status ? "fechada_com_contratacao" : "fechada_sem_contratacao",
        acoes: {
          ...acoes,
          menu: acoes?.menu?.filter((v) => v !== "fechar-vaga"),
        },
      });
      showNotifications(
        <div className="csp-toast">
          <h1>{intl.get("VAGAS.STATUS.FECHADA")}</h1>
          <p>{intl.get("VAGAS.INFO.VAGA_FECHADA")}</p>
        </div>,
        "success"
      );
    }
    if (openFlow === "marcar-aprovados") {
      showNotifications(
        <div className="csp-toast">
          <h1>{intl.get("HOME.CONTEUDO.MENSAGEM_ENVIADA.TITULO")}</h1>
          <p>{intl.get("HOME.CONTEUDO.MENSAGEM_ENVIADA.TEXTO3")}</p>
        </div>,
        "success"
      );
    }
  };

  return (
    <li className={`vg-list-item vg-${underscoreReplace(status)}`}>
      <ListItemHeader
        toggleModal={toggleModal}
        requestUpdateVacancy={requestUpdateVacancy}
        updateVacanciesTempList={updateVacanciesTempList}
        limiteDate={limiteDate}
        {...props}
      />
      <SlaBar classes={`vg-sla-${buttons.length}`} sla={sla} />
      <Phases
        sortingDefault={sortingDefault}
        phases={phases}
        id={id}
        totalAfterSorting={totalAfterSorting}
        triagemV3={triagemV3}
      >
        <Link
          id="btn_marcar_aprovados"
          className={
            status.toLowerCase().includes("fechada")
              ? "vg-approved-mark closed-vacancy"
              : "vg-approved-mark"
          }
          params={{ codigo_da_vaga: id }}
          onClick={(e, el) =>
            status.toLowerCase().includes("fechada")
              ? e.preventDefault()
              : toggleModal(el, "marcar-aprovados")
          }
        >
          <span className="status-box-vacancy">
            <Icon iconKey="user_circle" aria-hidden /> {positionTypes.fechadas}/
            {positionTypes.total}{" "}
            {status.toLowerCase().includes("fechada")
              ? intl.get("VAGAS.INFO.APROVADOS")
              : positionTypes.fechadas === positionTypes.total
              ? intl.get("VAGAS.ACOES.BOTOES.POSICOES_FECHADAS")
              : intl.get("VAGAS.ACOES.BOTOES.MARCAR_APROVADOS")}
          </span>
        </Link>
      </Phases>
      {isModalOpen ? (
        <div className="dialog-csp">
          <CloseSelectionProcess
            onAddApproved={updateVacancyAfterApproval}
            env={process.env.REACT_APP_ENV}
            vagaId={id}
            currentLocale={`${LOCALES[Cookie.get("IntegracaoLoginIdioma")]}`}
            domainCookie={process.env.REACT_APP_DOMAIN_COOKIE}
            nodeToken={process.env.REACT_APP_TOKEN}
            position={vacancy}
            fluxo={openFlow}
            step="step1"
            setIsModalOpen={(isOpen) => setIsModalOpen(isOpen)}
            onClose={(status) => onCloseSelectionProcess(openFlow, status)}
          />
        </div>
      ) : null}
    </li>
  );
};

SelectiveProcessListItem.defaultProps = {
  positions: {},
  buttons: [],
  isIntern: false,
  total: 0,
  status: "",
};

SelectiveProcessListItem.propTypes = {
  acoes: propTypes.object,
  id: propTypes.number.isRequired,
  buttons: propTypes.array.isRequired,
  phases: propTypes.array,
  positions: propTypes.object,
  totalAfterSorting: propTypes.number,
  sla: propTypes.shape({
    valor: propTypes.number,
    status: propTypes.string,
  }),
  status: propTypes.string,
  vacancy: propTypes.string.isRequired,
  requestUpdateVacancy: propTypes.any,
  updateVacanciesTempList: propTypes.func,
  limiteDate: propTypes.string,
  sortingDefault: propTypes.string,
  featureFlags: propTypes.array,
};

export default connect(mapStateToProps, {
  requestUpdateVacancy,
})(SelectiveProcessListItem);
