/* eslint-disable */
import { Icon, Link } from "../../index";

import React from "react";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";

export default function ActionList({ actions, toggleModal, item }) {
  return actions.length > 0 ? (
    <ul className="vg-phase-actions">
      {map(actions, (action) => (
        <li key={action.id}>
          <Icon iconKey={action.tipo_acao.tipo} />
          <p>
            {action.tipo_acao.tipo === "triar_candidatos_redes"
              ? intl.getHTML(`FASES.ACOES.TIPOS.${action.tipo_acao.tipo}`, {
                  conteudo: action.conteudo.vaga_modelo_nome,
                })
              : intl.getHTML(`FASES.ACOES.TIPOS.${action.tipo_acao.tipo}`, {
                  conteudo:
                    action.tipo_acao.tipo === "enviar_email" ||
                    "enviar_teste" ||
                    "enviar_ficha"
                      ? action.conteudo.titulo
                      : action.conteudo.carimbos.join(", "),
                })}{" "}
            {action.divisao && (
              <small>
                {intl.getHTML("FASES.ACOES.DIVISION", {
                  division: action.divisao.nome,
                })}
              </small>
            )}
          </p>
          <button
            type="button"
            onClick={(e) => toggleModal(e, item, action, "edit")}
          >
            <Icon iconKey="edit" />
          </button>
          <button
            type="button"
            onClick={(e) => toggleModal(e, item, action, "remove")}
          >
            <Icon iconKey="garbage" />
          </button>
        </li>
      ))}
    </ul>
  ) : (
    <ul className="vg-phase-actions vg-phase-empty">
      <li>
        <p>
          {intl.get("FASES.SEM_ACOES")}
          <Link href="#" onClick={(el) => toggleModal(el, item)}>
            {intl.get("FASES.CRIAR_ACAO")}
          </Link>
        </p>
      </li>
    </ul>
  );
}

ActionList.defaultProps = {
  actions: [],
};

ActionList.propTypes = {
  actions: propTypes.array.isRequired,
  toggleModal: propTypes.func.isRequired,
  item: propTypes.object.isRequired,
};
