import React, { useContext, useEffect } from "react";
import { useAccordion } from "./accordion.hook";
import { SetAccordionContext } from "./set-accordion.context";
import PropTypes from "prop-types";

export function Accordion({ children, allowAllHidden, ...rest }) {
  const accordion = useAccordion();
  const setAccordionContext = useContext(SetAccordionContext);

  useEffect(() => {
    accordion.setAllowAllHidden(allowAllHidden);
  }, [allowAllHidden]);

  useEffect(() => {
    setAccordionContext(accordion);
  }, [accordion]);

  return (
    <div data-component="accordion" ref={accordion.accordionRootRef} {...rest}>
      {children}
    </div>
  );
}

Accordion.propTypes = {
  children: PropTypes.any,
  allowAllHidden: PropTypes.any,
};
