import "./TokenField.scss";

import React, { Component } from "react";

import PropTypes from "prop-types";
import { WithContext as ReactTags } from "react-tag-input";

class TokenField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: props.tags,
      suggestions: [],
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
  }

  handleDelete(i) {
    const { tags } = this.state;
    this.setState(
      {
        tags: tags.filter((tag, index) => index !== i),
      },
      () => {
        this.props.terms(this.state.tags);
      }
    );
  }

  handleAddition(tag) {
    const { tagValidator } = this.props;
    if (tagValidator !== undefined && !tagValidator(tag.text)) {
      this.props.invalidInputHandler();
      return false;
    }
    this.setState(
      (prevState) => ({ tags: [...prevState.tags, tag] }),
      () => {
        this.props.terms(this.state.tags);
      }
    );
  }

  handleInputBlur(input) {
    const trimmed = input.trim();
    const len = this.state.tags.length;
    if (trimmed.length) {
      this.setState(
        (prevState) => ({
          tags: [...prevState.tags, { id: trimmed + len, text: trimmed }],
        }),
        () => {
          this.props.terms(this.state.tags);
        }
      );
    }
  }

  render() {
    const { tags, suggestions } = this.state;
    const { textInput, delimiters, createOnBlur } = this.props;

    return (
      <div className="vg-input-token">
        <ReactTags
          tags={tags}
          suggestions={suggestions}
          handleDelete={this.handleDelete}
          handleAddition={this.handleAddition}
          handleInputBlur={createOnBlur ? this.handleInputBlur : undefined}
          delimiters={delimiters}
          placeholder={textInput}
        />
      </div>
    );
  }
}

TokenField.defaultProps = {
  tags: [],
};

TokenField.propTypes = {
  textInput: PropTypes.string.isRequired,
  terms: PropTypes.any,
  tags: PropTypes.array,
  delimiters: PropTypes.array.isRequired,
  createOnBlur: PropTypes.bool,
  tagValidator: PropTypes.func,
  invalidInputHandler: PropTypes.func,
};

export default TokenField;
