import React from "react";
import propTypes from "prop-types";
import moment from "moment";
import { Avatar } from "@vagastec/epartner-ui";
import intl from "react-intl-universal";
import { FiveStars } from "../../index";
import "./VideoInterviewRatingsListItem.scss";

const VideoInterviewRatingsListItem = ({
  item: { pontuacao, identificacao, data_avaliacao, data_expiracao },
}) => {
  const relativeTime = moment(data_avaliacao)
    .fromNow()
    .toLowerCase()
    .split("há ")
    .join("");

  const expiredDate = moment(data_expiracao, "YYYY-MM-DD").format("DD/MM/YYYY");

  return (
    <div className="vg-video-detail-ratings-list-item">
      <div className="vg-item-avatar">
        <Avatar size="sm" kind="text" name={identificacao} />
      </div>
      <div className="vg-video-ratings-list-item">
        <FiveStars stars={pontuacao} />
        <div className="vg-item-email">{identificacao}</div>
        <div className="vg-item-date">
          {relativeTime}
          {!!data_expiracao &&
            ` ${intl.get(
              "VIDEO_ENTREVISTA.EMPRESA.VIDEO.ACESSO_TOKEN"
            )} ${expiredDate}`}
        </div>
      </div>
    </div>
  );
};

VideoInterviewRatingsListItem.defaultProps = {
  item: propTypes.shape({
    id: propTypes.number.isRequired,
    pontuacao: propTypes.number.isRequired,
    identificacao: propTypes.string.isRequired,
    data_avaliacao: propTypes.string.isRequired,
  }).isRequired,
};

VideoInterviewRatingsListItem.propTypes = {
  item: propTypes.object.isRequired,
};

export default VideoInterviewRatingsListItem;
