import React from "react";
import { node } from "prop-types";
import { Icon } from "../../index";
import "./FiveStars.scss";

const isUpperHalf = (n) => n - (Math.floor(n) + 0.5) >= 0;

const iconKeyForRating = (rating, index) => {
  return isUpperHalf(rating) && index === Math.floor(rating)
    ? "semistar"
    : "star";
};

const getClassForIndex = (n, index) =>
  index <= n ? "vg-star-filled" : "vg-star-unfilled";

const FiveStars = ({ stars }) => {
  const numStars =
    stars instanceof String ? parseInt(stars) : Math.round(stars);

  return (
    <span>
      <Icon
        addClass={getClassForIndex(numStars, 1)}
        iconKey={iconKeyForRating(stars, 0)}
      />
      <Icon
        addClass={getClassForIndex(numStars, 2)}
        iconKey={iconKeyForRating(stars, 1)}
      />
      <Icon
        addClass={getClassForIndex(numStars, 3)}
        iconKey={iconKeyForRating(stars, 2)}
      />
      <Icon
        addClass={getClassForIndex(numStars, 4)}
        iconKey={iconKeyForRating(stars, 3)}
      />
      <Icon
        addClass={getClassForIndex(numStars, 5)}
        iconKey={iconKeyForRating(stars, 4)}
      />
    </span>
  );
};

FiveStars.propTypes = {
  stars: node,
};

export default FiveStars;
