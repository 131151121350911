import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import propTypes from "prop-types";
import shortid from "shortid";

import { virtualPageviewDatalayer } from "../../../../utils/dataLayerPush";
import Cookie from "js-cookie";
import { vagasModeloList } from "../../../../store/modules/ModelVacancies";
import { receive } from "../../../../store/modules/Divisions";
import {
  post,
  receive as receiveActions,
  update,
  getList,
} from "../../../../store/modules/Actions";
import intl from "react-intl-universal";

import { ActionDivisions, Button, Link, Icon } from "../../../index";

const LOCALES = {
  _enUS: "en",
  _ptBR: "pt-BR",
  _esALT: "es",
};

export class Tests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      actionForm: {
        conteudo: {
          divisao_id: 0,
          model_vacancy_id: 0,
          approved_phase_id: 0,
          notapproved_phase_id: 0,
          nome_divisao: "",
        },
        divisao_id: 0,
        tipo_acao: "triar_candidatos_redes",
      },
      currentAction: {},
      isOpen: false,
      isHtml: false,
      hasNotApproved: false,
      errorSelect: false,
      phasesVagasModelosList: [],
    };

    this.handleActionForm = this.handleActionForm.bind(this);
    this.handleSelectModelVacancies =
      this.handleSelectModelVacancies.bind(this);
    this.handleSelectPhases = this.handleSelectPhases.bind(this);
    this.handleSelectPhasesNotApp = this.handleSelectPhasesNotApp.bind(this);
    this.createAction = this.createAction.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.handleNotApproved = this.handleNotApproved.bind(this);
    this.handleVagaModeloSelect = this.handleVagaModeloSelect(this);
  }

  componentDidMount() {
    // Carrega as divisoes
    this.props.receive("divisoes?tipo_divisao=redes");
    fetch(`${process.env.REACT_APP_ATHENA_URL}fases/acoes/fases-destino`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookie.get("access_token")}`,
        "Accept-Language": `${LOCALES[Cookie.get("IntegracaoLoginIdioma")]}`,
        "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_CONTROL,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        const findPhase = data.findIndex(
          (phase) => phase.id === this.props.currentItem.id
        );
        if (findPhase !== -1) {
          data.splice(findPhase, 1);
        }
        this.setState((prevState) => ({
          ...prevState,
          phasesVagasModelosList: data,
          actionForm: {
            ...prevState.actionForm,
            conteudo: {
              ...prevState.actionForm.conteudo,
              approved_phase_id:
                this.props.currentAction?.conteudo
                  ?.fase_destino_criterio_atendido ?? data[0].id,
            },
          },
        }));
      });
  }

  handleVagaModeloSelect() {
    if (this.props.currentAction.divisao) {
      this.props
        .vagasModeloList(
          `processos-seletivos/modelos?pagina=1&tamanho_pagina=100&divisao_id=${parseInt(
            this.props.currentAction.divisao.id,
            10
          )}`
        )
        .then(
          () => {},
          (error) => {
            const status = error.response.status;

            if (status === 404) {
              this.props.showNotifications(
                intl.getHTML("FASES.MODAL_ACTION.TIPO_ACAO.ERRO_FICHA.404"),
                "error"
              );
            }
          }
        );
    }
  }

  handleNotApproved() {
    this.setState(
      () => ({ hasNotApproved: this.state.hasNotApproved ? false : true }),
      () => {
        if (this.state.isEditing && !this.state.hasNotApproved) {
          this.setState((prevState) => ({
            actionForm: {
              ...prevState.actionForm,
              conteudo: {
                ...prevState.actionForm.conteudo,
                notapproved_phase_id: 0,
              },
            },
          }));
        }
      }
    );
  }

  handleActionForm(e, type) {
    const { currentAction } = this.props;
    const value = e.target.value;
    const textContent = e.target.children[e.target.selectedIndex].textContent;
    const urlDivison = value !== "0" ? `?divisao_id=${value}` : "";
    const urlActionId = currentAction.id ? `${currentAction.id}/` : "";

    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          divisao_id: parseInt(value, 10),
          nome_divisao: textContent,
        },
        divisao_id: parseInt(value, 10),
        nome_divisao: textContent,
      },
    }));
    if (type === "divisao_id") {
      fetch(
        `${process.env.REACT_APP_ATHENA_URL}fases/${this.props.currentItem.id}/acoes/${urlActionId}validar${urlDivison}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookie.get("access_token")}`,
            "Accept-Language": `${
              LOCALES[Cookie.get("IntegracaoLoginIdioma")]
            }`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_CONTROL,
          },
        }
      ).then(() => {
        if (this.props.currentItem.id) {
          this.props
            .vagasModeloList(
              "processos-seletivos/modelos?pagina=1&tamanho_pagina=100&divisao_id=" +
                parseInt(value, 10)
            )
            .then(
              () => {},
              (error) => {
                const status = error.response.status;

                if (status === 404) {
                  this.props.showNotifications(
                    intl.getHTML("FASES.MODAL_ACTION.TIPO_ACAO.ERRO_FICHA.404"),
                    "error"
                  );
                }
              }
            );

          virtualPageviewDatalayer(
            "/configuracoes/editar-fases/acao-automatica/ficha",
            "enviar ficha automatica"
          );
        }
      });
    }
  }

  handleSelectModelVacancies(el) {
    const { value } = el.target;
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          model_vacancy_id: parseInt(value, 10),
        },
      },
    }));
  }

  handleSelectPhases(el) {
    const { value } = el.target;
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          approved_phase_id: parseInt(value, 10),
        },
      },
    }));
  }

  handleSelectPhasesNotApp(el) {
    const { value } = el.target;
    if (
      parseInt(value, 10) === this.state.actionForm.conteudo.approved_phase_id
    ) {
      this.setState({ errorSelect: true });
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_FASE_DESTINO"),
        "error"
      );
      return;
    }
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          notapproved_phase_id: parseInt(value, 10),
        },
      },
      errorSelect: false,
    }));
  }

  createAction() {
    let postBody = { ...this.state.actionForm };
    const params = postBody.conteudo;
    const { currentItem } = this.props;

    postBody = {
      conteudo: {
        vaga_modelo_id: params.model_vacancy_id,
        fase_destino_criterio_atendido: params.approved_phase_id,
      },
      divisao_id: params.divisao_id,
      tipo_acao: this.state.actionForm.tipo_acao,
    };

    if (params.notapproved_phase_id) {
      postBody.conteudo.fase_destino_criterio_nao_atendido =
        params.notapproved_phase_id;
    } else if (this.state.hasNotApproved) {
      postBody.conteudo.fase_destino_criterio_nao_atendido =
        this.state.phasesVagasModelosList[0].id;
    }

    this.props.post(`fases/${currentItem.id}/acoes`, { ...postBody }).then(
      () => {
        // TODO: Notificando o usuário do sucesso da criação
        this.props.showNotifications(
          intl.getHTML("FASES.MENSAGENS.ACAO_CRIADA", {
            action: "",
          }),
          "success"
        );

        this.props.receiveActions(this.props.currentItem.id);

        // Fechando o modal
        this.props.closeActionModal();
      },
      (error) => {
        if (error.response.status === 409) {
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_ACAO_DUPLICADA"),
            "error"
          );
        } else {
          // TODO: Adicionar erro para validação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO", {
              action: "",
            }),
            "error"
          );
        }
      }
    );
  }

  updateAction() {
    let postBody = { ...this.state.actionForm };
    const params = postBody.conteudo;

    postBody = {
      conteudo: {
        vaga_modelo_id: params.model_vacancy_id,
        fase_destino_criterio_atendido: params.approved_phase_id,
      },
      tipo_acao: this.state.actionForm.tipo_acao,
    };

    if (params.notapproved_phase_id) {
      postBody.conteudo.fase_destino_criterio_nao_atendido =
        params.notapproved_phase_id;
    } else if (this.state.hasNotApproved) {
      postBody.conteudo.fase_destino_criterio_nao_atendido =
        this.state.phasesVagasModelosList[0].id;
    }

    this.props
      .update(
        `fases/${this.props.currentItem.id}/acoes/${this.props.currentAction.id}`,
        { ...postBody }
      )
      .then(
        () => {
          // TODO: Notificando o usuário do sucesso da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_ATUALIZADA", {
              action: "",
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          // Fechando o modal
          this.props.closeActionModal();
        },
        (error) => {
          if (error.response.status === 409) {
            this.props.showNotifications(
              intl.getHTML("FASES.MENSAGENS.ERRO_ACAO_DUPLICADA"),
              "error"
            );
          } else {
            // TODO: Adicionar erro para validação
            this.props.showNotifications(
              intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO", {
                action: "",
              }),
              "error"
            );
          }
        }
      );
  }

  static getDerivedStateFromProps(props, state) {
    if (
      Object.keys(props.currentAction).length &&
      !state.isEditing &&
      props.isOpen
    ) {
      return {
        isEditing: true,
        isOpen: props.isOpen,
        actionForm: {
          divisao_id: props.currentAction.divisao.id,
          conteudo: {
            model_vacancy_id: props.currentAction.conteudo.vaga_modelo_id,
            approved_phase_id:
              props.currentAction.conteudo.fase_destino_criterio_atendido,
            notapproved_phase_id:
              props.currentAction.conteudo.fase_destino_criterio_nao_atendido,
            nome_divisao: props.currentAction.divisao.nome,
          },
        },
        hasNotApproved:
          !!props.currentAction.conteudo.fase_destino_criterio_nao_atendido,
      };
    }
    return null;
  }

  render() {
    const {
      actionForm,
      isEditing,
      hasNotApproved,
      errorSelect,
      phasesVagasModelosList,
    } = this.state;
    const {
      divisions,
      closeActionModal,
      listVagaModelo,
      currentItem,
      currentAction,
    } = this.props;
    return (
      <div>
        <div className="vg-phases-modal-content vg-test-scroll">
          <h1>
            {isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.TITULO_ATUALIZAR"
                )
              : intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.TITULO"
                )}
          </h1>
          <p>
            {intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.DESCRICAO"
            )}{" "}
            <strong>{currentItem.nome}.</strong>
            <br />
            {intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.DESC_REDES"
            )}
          </p>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-selects-divisons-tests">
              {divisions.divisoes && divisions.divisoes.length > 0 && (
                <div className="vg-select-test">
                  <ActionDivisions
                    divisions={divisions}
                    actionForm={actionForm}
                    textSelectDivisons="Selecione"
                    handleActionForm={this.handleActionForm}
                    isEditing={!!(isEditing && currentAction.divisao)}
                  />
                </div>
              )}
              <div className="vg-select-test vg-text-left">
                <div className="vg-row">
                  <div className="vg-col-md-10 vg-col-xs-12 vg-form">
                    <label className="vg-label">
                      <span className="vg-label-text">
                        {intl.get(
                          "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.TITULO_SELECT"
                        )}
                      </span>
                    </label>
                    <div className="vg-custom-select">
                      {listVagaModelo &&
                        !listVagaModelo.processos_seletivos &&
                        this.handleVagaModeloSelect}
                      <select
                        value={actionForm.conteudo.model_vacancy_id}
                        className="vg-select-messages"
                        onChange={this.handleSelectModelVacancies}
                      >
                        <option value={0}>
                          {intl.get(
                            "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.SELECIONE"
                          )}
                        </option>
                        {listVagaModelo &&
                          listVagaModelo.processos_seletivos &&
                          listVagaModelo.processos_seletivos.map((item) => (
                            <option key={shortid.generate()} value={item.id}>
                              {item.cargo}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-form vg-title-test">
              <label className="vg-label">
                <b className="vg-label-text">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.LABEL_TITULO"
                  )}
                </b>
                <span className="vg-label-text--desc">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.LABEL_SUBTITULO"
                  )}
                </span>
                <span className="vg-label-text--desc">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.LABEL_SUB_2"
                  )}
                  <a href="/move-to/criterios-vagas-modelo-redes?url_params=configuracoes">
                    {intl.get(
                      "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.LINK_CRITERIOS"
                    )}
                  </a>
                </span>
              </label>
              <ul className="vg-phase-actions">
                <li>
                  <p>
                    {intl.get(
                      "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.PREFIX_FILTER"
                    )}
                    <span className="vg-phase-actions--type">
                      {intl.get(
                        "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.APPROVED"
                      )}
                    </span>
                    <i className="vg-phase-actions--ok"> atenderam</i>
                    {intl.get(
                      "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.SUFIX_FILTER"
                    )}
                    <div className="vg-custom-select vg-redes-select">
                      <select
                        value={actionForm.conteudo.approved_phase_id}
                        className="vg-select-messages"
                        onChange={this.handleSelectPhases}
                      >
                        {phasesVagasModelosList &&
                          phasesVagasModelosList.map((item) => (
                            <option key={shortid.generate()} value={item.id}>
                              {item.nome}
                            </option>
                          ))}
                      </select>
                    </div>
                  </p>
                </li>
                {hasNotApproved && (
                  <li>
                    <p>
                      {intl.get(
                        "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.PREFIX_FILTER"
                      )}
                      <span className="vg-phase-actions--type">
                        {intl.get(
                          "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.APPROVED"
                        )}
                      </span>
                      <i className="vg-phase-actions--notok"> não atenderam</i>
                      {intl.get(
                        "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.SUFIX_FILTER"
                      )}
                      <div
                        className={
                          !errorSelect
                            ? "vg-custom-select vg-redes-select"
                            : "vg-custom-select vg-redes-select vg-error-select"
                        }
                      >
                        <select
                          value={actionForm.conteudo.notapproved_phase_id}
                          className="vg-select-messages"
                          onChange={this.handleSelectPhasesNotApp}
                        >
                          {phasesVagasModelosList &&
                            phasesVagasModelosList.map((item) => (
                              <option key={shortid.generate()} value={item.id}>
                                {item.nome}
                              </option>
                            ))}
                        </select>
                      </div>
                      <button type="button" onClick={this.handleNotApproved}>
                        <Icon iconKey="garbage" />
                      </button>
                    </p>
                  </li>
                )}
              </ul>
              {!hasNotApproved && (
                <Link
                  className="vg-add-filter"
                  onClick={this.handleNotApproved}
                >
                  <Icon iconKey="rounded_plus_orange" />
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.BOTAO_ADD_FILTER"
                  )}
                </Link>
              )}
            </div>
          </div>
        </div>
        <div className="vg-wrap-buttons">
          <Link onClick={closeActionModal} className="vg-btn-link">
            {intl.get("FASES.MODAL.BOTAO_2")}
          </Link>
          <Button
            onClick={
              this.state.isEditing ? this.updateAction : this.createAction
            }
            disabled={
              actionForm.conteudo.divisao_id === 0 ||
              actionForm.conteudo.model_vacancy_id === 0
            }
            className="vg-btn-success"
          >
            {this.state.isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.BOTAO_EDITAR"
                )
              : intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.triar_candidatos_redes.BOTAO_SALVAR"
                )}
          </Button>
        </div>
      </div>
    );
  }
}

Tests.defaultProps = {
  messages: [],
  divisions: {
    redes: [],
  },
  fasesVagasModelo: [],
  currentAction: {},
  phasesVagasModelosList: [],
};

Tests.propTypes = {
  receive: propTypes.func.isRequired,
  divisions: propTypes.object.isRequired,
  listVagaModelo: propTypes.array.isRequired,
  currentAction: propTypes.object.isRequired,
  phasesVagasModelosList: propTypes.array.isRequired,
  isOpen: propTypes.bool.isRequired,
  closeActionModal: propTypes.func.isRequired,
  vagasModeloList: propTypes.func.isRequired,
  currentItem: propTypes.object.isRequired,
  post: propTypes.func.isRequired,
  getList: propTypes.func.isRequired,
  showNotifications: propTypes.func.isRequired,
  receiveActions: propTypes.func.isRequired,
  idStep: propTypes.number.isRequired,
  messages: propTypes.array.isRequired,
  update: propTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  divisions: state.Divisions.redes,
  listVagaModelo: state.ModelVacancies.data,
  messages: state.Messages.data.mensagens_padrao,
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      receive,
      vagasModeloList,
      post,
      getList,
      receiveActions,
      update,
    },
    dispatch
  )
)(Tests);
