import { BoxTitle, Link, VideoRecorder } from "../../index";
import React, { Component } from "react";

import { CSSTransition } from "react-transition-group";
import intl from "react-intl-universal";

export default class VideoInterviewCandidateTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verified: false,
    };

    this.handleCallback = this.handleCallback.bind(this);
  }

  handleCallback() {
    this.setState({
      verified: true,
    });
  }

  render() {
    return (
      <div>
        <BoxTitle
          title={intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.TESTE.TITULO")}
          text={intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.TESTE.SUBTITULO")}
          secondLevel
        />
        <div className="vg-container vg-interview-candidate">
          <div className="vg-interview-wrap-buttons">
            <VideoRecorder
              rerecordable={true}
              timelimit={10}
              simulate={true}
              callbackVerified={this.handleCallback}
              loaded={true}
            />

            <CSSTransition
              in={this.state.verified}
              timeout={400}
              unmountOnExit
              classNames="vg-show-buttons"
            >
              <div className="vg-interview-wrap-buttons">
                <p>{intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.TESTE.ALERTA")}</p>
                <Link
                  to="/candidato/video-entrevista/questao"
                  className="vg-btn-primary"
                >
                  {intl.getHTML("VIDEO_ENTREVISTA.CANDIDATO.TESTE.BOTAO_1")}
                </Link>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    );
  }
}
