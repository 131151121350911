import React, { Component } from "react";
import { VictoryArea, VictoryCursorContainer } from "victory";

import propTypes from "prop-types";

class GraphEmotions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: [],
      zoomDomain: {},
      serie: props.serie,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.serie !== state.serie) {
      return { serie: state.serie };
    }

    return null;
  }

  handleCursorChange(value) {
    this.setState({
      activePoint: value,
    });
  }

  render() {
    return (
      <div className="vg-graph-emotions">
        <svg style={{ position: "absolute", left: "-9999px" }}>
          <defs>
            <linearGradient id="lgrad" x1="0%" y1="50%" x2="100%" y2="50%">
              <stop
                offset="0%"
                style={{ stopColor: "rgb(255,186,53)", stopOpacity: 1 }}
              />
              <stop
                offset="100%"
                style={{ stopColor: "rgb(250,121,110)", stopOpacity: 1 }}
              />
            </linearGradient>
          </defs>
        </svg>

        <VictoryArea
          interpolation="natural"
          style={{
            data: { fill: "url(#lgrad)" },
          }}
          containerComponent={
            <VictoryCursorContainer
              cursorDimension="x"
              cursorLabel={(d) => `${d.x.toPrecision(2)}s`}
              onCursorChange={this.handleCursorChange.bind(this)}
              events={{
                target: "data",
                onClick: () => {
                  this.props.handleClick(false, this.state.activePoint, false);
                },
              }}
            />
          }
          data={this.state.serie}
          height={90}
          padding={0}
          domainPadding={{ y: [0, 30] }}
        />
      </div>
    );
  }
}

GraphEmotions.propTypes = {
  serie: propTypes.array.isRequired,
  handleClick: propTypes.func.isRequired,
};

export default GraphEmotions;
