import "./BoxGradeLimite.scss";

import React, { useEffect, useState } from "react";
import { Link, SelectGrade, Icon, InputGrade } from "../../../index";
import { string, func, array, object } from "prop-types";
import intl from "react-intl-universal";

const BoxGradeLimite = ({
  id,
  onDeleteGrid,
  selectPhases,
  onUpdateFormGrade,
  dataSelect,
  dataSelectLimite,
  formGrid,
}) => {
  const [form, setValues] = useState(formGrid);
  const [showErrorGradeMin, setShowErrorGradeMin] = useState(false);
  const [showErrorGradeMax, setShowErrorGradeMax] = useState(false);
  const [showErrorGradeInit, setShowErrorGradeInit] = useState(false);
  const [showErrorGradeFinal, setShowErrorGradeFinal] = useState(false);

  const [showMensageErrorOpe, setShowMensageErrorOpe] = useState(false);
  const [showMensageErrorOpeFinal, setShowMensageErrorOpeFinal] =
    useState(false);
  const [showMensageErrorNotaInit, setShowMensageErrorNotaInit] =
    useState(false);
  const [showMensageErrorNotaFinal, setShowMensageErrorNotaFinal] =
    useState(false);

  const [mensageError, setMensageError] = useState("");

  const [isValidGradeMin, setIsValidGradeMin] = useState(false);
  const [isValidGradeMax, setIsValidGradeMax] = useState(false);
  const [isValidOpeInit, setIsValidOpeInit] = useState(false);
  const [isValidOpeFinal, setIsValidOpeFinal] = useState(false);
  const updateInitGrade = (initGrade) => {
    form.nota_inicial = initGrade;
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };
  const updateInitGradeFinal = (grade) => {
    form.nota_final = grade;
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const updateSelectFase = (id) => {
    form.fase_destino_id = id;
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const updateSelectOperator = (operator) => {
    form.operador_inicial = operator;
    validateGradeInit();
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const updateSelectOperatorFinal = (operator) => {
    form.operador_final = operator;
    validateGradeFinal();
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const validateOperatorInit = () => {
    if (form.operador_inicial === "") {
      setShowMensageErrorOpe(true);
      setShowErrorGradeInit(true);
      setMensageError(`${intl.get("FASES.GRADE.MESSAGE_ERROR.OPE_INIT")}`);
      setIsValidOpeInit(false);
      form.validated = false;
    } else {
      setIsValidOpeInit(true);
      setShowMensageErrorOpe(false);
      setShowErrorGradeInit(false);
    }
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const validateOperatorFinal = () => {
    if (form.operador_final === "") {
      setShowMensageErrorOpeFinal(true);
      setShowErrorGradeFinal(true);
      setMensageError(`${intl.get("FASES.GRADE.MESSAGE_ERROR.OPE_FINAL")}`);
      setIsValidOpeFinal(false);
      form.validated = false;
    } else {
      setIsValidOpeFinal(true);
      setShowMensageErrorOpeFinal(false);
      setShowErrorGradeFinal(false);
    }
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const validateGradeInit = () => {
    const verify = /,/g;
    const verifyFinal = /,/g;
    let gradeInit = form.nota_inicial;
    let gradeFinal = form.nota_final;

    validateOperatorInit();

    if (verify.test(form.nota_inicial)) {
      gradeInit = gradeInit.replace(",", ".");
    }

    if (verifyFinal.test(form.nota_final)) {
      gradeFinal = gradeFinal.replace(",", ".");
    }

    if (gradeInit !== "") {
      gradeInit = Number(gradeInit);
    }

    if (gradeFinal !== "") {
      gradeFinal = Number(gradeFinal);
    }

    if (form.nota_inicial === "") {
      setMensageError(intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_INIT.VAZIO"));
      setShowErrorGradeMin(true);
      setShowMensageErrorNotaInit(true);
      setIsValidGradeMin(false);
      form.validated = false;
    } else if (gradeInit >= form.maxGrade && form.operador_inicial === ">") {
      setShowMensageErrorNotaInit(true);
      setMensageError(
        `${intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_INIT.MAIOR_MAX")} ${
          form.maxGrade
        }`
      );
      setShowErrorGradeMin(true);
      setShowMensageErrorNotaInit(true);
      setIsValidGradeMin(false);
      form.validated = false;
    } else if (gradeInit > gradeFinal && gradeFinal !== "") {
      setMensageError(
        intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_INIT.MAIOR_FINAL")
      );
      setShowErrorGradeMin(true);
      setShowMensageErrorNotaInit(true);
      setIsValidGradeMin(false);
      form.validated = false;
    } else {
      setIsValidGradeMin(true);
      setShowMensageErrorNotaInit(false);
      setShowErrorGradeMin(false);
    }

    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const validateGradeFinal = () => {
    const verify = /,/g;
    const verifyFinal = /,/g;
    let gradeInit = form.nota_inicial;
    let gradeFinal = form.nota_final;

    if (verify.test(form.nota_inicial)) {
      gradeInit = gradeInit.replace(",", ".");
    }

    if (verifyFinal.test(form.nota_final)) {
      gradeFinal = gradeFinal.replace(",", ".");
    }

    if (gradeInit !== "") {
      gradeInit = Number(gradeInit);
    }

    if (gradeFinal !== "") {
      gradeFinal = Number(gradeFinal);
    }

    if (form.nota_final === "") {
      setMensageError(intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_FINAL.VAZIO"));
      setShowMensageErrorNotaFinal(true);
      setShowErrorGradeMax(true);
      setIsValidGradeMax(false);
      form.validated = false;
    } else if (gradeFinal > form.maxGrade) {
      setMensageError(
        `${intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_FINAL.MAIOR_MAX")} ${
          form.maxGrade
        }`
      );
      setShowMensageErrorNotaFinal(true);
      setShowErrorGradeMax(true);
      setIsValidGradeMax(false);
      form.validated = false;
    } else if (gradeFinal < gradeInit) {
      setMensageError(
        intl.get("FASES.GRADE.MESSAGE_ERROR.GRADE_FINAL.MENOR_INIT")
      );
      setShowMensageErrorNotaFinal(true);
      setShowErrorGradeMax(true);
      setIsValidGradeMax(false);
      form.validated = false;
    } else {
      setIsValidGradeMax(true);
      setShowMensageErrorNotaFinal(false);
      setShowErrorGradeMax(false);
    }

    validateOperatorFinal();
    setValues((prevState) => ({
      ...prevState,
      form,
    }));
  };

  const clearMessageError = () => {
    setShowErrorGradeMax(false);
    setShowErrorGradeMin(false);
    setShowErrorGradeFinal(false);
    setShowErrorGradeInit(false);
    setShowMensageErrorOpe(false);
    setShowMensageErrorOpeFinal(false);
    setShowMensageErrorNotaInit(false);
    setShowMensageErrorNotaFinal(false);
  };

  useEffect(() => {
    if (
      isValidGradeMin &&
      isValidGradeMax &&
      isValidOpeInit &&
      isValidOpeFinal
    ) {
      form.validated = true;
      clearMessageError();
    } else {
      form.validated = false;
    }
    onUpdateFormGrade(form);
  }, [form]);

  const handleDeleteGrid = () => {
    onDeleteGrid(id);
  };

  useEffect(() => {
    if (form.isEdit) {
      validateAllForm();
    }
  }, []);

  const validateAllForm = () => {
    validateOperatorInit();
    validateOperatorFinal();
    validateGradeInit();
    validateGradeFinal();
  };

  return (
    <div className="vg-row grid-grade">
      <div className="vg-col-md-12 vg-col-xs-12 vg-form">
        <Link className="icon-trash" onClick={handleDeleteGrid}>
          <Icon iconKey="trash_gray" />
        </Link>
        <div className="vg-row grid-form">
          <div className="vg-col-md-12 vg-col-xs-12">
            <div className="div-reset-grade">
              <label className="label-grade">
                {intl.get("FASES.GRADE.CANDIDATOS")}
              </label>
              <div className="box-select-grade">
                <SelectGrade
                  name="operador_inicial"
                  onChangeSelect={updateSelectOperator}
                  data={dataSelect}
                  selected={form.operador_inicial}
                  showError={showErrorGradeInit}
                  handleValidateOnBlur={validateOperatorInit}
                />
              </div>
              <div className="box-input-grade">
                <InputGrade
                  name="nota_inicial"
                  value={form.nota_inicial}
                  onInputChange={updateInitGrade}
                  showError={showErrorGradeMin}
                  handleValidateOnBlur={validateGradeInit}
                />
              </div>

              <label className="label-grade">{intl.get("FASES.GRADE.E")}</label>
              <div className="box-select-grade">
                <SelectGrade
                  name="operador_final"
                  onChangeSelect={updateSelectOperatorFinal}
                  data={dataSelectLimite}
                  selected={form.operador_final}
                  showError={showErrorGradeFinal}
                  handleValidateOnBlur={validateOperatorFinal}
                />
              </div>
              <div className="box-input-grade">
                <InputGrade
                  name="nota_final"
                  value={form.nota_final}
                  onInputChange={updateInitGradeFinal}
                  showError={showErrorGradeMax}
                  handleValidateOnBlur={validateGradeFinal}
                />
              </div>
              <div className="box-phase-grade-limite">
                <label className="label-grade">
                  {intl.get("FASES.GRADE.MUDAR_FASE")}
                </label>
                <div className="box-select-grade">
                  {selectPhases.length > 0 && (
                    <SelectGrade
                      name="fase_id"
                      data={selectPhases}
                      onChangeSelect={updateSelectFase}
                      selected={form.fase_destino_id.toString()}
                      handleValidateOnBlur={validateOperatorFinal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showMensageErrorOpe ||
      showMensageErrorOpeFinal ||
      showMensageErrorNotaFinal ||
      showMensageErrorNotaInit ? (
        <div className="vg-col-md-12 vg-col-xs-12 box-error">
          {mensageError}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

BoxGradeLimite.defaultProps = {
  input: "",
  labelInput: "",
  onUpdateFormGrade: () => {},
  formGrid: {},
};

BoxGradeLimite.propTypes = {
  input: string,
  labelInput: string,
  labelSelect: string,
  id: string.isRequired,
  onDeleteGrid: func.isRequired,
  selectPhases: array.isRequired,
  dataSelect: array.isRequired,
  onUpdateFormGrade: func,
  dataSelectLimite: array.isRequired,
  formGrid: object,
};

export default BoxGradeLimite;
