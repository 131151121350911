import React, { useRef, useState, useContext } from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { Icon } from "../../../index";
import "./DragDrop.scss";
import DeleteModal from "../Modals/DeleteModal";
import { uploadPdf } from "./reducerPdfFile";
import { PdfContext } from "../Pdf";

const FilledInputDragDrop = (props) => {
  const { setPayload } = props;
  const inputRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { pdfFile, dispatchPdfFile } = useContext(PdfContext);

  const onChange = (e) => {
    e.preventDefault();
    let inputPdf = inputRef.current.files[0];
    uploadPdf(inputPdf, dispatchPdfFile);
  };

  const deleteFile = (e) => {
    e.preventDefault();
    setIsOpen(true);
  };

  const cancelChange = () => {
    setIsOpen(false);
  };

  function deleteTerms(e) {
    e.preventDefault();
    setIsOpen(false);
    dispatchPdfFile({ type: "deleteFile" });
    setPayload((oldState) => ({
      ...oldState,
      file: "",
      file_name: "",
    }));
  }

  return (
    <div>
      <div>
        <form className="pdf_input" method="post" action="#">
          <div className="input-change">
            <Icon iconKey="pdf" addClass="vg-icon-pdf" />
            {pdfFile.name}
            <div className="icon-input">
              <button className="icon-input-pdf" onClick={deleteFile}>
                <Icon iconKey="trashAlt" addClass="icon-modal" />
              </button>
              {pdfFile.url && (
                <a className="icon-input-pdf" target="blank" href={pdfFile.url}>
                  <Icon
                    iconKey="external_link_alt_solid"
                    addClass="icon-modal"
                  />
                </a>
              )}
            </div>
          </div>
          <label htmlFor="file-upload" className="change-pdf">
            <button
              className="false-input"
              onClick={(e) => {
                e.preventDefault();
                inputRef.current.click();
              }}
            >
              {intl.get("CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.CHANGE_BUTTON")}
            </button>
          </label>
          <input
            id="file-upload"
            className="input-pdf"
            ref={inputRef}
            type="file"
            onChange={onChange}
            accept="application/pdf"
          />
          <DeleteModal
            cancelChange={cancelChange}
            deleteTerms={deleteTerms}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </form>
        <div className="vg-text-info">
          <Icon iconKey="info" addClass="vg-img-info" />
          {intl.get("CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.TXT_INFO")}
        </div>
      </div>
    </div>
  );
};

FilledInputDragDrop.propTypes = {
  setPayload: PropTypes.func.isRequired,
};

export default FilledInputDragDrop;
