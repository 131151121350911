import React, { useContext, useRef } from "react";
import { AccordionContext } from "./accordion.context";
import PropTypes from "prop-types";

export function AccordionPanel({ children, shouldKeepMounted, ...rest }) {
  const localRef = useRef(null);
  const { activePanel } = useContext(AccordionContext);
  const isActive = localRef && localRef.current === activePanel;

  return (
    <div
      className="accordion-content"
      data-component="accordion-panel-container"
      ref={localRef}
      {...rest}
    >
      {shouldKeepMounted || isActive ? (
        <div
          role="region"
          style={
            shouldKeepMounted
              ? {
                  display: isActive ? "block" : "none",
                }
              : null
          }
        >
          {isActive ? children : null}
        </div>
      ) : null}
    </div>
  );
}

AccordionPanel.propTypes = {
  children: PropTypes.any,
  shouldKeepMounted: PropTypes.any,
};
