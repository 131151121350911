import React, { Component } from "react";
import PropTypes from "prop-types";
import { Transition } from "react-transition-group";
import { ModalContent } from "../../index";
import "./Modal.scss";

class Modal extends Component {
  render() {
    const { show } = this.props;
    return (
      <Transition in={show} timeout={300}>
        {(state) => <ModalContent state={state} {...this.props} />}
      </Transition>
    );
  }
}

Modal.propTypes = {
  show: PropTypes.bool,
};

export default Modal;
