import "./ContainerGrade.scss";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { func, array, number, bool, object } from "prop-types";
import { Checkbox, P, H3 } from "@vagastec/epartner-ui";
import { v4 as uuidv4 } from "uuid";
import {
  Icon,
  Link,
  BoxGrade,
  BoxGradeLimite,
  TooltipGrade,
  NotificationWarningBar,
} from "../../index";

import { connect } from "react-redux";

import Cookie from "js-cookie";
import intl from "react-intl-universal";

const mapStateToProps = (state) => ({
  accesses: state.Painel.painel.data,
});

const ContainerGrade = ({
  accesses,
  onUpdateFormGrid,
  maxGrade,
  idTest,
  fase_destino_por_nota,
  isMaxGrade,
  isGlobal,
  listTest,
  realPhase,
}) => {
  const [checkBoxGrade, setCheckBoxGrade] = useState(false);
  const [checkboxIntervaloGrade, setCheckboxIntervaloGrade] = useState(false);
  const [gridGrade, setGridGrade] = useState([]);
  const [gridGradeLimite, setGridGradeLimite] = useState([]);
  const [maxGradeToChildrens, setMaxGradeToChildrens] = useState([]);
  const [showMessageNoMaxGrade, setShowMessageNoMaxGrade] = useState(false);
  const [phases, setPhases] = useState([]);
  const firstUpdate = useRef(true);
  const onCheckBoxGrade = () => {
    setCheckBoxGrade(!checkBoxGrade);
  };
  const shouldAllowGradeOnCustomTestsFlag = accesses.feature_flags.includes(
    "acao_automatica_lista_testes_customizados"
  );

  useEffect(() => {
    if (checkBoxGrade && gridGrade.length == 0) {
      addGridGrade();
    }
  }, [checkBoxGrade]);

  const onCheckboxIntervaloGrade = () => {
    setCheckboxIntervaloGrade(!checkboxIntervaloGrade);
  };

  useEffect(() => {
    if (checkboxIntervaloGrade && gridGradeLimite.length == 0) {
      addGridGradeLimite();
    }
  }, [checkboxIntervaloGrade]);

  useEffect(() => {
    let form = [];
    const gridGradeFormat = setGridGradeFormat();
    const gridFormatLimit = formatGridLimit();
    if (checkBoxGrade && checkboxIntervaloGrade) {
      onUpdateFormGrid(form.concat(gridGradeFormat, gridFormatLimit));
    } else if (checkBoxGrade) {
      onUpdateFormGrid(gridGradeFormat);
    } else if (checkboxIntervaloGrade) {
      onUpdateFormGrid(gridFormatLimit);
    } else {
      onUpdateFormGrid(form);
    }
  }, [gridGrade, gridGradeLimite, checkBoxGrade, checkboxIntervaloGrade]);

  useEffect(() => {
    setMaxGradeToChildrens(maxGrade);
  }, [maxGrade]);

  useEffect(() => {
    if (!firstUpdate.current) {
      setGridGrade([]);
      setGridGradeLimite([]);
      setCheckBoxGrade(false);
      setCheckboxIntervaloGrade(false);
    }
  }, [idTest]);

  useEffect(() => {
    getAllFases();
    if (fase_destino_por_nota.length > 0) {
      const arrayLimit = addGridToEditLimit();
      const arrayLimitSimple = addGridToEditSimple();
      setGridGradeLimite(arrayLimit);
      setGridGrade(arrayLimitSimple);

      if (arrayLimit.length > 0) {
        setCheckboxIntervaloGrade(true);
      }
      if (arrayLimitSimple.length > 0) {
        setCheckBoxGrade(true);
      }
    }
    firstUpdate.current = false;
  }, [maxGrade]);

  const getDataSelectGrade = (gradeLimite = false, flag = "") => {
    const noselect = [{ id: "", nome: intl.get("FASES.GRADE.SELECIONE") }];
    const grade_maior = [
      { id: ">", nome: `> (${intl.get("FASES.GRADE.MAIOR")})` },
      {
        id: ">=",
        nome: `>= (${intl.get("FASES.GRADE.MAIOR")} ${intl.get(
          "FASES.GRADE.IGUAL"
        )})`,
      },
    ];
    const grade_menor = [
      { id: "<", nome: `< (${intl.get("FASES.GRADE.MENOR")})` },
      {
        id: "<=",
        nome: `<= (${intl.get("FASES.GRADE.MENOR")} ${intl.get(
          "FASES.GRADE.IGUAL"
        )})`,
      },
    ];

    if (gradeLimite) {
      if (flag === "corte") {
        return noselect.concat(grade_maior);
      }
      if (flag === "limite") {
        return noselect.concat(grade_menor);
      }
    }

    return noselect.concat(grade_maior, grade_menor);
  };

  const addGridGrade = () => {
    setGridGrade([
      ...gridGrade,
      {
        id: uuidv4(),
        operador_inicial: "",
        nota_inicial: "",
        operador_final: "",
        nota_final: maxGradeToChildrens,
        maxGrade: maxGradeToChildrens,
        fase_destino_id: getFirstPhase().id,
        tipo: "unico",
        validated: false,
        isEdit: false,
      },
    ]);
  };

  const addGridGradeLimite = () => {
    setGridGradeLimite([
      ...gridGradeLimite,
      {
        id: uuidv4(),
        operador_inicial: "",
        nota_inicial: "",
        operador_final: "",
        nota_final: "",
        fase_destino_id: getFirstPhase().id,
        tipo: "intervalo",
        validated: false,
        maxGrade: maxGradeToChildrens,
        isEdit: false,
      },
    ]);
  };

  const getFormGrade = (form) => {
    const isGrid = gridGrade.findIndex((grid) => grid.id === form.id);
    if (isGrid !== -1) {
      gridGrade[isGrid] = form;
      setGridGrade([...gridGrade]);
    }
  };

  const getFormGradeLimite = (form) => {
    const isGrid = gridGradeLimite.findIndex((grid) => grid.id === form.id);
    if (isGrid !== -1) {
      gridGradeLimite[isGrid] = form;
      setGridGradeLimite([...gridGradeLimite]);
    }
  };

  const deleteGridGrade = (id) => {
    const isGrid = gridGrade.findIndex((grid) => grid.id === id);
    if (isGrid !== -1) {
      gridGrade.splice(isGrid, 1);
      setGridGrade([...gridGrade]);
    }
  };

  const deleteGridGradeLimite = (id) => {
    const isGrid = gridGradeLimite.findIndex((grid) => grid.id === id);
    if (isGrid !== -1) {
      gridGradeLimite.splice(isGrid, 1);
      setGridGradeLimite([...gridGradeLimite]);
    }
  };

  const getGridGradeWithoutLimite = () => {
    return (
      gridGrade.length > 0 &&
      gridGrade.map((grid) => {
        return (
          <div className="vg-col-md-12 vg-col-xs-12" key={`grid-${grid.id}`}>
            <BoxGrade
              id={grid.id}
              dataSelect={getDataSelectGrade()}
              selectPhases={phases}
              onDeleteGrid={deleteGridGrade}
              onUpdateFormGrade={(e) => getFormGrade(e)}
              formGrid={grid}
            />
          </div>
        );
      })
    );
  };
  const getGridGradeWithLimite = () => {
    return (
      gridGradeLimite !== "undefined" &&
      gridGradeLimite.length > 0 &&
      gridGradeLimite.map((grid) => {
        return (
          <div className=" vg-col-md-12 vg-col-xs-12" key={`grid-${grid.id}`}>
            <BoxGradeLimite
              id={grid.id}
              dataSelect={getDataSelectGrade(true, "corte")}
              selectPhases={phases}
              onDeleteGrid={deleteGridGradeLimite}
              dataSelectLimite={getDataSelectGrade(true, "limite")}
              onUpdateFormGrade={(e) => getFormGradeLimite(e)}
              formGrid={grid}
            />
          </div>
        );
      })
    );
  };

  const setGridGradeFormat = () => {
    const griFormat = [];
    const grid = gridGrade;
    const verify = /,/g;

    grid.forEach((element) => {
      let newElement = {};
      if (
        element.operador_inicial === ">" ||
        element.operador_inicial === ">="
      ) {
        newElement = {
          ...newElement,
          nota_final: maxGrade,
          operador_final: "<=",
          nota_inicial: element.nota_inicial,
          operador_inicial: element.operador_inicial,
        };
      } else if (
        element.operador_inicial === "<" ||
        element.operador_inicial === "<="
      ) {
        newElement = {
          ...newElement,
          nota_final: element.nota_inicial,
          nota_inicial: 0,
          operador_inicial: ">=",
          operador_final: element.operador_inicial,
        };
      } else {
        newElement = {
          ...newElement,
          nota_inicial: element.nota_inicial,
          nota_final: maxGrade,
        };
      }

      newElement = {
        ...newElement,
        validated: element.validated,
        nota_maxima: maxGrade,
        maxGrade: maxGrade,
        fase_destino_id: Number(element.fase_destino_id),
        tipo: element.tipo,
      };

      if (newElement && verify.test(newElement.nota_inicial)) {
        newElement = {
          ...newElement,
          nota_inicial:
            newElement.nota_inicial !== ""
              ? newElement.nota_inicial.replace(",", ".")
              : null,
        };
      }

      if (newElement.nota_inicial !== "") {
        newElement.nota_inicial = parseFloat(newElement.nota_inicial).toFixed(
          2
        );
      }

      if (newElement && verify.test(newElement.nota_final)) {
        newElement = {
          ...newElement,
          nota_final:
            newElement.nota_final !== ""
              ? newElement.nota_final.replace(",", ".")
              : null,
        };
      }

      if (newElement.nota_final !== "") {
        newElement.nota_final = parseFloat(newElement.nota_final).toFixed(2);
      }

      griFormat.push(newElement);
    });

    return griFormat;
  };

  const formatGridLimit = () => {
    const griFormat = [];
    const grid = gridGradeLimite;
    const verify = /,/g;
    grid.forEach((element) => {
      let newElement = {
        nota_final: element.nota_final,
        nota_inicial: element.nota_inicial,
        operador_inicial: element.operador_inicial,
        operador_final: element.operador_final,
        validated: element.validated,
        nota_maxima: maxGrade,
        maxGrade: maxGrade,
        fase_destino_id: Number(element.fase_destino_id),
        tipo: element.tipo,
      };
      if (newElement && verify.test(newElement.nota_inicial)) {
        newElement = {
          ...newElement,
          nota_inicial:
            newElement.nota_inicial !== ""
              ? newElement.nota_inicial.replace(",", ".")
              : null,
        };
      }

      if (newElement && verify.test(newElement.nota_final)) {
        newElement = {
          ...newElement,
          nota_final:
            newElement.nota_final !== ""
              ? newElement.nota_final.replace(",", ".")
              : null,
        };
      }

      if (newElement.nota_inicial !== "") {
        newElement.nota_inicial = parseFloat(newElement.nota_inicial).toFixed(
          2
        );
      }

      if (newElement.nota_final !== "") {
        newElement.nota_final = parseFloat(newElement.nota_final).toFixed(2);
      }

      griFormat.push(newElement);
    });

    return griFormat;
  };

  const getFirstPhase = () => {
    let first = 0;
    if (phases.length > 0) first = phases[0];

    return first;
  };

  const addGridToEditLimit = () => {
    let arrayInterval = [];
    const arrayDestino = fase_destino_por_nota;
    const verify = /\./g;

    arrayDestino.forEach((grade) => {
      let gradeObject = {};
      if (grade.tipo === "intervalo") {
        gradeObject = {
          ...gradeObject,
          id: uuidv4(),
          maxGrade: maxGrade,
          validated: true,
          isEdit: true,
          tipo: grade.tipo,
          nota_inicial:
            grade.nota_inicial !== ""
              ? parseFloat(grade.nota_inicial).toFixed(2).toString()
              : grade.nota_inicial,
          nota_final:
            grade.nota_final !== ""
              ? parseFloat(grade.nota_final).toFixed(2).toString()
              : grade.nota_final,
          fase_destino_id: grade.fase_destino_id,
          operador_inicial: grade.operador_inicial,
          operador_final: grade.operador_final,
        };

        if (
          gradeObject &&
          gradeObject.nota_inicial &&
          verify.test(gradeObject.nota_inicial)
        ) {
          gradeObject = {
            ...gradeObject,
            nota_inicial:
              gradeObject.nota_inicial !== ""
                ? gradeObject.nota_inicial.replace(".", ",")
                : null,
          };
        }
        if (
          gradeObject &&
          gradeObject.nota_final &&
          verify.test(gradeObject.nota_final)
        ) {
          gradeObject = {
            ...gradeObject,
            nota_final:
              gradeObject.nota_final !== ""
                ? gradeObject.nota_final.replace(".", ",")
                : null,
          };
        }
        arrayInterval.push(gradeObject);
      }
    });

    return arrayInterval;
  };

  const addGridToEditSimple = () => {
    const verify = /\./g;
    let arraySimple = [];
    const arrayDestino = fase_destino_por_nota;

    arrayDestino.forEach((grade) => {
      let gradeObject = {};

      if (grade.tipo === "unico") {
        gradeObject = {
          id: uuidv4(),
          maxGrade: maxGrade,
          validated: true,
          isEdit: true,
          tipo: grade.tipo,
          fase_destino_id: grade.fase_destino_id,
        };

        if (Number(grade.nota_inicial) === 0) {
          gradeObject = {
            ...gradeObject,
            nota_inicial:
              grade.nota_final !== ""
                ? parseFloat(grade.nota_final).toFixed(2).toString()
                : grade.nota_final,
            nota_final: maxGrade,
            operador_inicial: grade.operador_final,
            operador_final: grade.operador_inicial,
          };
        }

        if (Number(grade.nota_inicial) > 0) {
          gradeObject = {
            ...gradeObject,
            nota_inicial:
              grade.nota_inicial !== ""
                ? parseFloat(grade.nota_inicial).toFixed(2).toString()
                : grade.nota_inicial,
            nota_final:
              grade.nota_final !== ""
                ? parseFloat(grade.nota_final).toFixed(2).toString()
                : grade.nota_final,

            operador_inicial: grade.operador_inicial,
            operador_final: grade.operador_final,
          };
        }

        if (
          gradeObject &&
          gradeObject.nota_inicial &&
          verify.test(gradeObject.nota_inicial)
        ) {
          gradeObject = {
            ...gradeObject,
            nota_inicial:
              gradeObject.nota_inicial !== ""
                ? gradeObject.nota_inicial.replace(".", ",")
                : null,
          };
        }
        if (
          gradeObject &&
          gradeObject.nota_final &&
          verify.test(gradeObject.nota_final)
        ) {
          gradeObject = {
            ...gradeObject,
            nota_final:
              gradeObject.nota_final !== ""
                ? gradeObject.nota_final.replace(".", ",")
                : null,
          };
        }
        arraySimple.push(gradeObject);
      }
    });
    return arraySimple;
  };

  useEffect(() => {
    if (listTest.length > 0) {
      setShowMessageNoMaxGrade(!isMaxGrade);
    }
  }, [listTest, isGlobal, isMaxGrade]);

  const getAllFases = () => {
    const LOCALES = {
      _enUS: "en",
      _ptBR: "pt-BR",
      _esALT: "es",
    };
    fetch(`${process.env.REACT_APP_ATHENA_URL}fases/acoes/fases-destino`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${Cookie.get("access_token")}`,
        "Accept-Language": `${LOCALES[Cookie.get("IntegracaoLoginIdioma")]}`,
        "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_CONTROL,
      },
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((data) => {
        const findPhase = data.findIndex((phase) => phase.id === realPhase);
        if (findPhase !== -1) {
          data.splice(findPhase, 1);
        }
        setPhases(data);
      });
  };

  return (
    <div className="container-grid-grade vg-col-md-10 vg-col-xs-12 vg-selects-divisons-tests">
      <div className="vg-col-md-12 vg-col-xs-12 ">
        <div className="div-container-tooltip-mobile">
          <H3 className="title-grade">{intl.get("FASES.GRADE.TITULO")}</H3>
          {isMaxGrade && (isGlobal || shouldAllowGradeOnCustomTestsFlag) && (
            <TooltipGrade
              position="bottom"
              text={intl.getHTML("FASES.GRADE.TEXTO_POPUP")}
              title={intl.getHTML("FASES.GRADE.TITULO_POPUP")}
            >
              <span className="vg-phase-total icon-more-info-range">
                <Link>
                  <Icon iconKey="rounded_error_orange" />
                </Link>
              </span>
            </TooltipGrade>
          )}
        </div>
        <div className="div-container-tooltip">
          <H3 className="title-grade">
            {intl.get("FASES.GRADE.TITULO")}
            {isMaxGrade && (isGlobal || shouldAllowGradeOnCustomTestsFlag) && (
              <TooltipGrade
                position="bottom"
                text={intl.getHTML("FASES.GRADE.TEXTO_POPUP")}
                title={intl.getHTML("FASES.GRADE.TITULO_POPUP")}
              >
                <span className="vg-phase-total ">
                  <Link>
                    <Icon iconKey="rounded_error_orange" />
                  </Link>
                </span>
              </TooltipGrade>
            )}
          </H3>
        </div>
        {!isGlobal && !shouldAllowGradeOnCustomTestsFlag && (
          <NotificationWarningBar
            text={intl.get("FASES.GRADE.MESSAGE_GLOBAL_TEST")}
          />
        )}
        {showMessageNoMaxGrade && (
          <NotificationWarningBar
            text={intl.get("FASES.GRADE.MESSAGE_NO_GRADE_MAX")}
          />
        )}
        {isMaxGrade && (isGlobal || shouldAllowGradeOnCustomTestsFlag) && (
          <P className="subtitle-grade">{intl.get("FASES.GRADE.SUBTITULO")}</P>
        )}
      </div>
      {isMaxGrade && (isGlobal || shouldAllowGradeOnCustomTestsFlag) && (
        <Fragment>
          <div className="vg-col-md-12 vg-col-xs-12 grid-grade-children">
            <div className="vg-row">
              <div className="vg-col-md-6 vg-col-xs-12">
                <div className="div-check-box-grade">
                  <Checkbox
                    name="check1"
                    checked={checkBoxGrade}
                    toggle={onCheckBoxGrade}
                    label={intl.get("FASES.GRADE.CHECKBOOK_UNICA")}
                    focus={checkBoxGrade.toString()}
                  />
                </div>
              </div>
              {checkBoxGrade ? (
                <Fragment>
                  <div className="vg-col-md-12 vg-col-xs-12">
                    <div className="vg-row">{getGridGradeWithoutLimite()}</div>
                  </div>
                  <div className="vg-col-md-12 vg-col-xs-12 grid-add-grid">
                    <Link onClick={() => addGridGrade()}>
                      <div className="label-container-grade">
                        {intl.get("FASES.GRADE.ADD")}
                      </div>
                      <Icon iconKey="rounded_plus_orange" />
                    </Link>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="vg-col-md-12 vg-col-xs-12 grid-grade-children">
            <div className="vg-row">
              <div className="vg-col-md-4 vg-col-xs-12">
                <div className="div-check-box-grade">
                  <Checkbox
                    name="check2"
                    checked={checkboxIntervaloGrade}
                    toggle={onCheckboxIntervaloGrade}
                    label={intl.get("FASES.GRADE.CHECKBOOK_INTERVALO")}
                    focus={checkboxIntervaloGrade.toString()}
                  />
                </div>
              </div>
              {checkboxIntervaloGrade ? (
                <Fragment>
                  <div className="vg-col-md-12 vg-col-xs-12">
                    <div className="vg-row">{getGridGradeWithLimite()}</div>
                  </div>
                  <div className="vg-col-md-12 vg-col-xs-12 grid-add-grid">
                    <Link onClick={() => addGridGradeLimite()}>
                      <div className="label-container-grade">
                        {intl.get("FASES.GRADE.ADD")}
                      </div>
                      <Icon iconKey="rounded_plus_orange" />
                    </Link>
                  </div>
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </Fragment>
      )}
    </div>
  );
};

ContainerGrade.defaultProps = {
  accesses: {
    funcionario: {},
    cliente: {},
    acessos: {},
    feature_flags: [],
  },
  fase_destino_por_nota: [],
  idTest: 0,
  isMaxGrade: false,
  isGlobal: true,
  listTest: [],
  realPhase: 0,
};

ContainerGrade.propTypes = {
  accesses: object,
  maxGrade: number.isRequired,
  onUpdateFormGrid: func.isRequired,
  fase_destino_por_nota: array,
  idTest: number,
  isMaxGrade: bool,
  isGlobal: bool,
  listTest: array,
  realPhase: number,
};

export default connect(mapStateToProps, null)(ContainerGrade);
