// Action Types
const LOAD_CANDIDATES = "services/LOAD_CANDIDATES";
const LOAD_CANDIDATES_SUCCESS = "services/LOAD_CANDIDATES_SUCCESS";
const LOAD_CANDIDATES_FAIL = "services/LOAD_CANDIDATES_FAIL";

// InitialState
const initialState = {
  loaded: false,
  data: {
    candidatos: [],
  },
};

// Reducer
export default function candidates(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CANDIDATES:
      return {
        ...state,
        loading: true,
      };
    case LOAD_CANDIDATES_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_CANDIDATES_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [LOAD_CANDIDATES, LOAD_CANDIDATES_SUCCESS, LOAD_CANDIDATES_FAIL],
    promise: (client) => client.get(path),
  };
}
