// Action Types
const MODAL_OPEN = "triagem/MODAL_OPEN";
const CLOSE_MODAL = "triagem/CLOSE_MODAL";
const POST_SORTING = "services/POST_SORTING";
const POST_SORTING_SUCCESS = "services/POST_SORTING_SUCCESS";
const POST_SORTING_FAIL = "services/POST_SORTING_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  modal: false,
  data: {},
  id: "",
};

// Reducer
export default function SelectSorting(state = initialState, action = {}) {
  switch (action.type) {
    case POST_SORTING:
      return {
        ...state,
        loading: true,
      };
    case POST_SORTING_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { post: true, ...action.result },
      };
    case POST_SORTING_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case MODAL_OPEN:
      return {
        ...state,
        modal: !state.modal,
        id: action.id,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: false,
      };
    default:
      return state;
  }
}

export function post(data) {
  return {
    types: [POST_SORTING, POST_SORTING_SUCCESS, POST_SORTING_FAIL],
    promise: (client) => client.post("painel/configuracao-usuario", { data }),
  };
}

export function toggleModalTriagem(el, id) {
  !!el && el.preventDefault();
  return {
    type: MODAL_OPEN,
    id: id,
  };
}

export function closeModalTriagem() {
  return {
    type: CLOSE_MODAL,
  };
}
