import React, { Component } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { deleteUser, load, receive } from "../../../store/modules/UsersRede";
import { Icon } from "../../index";
import { intlReplace } from "../../../utils/IntlReplace";

import "./UsersRedesRemove.scss";

const mapStateToProps = (state) => ({
  UsersRedesList: state.UsersRede.data.funcionarios,
});

class UsersRedesRemove extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastAdmin: false,
    };

    this.handleClick = this.handleClick.bind(this);
    this.lastAdminVerify = this.lastAdminVerify.bind(this);
  }

  showNotifications(message, success) {
    const classToast = success ? "vg-toast-success" : "vg-toast-error";
    const classIconToast = success ? "toastSuccess" : "toastError";
    const Msg = () => (
      <div className="vg-toast">
        <div className="vg-toast-icon">
          <Icon iconKey={classIconToast} />
        </div>
        <div className="vg-toast-content">{message}</div>
      </div>
    );
    toast(Msg, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast ${classToast}`,
    });
  }

  componentDidMount() {
    this.setState({
      lastAdmin: this.lastAdminVerify(),
    });

    const { receive, redeId } = this.props;
    receive(redeId);
  }

  lastAdminVerify() {
    const { funcionarioID } = this.props;
    if (this.props.UsersRedesList.length > 0) {
      const funcionariosUnid = this.props.UsersRedesList;
      let countLastAdm = 0;
      let funcDelProfile = false;
      funcionariosUnid.forEach((func) => {
        if (func.perfil == "administrador") {
          countLastAdm++;
        }
        if (func.id == funcionarioID) {
          funcDelProfile = func.perfil == "administrador";
        }
      });
      return countLastAdm == 1 && funcDelProfile;
    }
  }

  handleClick(funcionarioID) {
    const { deleteUser, load, redeId } = this.props;
    this.props.onClose();
    deleteUser(parseInt(funcionarioID))
      .then(() => {
        this.showNotifications(
          intl.getHTML("UNIDADES_REDES.MODAL_EXCLUIR.MSG_SUCCESS"),
          true,
        );
      })
      .catch((error) => {
        this.showNotifications(
          intl.getHTML(
            `UNIDADES_REDES.MODAL_EXCLUIR.TEXTO_EXCLUIR_${intlReplace(
              error.response.body.error,
            )}`,
          ),
        );
        load(redeId);
      });
  }

  render() {
    const { funcionarioID, onClose } = this.props;
    const { lastAdmin } = this.state;

    return (
      <div className="vg-modal-body-removeUR">
        <p className="vg-modal-body-text vg-modal-body-removeUR-text">
          {!lastAdmin &&
            intl.getHTML("UNIDADES_REDES.MODAL_EXCLUIR.TEXTO_EXCLUIR")}
          {lastAdmin &&
            intl.getHTML(
              "UNIDADES_REDES.MODAL_EXCLUIR.TEXTO_EXCLUIR_LAST_ADMIN",
            )}
        </p>
        <div className="vg-wrap-buttons">
          <div className="vg-row between-xs">
            <div className="vg-col-md-2 vg-col-xs-12 first-md" />
            <div className="vg-col-md-10 vg-col-xs-12 first-xs">
              <a className="vg-btn-outline-gray" onClick={onClose}>
                {intl.get("UNIDADES_REDES.MODAL_EXCLUIR.BOTAO_CANCELAR")}
              </a>
              {!lastAdmin && (
                <a
                  onClick={() => this.handleClick(funcionarioID)}
                  className="vg-btn-danger"
                >
                  {intl.get("UNIDADES_REDES.MODAL_EXCLUIR.BOTAO_EXCLUIR")}
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UsersRedesRemove.defaultProps = {
  UsersRedesList: [],
};

UsersRedesRemove.propTypes = {
  onClose: propTypes.func.isRequired,
  funcionarioID: propTypes.number,
  deleteUser: propTypes.func.isRequired,
  redeId: propTypes.number.isRequired,
  load: propTypes.func,
  receive: propTypes.func.isRequired,
  UsersRedesList: propTypes.array.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ deleteUser, load, receive }, dispatch),
)(UsersRedesRemove);
