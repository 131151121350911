import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Tooltip.scss";

class Tooltip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  showTooltipDelayed = () => {
    if (this.intv) clearInterval(this.intv);
    this.setState({ show: true });
  };

  showTooltip = () => {
    if (this.intv) clearInterval(this.intv);
    this.intv = setTimeout(this.showTooltipDelayed, 600);
  };

  hideTooltip = () => {
    if (this.intv) clearInterval(this.intv);
    this.setState({ show: false });
  };

  render() {
    const { show } = this.state;
    const { text, position, children } = this.props;
    return (
      <span
        className="tooltip"
        onMouseOver={this.showTooltip}
        onMouseLeave={this.hideTooltip}
      >
        {show && text && (
          <span className={`tooltip-message on-${position ? position : "top"}`}>
            {text}
          </span>
        )}
        {children}
      </span>
    );
  }
}

Tooltip.propTypes = {
  text: PropTypes.node.isRequired,
  position: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Tooltip;
