import React, { Component } from "react";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { map } from "lodash"; // eslint-disable-line no-unused-vars
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Reports";
import "./QuickStats.scss";

const mapStateToProps = (state) => ({
  reports: state.Reports.data,
});

class QuickStats extends Component {
  componentDidMount() {
    const { receive } = this.props;
    receive();
  }

  render() {
    const { reports } = this.props;
    return (
      <div className="vg-quick-stats">
        <div className="vg-row">
          <div className="vg-col-xs-12 vg-col-md vg-stats-content">
            <p>
              {intl.getHTML("QUICK_STATS.STATS_1", {
                value:
                  reports.total_candidaturas === undefined
                    ? 0
                    : reports.total_candidaturas,
              })}
            </p>
          </div>
          <div className="vg-col-xs-12 vg-col-md vg-stats-content">
            <p>
              {intl.getHTML("QUICK_STATS.STATS_2", {
                value:
                  reports.total_processos_por_status
                    .em_andamento_inscricao_encerrada === undefined
                    ? 0
                    : reports.total_processos_por_status
                        .em_andamento_inscricao_encerrada,
              })}
            </p>
          </div>
          <div className="vg-col-xs-12 vg-col-md vg-stats-content">
            <p>
              {intl.getHTML("QUICK_STATS.STATS_3", {
                value:
                  reports.total_processos_por_status.veiculacao_suspensa ===
                  undefined
                    ? 0
                    : reports.total_processos_por_status.veiculacao_suspensa,
              })}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

QuickStats.propTypes = {
  reports: propTypes.object.isRequired,
  receive: propTypes.func.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(QuickStats);
