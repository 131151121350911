import React, { Component } from "react";
import propTypes from "prop-types";
import { Loader, Input, UsersRedesListItem, Icon, Toast } from "../../index";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import "./UsersRedesList.scss";
import {
  receive as receiveUnidResponsable,
  updateUnidResponsable,
} from "../../../store/modules/UnidResponsable";

class UsersRedesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editEmail: false,
      emailCagc: "",
      emailMessageError: "",
      shouldHaveEmailCAGC: false,
    };

    this.handleEditEmail = this.handleEditEmail.bind(this);
    this.handleEditEmailCancel = this.handleEditEmailCancel.bind(this);
    this.handleEditEmailSave = this.handleEditEmailSave.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.verifyEmailCAGC = this.verifyEmailCAGC.bind(this);
  }

  componentDidMount() {
    const { receiveUnidResponsable, rede } = this.props;
    receiveUnidResponsable(rede.id, rede.identidade);

    if (
      this.props.PanelFeatureFlags.indexOf("redes_criar_usuario_email_CAGC") !=
      -1
    ) {
      this.setState({
        shouldHaveEmailCAGC: true,
      });
    }
  }

  componentDidUpdate(prevProps) {
    const { receiveUnidResponsable, rede, UnidResponsable } = this.props;
    if (prevProps.rede.id != rede.id) {
      receiveUnidResponsable(rede.id, rede.identidade);
      this.setState({
        editEmail: false,
      });
    }
    if (prevProps.UnidResponsable.id != UnidResponsable.id) {
      this.setState({
        emailCagc: UnidResponsable.email,
      });
    }
  }

  handleEditEmail() {
    this.setState({
      editEmail: true,
    });
  }

  handleEditEmailCancel() {
    const { UnidResponsable } = this.props;
    this.setState({
      editEmail: false,
      emailCagc: UnidResponsable.email,
      emailMessageError: "",
    });
  }

  handleEditEmailSave() {
    const { updateUnidResponsable, rede } = this.props;
    const { emailCagc } = this.state;

    if (this.verifyEmailCAGC(emailCagc)) {
      const data = {
        identidade: rede.identidade,
        email: emailCagc,
      };

      updateUnidResponsable(rede.id, data)
        .then(() => {
          this.showNotifications(
            intl.getHTML("UNIDADES_REDES.LISTAGEM.SUCESSO"),
            "success",
          );
        })
        .catch(() => {
          this.showNotifications(
            intl.getHTML("UNIDADES_REDES.LISTAGEM.ERRO"),
            "error",
          );
        });

      this.setState({
        editEmail: false,
      });
    }
  }

  handleEmail(e) {
    let emailValue = e.target.value;
    this.setState({
      emailCagc: emailValue,
    });
    this.verifyEmailCAGC(emailValue);
  }

  verifyEmailCAGC(email) {
    let emailValid = false;
    if (email.length > 5 && !/\S+@\S+\.\S+/.test(email)) {
      this.setState({
        emailMessageError: intl.getHTML("UNIDADES_REDES.LISTAGEM.ERRO_EMAIL"),
      });
    } else {
      emailValid = true;
      this.setState({
        emailMessageError: "",
      });
    }
    return emailValid;
  }

  showNotifications(message, type = "success") {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  }

  render() {
    const { indexSelected, rede } = this.props; // eslint-disable-line no-shadow
    const { editEmail, emailCagc, emailMessageError, shouldHaveEmailCAGC } =
      this.state;

    return Object.keys(rede).length > 0 ? (
      <div className="vg-list-wrap">
        <div className="vg-container-list">
          <div className="vg-content-box vg-content-email">
            <p className="vg-container-list-title">
              {rede.identidade}
              <span>
                {rede.endereco.logradouro} {rede.endereco.numero},{" "}
                {rede.endereco.cidade.nome}/{rede.endereco.estado.nome}
              </span>
              {shouldHaveEmailCAGC && !editEmail && (
                <React.Fragment>
                  <div
                    className={`vg-text-email vg-display-${!!emailCagc}`}
                    onClick={this.handleEditEmail}
                  >
                    <Icon iconKey={"envelope"} />
                    {intl.get("UNIDADES_REDES.LISTAGEM.MATRIZ")}
                    <span>
                      {" "}
                      {emailCagc} <Icon iconKey={"edit"} />
                    </span>
                  </div>
                  <div
                    className={`vg-text-email vg-email-empty vg-display-${!emailCagc}`}
                    onClick={this.handleEditEmail}
                  >
                    <Icon iconKey={"envelope"} />
                    {intl.get("UNIDADES_REDES.LISTAGEM.ADD_EMAIL")}
                  </div>
                </React.Fragment>
              )}
              {shouldHaveEmailCAGC && editEmail && (
                <React.Fragment>
                  <div className="vg-text-email">
                    <Icon iconKey={"envelope"} />
                    {intl.get("UNIDADES_REDES.LISTAGEM.MATRIZ")}
                    <span>{intl.get("UNIDADES_REDES.LISTAGEM.DIGITE")}</span>
                  </div>
                  <div className="vg-box-input-email">
                    <Input
                      inputType="email"
                      onChangeInput={this.handleEmail}
                      valueInput={emailCagc}
                      message={emailMessageError}
                    />
                    <a
                      className="vg-btn-outline-gray"
                      onClick={this.handleEditEmailCancel}
                    >
                      {intl.get("UNIDADES_REDES.LISTAGEM.CANCELAR")}
                    </a>
                    <a
                      className="vg-btn-success"
                      onClick={this.handleEditEmailSave}
                    >
                      {intl.get("UNIDADES_REDES.LISTAGEM.SALVAR")}
                    </a>
                  </div>
                </React.Fragment>
              )}
            </p>
          </div>
          <UsersRedesListItem rede={rede} indexSelected={indexSelected} />
        </div>
      </div>
    ) : (
      <Loader />
    );
  }
}

UsersRedesList.defaultProps = {
  PanelFeatureFlags: [],
  UnidResponsable: { email: "", id: "", nome: "" },
};

UsersRedesList.propTypes = {
  rede: propTypes.object.isRequired,
  indexSelected: propTypes.number.isRequired,
  receiveUnidResponsable: propTypes.func.isRequired,
  updateUnidResponsable: propTypes.func.isRequired,
  UnidResponsable: propTypes.object.isRequired,
  PanelFeatureFlags: propTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  PanelFeatureFlags:
    state.Painel.painel.data && state.Painel.painel.data.feature_flags,
  UnidResponsable: state.UnidResponsable.data,
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    { receiveUnidResponsable, updateUnidResponsable },
    dispatch,
  ),
)(UsersRedesList);
