// Action Types
const LOAD_GROUPSVIDEOS = "services/LOAD_GROUPSVIDEOS";
const LOAD_GROUPSVIDEOS_SUCCESS = "services/LOAD_GROUPSVIDEOS_SUCCESS";
const LOAD_GROUPSVIDEOS_FAIL = "services/LOAD_GROUPSVIDEOS_FAIL";
const CLEAN = "services/CLEAN";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  data: {},
};

// Reducer
export default function GROUPSVIDEOS(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_GROUPSVIDEOS:
      return {
        ...state,
        loading: true,
      };
    case LOAD_GROUPSVIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_GROUPSVIDEOS_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case CLEAN:
      return {
        ...state,
      };
    default:
      return state;
  }
}

export function load(processo_id) {
  return {
    types: [
      LOAD_GROUPSVIDEOS,
      LOAD_GROUPSVIDEOS_SUCCESS,
      LOAD_GROUPSVIDEOS_FAIL,
    ],
    promise: (client) =>
      client.get(
        `video-entrevistas/grupos?processo_seletivo_id=${processo_id}&remover_perguntas=true`
      ),
  };
}

function shouldFetch(state) {
  return state.GROUPSVIDEOS.loading
    ? false
    : Object.keys(state.GROUPSVIDEOS.data).length
    ? false
    : true;
}

export function receive(processo_id) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(processo_id));
    }
  };
}

export const clean = () => ({
  type: CLEAN,
});
