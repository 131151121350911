import PropTypes from "prop-types";
import React, { Component } from "react";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Divisions";
import { map } from "lodash";
import "./SelectRi.scss";

const mapStateToProps = (state) => ({
  listDivisions: state.Divisions.recrutamento_interno,
  loaded: state.Divisions.loaded,
  loading: state.Divisions.loading,
});

class SelectRi extends Component {
  constructor(props) {
    super(props);
    this.state = { value: 0 };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.getId(event.target.value);
  }

  componentDidMount() {
    const { receive, path, listDivisions, getId } = this.props;
    receive(path);

    listDivisions.divisoes.length === 1 && getId(listDivisions.divisoes[0].id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { getId } = this.props;

    if (nextProps.listDivisions.divisoes.length === 1) {
      getId(nextProps.listDivisions.divisoes[0].id);
    }
  }

  render() {
    const { listDivisions } = this.props;
    return listDivisions.divisoes.length > 1 ? (
      <div className="vg-custom-select">
        <select value={this.state.value} onChange={this.handleChange}>
          <option value="0">
            {intl.get(
              "PROFISSIONAIS.CONTEUDO_BOXES.colaboradores.LABEL_SELECT"
            )}
          </option>
          {map(listDivisions.divisoes, (item) => {
            return (
              <option key={item.id} value={item.id}>
                {item.nome}
              </option>
            );
          })}
        </select>
      </div>
    ) : null;
  }
}

SelectRi.defaultProps = {
  listDivisions: {
    divisoes: [],
  },
};

SelectRi.propTypes = {
  onChangeSelect: PropTypes.func,
  receive: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
  listDivisions: PropTypes.object.isRequired,
  getId: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive }, dispatch)
)(SelectRi);
