/* eslint-disable */
import { Link, Select } from "../../index";
import React, { Component } from "react";

import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import propTypes from "prop-types";

class TabPanelItem extends Component {
  constructor(props) {
    super(props);
    const { currentLine } = this.props;
    const redes = ["ambiente-redes", "acesso-empresa-redes"];
    const recrutamentoInterno = [
      "triagem-membros-comunidades",
      "acesso-membros-comunidades",
    ];

    this.state = {
      selectValue: false,
      selectPath:
        redes.indexOf(currentLine) >= 0
          ? "/divisoes?tipo_divisao=redes"
          : recrutamentoInterno.indexOf(currentLine) >= 0
          ? "/divisoes?tipo_divisao=recrutamento_interno"
          : false,

      // Lista de links que serão internos
      nonMoveTo: {
        "configuracao-sla": "/configuracoes/sla",
        "fases-do-processo": "/configuracoes/editar-fases",
        "gestao-usuarios-redes": "/configuracoes/redes",
        "termo-aceite": "/configuracoes/termo-aceite",
      },
    };

    this.onChangeSelect = this.onChangeSelect.bind(this);
  }

  onChangeSelect(value) {
    this.setState({
      selectValue: parseInt(value),
    });
  }

  render() {
    const { currentLine } = this.props;
    const { selectValue, selectPath, nonMoveTo } = this.state;
    const noMoveToLinks = nonMoveTo.hasOwnProperty(currentLine);
    const linkToShow = selectPath
      ? selectValue
        ? `${currentLine}?codigo_da_divisao=${selectValue}`
        : false
      : currentLine;

    return (
      <div className="vg-content-item clearfix">
        <p className="vg-title-content">
          {intl.get(
            `CONFIGURACOES.CONTEUDO_ABAS.${intlReplace(currentLine)}.title`
          )}
        </p>
        {selectPath && (
          <Select
            itemSelect={currentLine}
            path={selectPath}
            onChangeSelect={this.onChangeSelect}
          />
        )}
        {noMoveToLinks ? (
          <Link className="vg-btn-link" to={nonMoveTo[currentLine]}>
            {intl.get(
              `CONFIGURACOES.CONTEUDO_ABAS.${intlReplace(currentLine)}.button`
            )}
          </Link>
        ) : (
          <Link id={linkToShow} className="vg-btn-link" moveTo={linkToShow}>
            {intl.get(
              `CONFIGURACOES.CONTEUDO_ABAS.${intlReplace(currentLine)}.button`
            )}
          </Link>
        )}
      </div>
    );
  }
}

export default TabPanelItem;

TabPanelItem.propTypes = {
  currentLine: propTypes.string,
};
