import propTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import intl from "react-intl-universal";
import { connect } from "react-redux";
import { load } from "../../../store/modules/CaptacaoAberta";
import { map } from "lodash";
import { Link, Icon } from "../../index";
import "./CaptacaoAberta.scss";

const mapStateToProps = (state) => ({
  listaCaptacao: state.CaptacaoAberta.data.captacao_aberta,
  loaded: state.CaptacaoAberta.loaded,
  loading: state.CaptacaoAberta.loading,
});

class CaptacaoAberta extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    const { load, path } = this.props;
    load(path);
  }

  render() {
    const { listaCaptacao, link } = this.props;
    return listaCaptacao.length < 1 ? null : (
      <div className="vg-ct-captacao-aberta">
        <h1 className="vg-title-1 vg-title-vacancies">
          {intl.get("PROFISSIONAIS.CAPTACAO_ABERTA.TITULO_CAPTACAO")}
          <p className="vg-sub-tit">
            {intl.get("PROFISSIONAIS.CAPTACAO_ABERTA.SUB_TITULO_CAPTACAO")}
          </p>
        </h1>
        {map(listaCaptacao, (item) => {
          const servicoRuby2Asp =
            item.status == "aguardando_ativacao_homepage"
              ? "ativar-homepages"
              : "configurar-captacao-aberta";
          return (
            <div key={item.id} className="vg-item-captacao-aberta">
              <div className="vg-row middle-md middle-sm">
                <div className="vg-col-md-5 vg-col-sm-5 vg-col-xs-12">
                  <p className="vg-division-name">
                    <span
                      className={`vg-status-captacao vg-status-${item.status}`}
                    />
                    {item.divisao}
                  </p>
                  <Link
                    className="vg-btn-link"
                    moveTo={servicoRuby2Asp}
                    params={{ codigo_da_divisao: item.divisao_id }}
                  >
                    {intl.get(`PROFISSIONAIS.CAPTACAO_ABERTA.${item.status}`)}
                  </Link>
                </div>
                <div className="vg-col-md-2 vg-col-sm-2 vg-col-xs-12 vg-total-candidatos">
                  <p className="vg-total vg-dados-captacao">
                    <Icon iconKey="candidate" aria-hidden />
                    <span>
                      {intl.getHTML(
                        "PROFISSIONAIS.CAPTACAO_ABERTA.QUANTIDADE",
                        { quantidade: item.total_candidatos }
                      )}
                    </span>
                  </p>
                </div>
                <div className="vg-col-md-5 vg-col-sm-5 vg-col-xs-12 vg-ct-links">
                  {link.triagem.length > 1 && (
                    <Link
                      className="vg-btn-link"
                      moveTo={"triagem-1-0-captacao-aberta"}
                      params={{ codigo_da_vaga: item.id }}
                    >
                      {intl.get(
                        "PROFISSIONAIS.CAPTACAO_ABERTA.botao_triagem_1"
                      )}
                    </Link>
                  )}
                  <Link
                    className="vg-btn-outline"
                    moveTo={"triagem-captacao-aberta"}
                    params={{ codigo_da_vaga: item.id }}
                  >
                    {intl.get("PROFISSIONAIS.CAPTACAO_ABERTA.botao_triagem")}
                  </Link>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

CaptacaoAberta.defaultProps = {
  link: {},
};

CaptacaoAberta.propTypes = {
  load: propTypes.func.isRequired,
  path: propTypes.string.isRequired,
  listaCaptacao: propTypes.array,
  link: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ load }, dispatch)
)(CaptacaoAberta);
