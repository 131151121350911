// Action Types
const GRAPH = "services/GRAPH";
const GRAPH_SUCCESS = "services/GRAPH_SUCCESS";
const GRAPH_FAIL = "services/GRAPH_FAIL";

// InitialState
const initialState = {
  sla: {
    loading: false,
    loaded: false,
    data: { serie: [] },
  },
  capacidade_do_bce: {
    loading: false,
    loaded: false,
    data: { serie: [] },
  },
  candidatos_novos_x_mes: {
    loading: false,
    loaded: false,
    data: { serie: [] },
  },
};

// Reducers
export default function graphs(state = initialState, action = {}) {
  switch (action.type) {
    case GRAPH:
      return {
        ...state,
        [action.receiveType]: {
          ...state[action.receiveType],
          loading: true,
        },
      };
    case GRAPH_SUCCESS:
      return {
        ...state,
        [action.receiveType]: {
          ...state[action.receiveType],
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case GRAPH_FAIL:
      return {
        ...state,
        [action.receiveType]: {
          ...state[action.receiveType],
          loading: false,
          loaded: false,
          error: action.error.status,
        },
      };
    default:
      return state;
  }
}

// Actions
export function load(type) {
  return {
    types: [GRAPH, GRAPH_SUCCESS, GRAPH_FAIL],
    promise: (client) => client.get(`graficos/${type}`),
    receiveType: type,
  };
}

function shouldFetch(state, type) {
  return state.Graphs[type].loading
    ? false
    : state.Graphs[type].data.serie.length
    ? false
    : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(type) {
  return (dispatch, getState) => {
    if (shouldFetch(getState(), type)) {
      return dispatch(load(type));
    }
  };
}
