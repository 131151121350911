import "../Actions.scss";
import { ActionDivisions, Button, Link, TokenField } from "../../../index";
import React, { Component } from "react";
import {
  post,
  receive as receiveActions,
  update,
} from "../../../../store/modules/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import { map } from "lodash";
import propTypes from "prop-types";
import { receive } from "../../../../store/modules/Divisions";
import {
  automaticActionDatalayer,
  virtualPageviewDatalayer,
} from "../../../../utils/dataLayerPush";

const mapStateToProps = (state) => ({
  divisions: state.Divisions.sla,
  accesses: state.Painel.painel.data,
});

const KeyCodes = {
  comma: 188,
  enter: 13,
};
const delimiters = [KeyCodes.enter, KeyCodes.comma, KeyCodes.space];

export class ActionTags extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      currentAction: {},
      actionForm: {
        conteudo: {
          carimbos: [],
        },
        divisao_id: 0,
        tipo_acao_id: 2,
        tipo_acao: "carimbar_candidato",
        ativo: true,
      },
      isOpen: false,
      tags: [],
      nomeDivisao: "todas-as-divisoes",
    };

    this.handleActionForm = this.handleActionForm.bind(this);
    this.createAction = this.createAction.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.getTag = this.getTag.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  componentDidMount() {
    // Carrega as divisoes
    this.props.receive("divisoes", { incluir_sla: true });

    virtualPageviewDatalayer(
      "/configuracoes/editar-fases/acao-automatica/carimbo",
      "marcar candidato com carimbo"
    );
  }

  handleActionForm(e, type) {
    const targetContent = e.target.value;
    if (type === "divisao_id") {
      const textContent = e.target.options[e.target.selectedIndex].text;

      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          [type]: targetContent,
        },
        nomeDivisao: textContent,
      }));
    } else {
      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          conteudo: {
            ...prevState.actionForm.conteudo,
            [type]: targetContent,
          },
        },
      }));
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.divisions.divisoes && props.divisions.divisoes.length === 1) {
      if (
        Object.keys(props.currentAction).length &&
        !state.isEditing &&
        props.isOpen
      ) {
        return {
          isEditing: true,
          isOpen: props.isOpen,
          tags:
            props.currentAction.tipo_acao.id === 2
              ? map(props.currentAction.conteudo.carimbos, (item, index) => ({
                  id: `tag-${index}`,
                  text: item,
                }))
              : [],
          actionForm: {
            conteudo: { ...props.currentAction.conteudo },
            divisao_id: props.currentAction.divisao
              ? props.currentAction.divisao.id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao.id,
            tipo_acao: props.currentAction.tipo_acao.tipo,
            ativo: props.currentAction.ativo,
          },
        };
      } else {
        return {
          actionForm: {
            ...state.actionForm,
            divisao_id: props.divisions.divisoes
              ? props.divisions.divisoes[0].id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao
              ? props.currentAction.tipo_acao.id
              : props.idStep,
          },
        };
      }
    }

    if (
      Object.keys(props.currentAction).length &&
      !state.isEditing &&
      props.isOpen
    ) {
      return {
        isEditing: true,
        isOpen: props.isOpen,
        tags:
          props.currentAction.tipo_acao.id === 2
            ? map(props.currentAction.conteudo.carimbos, (item, index) => ({
                id: `tag-${index}`,
                text: item,
              }))
            : [],
        actionForm: {
          conteudo: { ...props.currentAction.conteudo },
          divisao_id: props.currentAction.divisao
            ? props.currentAction.divisao.id
            : 0,
          tipo_acao_id: props.currentAction.tipo_acao.id,
          tipo_acao: props.currentAction.tipo_acao.tipo,
          ativo: props.currentAction.ativo,
        },
      };
    }

    if (props.isOpen !== state.isOpen) {
      return {
        isEditing: false,
        isOpen: props.isOpen,
        selectMessage: 0,
        actionForm: {
          conteudo: {
            carimbos: [],
          },
          divisao_id: 0,
          tipo_acao_id: props.idStep,
          tipo_acao: "carimbar_candidato",
          ativo: true,
        },
      };
    }

    return null;
  }

  clearText(text) {
    const cleanText = text
      .replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return cleanText;
  }

  createAction() {
    let postBody = { ...this.state.actionForm };
    const { currentItem } = this.props;
    if (this.state.actionForm.divisao_id == 0) {
      delete postBody.divisao_id;
    }

    automaticActionDatalayer(
      "interaction",
      "carimbo:clicou:salvar",
      `divisao:${this.clearText(
        this.state.nomeDivisao
      )} - carimbo:${this.clearText(
        this.state.actionForm.conteudo.carimbos.toString()
      )}`,
      this.clearText(currentItem.nome)
    );

    this.props
      .post(`fases/${this.props.currentItem.id}/acoes`, { ...postBody })
      .then(
        () => {
          // Notificando o usuário do sucesso da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_CRIADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          automaticActionDatalayer(
            "nonInteraction",
            "sucesso:acao-automatica-criada",
            "carimbo",
            this.clearText(currentItem.nome)
          );

          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "error"
          );
        }
      );
  }

  updateAction() {
    let postBody = { ...this.state.actionForm };
    if (this.state.actionForm.divisao_id == 0) {
      postBody.divisao_id = "";
    }

    this.props
      .update(
        `fases/${this.props.currentItem.id}/acoes/${this.props.currentAction.id}`,
        { ...postBody }
      )
      .then(
        () => {
          // Notificando o usuário do sucesso da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_ATUALIZADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "error"
          );
        }
      );
  }

  getTag(tags) {
    this.setState(
      (prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          conteudo: {
            carimbos: [],
          },
        },
      }),
      () => {
        map(tags, (tag) => {
          this.setState((prevState) => ({
            actionForm: {
              ...prevState.actionForm,
              conteudo: {
                ...prevState.actionForm.conteudo,
                carimbos: [...prevState.actionForm.conteudo.carimbos, tag.text],
              },
            },
          }));
        });
      }
    );
  }

  closeModal() {
    const { currentItem, closeActionModal } = this.props;
    const { nomeDivisao, actionForm } = this.state;

    automaticActionDatalayer(
      "interaction",
      "carimbo:clicou:cancelar",
      `divisao:${this.clearText(nomeDivisao)} - carimbo:${this.clearText(
        actionForm.conteudo.carimbos.length > 0
          ? actionForm.conteudo.carimbos.toString()
          : "valor-indisponivel"
      )}`,
      this.clearText(currentItem.nome)
    );

    closeActionModal();
  }

  render() {
    const { actionForm, isEditing } = this.state;
    const { divisions, currentItem, currentAction } = this.props;

    return (
      <div>
        <div className="vg-phases-modal-content">
          <h1>
            {intl.get("FASES.MODAL_ACTION.TIPO_ACAO.carimbar_candidato.TITULO")}
          </h1>
          <p>
            {intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.carimbar_candidato.DESCRICAO"
            )}{" "}
            <strong>{currentItem.nome}</strong>
          </p>
          <div className="vg-row  center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-selects-divisons-tests">
              <ActionDivisions
                divisions={divisions}
                actionForm={actionForm}
                handleActionForm={this.handleActionForm}
                isEditing={!!(isEditing && currentAction.divisao)}
              />
            </div>
            <div className="vg-col-md-10 vg-col-xs-12 vg-form">
              <label className="vg-label">
                <span className="vg-label-text">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.carimbar_candidato.LABEL_TITULO"
                  )}
                </span>
                <TokenField
                  terms={this.getTag}
                  tags={this.state.tags}
                  delimiters={delimiters}
                  textInput={intl.get(
                    "VIDEO_ENTREVISTA.EMPRESA.CRIACAO.PERGUNTAS.MODAL.TERMOS_INPUT"
                  )}
                />
              </label>
            </div>
          </div>
        </div>
        <div className="vg-wrap-buttons">
          <Link onClick={this.closeModal} className="vg-btn-link">
            {intl.get("FASES.MODAL.BOTAO_2")}
          </Link>
          <Button
            onClick={
              this.state.isEditing ? this.updateAction : this.createAction
            }
            disabled={actionForm.conteudo.carimbos.length == 0}
            className="vg-btn-success"
          >
            {this.state.isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.carimbar_candidato.BOTAO_EDITAR"
                )
              : intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.carimbar_candidato.BOTAO_SALVAR"
                )}
          </Button>
        </div>
      </div>
    );
  }
}

ActionTags.defaultProps = {
  divisions: {
    sla: [],
  },
  currentAction: {},
};

ActionTags.propTypes = {
  update: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  receiveActions: propTypes.func.isRequired,
  closeActionModal: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  currentItem: propTypes.object.isRequired,
  showNotifications: propTypes.func.isRequired,
  divisions: propTypes.object.isRequired,
  currentAction: propTypes.object.isRequired,
  idStep: propTypes.number.isRequired,
  accesses: propTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, post, update, receiveActions }, dispatch)
)(ActionTags);
