import React, { useRef, useContext } from "react";
import intl from "react-intl-universal";
import { Icon } from "../../../index";
import "./DragDrop.scss";
import { uploadPdf } from "./reducerPdfFile";
import { PdfContext } from "../Pdf";

const DragDropPdf = () => {
  const { dispatchPdfFile } = useContext(PdfContext);
  const txtButton = intl.get(
    "CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.SELECT_BUTTON"
  );
  const inputRef = useRef(null);

  const dragOver = (e) => {
    e.preventDefault();
  };

  const dragLeave = (e) => {
    e.preventDefault();
  };

  const droppedFile = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    const filePdf = files[0];
    if (filePdf) {
      inputRef.current.files = files;
      uploadPdf(filePdf, dispatchPdfFile);
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    let filePdf = inputRef.current.files[0];
    uploadPdf(filePdf, dispatchPdfFile);
  };

  return (
    <div>
      <form
        className="drag-drop-container"
        method="post"
        action="#"
        onDragOver={dragOver}
        onDragLeave={dragLeave}
        onDrop={droppedFile}
      >
        <div className="drop-message">
          <Icon iconKey="pdf" addClass="drag-drop-icon vg-icon-pdf" />
          <div className="drag-drop-title">
            {intl.get("CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.TXT_TYPE")}
          </div>
          <div className="drag-drop-message">
            {intl.get("CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.TXT_DROP_AREA")}
          </div>
        </div>
        <label htmlFor="file-upload">
          <button
            className="false-input"
            onClick={(e) => {
              e.preventDefault();
              inputRef.current.click();
            }}
          >
            {txtButton}
          </button>
        </label>
        <input
          id="file-upload"
          className="input-drag-drop"
          ref={inputRef}
          type="file"
          onChange={onChange}
          accept="application/pdf"
        />
      </form>
      <div className="vg-text-info">
        <Icon iconKey="info" addClass="vg-img-info" />
        {intl.get("CONFIGURACOES.TERMO_ACEITE.DRAG_DROP.TXT_INFO")}
      </div>
    </div>
  );
};

export default DragDropPdf;
