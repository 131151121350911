import "./VideoRecorder.scss";

import React, { Component } from "react";

import { ZiggeoRecorder } from "react-ziggeo";
import propTypes from "prop-types";

class VideoRecorder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      apiKey: process.env.REACT_APP_ZIGGEO_API_KEY,
      timelimit: props.timelimit,
      countdown: 3,
      localplayback: props.localplayback,
      stretch: true,
      allowcustomupload: false,
      rerecordable: false,
      allowupload: false,
      verified: false,
      simulate: props.simulate,
      readtime: props.readtime,
      showCounter: true,
      loaded: props.loaded,
      initCounter: false,
    };

    this.recorderVerified = this.recorderVerified.bind(this);
    this.decreaseCounter = this.decreaseCounter.bind(this);
    this.convertTime = this.convertTime.bind(this);
    this.onRecording = this.onRecording.bind(this);
    this.timeHandler;
  }

  componentDidMount() {
    if (!this.state.initCounter) {
      this.decreaseCounter();
    }
  }

  recorderVerified() {
    this.setState(
      {
        videoToken: this.child.get("video"),
      },
      () => {
        this.props.callbackVerified(this.state.videoToken);
      }
    );
  }

  decreaseCounter() {
    this.setState(
      (prevState) => ({
        readtime: prevState.readtime - 1,
        initCounter: true,
      }),
      () => {
        if (this.state.readtime == 0) {
          this.child.record();
          this.setState({
            showCounter: false,
          });
          clearTimeout(this.timeHandler);
        } else {
          this.timeHandler = setTimeout(() => this.decreaseCounter(), 1000);
        }
      }
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeHandler);
  }

  convertTime(time) {
    const hrs = ~~(time / 3600);
    const mins = ~~((time % 3600) / 60);
    const secs = time % 60;

    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  onRecording() {
    this.setState({
      showCounter: false,
    });
  }

  render() {
    const {
      apiKey,
      timelimit,
      countdown,
      stretch,
      localplayback,
      allowcustomupload,
      rerecordable,
      allowupload,
      simulate,
      readtime,
      showCounter,
    } = this.state;

    let formatted = this.convertTime(readtime);

    return (
      <div className="vg-video-recorder">
        {showCounter && readtime > 0 && (
          <div className="vg-video-recorder-readtime">
            <p>Seu tempo de leitura da pergunta é de {formatted} min.</p>
          </div>
        )}
        <ZiggeoRecorder
          className="vg-video-wrapper"
          apiKey={apiKey}
          responsive
          flip-camera
          simulate={simulate}
          skipinitial
          picksnapshots={false}
          title="Um título de exemplo"
          description="uma descrição"
          theme="space"
          stretch={stretch}
          timelimit={timelimit}
          countdown={countdown}
          allowupload={allowupload}
          localplayback={localplayback}
          allowcustomupload={allowcustomupload}
          skipinitialonrerecord
          rerecordable={rerecordable}
          onVerified={this.recorderVerified}
          onCountdown={this.onRecording}
          onRef={(ref) => (this.child = ref)}
          audiobitrate={320}
        />
      </div>
    );
  }
}

VideoRecorder.propTypes = {
  timelimit: propTypes.number.isRequired,
  simulate: propTypes.bool,
  localplayback: propTypes.bool,
  callbackVerified: propTypes.func.isRequired,
  readtime: propTypes.number,
  loaded: propTypes.bool.isRequired,
};

export default VideoRecorder;
