import "./GroupsVideoInterview.scss";

import { withRouter } from "react-router";
import propTypes from "prop-types";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { load, clean } from "../../../store/modules/GroupsVideoInterview";

const mapStateToProps = (state) => ({
  groupsVideoInterview: state.GroupsVideoInterview.data,
  loaded: state.GroupsVideoInterview.loaded,
});

class GroupsVideoInterview extends Component {
  constructor(props) {
    super(props);
    const { video_entrevista_id } = props;
    this.state = {
      selectGroups: video_entrevista_id,
    };

    this.handleChangeGroups = this.handleChangeGroups.bind(this);
  }

  handleChangeGroups(value) {
    const { history, processo_seletivo_id, candidato_id } = this.props;

    this.setState(
      {
        selectGroups: value,
      },
      () =>
        history.push(
          `/video-entrevista/candidato?video_entrevista=${value}&candidato_id=${candidato_id}&vaga_id=${processo_seletivo_id}`
        )
    );
  }

  componentDidMount() {
    const { load, processo_seletivo_id, candidato_id } = this.props;
    load(processo_seletivo_id, candidato_id);
  }

  componentWillUnmount() {
    const { clean } = this.props;
    clean();
  }

  render() {
    const { groupsVideoInterview, loaded } = this.props;
    const { selectGroups } = this.state;
    const options = loaded
      ? groupsVideoInterview.grupos.map((item) => (
          <option key={item.id} value={item.id}>
            {item.identificacao}
          </option>
        ))
      : "";
    const select = (
      <div className="vg-inline-block">
        <div className="vg-custom-select">
          <select
            value={selectGroups}
            onChange={(el) => this.handleChangeGroups(el.target.value)}
            className="vg-select-groups"
          >
            <option value={0} disabled>
              Grupo: Escolha uma opção
            </option>
            {options}
          </select>
        </div>
      </div>
    );
    return loaded && (groupsVideoInterview.grupos.length <= 1 ? null : select);
  }
}

GroupsVideoInterview.defaultProps = {
  groupsVideoInterview: {},
  type: "",
  loading: false,
};

GroupsVideoInterview.propTypes = {
  groupsVideoInterview: propTypes.object.isRequired,
  load: propTypes.func.isRequired,
  clean: propTypes.func.isRequired,
  type: propTypes.string.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
  candidato_id: propTypes.number.isRequired,
  processo_seletivo_id: propTypes.number.isRequired,
  video_entrevista_id: propTypes.number.isRequired,
  history: propTypes.object,
};

export default withRouter(
  connect(mapStateToProps, (dispatch) =>
    bindActionCreators({ load, clean }, dispatch)
  )(GroupsVideoInterview)
);
