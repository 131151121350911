import propTypes from "prop-types";
import React from "react";
import intl from "react-intl-universal";
import { Icon } from "../../index";
import { underscoreReplace } from "../../../utils/underscoreReplace";

import "./VacancyStatus.scss";

const VacancyStatus = ({ status }) => {
  function statusText(status) {
    if (
      status === "FECHADA_COM_CONTRATACAO" ||
      status === "FECHADA_SEM_CONTRATACAO"
    ) {
      return (
        <span className={`vg-status vg-${underscoreReplace(status)}`}>
          <Icon iconKey="checked" />
          {intl.get("VAGAS.STATUS.FECHADA")}
        </span>
      );
    }

    if (
      status === "EM_ANDAMENTO" ||
      status === "EM_ANDAMENTO_INSCRICAO_ABERTA"
    ) {
      return null;
    }

    return (
      <span className={`vg-status vg-${underscoreReplace(status)}`}>
        {status === "VEICULACAO_SUSPENSA" && <Icon iconKey="warning" />}
        {status === "AGENDADA" && <Icon iconKey="clock" />}
        {status === "EM_ANDAMENTO_INSCRICAO_ENCERRADA" && (
          <Icon iconKey="warning" />
        )}
        {intl.get(`VAGAS.STATUS.${status}`)}
      </span>
    );
  }

  return <>{statusText(status)}</>;
};

VacancyStatus.propTypes = {
  status: propTypes.string.isRequired,
  id: propTypes.number.isRequired,
};

export default VacancyStatus;
