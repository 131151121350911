import PropTypes from "prop-types";
import React, { Component } from "react";
import { map } from "lodash";
import intl from "react-intl-universal";

class NewSelect extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onChangeNewSelect(event.target.value);
  }
  render() {
    const {
      listItens,
      selectClass,
      selectId,
      itemOption,
      valueOption,
      firstOption,
      classCustomSelect,
      dataOption,
    } = this.props; // eslint-disable-line no-shadow
    const classSelect = selectClass ? `vg-select ${selectClass}` : "vg-select";
    return (
      <div className={`vg-custom-select ${classCustomSelect}`}>
        <select
          className={classSelect}
          id={selectId}
          onChange={this.handleChange}
        >
          {firstOption ? <option value="-1">{firstOption}</option> : ""}
          {listItens.length ? (
            map(listItens, (item, index) => {
              let valueWData =
                dataOption && valueOption
                  ? `${item[valueOption]}_${item[dataOption]}`
                  : "";
              return (
                <option
                  key={index}
                  value={
                    valueWData
                      ? valueWData
                      : valueOption
                      ? item[valueOption]
                      : index
                  }
                >
                  {item[itemOption]}
                </option>
              );
            })
          ) : (
            <option>{intl.get("CARREGANDO")}</option>
          )}
        </select>
      </div>
    );
  }
}

export default NewSelect;

NewSelect.defaultProps = {
  listItens: [],
  loaded: false,
  selectClass: false,
  selectId: "vg-select",
  itemOption: "",
  valueOption: false,
};

NewSelect.propTypes = {
  loaded: PropTypes.bool.isRequired,
  selectClass: PropTypes.string.isRequired,
  selectId: PropTypes.string.isRequired,
  listItens: PropTypes.any.isRequired,
  onChangeNewSelect: PropTypes.func.isRequired,
  itemOption: PropTypes.string.isRequired,
  valueOption: PropTypes.bool.isRequired,
  firstOption: PropTypes.string,
  classCustomSelect: PropTypes.string,
  dataOption: PropTypes.string,
};
