import React from "react";
import propTypes from "prop-types";
import { Avatar } from "@vagastec/epartner-ui";
import "./VideoInterviewCommentsListItem.scss";

const VideoInterviewCommentsListItem = ({
  item: { identificacao, comentario },
}) => {
  return (
    <div className="vg-video-detail-comments-list-item">
      <div className="vg-item-avatar">
        <Avatar size="sm" kind="text" name={identificacao} />
      </div>
      <div className="vg-video-comments-list-item">
        <div className="vg-item-user-name">{identificacao}</div>
        <div className="vg-item-comment"> {comentario} </div>
      </div>
    </div>
  );
};

VideoInterviewCommentsListItem.defaultProps = {
  item: propTypes.shape({
    id: propTypes.number.isRequired,
    comentario: propTypes.string.isRequired,
    identificacao: propTypes.string.isRequired,
    data_hora: propTypes.string.isRequired,
  }).isRequired,
};

VideoInterviewCommentsListItem.propTypes = {
  item: propTypes.object.isRequired,
};

export default VideoInterviewCommentsListItem;
