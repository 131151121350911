function getCurrentLanguage() {
  let allCookies = [];
  let language = "";

  window.document.cookie.split("; ").map((item) => allCookies.push(item));
  const languageCookie = allCookies.find((el) =>
    el.includes("IntegracaoLoginIdioma")
  );
  typeof languageCookie === "undefined"
    ? (language = "_ptBR")
    : (language = languageCookie.split("=")[1]);
  return language;
}

function getLoaderMessage(lang) {
  const i18n = {
    _enUS: "Wait, we are loading the content.",
    _esALT: "Espera, estamos cargando el contenido.",
    _ptBR: "Aguarde, estamos carregando o conteúdo.",
  };
  return i18n[lang] || i18n["_ptBR"];
}

function setLoaderMessage() {
  const currentLanguage = getCurrentLanguage();
  const loaderMessage = getLoaderMessage(currentLanguage);
  const loaderMessageNode = document.getElementById("vg-legend-loader");
  loaderMessageNode.innerText = loaderMessage;
}
setLoaderMessage();
