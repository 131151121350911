import React, { useRef } from "react";

import arrowRight from "../../../assets/landingPageTriagem/landing-page-arrow-right.svg";
import mouseIcon from "../../../assets/landingPageTriagem/landing-page-icon-mouse.svg";
import robotIcon from "../../../assets/landingPageTriagem/icon-landing-page-robot.svg";
import filterIcon from "../../../assets/landingPageTriagem/icon-landing-page-filter.svg";
import comunicateIcon from "../../../assets/landingPageTriagem/icon-landing-page-comunicate.svg";
import symbolStar from "../../../assets/landingPageTriagem/icon-landing-page-start.svg";
import notebookImage from "../../../assets/landingPageTriagem/landing-page-notebook.png";
import buttonCheck from "../../../assets/landingPageTriagem/landing-page-button-check.svg";

import "./LandingPageTriagem.scss";

const InfoItems = [
  {
    id: 0,
    icon: mouseIcon,
    title: "Interface intuitiva",
    description:
      "Projetada com uma interface amigável, que permite navegar facilmente pelas etapas do processo de triagem. Você poderá se familiarizar rapidamente com as funcionalidades e utilizar todo o potencial da plataforma desde o primeiro dia.",
  },
  {
    id: 1,
    icon: robotIcon,
    title: "Inteligência artificial",
    description:
      "Algoritmos de inteligência artificial de última geração auxiliam na análise dos candidatos de acordo com os requisitos da vaga. Identifique os candidatos mais qualificados e relevantes para suas vagas, economizando seu tempo valioso!",
  },
  {
    id: 2,
    icon: filterIcon,
    title: "Personalização e critérios avançados",
    description:
      "Defina critérios específicos e encontre os candidatos de acordo com suas necessidades. Personalize os requisitos, como habilidades, experiência, formação acadêmica e muito mais. Essa flexibilidade garante que apenas os candidatos mais adequados sejam selecionados, tornando seu processo de triagem mais eficiente.",
  },
  {
    id: 3,
    icon: comunicateIcon,
    title: "Comunicação simplificada",
    description:
      "Facilitamos a comunicação entre recrutador e candidato, oferecendo uma opção de bate-papo integrada. Você poderá conduzir entrevistas preliminares, esclarecer dúvidas e agendar etapas adicionais do processo de seleção diretamente na plataforma. Isso reduzirá a necessidade de trocar e-mails ou utilizar várias ferramentas de comunicação.",
  },
];

const LandingPageTriagem = () => {
  const scrollToTopRef = useRef(null);

  const handleScrollToTop = () => {
    scrollToTopRef?.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="container">
        <div className="hero" ref={scrollToTopRef}>
          <div className="hero-wrapper">
            <div className="content-start">
              <div className="content-start-headings">
                <span className="content-start-heading">
                  Vem aí uma nova experiência na
                </span>
                <span className="content-start-subheading">
                  Triagem de candidatos!
                </span>
              </div>
              <span className="content-start-description">
                Experimente a nova Triagem do Vagas For Business e encontre os
                candidatos perfeitos de forma mais rápida e eficiente.
              </span>
            </div>
            <div className="content-end">
              <iframe
                className="form"
                src="https://docs.google.com/forms/d/e/1FAIpQLScwYiBMTymS61LASbNGVyeoIDNEaLTrd3PSMwpNSau5IBb0AQ/viewform?embedded=true"
                frameBorder={0}
              >
                Carregando formulário...
              </iframe>
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="sectionExplain">
          <div className="content-start">
            <img
              className="content-start-image-notebook"
              src={notebookImage}
              alt="Imagem do sistema nova triagem"
              title="Imagem do sistema nova triagem"
              aria-hidden
            />
          </div>
          <div className="content-end">
            <img
              className="content-end-symbol-star"
              src={symbolStar}
              alt="Symbolo de estrela"
              title="Symbolo de estrela"
              height={128}
              width={128}
              aria-hidden
            />
            <img
              className="content-end-symbol-arrow"
              src={arrowRight}
              alt="Symbolo de estrela"
              title="Symbolo de estrela"
              aria-hidden
            />
            <div className="content-end-text">
              O Vagas For Business, software pioneiro em recrutamento e seleção,
              está sempre trabalhando para trazer novidades para que você tenha
              contratações cada vez mais ágeis e eficientes.
            </div>
          </div>
        </div>
        <div className="divider" />
        <div className="sectionInfoItems">
          {InfoItems.map(({ id, title, description, icon }) => (
            <div key={id} className="infoItem">
              <div className="itemIconWrapper">
                <div
                  className="itemIcon"
                  style={{ backgroundImage: `url(${icon})` }}
                />
              </div>
              <div className="info-item-title">{title}</div>
              <div className="info-item-description">{description}</div>
            </div>
          ))}
        </div>
        <div className="divider" />
        <div className="sectionCTA">
          <div className="sectionCTA-image" aria-hidden />
          <div className="sectionCTA-infos">
            <div className="sectionCTA-infos-title">
              Seja uma das primeiras pessoas a usar
            </div>
            <div className="sectionCTA-infos-description">
              a nova Triagem do Vagas For Business e encontre os candidatos
              perfeitos de forma mais rápida e eficiente.
            </div>
            <button className="button" onClick={handleScrollToTop}>
              Quero Testar
              <img
                className="content-start-image"
                src={buttonCheck}
                alt="Ícone de checado"
                title="Ícone de checado"
                height={16}
                width={16}
                aria-hidden
              />
            </button>
          </div>
        </div>
        <div className="divider" />
      </div>
    </>
  );
};

export default LandingPageTriagem;
