import "./Highlight.scss";

import React, { Component } from "react";
import { map, replace } from "lodash";

import propTypes from "prop-types";

export default class Highlight extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sentence: props.children,
      firstTime: false,
    };

    this.createMarkup = this.createMarkup.bind(this);
    this.getHighLight = this.getHighLight.bind(this);
  }

  componentDidMount() {
    this.getHighLight();
  }

  getHighLight() {
    if (!this.state.firstTime) {
      const { match } = this.props;
      const { sentence } = this.state;

      map(Object.keys(match), (item) => {
        const isRelated = match[item].correlacao_pai;
        const restLocals = match[item].locais;

        // Remove itens duplicados do array
        const locals = restLocals.filter((el, pos) => {
          return restLocals.indexOf(el) == pos;
        });

        for (let local of locals) {
          const isLocated = sentence.split(" ")[local - 1];
          this.setState({
            firstTime: true,
            sentence: replace(sentence, isLocated, (str) => {
              return isRelated !== ""
                ? `<mark class="vg-mark-related"><span>${isRelated}</span>${str}</mark>`
                : `<mark class="vg-mark-match">${str}</mark>`;
            }),
          });
        }
      });
    }
  }

  createMarkup() {
    return { __html: this.state.sentence };
  }

  render() {
    return <span dangerouslySetInnerHTML={this.createMarkup()} />;
  }
}

Highlight.propTypes = {
  children: propTypes.node.isRequired,
  match: propTypes.object.isRequired,
};
