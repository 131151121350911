import propTypes from "prop-types";
import React from "react";
import {
  Icon,
  HiddenVacanciesListItemInfo,
  Tooltip,
} from "../../index";
import intl from "react-intl-universal";
import { Avatar } from "@vagastec/epartner-ui";

const HiddenVacanciesListItemHeader = (props) => {
  const {
    vacancyType,
    vacancy,
    pcd,
    buttons,
    hideVacancy,
    id,
    responsavel,
    grupo,
  } = props;

  function renderVacancyIcons(type, pcd) {
    return (
      <>
        {type === "programa_estagio_trainee" && <Icon iconKey="pet" />}
        {type === "programa_recrutamento_continuo" && <Icon iconKey="loop" />}
        {pcd && <Icon iconKey="pcd" />}
      </>
    );
  }

  function renderVacancyButtons(buttons) {
    if (!buttons) return;

    return (
      <>
        {buttons.includes('desocultar-vaga') ?
          <button
            className="vg-btn-outline-light-gray"
            onClick={(el) => hideVacancy(el, id)}
          >
            {intl.get('VAGAS.ACOES.MENU.DESOCULTAR_VAGA')}
          </button> :
          <></>
        }
      </>
    );
  }

  return (
    <div className="vg-selective-process-list-header">
      <div className="vg-list-left-content">
        <div className="vg-item-avatar">
          <Avatar
            kind="image"
            name={responsavel.nome || ""}
            src={responsavel.avatar_url || ""}
          />
        </div>
        <div className="vg-detail">
          <h2 className="vg-name-vacancy">
            {renderVacancyIcons(vacancyType, pcd)}
            <Tooltip position="top" text={vacancy}>
              <span className="vg-title-vacancy">{vacancy}</span>
              <span className="vg-code-vacancy">v{id}</span>
            </Tooltip>
            {grupo && <span className="vg-name-group">{grupo}</span>}
          </h2>
          <HiddenVacanciesListItemInfo {...props} />
        </div>
      </div>
      <div className="vg-middle-section">
      </div>
      <div className={`vg-list-buttons`}>
        {renderVacancyButtons(buttons)}
      </div>
    </div>
  );
};

HiddenVacanciesListItemHeader.propTypes = {
  buttons: propTypes.array.isRequired,
  id: propTypes.number.isRequired,
  pcd: propTypes.any,
  hideVacancy: propTypes.func.isRequired,
  responsavel: propTypes.object.isRequired,
  vacancy: propTypes.string.isRequired,
  vacancyType: propTypes.string.isRequired,
  grupo: propTypes.string,
};

export default HiddenVacanciesListItemHeader;
