import propTypes from "prop-types";
import React, { Component } from "react";
import { TabList, TabPanel, ErrorMessage, Loader } from "../../index";
import intl from "react-intl-universal";
import "./Tabs.scss";

class Tabs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: "",
    };

    this.toggleTab = this.toggleTab.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    nextProps.tabs !== this.props.tabs &&
      this.setState({
        currentTab: Object.keys(nextProps.tabs.acessos)[0],
      });
  }

  componentDidMount() {
    this.setState({
      currentTab: Object.keys(this.props.tabs.acessos)[0],
    });
  }

  toggleTab(tab) {
    this.setState({
      currentTab: tab,
    });
  }

  render() {
    const { tabs, loading, loaded } = this.props;
    const { currentTab } = this.state;

    if (loading) {
      return <Loader />;
    }

    return !loading && !loaded ? (
      <ErrorMessage
        message={intl.get("MENSAGEM_DE_ERRO.CONFIGURACOES_NAO_CARREGADA")}
      />
    ) : (
      <div className="vg-wrapper-tabs">
        <div className="vg-tab">
          <TabList
            tabList={Object.keys(tabs.acessos)}
            currentTab={currentTab}
            toggleTab={this.toggleTab}
          />
          <TabPanel
            tabPanel={tabs.acessos[currentTab]}
            currentTab={currentTab}
          />
        </div>
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: propTypes.object.isRequired,
  loading: propTypes.bool.isRequired,
  loaded: propTypes.bool.isRequired,
};

export default Tabs;
