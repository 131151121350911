import "./Content.scss";

import { EditPhasesActions, Link } from "../../index";
import React, { Component } from "react";

import intl from "react-intl-universal";
import propTypes from "prop-types";

export class EditPhasesContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  // Método para abrir e fechar o <Modal />
  toggleModal(el) {
    el && el.preventDefault();

    this.setState(
      (prevState) => (
        {
          isOpen: !prevState.isOpen,
        },
        () =>
          document
            .getElementsByTagName("body")[0]
            .classList.toggle("vg-no-scroll")
      )
    );
  }

  componentDidMount() {
    // Adiciona evento de keyup para fechar o modal
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.setState(
          {
            isOpen: false,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll")
        );
      }
    });
  }

  // Remove o eventListener do document
  componentWillUnmount() {
    document.removeEventListener("keyup", () =>
      document.getElementsByTagName("body")[0].classList.remove("vg-no-scroll")
    );
  }

  render() {
    const { editHandler, item, removeHandler, toggleActionModal, toggleModal } =
      this.props;
    return (
      <div className="vg-phase-content vg-row">
        <div className="vg-col-xs-12 vg-col-md-10 vg-col-md-offset-1">
          <div className="vg-row middle-xs">
            <div className="vg-col-xs-12 vg-col-sm-8">
              <h3>{intl.get("FASES.ACOES.INFORMACAO_FASE")}</h3>
            </div>
            <div className="vg-col-xs-12 vg-col-sm-4 vg-text-right">
              <Link
                href="#"
                className="vg-btn-link"
                onClick={(e) => editHandler(e, item)}
              >
                {intl.get("FASES.ACOES.EDITAR_FASE")}
              </Link>
            </div>
          </div>
          <div className="vg-row middle-xs">
            <div className="vg-col-xs-12 vg-col-sm-8">
              <h3>{intl.get("FASES.ACOES.TITULO")}</h3>
            </div>
            <div className="vg-col-xs-12 vg-col-sm-4 vg-text-right">
              <Link
                href="#"
                className="vg-btn-link"
                onClick={(e) => toggleActionModal(e, item)}
              >
                {intl.get("FASES.ACOES.CRIAR_ACAO_AUTOMATICA")}
              </Link>
            </div>
            <div className="vg-col-xs-12">
              <EditPhasesActions
                item={item}
                isOpen={this.state.isOpen}
                toggleModalPhase={toggleModal}
                toggleModal={toggleActionModal}
              />
            </div>
          </div>
          <div className="vg-row middle-xs">
            <div className="vg-col-sm-12 vg-text-right">
              <Link
                href="#"
                className="vg-btn-danger vg-btn-outline"
                onClick={(e) => removeHandler(e, item)}
              >
                {intl.get("FASES.EXCLUIR_FASE")}
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

EditPhasesContent.defaultProps = {
  item: {},
  data: [],
};

EditPhasesContent.propTypes = {
  editHandler: propTypes.func.isRequired,
  toggleActionModal: propTypes.func.isRequired,
  toggleModal: propTypes.func.isRequired,
  removeHandler: propTypes.func.isRequired,
  item: propTypes.object.isRequired,
};

export default EditPhasesContent;
