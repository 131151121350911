import "./ModalShareVideo.scss";

import React, { Component } from "react";
import {
  Button,
  Link,
  TokenField,
  RichTextEditor,
  Icon,
  Toast,
} from "../../index";

import { toast } from "react-toastify";
import {
  post,
  closeModal,
} from "../../../store/modules/ModalShareVideoInterview";
import { receive as receiveMessage } from "../../../store/modules/Messages";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { get as safeGet } from "lodash";
import isValidEmail from "../../../utils/is-valid-email";

const mapStateToProps = (state) => ({
  messages: safeGet(state, "Messages.data.mensagens_padrao", []),
  participantIds: state.ModalShareVideoInterview.selectedIds,
  error: state.ModalShareVideoInterview.error,
  success: state.ModalShareVideoInterview.success,
  loading: state.ModalShareVideoInterview.loading,
  interviewList: state.VideoInterviewListProcessed.data,
});

const KeyCodes = {
  comma: 188,
  enter: 13,
  semicolon: 186,
  space: 32,
  tab: 9,
};
const delimiters = [
  KeyCodes.enter,
  KeyCodes.comma,
  KeyCodes.semicolon,
  KeyCodes.space,
  KeyCodes.tab,
];

export class ModalShareVideo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultMessage: "<p></p>\n",
      message: "",
      isHtml: false,
      recipients: [],
    };

    this.getTags = this.getTags.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.handleClickShare = this.handleClickShare.bind(this);
    this.showNotifications = this.showNotifications.bind(this);
    this.showInvalidEmailNotification =
      this.showInvalidEmailNotification.bind(this);
  }

  componentDidMount() {
    // Carrega as mensagens padrão
    this.props.receiveMessage();
  }

  showNotifications(message, type = "success") {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  }

  showInvalidEmailNotification() {
    this.showNotifications(
      intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.EMAIL_INVALIDO"),
      "error",
    );
  }

  handleClickShare() {
    const { post, closeModal, interviewList } = this.props;
    const payload = {
      emails: this.state.recipients.map((r) => r.text),
      participantes: this.props.participantIds,
      email_corpo: this.state.defaultMessage || "",
      processo_seletivo_id: interviewList.processo_seletivo.id,
      processo_seletivo_cargo: interviewList.processo_seletivo.cargo,
    };

    post("video-entrevistas/compartilhamentos", payload).then(
      () => {
        this.showNotifications(
          intl.getHTML("VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.ENVIO_OK"),
          "success",
        );
        closeModal();
      },
      () => {
        this.showNotifications(
          intl.getHTML(
            "VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.ENVIO_FALHA",
          ),
          "error",
        );
      },
    );
  }

  handleEditorChange(text) {
    this.setState({
      defaultMessage: text,
    });
  }

  handleMessageChange = (id) => {
    const selected = this.props.messages.filter((m) => {
      return String(m.id) === id;
    })[0];
    this.setState({
      message: selected ? `<p></p>\n${selected.conteudo}` : "",
      isHtml: selected ? selected.contem_html : false,
    });
  };

  getTags(tags) {
    this.setState({
      recipients: tags,
    });
  }

  render() {
    const { message, defaultMessage } = this.state;
    const { closeModal, loading, messages } = this.props;

    const options = messages.map((item) => (
      <option key={item.id} value={item.id}>
        {item.identificacao}
      </option>
    ));

    return (
      <div className="vg-modal-share-video">
        <div className="vg-row center-xs">
          <div className="vg-col-md-10 vg-col-xs-12">
            <p className="vg-modal-share-video-subtitulo">
              {intl.getHTML(
                "VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.DESCRICAO",
              )}
            </p>
          </div>
        </div>
        <div className="vg-row center-xs">
          <div className="vg-col-md-10 vg-col-xs-12">
            <label className="vg-label">
              <p className="vg-label-text">
                {intl.get(
                  "VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.ETIQUETA_FORMULARIO",
                )}
              </p>
              <TokenField
                createOnBlur
                terms={this.getTags}
                tags={this.state.recipients}
                delimiters={delimiters}
                textInput={""}
                tagValidator={isValidEmail}
                invalidInputHandler={this.showInvalidEmailNotification}
              />
            </label>
          </div>
        </div>

        <div className="vg-row center-xs">
          <div className="vg-col-md-10 vg-col-xs-12">
            <div className="vg-relative">
              <RichTextEditor
                changeContent={this.handleEditorChange}
                content={defaultMessage}
                newContent={message}
                isHtml={this.state.isHtml}
              />
              <div className="vg-custom-select">
                <select
                  disabled={!messages.length}
                  onChange={(el) => this.handleMessageChange(el.target.value)}
                >
                  <option value={0}>
                    {intl.get(
                      "VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.LISTA_TEMPLATES.LABEL_SELECT",
                    )}
                  </option>
                  {options}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="vg-wrap-buttons">
          <Link onClick={closeModal} className="vg-btn-primary vg-btn-outline">
            {intl.get(
              "VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.BOTAO_CANCELAR",
            )}
          </Link>
          <Button
            onClick={this.handleClickShare}
            disabled={loading || !this.state.recipients.length}
            className="vg-btn-success"
          >
            {intl.get("VIDEO_ENTREVISTA.EMPRESA.MODAL_SHARE_VIDEO.BOTAO_ACAO")}
            {loading && (
              <span className="vg-spinner-icon animation-spinner">
                <Icon iconKey="spinner_botao" />
              </span>
            )}
          </Button>
        </div>
      </div>
    );
  }
}

ModalShareVideo.propTypes = {
  messages: propTypes.array,
  closeModal: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  receiveMessage: propTypes.func.isRequired,
  participantIds: propTypes.array.isRequired,
  loading: propTypes.bool.isRequired,
  interviewList: propTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      receiveMessage,
      post,
      closeModal,
    },
    dispatch,
  ),
)(ModalShareVideo);
