import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import { RadioButton } from "@vagastec/epartner-ui";
import PropTypes from "prop-types";
import { TermContext } from "./AcceptanceTermList";
import { Url, Pdf } from "../../index";

const Upload = (props) => {
  const { id } = props;
  const [documentType, setDocumentType] = useState(null);
  const { terms } = React.useContext(TermContext);

  useEffect(() => {
    if (terms) {
      let term = terms.find((term) => term.id == id);
      term && setDocumentType(term.type);
    }
  }, [terms]);

  const upload_options = [
    {
      value: "pdf",
      label: intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.OPCAO_PDF_LABEL"),
    },
    {
      value: "url",
      label: intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.OPCAO_URL_LABEL"),
    },
  ];

  return (
    <div className="vg-upload-box">
      <p className="bold-title">
        {intl.get("CONFIGURACOES.TERMO_ACEITE.UPLOAD.TITULO")}
      </p>
      <div className="vg-upload-flex-options">
        {upload_options.map((option) => (
          <div key={option.value} className="vg-radio-button">
            <RadioButton
              key={option.value}
              currentChecked={documentType}
              label={option.label}
              name="answer"
              onChange={() => setDocumentType(option.value)}
              value={option.value}
            />
          </div>
        ))}
      </div>

      {documentType == "url" ? <Url id={id} /> : ""}
      {documentType == "pdf" ? <Pdf id={id} /> : ""}
    </div>
  );
};

Upload.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Upload;
