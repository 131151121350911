import React, { useState } from "react";
import propTypes from "prop-types";
import intl from "react-intl-universal";
import {
  Icon,
  Tooltip,
  Location,
  HiddenVacanciesListInfoDropdownContent,
} from "../../index";
import { styled } from "@vagastec/epartner-ui";
import Dropdown from "../../atoms/Dropdown";

const getLocationSafe = (obj) => obj.cidade || obj.estado || obj.pais || "";
const DropdownWrapper = styled.div`
  position: relative;
  flex-shrink: 0;

  + div {
    margin-left: 8px;
  }
`;

const HiddenVacanciesListItemInfo = (props) => {
  const {
    location,
    grupo,
    divisao,
    totalAfterSorting,
    total,
    visualizations,
    id,
  } = props;

  const [toggleOpeningInfoDropdown, setToggleOpeningInfoDropdown] =
    useState(false);

  return (
    <div className="vg-timeline">
      <Tooltip position="bottom" text={intl.get("VAGAS.DATA.PERIODO")}>
      </Tooltip>
      <Tooltip
        position="bottom"
        text={location.descricao || getLocationSafe(location)}
      >
        <Location label={getLocationSafe(location)} />
      </Tooltip>
      <div className="vg-text-info">
        <button
          onClick={() =>
            setToggleOpeningInfoDropdown(!toggleOpeningInfoDropdown)
          }
        >
          <Icon iconKey="info" addClass="vg-icon-info" />
          {intl.get("VAGAS.INFO.LABEL")}
          <Icon
            iconKey="arrow"
            addClass={`vg-icon-opening-info-situation
            vg-icon-opening-info-situation${
              toggleOpeningInfoDropdown ? "-up" : ""
            }
          `}
          />
        </button>
      </div>
      {toggleOpeningInfoDropdown && (
        <DropdownWrapper>
          <Dropdown
            setShowDropdown={setToggleOpeningInfoDropdown}
            label={intl.get("VAGAS.INFO.TITULO")}
            align="left"
            closeTabIndex={0}
            alignAdjustment="-38px"
            className="vg-dropdown-info"
            removeArrow
          >
            <HiddenVacanciesListInfoDropdownContent
              id={id}
              location={location}
              divisao={divisao}
              grupo={grupo}
              visualizations={visualizations}
              totalAfterSorting={totalAfterSorting}
              total={total}
            />
          </Dropdown>
        </DropdownWrapper>
      )}
    </div>
  );
};

HiddenVacanciesListItemInfo.propTypes = {
  expirationDate: propTypes.string.isRequired,
  openDate: propTypes.string.isRequired,
  openingDateSituation: propTypes.string.isRequired,
  location: propTypes.shape({
    cidade: propTypes.string,
    estado: propTypes.string,
    pais: propTypes.string,
    descricao: propTypes.string,
  }),
  grupo: propTypes.string,
  divisao: propTypes.string,
  totalAfterSorting: propTypes.number,
  total: propTypes.number,
  visualizations: propTypes.number,
  id: propTypes.number.isRequired,
  updateVacanciesTempList: propTypes.func,
  limiteDate: propTypes.string,
  status: propTypes.string,
};

export default HiddenVacanciesListItemInfo;
