import React from "react";
import PropTypes from "prop-types";

export function AccordionHeader({ children, ...rest }) {
  return (
    <button
      className="accordion-title"
      data-component="accordion-header"
      type="button"
      {...rest}
    >
      {children}
    </button>
  );
}

AccordionHeader.propTypes = {
  children: PropTypes.any,
};
