import React from "react";
import propTypes from "prop-types";
import { Tooltip } from "../../index";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import "./SlaBar.scss";

const SlaBar = ({ sla, classes }) => {
  const getTooltipText = () => {
    const slaValueAsText = sla.valor !== null ? `${sla.valor}% - ` : "";
    const slaStatusAsText = `SLA.STATUS.${intlReplace(sla.status || "vazio")}`;

    return `SLA ${slaValueAsText}${intl.get(slaStatusAsText)}`;
  };

  return (
    <div className={`vg-sla ${classes}`}>
      <Tooltip position="top" text={getTooltipText()}>
        <span className="vg-sla-bar">
          <span
            style={sla.valor ? { width: `${sla.valor}%` } : undefined}
            className={
              sla.status
                ? `vg-sla-bar-status-${sla.status} vg-sla-width`
                : undefined
            }
          />
        </span>
      </Tooltip>
    </div>
  );
};

SlaBar.defaultProps = {
  sla: {
    status: null,
    valor: null,
    dias_transcorridos: 0,
    dias_quantidade_maxima: 0,
  },
};

SlaBar.propTypes = {
  sla: propTypes.shape({
    status: propTypes.oneOf(["excedido", "no_prazo", "pausado", null]),
    valor: propTypes.number,
    dias_transcorridos: propTypes.number,
    dias_quantidade_maxima: propTypes.number,
  }),
  classes: propTypes.string.isRequired,
};

export default SlaBar;
