import "./SelectiveProcessState.scss";

import React from "react";
import propTypes from "prop-types";

const SelectiveProcessState = ({ children }) => (
  <div className="vg-selective-process-state">{children}</div>
);

SelectiveProcessState.propTypes = {
  children: propTypes.node,
};

export default SelectiveProcessState;
