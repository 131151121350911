import propTypes from "prop-types";
import React, { Component } from "react";
import shortid from "shortid";
import "./InputCheckBox.scss";

class InputCheckBox extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { name, label, qtdItens, isChecked, checking } = this.props;

    return (
      <div className="vg-custom-check">
        <label className="vg-label" key={shortid.generate()}>
          <span className="vg-input-checkbox">
            <input
              type="checkbox"
              name={name}
              checked={isChecked}
              onChange={checking}
            />
            <span className="vg-input-checkbox-inner" />
          </span>
          <span className="vg-label-text">
            {label}
            {qtdItens && <span className="vg-qtd-itens">({qtdItens})</span>}
          </span>
        </label>
      </div>
    );
  }
}

InputCheckBox.defaultProps = {
  name: "",
};

InputCheckBox.propTypes = {
  name: propTypes.any,
  isChecked: propTypes.bool,
  label: propTypes.string,
  qtdItens: propTypes.number,
  checking: propTypes.func,
};

export default InputCheckBox;
