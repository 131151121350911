import "./Actions.scss";

import { Button, Link, Modal } from "../../index";
import React, { Component } from "react";
import { receive, remove } from "../../../store/modules/Actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { automaticActionDatalayer } from "../../../utils/dataLayerPush";

const mapStateToProps = () => ({});

export class EditPhasesActionModalRemove extends Component {
  constructor(props) {
    super(props);

    this.removeAction = this.removeAction.bind(this);
  }

  textCleaning(text) {
    const cleanText = text
      .replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return cleanText;
  }

  removeAction() {
    const { currentAction } = this.props;

    const type = {
      1: "email",
      2: "carimbo",
      3: "ficha",
      4: "teste",
    };

    this.props
      .remove(
        `fases/${this.props.currentItem.id}/acoes/${this.props.currentAction.id}`,
      )
      .then(
        () => {
          // Notificando o usuário do sucesso da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_REMOVIDA", {
              action: this.props.currentAction.conteudo.titulo,
            }),
            "success",
          );

          this.props.receive(this.props.currentItem.id);
          automaticActionDatalayer(
            "nonInteraction",
            "sucesso:acao-automatica-removida",
            `${type[currentAction.tipo_acao.id]}`,
            this.textCleaning(this.props.currentItem.nome),
          );
          // Fechando o modal
          this.props.closeActionModal();
        },
        () => {
          // Notificando o usuário do erro da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_REMOVER_ACAO", {
              action: this.props.currentAction.conteudo.titulo,
            }),
            "error",
          );
        },
      );
  }

  render() {
    const { closeActionModal, isOpen } = this.props;

    return (
      <Modal
        show={isOpen}
        small
        onClose={(e) => closeActionModal(e)}
        contentLabel={intl.get("FASES.MODAL_ACTION_REMOVE.MODAL_HEADER")}
      >
        <div className="vg-phases-modal">
          <div className="vg-phases-modal-content">
            <h1>{intl.get("FASES.MODAL_ACTION_REMOVE.TITULO")}</h1>
            <p>{intl.getHTML("FASES.MODAL_ACTION_REMOVE.TEXTO")}</p>
            <div className="vg-phases-modal-inside-buttons">
              <Button className="vg-btn-danger" onClick={this.removeAction}>
                {intl.get("FASES.MODAL_ACTION_REMOVE.BOTAO1")}
              </Button>
              <Link
                onClick={(e) => closeActionModal(e)}
                className="vg-btn-link"
              >
                {intl.get("FASES.MODAL_ACTION_REMOVE.BOTAO2")}
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

EditPhasesActionModalRemove.propTypes = {
  remove: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  closeActionModal: propTypes.func.isRequired,
  showNotifications: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  currentItem: propTypes.object.isRequired,
  currentAction: propTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ remove, receive }, dispatch),
)(EditPhasesActionModalRemove);
