import React from "react";
import "./HiddenVacancies.scss";
import {
  BoxTitle,
  HiddenVacanciesList
} from "../../index";
import intl from "react-intl-universal";

const HiddenVacancies = ( ) => {
  return (
    <div className="vg-hidden-vacancies-page">
      <BoxTitle
        title={intl.get("VAGAS_OCULTAS.BOX_TITLE.TITULO")}
        text={intl.getHTML("VAGAS_OCULTAS.BOX_TITLE.TEXTO")}
      />
      <div className="vg-container">
        {<HiddenVacanciesList
          path="processos-seletivos/ocultas"
          type="hidden"
          paginate
        />}
      </div>
    </div>
  );
};

export default HiddenVacancies;
