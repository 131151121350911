import React from "react";
import { Field } from "react-final-form";
import PropTypes from "prop-types";
import { map } from "lodash";

const FilterItem = ({ type, items, filtering, filtered }) =>
  map(items, (item) => (
    <div
      className={`vg-custom-check ${
        !filtered.some((filter) => filter.id === item.id) && filtering && "hide"
      }`}
      key={`${type}-${item.id}`}
    >
      <label className="vg-label">
        <span className="vg-input-checkbox">
          <Field
            value={`${item.id}`}
            name={type}
            component="input"
            type="checkbox"
          />
          <span className="vg-input-checkbox-inner" />
        </span>
        <span className="vg-label-text">{item.nome}</span>
      </label>
    </div>
  ));

export default FilterItem;

FilterItem.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  filtering: PropTypes.bool.isRequired,
  filtered: PropTypes.array.isRequired,
};
