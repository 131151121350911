import React from "react";
import intl from "react-intl-universal";
import { bool, string } from "prop-types";
import "./Loader.scss";

const Loader = ({ message = intl.getHTML("CARREGANDO"), isSending }) => (
  <div className={`vg-loader-wrap ${isSending && "vg-loading"}`}>
    <div className="vg-loader">
      <span className="vg-loader-inner" />
    </div>{" "}
    <p>{message}</p>
  </div>
);

Loader.propTypes = {
  message: string,
  isSending: bool,
};

export default Loader;
