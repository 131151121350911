import propTypes from "prop-types";
import React, { Component } from "react";
import intl from "react-intl-universal";
import { intlReplace } from "../../../utils/IntlReplace";
import { Icon, Link } from "../../index";
import { map } from "lodash";

class TabList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isToggleOn: false,
    };

    this.openMenuTab = this.openMenuTab.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  openMenuTab() {
    this.setState((prevState) => ({
      isToggleOn: !prevState.isToggleOn,
    }));
  }
  changeTab(tab) {
    this.props.toggleTab(tab);
    this.openMenuTab();
  }

  render() {
    const { tabList, currentTab } = this.props;
    return (
      <div className="vg-content-list-tabs">
        <ul
          className={`vg-list-tabs ${this.state.isToggleOn && "vg-tab-open"}`}
        >
          {map(tabList, (tab) => {
            return (
              <li
                className={`vg-tab-${tab} ${
                  currentTab == tab && "vg-tab-active"
                }`}
                key={tab}
              >
                <Link onClick={() => this.changeTab(tab)}>
                  {intl.get(`CONFIGURACOES.TITULOS_ABAS.${intlReplace(tab)}`)}
                </Link>
              </li>
            );
          })}
        </ul>
        <Link
          className={`vg-btn-primary vg-btn-outline vg-btn-tab ${
            this.state.isToggleOn && "vg-btn-active"
          }`}
          onClick={this.openMenuTab}
        >
          <Icon iconKey="arrow" className="vg-menu-icon" />
        </Link>
      </div>
    );
  }
}

TabList.defaultProps = {
  currentTab: "",
};

TabList.propTypes = {
  tabList: propTypes.array.isRequired,
  toggleTab: propTypes.func.isRequired,
  currentTab: propTypes.string.isRequired,
};

export default TabList;
