// Action Types
const LOAD_HELP = "services/LOAD_HELP";
const LOAD_HELP_SUCCESS = "services/LOAD_HELP_SUCCESS";
const LOAD_HELP_FAIL = "services/LOAD_HELP_FAIL";
const OPEN_HELP = "services/OPEN_HELP";
const CLOSE_HELP = "services/CLOSE_HELP";
const TOGGLE_HELP = "services/TOGGLE_HELP";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  isOpen: false,
};

// Reducer
export default function Help(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_HELP:
      return {
        ...state,
        loading: true,
      };
    case LOAD_HELP_SUCCESS:
      return {
        ...state,
        loaded: true,
        loading: false,
      };
    case LOAD_HELP_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    case OPEN_HELP:
      return {
        ...state,
        isOpen: true,
      };
    case CLOSE_HELP:
      return {
        ...state,
        isOpen: false,
      };
    case TOGGLE_HELP:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    default:
      return state;
  }
}

export function load(path, options = {}) {
  return {
    types: [LOAD_HELP, LOAD_HELP_SUCCESS, LOAD_HELP_FAIL],
    promise: (client) => client.get(path, options),
  };
}

export function toggleHelp() {
  return { type: TOGGLE_HELP };
}
