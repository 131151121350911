import React, { useState, useEffect } from "react";
import intl from "react-intl-universal";
import PropTypes from "prop-types";
import { Icon, ButtonCancel } from "../../index";
import { TermContext } from "./AcceptanceTermList";
import { updateAcceptanceTerm, showNotifications } from "./functions";
import DeleteModal from "./Modals/DeleteModal";

const Url = (props) => {
  const initialTxt = "";
  const [message, setMessage] = useState("info");
  const [errorSubmit, setErrorSubmit] = useState("");
  const { terms, dispatchTerms, companyId } = React.useContext(TermContext);
  const [termUrl, setTermUrl] = useState("");
  const [initialTerm, setInitialTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [payload, setPayload] = useState({
    term: "",
    division: props.id,
    type: "url",
    shouldSubmit: false,
    termId: "",
  });
  const urlRequest = "termo_aceite";

  useEffect(() => {
    const divisionTerm = terms.find((element) => element.id == props.id);
    if (divisionTerm && divisionTerm.type == "url") {
      setTermUrl(divisionTerm.term);
      setInitialTerm(divisionTerm.term);
      divisionTerm.termId &&
        setPayload((oldState) => ({
          ...oldState,
          termId: divisionTerm.termId,
        }));
    }
  }, [terms]);

  useEffect(() => {
    validURL(termUrl);
  }, [termUrl]);

  function handleChange(e) {
    let url = e.target.value;
    setErrorSubmit("");
    setTermUrl(url);
  }

  function validURL(url) {
    let pattern = new RegExp(
      "http[s]?://(?:[a-zA-Z]|[0-9]|[$-_@.&+]|[!*,]|(?:%[0-9a-fA-F][0-9a-fA-F]))"
    ); // fragment locator
    if (url === "") {
      setMessage("info");
    } else if (!!pattern.test(url) == false) {
      setMessage("toastError");
    } else {
      setMessage("checkCircleFilled");
      updatePayload(url);
    }
  }

  function updatePayload(url) {
    if (url != initialTxt) {
      setPayload((oldState) => ({ ...oldState, term: url }));
    }
  }

  function span(message) {
    return (
      <span className={`vg-span-text ${message}`}>
        <Icon iconKey={message} addClass={`vg-icon-${message}`} />
        {intl.get(`CONFIGURACOES.TERMO_ACEITE.URL.${message.toUpperCase()}`)}
      </span>
    );
  }

  function spanError(errorSubmit) {
    if (errorSubmit == "Error") {
      return (
        <span className="vg-span-text toastError">
          <Icon iconKey="toastError" addClass="vg-icon-toastError" />
          {intl.get("CONFIGURACOES.TERMO_ACEITE.URL.TOASTERROR")}
        </span>
      );
    }
  }

  const submit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const copyPayload = { ...payload };
    delete copyPayload.shouldSubmit;

    if (message != "toastError") {
      updateAcceptanceTerm(copyPayload, urlRequest).then((response) => {
        if (response.status === 200) {
          response.json().then((termoAceite) => {
            let responseTerm = termoAceite.termo ? termoAceite.termo : "";
            dispatchTerms({
              type: "updateTerm",
              payload: {
                divisionId: termoAceite.divisao_id || 0,
                term: responseTerm,
                companyId: companyId,
                type: "url",
                termId: termoAceite.id,
              },
            });
            setTermUrl(responseTerm);
            responseTerm === ""
              ? showNotifications("delete")
              : showNotifications("success");
          });
        } else {
          showNotifications("error");
        }
      });
    }
  };

  const deleteFile = (e) => {
    e.preventDefault();
    if (initialTerm === "") {
      return;
    }
    setIsOpen(true);
  };

  const cancelChange = () => {
    setIsOpen(false);
  };

  const deleteTerms = (e) => {
    e.preventDefault();
    setPayload((oldState) => ({ ...oldState, term: "", shouldSubmit: true }));
    setIsOpen(false);
  };

  useEffect(() => {
    if (payload.shouldSubmit) {
      submit();
      setPayload((oldState) => ({ ...oldState, shouldSubmit: false }));
    }
  }, [payload.shouldSubmit]);

  return (
    <form>
      <div className={`vg-input-text ${message}`}>
        <input
          type="text"
          name="term-url"
          value={termUrl}
          onChange={(e) => handleChange(e)}
          autoComplete="off"
          placeholder={intl.get("CONFIGURACOES.TERMO_ACEITE.URL.PLACEHOLDER")}
        />
        <button onClick={deleteFile}>
          <Icon iconKey="trashAlt" addClass="icon-trash" />
        </button>
      </div>
      {span(message)}
      {spanError(errorSubmit)}
      <div className="vg-input-box-flex">
        <ButtonCancel
          url={{
            setTermUrl: setTermUrl,
            initialTerm: initialTerm,
            termUrl: termUrl,
          }}
        />
        <button
          className={`vg-input-button ${
            message != "checkCircleFilled" || initialTerm == termUrl
              ? "disabled"
              : "enabled"
          }`}
          onClick={submit}
          disabled={message != "checkCircleFilled" || initialTerm == termUrl}
        >
          {intl.get("CONFIGURACOES.TERMO_ACEITE.URL.ENVIAR")}
        </button>
      </div>
      <DeleteModal
        cancelChange={cancelChange}
        deleteTerms={deleteTerms}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </form>
  );
};

Url.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Url;
