import React from "react";
import { Icon } from "../../index";
import icons from "../../../utils/icons";
import shortid from "shortid";

const IconsList = () => {
  return (
    <div className="vg-container">
      <h2>Ícones Disponíveis</h2>
      <div className="vg-container">
        {Object.keys(icons)
          .sort()
          .map((iconKey) => {
            return (
              <div key={shortid.generate()} className="vg-row center-md">
                <div className="vg-col-md-4">
                  <p>Key: {iconKey}</p>
                </div>
                <div className="vg-col-md-2">
                  <Icon iconKey={iconKey} />
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default IconsList;
