import propTypes from "prop-types";
import React, { Component } from "react";
import { Modal, Icon, Button, Toast } from "../../index";
import intl from "react-intl-universal";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { post, closeModalTriagem } from "../../../store/modules/SelectSorting";
import { toast } from "react-toastify";

import "./ModalSorting.scss";

const mapStateToProps = (state) => ({
  SelectSorting: state.SelectSorting.data.SelectSorting,
});

export class ModalSorting extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      selectedSorting: "",
    };
  }

  componentDidMount() {
    document.addEventListener("keyup", (e) => {
      if (e.keyCode === 27) {
        this.props.closeModalTriagem();
      }
    });
  }

  // Método utilizado para exibir os feedbacks dos usuários
  showNotifications(message, el) {
    toast(<Toast message={message} type="success" />, {
      position: "bottom-right",
      autoClose: 2000,
      closeOnClick: true,
      pauseOnHover: true,
      className: "sr-toast vg-toast-success",
      onClose: () => this.redirect(el),
    });
  }

  handleClick(el) {
    const { post } = this.props;

    const data = {
      configuracao_usuario: {
        triagem_padrao: el,
      },
    };
    this.setState({
      selectedSorting: el,
    });

    post(data).then(() => {
      this.showNotifications(
        intl.getHTML("MODAL_SELECAO_TRIAGEM.MENSAGEM_SUCESSO"),
        el,
      );
    });
  }
  redirect(el) {
    const moveTo =
      el === "triagem_1"
        ? "ver-candidaturas-triagem1"
        : "ver-candidaturas-triagem2";
    window.location = `/move-to/${moveTo}?url_params=vagas&codigo_da_vaga=${this.props.idTriagem}`;
  }
  render() {
    const { toggleModalTriagem, isOpenTriagem } = this.props;
    const { selectedSorting } = this.state;
    return (
      <Modal show={isOpenTriagem} onClose={toggleModalTriagem}>
        <div className="vg-modal-sorting">
          <h2>{intl.get("MODAL_SELECAO_TRIAGEM.TITULO")}</h2>
          <p>{intl.get("MODAL_SELECAO_TRIAGEM.SUB_TITULO")}</p>
          <div className=" vg-row vg-modal-sorting-content">
            <div className="vg-col-md-6 vg-col-xs-12">
              <Button
                onClick={() => this.handleClick("triagem_1")}
                className={`${
                  selectedSorting === "triagem_1" && "vg-link-triagem-selected"
                }  vg-link-triagem`}
              >
                <Icon iconKey="triagem1" />
                <Icon iconKey="aprovada_com_vaga" />
                <p>{intl.get("MODAL_SELECAO_TRIAGEM.TRIAGEM_1")}</p>
              </Button>
            </div>
            <div className="vg-col-md-6 vg-col-xs-12">
              <Button
                onClick={() => this.handleClick("triagem_2")}
                className={`${
                  selectedSorting === "triagem_2" && "vg-link-triagem-selected"
                } vg-link-triagem`}
              >
                <Icon iconKey="triagem2" />
                <Icon iconKey="aprovada_com_vaga" />
                <p>{intl.get("MODAL_SELECAO_TRIAGEM.TRIAGEM_2")}</p>
              </Button>
            </div>
          </div>
          <p>{intl.get("MODAL_SELECAO_TRIAGEM.AVISO")}</p>
        </div>
      </Modal>
    );
  }
}

ModalSorting.propTypes = {
  toggleModalTriagem: propTypes.func,
  isOpenTriagem: propTypes.bool,
  idTriagem: propTypes.number,
  post: propTypes.func.isRequired,
  closeModalTriagem: propTypes.func.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ post, closeModalTriagem }, dispatch),
)(ModalSorting);
