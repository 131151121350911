import React from "react";
import cx from "classnames";
import { bool, string } from "prop-types";
import { Link } from "../../index";
import "./Button.scss";

const Button = ({ to, href, primary, ...props }) => {
  const classNames = cx("vg-btn-sm", {
    primary,
  });

  if (to || href) {
    return <Link className={classNames} to={to} href={href} {...props} />;
  }
  return <button className={classNames} {...props} />;
};

Button.propTypes = {
  href: string,
  primary: bool,
  to: string,
};

export default Button;
