import React from "react";
import intl from "react-intl-universal";
import { bool, object, string } from "prop-types";
import { Icon } from "../../index";
import "./NotificationBar.scss";

export const NotificationWarningBar = ({ text }) => (
  <div className="vg-notification-bar vg-notification-bar-warning">
    <div className="vg-notification-icon vg-notification-icon-warning">
      <Icon iconKey="warning" />
    </div>
    <p className="vg-notification-text vg-notification-text-warning">{text}</p>
  </div>
);

export const NotificationErrorBar = ({ text }) => (
  <div className="vg-notification-bar vg-notification-bar-error">
    <div className="vg-notification-icon vg-notification-icon-error">
      <Icon iconKey="warning" />
    </div>
    <p className="vg-notification-text vg-notification-text-error">{text}</p>
  </div>
);

export const NotificationVagaIntl = ({ info, hasNotification }) => (
  <div className="vg-notification-intl" data-testid="modal-notifications">
    <h3> {intl.get("VAGAS.NOTIFICACOES")}</h3>
    {info.length ? (
      <>
        <ul>
          {info.map((item) => (
            <li key={item.id}>
              <div className="vg-vaga-publicada">
                <div>
                  <p>{intl.get("VAGAS.VAGA_PUBLICADA")}</p>
                  <span>
                    {item.date} {intl.get("VAGAS.AS")} {item.hour}
                  </span>
                </div>
                {item.new && hasNotification && (
                  <div>
                    <span className="vg-new-notification" />
                  </div>
                )}
              </div>
              <p>
                {intl.get("VAGAS.VAGA_PUBLICADA_INFO", {
                  vacancy: item.vacancy,
                })}
                .
              </p>
              <span className="vg-separator"></span>
            </li>
          ))}
        </ul>
      </>
    ) : (
      <div className="vg-no-notifications">
        <span>
          <box-icon name="notification-off" color="#475467"></box-icon>
        </span>
        <p>{intl.get("VAGAS.SEM_NOTIFICACOES")}</p>
      </div>
    )}
    <div className="vg-ver-todas">
      <a href="/vagas">{intl.get("VAGAS.VER_TODAS")}</a>
    </div>
  </div>
);

NotificationWarningBar.propTypes = {
  text: string.isRequired,
};
NotificationErrorBar.propTypes = {
  text: string.isRequired,
};
NotificationVagaIntl.propTypes = {
  hasNotification: bool.isRequired,
  info: object.isRequired,
};
