import React, { Component } from "react";
import { node, func, string, number } from "prop-types";

class InfiniteScroll extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      count: 1,
      value: "",
    };

    this.getItems = this.getItems.bind(this);
    this.registerScrollEvent = this.registerScrollEvent.bind(this);
    this.unRegisterScrollEvent = this.unRegisterScrollEvent.bind(this);
    this.scroll = React.createRef();
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state.value) {
      return {
        value: props.value,
        count: 1,
      };
    }

    return null;
  }

  componentDidMount() {
    // Register scroll event
    this.registerScrollEvent();
  }

  componentWillUnmount() {
    // UnRegister scroll event
    this.unRegisterScrollEvent();
  }

  getItems() {
    const { loading, count } = this.state;
    const { moreItems, total } = this.props;

    if (!loading && count < total) {
      // Set loading state to true to
      // avoid multiple requests on scroll
      this.setState(
        (prevState) => ({
          loading: true,
          count: prevState.count + 1,
        }),
        () => {
          // Get more items
          moreItems(this.state.count).then(() => {
            this.setState({
              loading: false,
            });
          });
        }
      );
    }
  }

  registerScrollEvent() {
    this.scroll.current.addEventListener("scroll", () => {
      if (
        this.scroll.current.scrollTop + this.scroll.current.clientHeight >=
        this.scroll.current.scrollHeight
      ) {
        this.getItems();
      }
    });
  }

  unRegisterScrollEvent() {
    this.scroll.current.removeEventListener("scroll", undefined, false);
  }

  render() {
    const { children } = this.props;

    return (
      <div className="vg-filter-scroll" ref={this.scroll}>
        {children}
      </div>
    );
  }
}

InfiniteScroll.propTypes = {
  children: node.isRequired,
  moreItems: func.isRequired,
  total: number,
  value: string,
};

export default InfiniteScroll;
