import React, { useEffect } from "react";
import intl from "react-intl-universal";
import { Button, Link } from "../../index";
import { intlReplace } from "../../../utils/IntlReplace";
import shortid from "shortid";
import { map } from "lodash";
import propTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { receive as receivePainel } from "../../../store/modules/Painel";

const mapStateToProps = (state) => ({
  accesses: state.Painel.painel.data,
});

const Buttons = ({
  botoes,
  id,
  toggleModal,
  toggleModalVincular,
  status_aprovacao,
  vagaId,
  popUp,
}) => {
  useEffect(() => {
    if (id > 0) {
      receivePainel("painel", "painel");
    }
  }, []);

  const linkInterno = ["ver-requisicao", "ver-fluxo-aprovacao"];

  return map(botoes, (value, key) => {
    return (
      <span key={shortid.generate()}>
        {linkInterno.includes(value) ? (
          <Button
            onClick={() =>
              value === "ver-requisicao"
                ? popUp(id)
                : value === "ver-fluxo-aprovacao" && toggleModal()
            }
            className="vg-btn-primary vg-btn-outline vg-btn-block"
          >
            {intl.get(`REQUISICOES_VAGAS.BOTOES.${intlReplace(value)}`)}
          </Button>
        ) : value === "publicar-vaga" ? (
          <Button
            onClick={() => toggleModalVincular()}
            className="vg-btn-success vg-btn-block"
          >
            {intl.get("REQUISICOES_VAGAS.BOTOES.VINCULAR")}
          </Button>
        ) : (
          <Link
            className={
              key === "primario"
                ? "vg-btn-success vg-btn-block"
                : "vg-btn-primary vg-btn-outline vg-btn-block"
            }
            moveTo={value}
            params={
              status_aprovacao.status === "aprovada_com_vaga" &&
              value === "ver-vaga"
                ? { codigo_da_vaga: vagaId }
                : { codigo_da_requisicao: id }
            }
          >
            {intl.get(`REQUISICOES_VAGAS.BOTOES.${intlReplace(value)}`)}
          </Link>
        )}
      </span>
    );
  });
};

Buttons.defaultProps = {
  accesses: {},
};

Buttons.propTypes = {
  accesses: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receivePainel }, dispatch)
)(Buttons);
