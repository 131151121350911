import "./ErrorHandler.scss";

import { Icon, Link } from "../../index";
import React, { Component } from "react";

import intl from "react-intl-universal";
import propTypes from "prop-types";

export class ErrorHandler extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { error, text, retryHandler } = this.props;

    return (
      <div className="vg-error-handler-wrapper">
        <div className="vg-error-handler">
          <Icon iconKey="spark" />
          <h2>{error}</h2>
          <p>{text}</p>
          <Link
            onClick={retryHandler}
            className="vg-btn-primary vg-btn-outline vg-btn-block"
          >
            {intl.get("GRAPHS.BOTAO")}
          </Link>
        </div>
      </div>
    );
  }
}

ErrorHandler.propTypes = {
  error: propTypes.any,
  text: propTypes.any.isRequired,
  retryHandler: propTypes.func.isRequired,
};

export default ErrorHandler;
