import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import propTypes from "prop-types";
import { map } from "lodash";
import {
  automaticActionDatalayer,
  virtualPageviewDatalayer,
} from "../../../../utils/dataLayerPush";
import Cookie from "js-cookie";
import { testList } from "../../../../store/modules/GlobalTestAction";
import { receive as painel } from "../../../../store/modules/Painel";
import { receive } from "../../../../store/modules/Divisions";
import {
  post,
  receive as receiveActions,
  update,
} from "../../../../store/modules/Actions";
import { receive as receiveMessage } from "../../../../store/modules/Messages";
import intl from "react-intl-universal";
import shortid from "shortid";

import {
  ActionDivisions,
  SelectTests,
  RichTextEditor,
  Button,
  Link,
  NotificationErrorBar,
  ContainerGrade,
  Icon,
  WarningTitle,
} from "../../../index";
export class Tests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      selectMessage: 0,
      selectMessageContent: "",
      actionForm: {
        conteudo: {
          titulo: "",
          corpo: `${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.TESTE_DATA",
            { url: "{{URL_TESTE}}", data: "{{DATA_LIMITE}}" }
          )}`,
          adendo_id: 0,
          prazo_dias: 1,
          fase_destino_por_nota: [],
          global: false,
        },
        divisao_id: 0,
        tipo_acao_id: 1,
        tipo_acao: "enviar_teste",
        ativo: true,
      },
      currentAction: {},
      isOpen: false,
      isHtml: false,
      nomeDivisao: "todas-as-divisoes",
      nomeTeste: "",
      lock: false,
      valueDeadline: "",
      max_grade: null,
      doValidGrade: false,
      isMaxGrade: false,
      showMessageMaxGrade: false,
      isGlobal: true,
      errorTitle: "",
      errorMessage: "",
    };

    this.handleTitle = this.handleTitle.bind(this);
    this.handleContent = this.handleContent.bind(this);
    this.handleActionForm = this.handleActionForm.bind(this);
    this.handleSelectTests = this.handleSelectTests.bind(this);
    this.createAction = this.createAction.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.getFormGrade = this.getFormGrade.bind(this);
    this.handleChangeDay = this.handleChangeDay.bind(this);
    this.handleButtonCountPlus = this.handleButtonCountPlus.bind(this);
    this.handleButtonCountMinor = this.handleButtonCountMinor.bind(this);
    this.setDeadLine = this.setDeadLine.bind(this);
    this.validateFormGrade = this.validateFormGrade.bind(this);
    this.goToErrorNotificationTeste =
      this.goToErrorNotificationTeste.bind(this);
    this.refErrorNotificationTeste = React.createRef();
    this.handleOnBlurTitle = this.handleOnBlurTitle.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
  }

  componentDidMount() {
    // Carrega as mensagens padrões
    this.props.receiveMessage();

    // Carrega as divisoes
    this.props.receive("divisoes", { incluir_sla: true });

    if (this.props.currentItem.id) {
      this.props
        .testList(`fases/${this.props.currentItem.id}/adendos?tipo=teste`)
        .then(
          () => {},
          (error) => {
            const status = error.response.status;

            if (status === 404) {
              this.props.showNotifications(
                intl.getHTML("FASES.MODAL_ACTION.TIPO_ACAO.ERRO_TESTE.404"),
                "error"
              );
            }
          }
        );

      virtualPageviewDatalayer(
        "/configuracoes/editar-fases/acao-automatica/teste",
        "enviar teste automatico"
      );
    }
  }

  handleChangeMessage(event) {
    const getMessage = this.props.messages.filter((message) => {
      return message.id.toString() === event.target.value;
    });
    this.setState({
      selectMessage: event.target.value,
      selectMessageContent: getMessage[0]
        ? `<div>${getMessage[0].conteudo} <div>${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.TESTE_VARIAVEL",
            { url: "{{URL_TESTE}}", data: "{{DATA_LIMITE}}" }
          )}</div></div>`
        : `<p></p>\n<div>${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.TESTE_DATA",
            {
              nome: "{{CANDIDATO_NOME}}",
              url: "{{URL_TESTE}}",
              data: "{{DATA_LIMITE}}",
            }
          )}</div>`,
      isHtml: getMessage[0] ? getMessage[0].contem_html : false,
    });
  }

  handleTitle(e) {
    let { value } = e.target;
    const { errorTitle } = this.state;

    if (value.length > 0 && errorTitle.length > 0) {
      this.setState({ errorTitle: "" });
    }
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          titulo: value,
        },
      },
    }));
  }

  handleContent(text) {
    const { errorMessage } = this.state;
    if (text.length > 8 && errorMessage.length > 0) {
      this.setState({ errorMessage: "" });
    }
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          corpo: text,
        },
      },
    }));
  }

  handleActionForm(e, type) {
    const { currentAction } = this.props;
    const targetContent = e.target.value;
    const textContent = e.target.children[e.target.selectedIndex].textContent;
    const urlDivison =
      targetContent !== "0" ? `?divisao_id=${targetContent}` : "";
    const urlActionId = currentAction.id ? `${currentAction.id}/` : "";

    const LOCALES = {
      _enUS: "en",
      _ptBR: "pt-BR",
      _esALT: "es",
    };

    if (type === "divisao_id") {
      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          [type]: targetContent,
        },
        nomeDivisao: textContent,
      }));

      fetch(
        `${process.env.REACT_APP_ATHENA_URL}fases/${this.props.currentItem.id}/acoes/${urlActionId}validar${urlDivison}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${Cookie.get("access_token")}`,
            "Accept-Language": `${
              LOCALES[Cookie.get("IntegracaoLoginIdioma")]
            }`,
            "Access-Control-Allow-Origin": process.env.REACT_APP_ACCESS_CONTROL,
          },
        }
      ).then((response) => {
        if (response.status === 409) {
          this.goToErrorNotificationTeste();
          this.setState({
            lock: true,
          });
          return;
        }
        this.setState({
          lock: false,
        });
      });
    } else {
      this.setState((prevState) => ({
        actionForm: {
          ...prevState.actionForm,
          conteudo: {
            ...prevState.actionForm.conteudo,
            [type]: targetContent,
          },
        },
      }));
    }
  }

  handleSelectTests(el) {
    const { value } = el.target;
    const textContent = el.target.children[el.target.selectedIndex].textContent;
    const { listTest } = this.props;
    const index = listTest.findIndex(
      (list) => list.ficha_id === parseInt(value, 10)
    );
    const nota_maxima = index !== -1 ? listTest[index].nota_maxima : null;

    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          adendo_id: parseInt(value, 10),
          fase_destino_por_nota:
            nota_maxima === null
              ? []
              : prevState.actionForm.conteudo.fase_destino_por_nota,
        },
      },
      nomeTeste: textContent,
      max_grade: nota_maxima,
      isMaxGrade: nota_maxima !== null ? true : false,
      showMessageMaxGrade: nota_maxima === null ? true : false,
      isGlobal: !prevState.actionForm.conteudo.global,
    }));
  }

  textCleaning(text) {
    const cleanText = text
      .replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    return cleanText;
  }

  createAction() {
    let postBody = { ...this.state.actionForm };
    const content = this.state.actionForm.conteudo.corpo;
    const { currentItem } = this.props;

    if (this.state.actionForm.divisao_id == 0) {
      delete postBody.divisao_id;
    }

    if (
      !content.includes("{{URL_TESTE}}") &&
      !content.includes("{{DATA_LIMITE}}")
    ) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{URL_TESTE}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{DATA_LIMITE}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    automaticActionDatalayer(
      "interaction",
      "enviar-teste:clicou:criar-envio-automatico",
      `divisao:${this.textCleaning(
        this.state.nomeDivisao
      )} - teste:${this.textCleaning(this.state.nomeTeste)}`,
      this.textCleaning(currentItem.nome)
    );

    this.props
      .post(`fases/${this.props.currentItem.id}/acoes`, { ...postBody })
      .then(
        () => {
          // Notificando o usuário do sucesso da criação
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_CRIADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          automaticActionDatalayer(
            "nonInteraction",
            "sucesso:acao-automatica-criada",
            "teste",
            this.textCleaning(currentItem.nome)
          );

          // Fechando o modal
          this.props.closeActionModal();
        },
        (error) => {
          if (error.response.status === 409) {
            this.goToErrorNotificationTeste();
            this.setState({
              lock: true,
            });
          } else {
            // Notificando o usuário do erro da criação
            this.props.showNotifications(
              intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_ACAO", {
                action: this.state.actionForm.conteudo.titulo,
              }),
              "error"
            );
          }
        }
      );
  }

  updateAction() {
    let postBody = { ...this.state.actionForm };
    const content = this.state.actionForm.conteudo.corpo;

    if (this.state.actionForm.divisao_id == 0) {
      postBody.divisao_id = "";
    }

    if (
      !content.includes("{{URL_TESTE}}") &&
      !content.includes("{{DATA_LIMITE}}")
    ) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{URL_TESTE}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    if (!content.includes("{{DATA_LIMITE}}")) {
      this.props.showNotifications(
        intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO_VARIAVEL_DATA", {
          action: this.state.actionForm.conteudo.titulo,
        }),
        "error"
      );
      return;
    }

    this.props
      .update(
        `fases/${this.props.currentItem.id}/acoes/${this.props.currentAction.id}`,
        { ...postBody }
      )
      .then(
        () => {
          // Notificando o usuário do sucesso da atualização
          this.props.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ACAO_ATUALIZADA", {
              action: this.state.actionForm.conteudo.titulo,
            }),
            "success"
          );

          this.props.receiveActions(this.props.currentItem.id);

          // Fechando o modal
          this.props.closeActionModal();
        },
        (error) => {
          if (error.response.status === 409) {
            this.goToErrorNotificationTeste();
            this.setState({
              lock: true,
            });
          } else {
            // Notificando o usuário do erro da atualização
            this.props.showNotifications(
              intl.getHTML("FASES.MENSAGENS.ACAO_ATUALIZADA", {
                action: this.state.actionForm.conteudo.titulo,
              }),
              "success"
            );

            this.props.receiveActions(this.props.currentItem.id);

            // Fechando o modal
            this.props.closeActionModal();
          }
        },
        (error) => {
          if (error.response.status === 409) {
            this.goToErrorNotificationTeste();
            this.setState({
              lock: true,
            });
          } else {
            // Notificando o usuário do erro da atualização
            this.props.showNotifications(
              intl.getHTML("FASES.MENSAGENS.ERRO_AO_ATUALIZAR_ACAO", {
                action: this.state.actionForm.conteudo.titulo,
              }),
              "error"
            );
          }
        }
      );
  }

  static getDerivedStateFromProps(props, state) {
    let nota_maxima = null;
    let isGlobal = true;

    if (props.divisions.divisoes && props.divisions.divisoes.length === 1) {
      if (
        Object.keys(props.currentAction).length &&
        !state.isEditing &&
        props.isOpen
      ) {
        if (props.accesses.feature_flags.includes("range-nota")) {
          const { listTest } = props;
          const index = listTest.findIndex(
            (list) => list.ficha_id === props.currentAction.conteudo.adendo_id
          );
          nota_maxima = index !== -1 ? listTest[index].nota_maxima : null;
          isGlobal = index !== -1 ? listTest[index].global : false;

          return {
            isEditing: true,
            isOpen: props.isOpen,
            actionForm: {
              conteudo: {
                ...props.currentAction.conteudo,
                prazo_dias: props.currentAction.conteudo.prazo_dias
                  ? props.currentAction.conteudo.prazo_dias
                  : 1,
                fase_destino_por_nota:
                  props.currentAction.conteudo.fase_destino_por_nota,
              },
              divisao_id: props.currentAction.divisao
                ? props.currentAction.divisao.id
                : 0,
              tipo_acao_id: props.currentAction.tipo_acao.id,
              tipo_acao: props.currentAction.tipo_acao.tipo,
              ativo: props.currentAction.ativo,
            },
            valueDeadline: `${intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS",
              {
                dias: props.currentAction.conteudo.prazo_dias
                  ? props.currentAction.conteudo.prazo_dias
                  : 1,
              }
            )}`,
            isMaxGrade: nota_maxima !== null ? true : false,
            max_grade: nota_maxima,
            showMessageMaxGrade: nota_maxima === null ? true : false,
            isGlobal: isGlobal,
          };
        }
        return {
          isEditing: true,
          isOpen: props.isOpen,
          actionForm: {
            conteudo: {
              ...props.currentAction.conteudo,
              prazo_dias: props.currentAction.conteudo.prazo_dias
                ? props.currentAction.conteudo.prazo_dias
                : 1,
            },
            divisao_id: props.currentAction.divisao
              ? props.currentAction.divisao.id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao.id,
            tipo_acao: props.currentAction.tipo_acao.tipo,
            ativo: props.currentAction.ativo,
          },
          valueDeadline: `${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS",
            {
              dias: props.currentAction.conteudo.prazo_dias
                ? props.currentAction.conteudo.prazo_dias
                : 1,
            }
          )}`,
        };
      } else {
        if (props.accesses.feature_flags.includes("range-nota")) {
          const { listTest } = props;
          const index = listTest.findIndex(
            (list) => list.ficha_id === state.actionForm.conteudo.adendo_id
          );
          nota_maxima = index !== -1 ? listTest[index].nota_maxima : null;
          isGlobal = index !== -1 ? listTest[index].global : false;
          return {
            actionForm: {
              ...state.actionForm,
              conteudo: {
                ...state.actionForm.conteudo,
                fase_destino_por_nota:
                  state.actionForm.conteudo.fase_destino_por_nota,
              },
              divisao_id: props.divisions.divisoes
                ? props.divisions.divisoes[0].id
                : 0,
              tipo_acao_id: props.currentAction.tipo_acao
                ? props.currentAction.tipo_acao.id
                : props.idStep,
            },
            isMaxGrade: nota_maxima !== null ? true : false,
            max_grade: nota_maxima,
            isGlobal: isGlobal,
          };
        }
        return {
          actionForm: {
            ...state.actionForm,
            divisao_id: props.divisions.divisoes
              ? props.divisions.divisoes[0].id
              : 0,
            tipo_acao_id: props.idStep,
          },
        };
      }
    }

    if (
      Object.keys(props.currentAction).length &&
      !state.isEditing &&
      props.isOpen
    ) {
      if (props.accesses.feature_flags.includes("range-nota")) {
        const { listTest } = props;
        const index = listTest.findIndex(
          (list) => list.ficha_id === props.currentAction.conteudo.adendo_id
        );
        nota_maxima = index !== -1 ? listTest[index].nota_maxima : null;
        isGlobal = index !== -1 ? listTest[index].global : false;

        return {
          isEditing: true,
          isOpen: props.isOpen,
          actionForm: {
            conteudo: {
              ...props.currentAction.conteudo,
              prazo_dias: props.currentAction.conteudo.prazo_dias
                ? props.currentAction.conteudo.prazo_dias
                : 1,
              fase_destino_por_nota:
                props.currentAction.conteudo.fase_destino_por_nota,
            },
            divisao_id: props.currentAction.divisao
              ? props.currentAction.divisao.id
              : 0,
            tipo_acao_id: props.currentAction.tipo_acao.id,
            tipo_acao: props.currentAction.tipo_acao.tipo,
            ativo: props.currentAction.ativo,
          },
          valueDeadline: `${intl.get(
            "FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS",
            {
              dias: props.currentAction.conteudo.prazo_dias
                ? props.currentAction.conteudo.prazo_dias
                : 1,
            }
          )}`,
          isMaxGrade: nota_maxima !== null ? true : false,
          max_grade: nota_maxima,
          isGlobal: isGlobal,
        };
      }

      return {
        isEditing: true,
        isOpen: props.isOpen,
        actionForm: {
          conteudo: {
            ...props.currentAction.conteudo,
            prazo_dias: props.currentAction.conteudo.prazo_dias
              ? props.currentAction.conteudo.prazo_dias
              : 1,
          },
          divisao_id: props.currentAction.divisao
            ? props.currentAction.divisao.id
            : 0,
          tipo_acao_id: props.currentAction.tipo_acao.id,
          tipo_acao: props.currentAction.tipo_acao.tipo,
          ativo: props.currentAction.ativo,
        },
        valueDeadline: `${intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS", {
          dias: props.currentAction.conteudo.prazo_dias
            ? props.currentAction.conteudo.prazo_dias
            : 1,
        })}`,
      };
    }

    if (
      props.isOpen !== state.isOpen &&
      props.accesses.feature_flags &&
      props.accesses.feature_flags.includes("range-nota")
    ) {
      return {
        isEditing: false,
        isOpen: props.isOpen,
        selectMessage: 0,
        actionForm: {
          conteudo: {
            titulo: "",
            corpo: `${intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.TESTE_DATA",
              { url: "{{URL_TESTE}}", data: "{{DATA_LIMITE}}" }
            )}`,
            adendo_id: 0,
            prazo_dias: 1,
            fase_destino_por_nota: [],
          },
          divisao_id: 0,
          tipo_acao_id: props.idStep,
          tipo_acao: "enviar_teste",
          ativo: true,
        },
        isMaxGrade: false,
        max_grade: null,
        isGlobal: true,
      };
    }

    if (props.isOpen !== state.isOpen) {
      return {
        isEditing: false,
        isOpen: props.isOpen,
        selectMessage: 0,
        actionForm: {
          conteudo: {
            titulo: "",
            corpo: `${intl.get(
              "FASES.MODAL_ACTION.TIPO_ACAO.MENSAGEM_DEFAULT.TESTE_DATA",
              { url: "{{URL_TESTE}}", data: "{{DATA_LIMITE}}" }
            )}`,
            adendo_id: 0,
            prazo_dias: 1,
          },
          divisao_id: 0,
          tipo_acao_id: props.idStep,
          tipo_acao: "enviar_teste",
          ativo: true,
        },
      };
    }

    if (props.accesses.feature_flags.includes("range-nota")) {
      const { listTest } = props;

      if (listTest.length > 0) {
        const index = listTest.findIndex(
          (list) => list.ficha_id === state.actionForm.conteudo.adendo_id
        );
        nota_maxima = index !== -1 ? listTest[index].nota_maxima : null;
        isGlobal = index !== -1 ? listTest[index].global : false;
        return {
          isMaxGrade: nota_maxima !== null ? true : false,
          max_grade: nota_maxima,
          showMessageMaxGrade:
            nota_maxima === null && state.actionForm.conteudo.adendo_id !== 0
              ? true
              : false,
          isGlobal: isGlobal,
        };
      }
    }

    return null;
  }

  closeModal() {
    const { currentItem } = this.props;
    automaticActionDatalayer(
      "interaction",
      "enviar-teste:clicou:cancelar",
      `divisao:${this.textCleaning(
        this.state.nomeDivisao
      )} - teste:${this.textCleaning(this.state.nomeTeste)}`,
      this.textCleaning(currentItem.nome)
    );
    this.props.closeActionModal();
  }
  setDeadLine(value) {
    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          prazo_dias: value,
        },
      },
      valueDeadline: `${intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS", {
        dias: value,
      })}`,
    }));
  }

  handleChangeDay(event) {
    const value = Number(event.target.value.replace(/[^\d/]/g, ""));

    this.setDeadLine(value);
  }

  handleButtonCountPlus() {
    const { actionForm } = this.state;
    const newValue = actionForm.conteudo.prazo_dias
      ? actionForm.conteudo.prazo_dias + 1
      : 1;

    this.setDeadLine(newValue);
  }

  handleButtonCountMinor() {
    const { actionForm } = this.state;
    const newValue =
      actionForm.conteudo.prazo_dias <= 1
        ? 1
        : actionForm.conteudo.prazo_dias - 1;

    this.setDeadLine(newValue);
  }

  getFormGrade(form) {
    let isValid = true;
    form.forEach((grade) => {
      if (!grade.validated) {
        isValid = false;
      }
    });

    this.setState((prevState) => ({
      actionForm: {
        ...prevState.actionForm,
        conteudo: {
          ...prevState.actionForm.conteudo,
          fase_destino_por_nota: form,
        },
      },
      doValidGrade: isValid,
    }));
  }

  validateFormGrade() {
    const { accesses } = this.props;
    const {
      actionForm: {
        conteudo: { fase_destino_por_nota },
      },
      max_grade,
    } = this.state;
    if (
      accesses.feature_flags &&
      accesses.feature_flags.includes("range-nota") &&
      fase_destino_por_nota.length > 0
    ) {
      this.setState({ doValidGrade: true });
      let isValid = true;
      fase_destino_por_nota.forEach((grade) => {
        if (!grade.validated) {
          isValid = false;
        }
      });

      if (isValid) {
        const bodyToSend = JSON.stringify({
          intervalo_notas: fase_destino_por_nota,
          nota_maxima: max_grade,
        });

        fetch(
          `${process.env.REACT_APP_ATHENA_URL}fases/acoes/intervalo_notas/validar`,
          {
            method: "POST",
            body: bodyToSend,
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${Cookie.get("access_token")}`,
              "Access-Control-Allow-Origin":
                process.env.REACT_APP_ACCESS_CONTROL,
            },
          }
        )
          .then((response) => {
            if (response.status === 200) {
              this.setState({ doValidGrade: true });
              this.state.isEditing ? this.updateAction() : this.createAction();
            } else {
              return response.json();
            }
          })
          .then((data) => {
            if (data.code === 412) {
              const message = data.error;
              this.props.showNotifications(
                intl.getHTML("FASES.GRADE.MESSAGE_ERROR.ERRO_SOBREESCRITA", {
                  action: message,
                }),
                "error"
              );
            } else {
              this.props.showNotifications(
                intl.getHTML("FASES.GRADE.MESSAGE_ERROR.ERRO_DEFAULT"),
                "error"
              );
            }
          });
      } else {
        this.props.showNotifications(
          intl.getHTML("FASES.GRADE.MESSAGE_ERROR.FORM_VAZIO"),
          "error"
        );
      }
      setTimeout(() => {
        this.setState({ doValidGrade: false });
      }, 2000);
    } else {
      this.state.isEditing ? this.updateAction() : this.createAction();
    }
  }

  getMaxGrade() {
    const {
      actionForm: {
        conteudo: { adendo_id },
      },
    } = this.state;

    const { listTest } = this.props;
    const index = listTest.findIndex((list) => list.ficha_id === adendo_id);
    if (index === -1) return null;

    return listTest[index].nota_maxima;
  }

  isMaxGradeTruely() {
    const {
      actionForm: {
        conteudo: { adendo_id },
      },
    } = this.state;

    const { listTest } = this.props;
    if (listTest.length > 0) {
      const index = listTest.findIndex((list) => list.ficha_id === adendo_id);
      if (index !== -1 && listTest[index].nota_maxima !== null) return true;
    }

    return false;
  }
  handleOnBlurTitle() {
    const { actionForm } = this.state;
    !actionForm.conteudo.titulo.length
      ? this.setState({ errorTitle: "vg-empty-content-error" })
      : this.setState({ errorTitle: "" });
  }

  handleMessage() {
    const { actionForm } = this.state;
    actionForm.conteudo.corpo.length === 8
      ? this.setState({ errorMessage: "vg-empty-content-error" })
      : this.setState({ errorMessage: "" });
  }

  goToErrorNotificationTeste() {
    this.refErrorNotificationTeste.current.scroll(0, 0);
  }

  render() {
    const {
      selectMessageContent,
      actionForm,
      isHtml,
      selectMessage,
      isEditing,
      lock,
      valueDeadline,
      max_grade,
      doValidGrade,
      isMaxGrade,
      isGlobal,
      errorTitle,
      errorMessage,
    } = this.state;
    const {
      divisions,
      listTest,
      messages,
      currentItem,
      currentAction,
      accesses,
    } = this.props;
    return (
      <div>
        <div
          className="vg-phases-modal-content vg-test-scroll"
          ref={this.refErrorNotificationTeste}
        >
          <h1>
            {isEditing
              ? intl.get(
                  "FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.TITULO_ATUALIZAR"
                )
              : intl.get("FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.TITULO")}
          </h1>
          <p>
            {intl.get("FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.DESCRICAO")}{" "}
            <strong>{currentItem.nome}</strong>
          </p>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-selects-divisons-tests">
              {divisions.divisoes && divisions.divisoes.length > 1 && (
                <div className="vg-select-test">
                  <ActionDivisions
                    divisions={divisions}
                    actionForm={actionForm}
                    handleActionForm={this.handleActionForm}
                    isEditing={!!(isEditing && currentAction.divisao)}
                  />
                </div>
              )}

              <div className="vg-select-test">
                <SelectTests
                  handleSelectTests={this.handleSelectTests}
                  actionForm={actionForm}
                  items={listTest}
                  type="enviar_teste"
                />
              </div>

              {lock && (
                <div className="vg-notifications">
                  <NotificationErrorBar
                    text={intl.get("FASES.MENSAGENS.ERRO_CONFLITO_TESTE")}
                  />
                </div>
              )}
            </div>
          </div>
          {accesses.feature_flags &&
            accesses.feature_flags.includes("range-nota") &&
            actionForm.conteudo.adendo_id !== 0 && (
              <div className="vg-row center-xs">
                <ContainerGrade
                  onUpdateFormGrid={this.getFormGrade}
                  maxGrade={max_grade}
                  isMaxGrade={isMaxGrade}
                  fase_destino_por_nota={
                    actionForm.conteudo.fase_destino_por_nota
                  }
                  idTest={actionForm.conteudo.adendo_id}
                  isGlobal={isGlobal}
                  listTest={listTest}
                  realPhase={currentItem.id}
                />
              </div>
            )}
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-record-deadline">
              <h3>{intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.TITULO")}</h3>
              <p>
                {intl.get("FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.TEXTO_TESTE")}
              </p>
              <div className="vg-record-deadline-form">
                <Button
                  disabled={actionForm.conteudo.prazo_dias <= 1}
                  className="vg-record-deadline-btn-count"
                  data-type="record-dealine-minor"
                  onClick={this.handleButtonCountMinor}
                >
                  <Icon iconKey="minor_light" data-type="record-dealine-plus" />
                </Button>
                <input
                  type="text"
                  value={valueDeadline}
                  onChange={this.handleChangeDay}
                  placeholder={intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.PRAZO.DIAS",
                    { dias: 1 }
                  )}
                  maxLength={7}
                  className="vg-record-deadline-view"
                />
                <Button
                  disabled={actionForm.conteudo.prazo_dias >= 99}
                  className="vg-record-deadline-btn-count"
                  data-type="record-dealine-plus"
                  onClick={this.handleButtonCountPlus}
                >
                  <Icon iconKey="plus_light" data-type="record-dealine-plus" />
                </Button>
              </div>
            </div>
          </div>
          <div className="vg-row center-xs">
            <div className="vg-col-md-10 vg-col-xs-12 vg-form vg-title-test">
              <label className="vg-label">
                <span className="vg-label-text">
                  {intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.LABEL_TITULO"
                  )}
                </span>
                <input
                  type="text"
                  value={actionForm.conteudo.titulo}
                  onChange={(e) => this.handleTitle(e)}
                  onBlur={this.handleOnBlurTitle}
                  className={`vg-input-text ${errorTitle}`}
                />
                <WarningTitle
                  error={errorTitle}
                  text={intl.get("FASES.MODAL_ACTION.ERRO_GENERICO.TITULO")}
                />
              </label>
            </div>
            <div className="vg-col-md-10 vg-col-xs-12">
              <RichTextEditor
                changeContent={(e) => this.handleContent(e)}
                content={actionForm.conteudo.corpo}
                newContent={selectMessageContent}
                withVariables
                isHtml={isHtml}
                isTest={true}
                onBlur={this.handleMessage}
                classError={errorMessage}
              />
              <WarningTitle
                error={errorMessage}
                text={intl.get("FASES.MODAL_ACTION.ERRO_GENERICO.MENSAGEM")}
              />
            </div>
          </div>
          {messages.length > 0 && (
            <div className="vg-row center-xs">
              <div className="vg-col-md-10 vg-col-xs-12">
                <p>{intl.getHTML("HOME.CONTEUDO.ENVIAR_MENSAGEM.TEXTO3")}</p>

                <div className="vg-custom-select">
                  <select
                    value={selectMessage}
                    onChange={this.handleChangeMessage}
                    className="vg-select-messages"
                  >
                    <option value={0}>-</option>
                    {map(messages, (message) => {
                      return (
                        <option key={shortid.generate()} value={message.id}>
                          {message.identificacao}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="vg-wrap-buttons">
          <Link onClick={this.closeModal} className="vg-btn-link">
            {intl.get("FASES.MODAL.BOTAO_2")}
          </Link>
          {accesses.feature_flags &&
          accesses.feature_flags.includes("range-nota") &&
          actionForm.conteudo.adendo_id !== 0 ? (
            <Button
              onClick={this.validateFormGrade}
              disabled={
                actionForm.conteudo.titulo === "" ||
                actionForm.conteudo.corpo === "<p></p>\n" ||
                actionForm.conteudo.corpo === "" ||
                actionForm.conteudo.adendo_id == 0 ||
                lock === true ||
                !doValidGrade
              }
              className="vg-btn-success"
            >
              {this.state.isEditing
                ? intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.BOTAO_EDITAR"
                  )
                : intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.BOTAO_SALVAR"
                  )}
            </Button>
          ) : (
            <Button
              onClick={this.validateFormGrade}
              disabled={
                actionForm.conteudo.titulo === "" ||
                actionForm.conteudo.corpo === "<p></p>\n" ||
                actionForm.conteudo.corpo === "" ||
                actionForm.conteudo.adendo_id == 0 ||
                lock === true
              }
              className="vg-btn-success"
            >
              {this.state.isEditing
                ? intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.BOTAO_EDITAR"
                  )
                : intl.get(
                    "FASES.MODAL_ACTION.TIPO_ACAO.enviar_teste.BOTAO_SALVAR"
                  )}
            </Button>
          )}
        </div>
      </div>
    );
  }
}

Tests.defaultProps = {
  messages: [],
  divisions: {
    sla: [],
  },
  currentAction: {},
  accesses: {
    feature_flags: [],
  },
};

Tests.propTypes = {
  receiveMessage: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  divisions: propTypes.object.isRequired,
  listTest: propTypes.array.isRequired,
  currentAction: propTypes.object.isRequired,
  isOpen: propTypes.bool.isRequired,
  closeActionModal: propTypes.func.isRequired,
  testList: propTypes.func.isRequired,
  currentItem: propTypes.object.isRequired,
  painel: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  showNotifications: propTypes.func.isRequired,
  receiveActions: propTypes.func.isRequired,
  idStep: propTypes.number.isRequired,
  messages: propTypes.array.isRequired,
  update: propTypes.func.isRequired,
  phases: propTypes.array.isRequired,
  accesses: propTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  divisions: state.Divisions.sla,
  listTest: state.GlobalTestAction.data,
  messages: state.Messages.data.mensagens_padrao,
  phases: state.Phases.data,
  accesses: state.Painel.painel.data,
});

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators(
    {
      receive,
      receiveMessage,
      testList,
      painel,
      post,
      receiveActions,
      update,
    },
    dispatch
  )
)(Tests);
