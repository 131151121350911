import { styled, keyframes } from "@vagastec/epartner-ui";

export const VacancieLabel = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  margin: 0 0 16px;
`;

export const ButtonAccordion = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: 0;
  font-size: 1.8em;
  font-weight: ${({ theme }) => theme.font.regular};

  position: relative;
  text-align: left;
  width: 100%;

  &.last-button {
    border-top: solid 1px ${({ theme }) => theme.colors.lightGray};
  }
`;

export const FormStyle = styled.form`
  margin: 0 0 24px;

  p {
    margin: 0 0 16px;
    padding: 0;
  }
`;

export const AccordionContent = styled.div`
  padding: 20px 36px;
`;

export const BodyModal = styled.div`
  padding: 32px;
`;

export const ContainerAccordion = styled.div`
  border: solid 1px ${({ theme }) => theme.colors.lightGray};
  border-radius: 4px;
  margin: 0 0 24px;
`;

const InfiniteFade = keyframes`
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
`;

export const VacanciesLoad = styled.div`
  animation: ${InfiniteFade} 0.4s linear 0.2s infinite both alternate;
  display: block;
  margin: 24px 0 0;
  width: 100%;
`;

export const ButtonContainer = styled.div`
  float: right;

  button + button {
    margin: 0 0 0 8px;
  }
`;
