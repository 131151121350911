import React, { Component } from "react";
import PropTypes from "prop-types";
import intl from "react-intl-universal";
import { Input, SelectDivUnit, Radio, Toast } from "../../index";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { addUser, atualizaUser } from "../../../store/modules/UsersRede";

import "./UserRedesForm.scss";

const mapStateToProps = () => ({});

class UserRedesForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedUserType: this.props.funcionarioPerfil || "administrador",
      userLogin: this.props.funcionarioIdentidade || "",
      userName: this.props.funcionarioNome || "",
      userEmail: this.props.funcionarioEmail || "",
      userUnidade: "",
      divisao_id: "",
      unidadeMessageError: "",
      loginMessageError: "",
      emailMessageError: "",
      nameMessageError: "",
    };

    this.radios = [
      [
        "UserType",
        "administrador",
        "UNIDADES_REDES.FORM_ADD_USER.RADIO_TYPE_USER_ADMIN",
      ],
      [
        "UserType",
        "normal",
        "UNIDADES_REDES.FORM_ADD_USER.RADIO_TYPE_USER_PADRAO",
      ],
    ];
    this.handleRadios = this.handleRadios.bind(this);
    this.handleUserLogin = this.handleUserLogin.bind(this);
    this.handleUserName = this.handleUserName.bind(this);
    this.handleUserEmail = this.handleUserEmail.bind(this);
    this.userFormClick = this.userFormClick.bind(this);
    this.handleSelectForm = this.handleSelectForm.bind(this);
  }

  handleRadios(e) {
    this.setState({
      checkedUserType: e.target.value,
    });
  }

  handleUserLogin(e) {
    this.setState({
      userLogin: e.target.value,
      loginMessageError: "",
    });
  }

  handleUserName(e) {
    this.setState({
      userName: e.target.value,
      nameMessageError: "",
    });
  }

  handleUserEmail(e) {
    let emailValue = e.target.value;
    this.setState({
      userEmail: emailValue,
    });
    if (emailValue.length > 5 && !/\S+@\S+\.\S+/.test(emailValue)) {
      this.setState({
        emailMessageError: intl.get(
          "UNIDADES_REDES.FORM_ADD_USER.MSG_EMAIL_INVALIDO",
        ),
      });
    } else {
      this.setState({
        emailMessageError: "",
      });
    }
  }

  handleSelectForm(value) {
    let params = value.split("_"),
      unidadeValue = parseInt(params[0]),
      divisao_id = parseInt(params[1]);
    if (unidadeValue > 0) {
      this.setState({
        userUnidade: unidadeValue,
        divisao_id: divisao_id,
        unidadeMessageError: "",
      });
    } else {
      this.setState({
        userUnidade: 0,
        unidadeMessageError: "",
      });
    }
  }

  showNotifications(message, type = "success") {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  }

  userFormClick() {
    const {
      userName,
      userLogin,
      userEmail,
      userUnidade,
      checkedUserType,
      divisao_id,
    } = this.state;
    const { addUser, atualizaUser, funcionarioID } = this.props;

    if (this.verifyForm()) {
      if (!funcionarioID) {
        const data = {
          cliente_id: userUnidade,
          identidade: userLogin,
          nome: userName,
          email: userEmail,
          perfil: checkedUserType,
          divisao_id: divisao_id,
        };
        addUser(data, userUnidade)
          .then(() => {
            this.showNotifications(
              intl.getHTML("UNIDADES_REDES.FORM_ADD_USER.TOST_MSG_SUCCESS"),
              "success",
            );
            this.props.onClose();
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll");
          })
          .catch((resp) => {
            let response = resp.response.body;
            if (response.mensagem.includes("usuário") && response.mensagem) {
              this.setState({
                loginMessageError: response.mensagem,
              });
            } else {
              this.showNotifications(
                intl.getHTML("UNIDADES_REDES.FORM_ADD_USER.TOST_MSG_ERROR"),
                "error",
              );
            }
          });
      } else {
        const data = {
          funcId: funcionarioID,
          identidade: userLogin,
          nome: userName,
          email: userEmail,
          perfil: checkedUserType,
        };
        atualizaUser(funcionarioID, data)
          .then(() => {
            this.showNotifications(
              intl.getHTML("UNIDADES_REDES.FORM_EDIT_USER.TOST_MSG_SUCCESS"),
              "success",
            );
            this.props.onClose();
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll");
          })
          .catch((resp) => {
            let response = resp.response.body;
            if (response.mensagem.includes("usuário") && response.mensagem) {
              this.setState({
                loginMessageError: response.mensagem,
              });
            } else {
              this.showNotifications(
                intl.getHTML("UNIDADES_REDES.FORM_EDIT_USER.TOST_MSG_ERROR"),
                "error",
              );
            }
          });
      }
    }
  }

  verifyForm() {
    const { userName, userEmail, userLogin, userUnidade, emailMessageError } =
      this.state;
    const { funcionarioID } = this.props;
    let formInvalid = false;

    if (!userUnidade && !funcionarioID) {
      this.setState({
        unidadeMessageError: intl.get(
          "UNIDADES_REDES.FORM_ADD_USER.MSG_COMPO_OBRIGATORIO",
        ),
      });
      formInvalid = true;
    }
    if (!userName) {
      this.setState({
        nameMessageError: intl.get(
          "UNIDADES_REDES.FORM_ADD_USER.MSG_COMPO_OBRIGATORIO",
        ),
      });
      formInvalid = true;
    }
    if (!userEmail || userEmail.length < 6) {
      this.setState({
        emailMessageError: intl.get(
          "UNIDADES_REDES.FORM_ADD_USER.MSG_COMPO_OBRIGATORIO",
        ),
      });
      formInvalid = true;
    }
    if (emailMessageError) {
      this.setState({
        emailMessageError: intl.get(
          "UNIDADES_REDES.FORM_ADD_USER.MSG_EMAIL_INVALIDO",
        ),
      });
      formInvalid = true;
    }
    if (!userLogin) {
      this.setState({
        loginMessageError: intl.get(
          "UNIDADES_REDES.FORM_ADD_USER.MSG_COMPO_OBRIGATORIO",
        ),
      });
      formInvalid = true;
    }
    return formInvalid ? false : true;
  }

  render() {
    const {
      checkedUserType,
      userLogin,
      userName,
      userEmail,
      loginMessageError,
      nameMessageError,
      emailMessageError,
      unidadeMessageError,
    } = this.state;

    const { funcionarioID, rede } = this.props;
    return (
      <div className="vg-modal-form-redes">
        <form className="vg-form-redes">
          <div className="vg-form-redes-access">
            <h3>{intl.get("UNIDADES_REDES.FORM_ADD_USER.TITULO_DADOS")}</h3>
            {!funcionarioID && (
              <SelectDivUnit
                onChangeSelectForm={this.handleSelectForm}
                message={unidadeMessageError}
              />
            )}
            {funcionarioID && (
              <div>
                <fieldset>
                  <label>
                    {intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_DIVISAO")}
                  </label>
                  <p className="vg-label-text-rede">{rede.rede}</p>
                </fieldset>
                <fieldset>
                  <label>
                    {intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_UNIDADE")}
                  </label>
                  <p className="vg-label-text-rede">{rede.identidade}</p>
                </fieldset>
              </div>
            )}
            <Input
              inputType="text"
              onChangeInput={this.handleUserLogin}
              valueInput={userLogin}
              label={intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_USUARIO")}
              message={loginMessageError}
            />
          </div>
          <div className="vg-form-redes-userinfo">
            <h3>{intl.get("UNIDADES_REDES.FORM_ADD_USER.TITULO_INFOS")}</h3>
            <Input
              inputType="text"
              onChangeInput={this.handleUserName}
              valueInput={userName}
              label={intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_NOME")}
              message={nameMessageError}
            />
            <Input
              inputType="email"
              onChangeInput={this.handleUserEmail}
              valueInput={userEmail}
              label={intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_EMAIL")}
              message={emailMessageError}
            />
            <fieldset>
              <label>
                {intl.get("UNIDADES_REDES.FORM_ADD_USER.LABEL_TYPE")}
              </label>
              <Radio
                inputs={this.radios}
                intialChecked={checkedUserType}
                handleRadioCheck={this.handleRadios}
                id="userRedes"
              />
            </fieldset>
          </div>
        </form>
        <div className="vg-wrap-buttons">
          <div className="vg-row between-xs">
            <div className="vg-col-md-2 vg-col-xs-12 first-md" />
            <div className="vg-col-md-10 vg-col-xs-12 first-xs">
              <a
                className="vg-btn-outline-gray vg-cancel-redes"
                onClick={() => this.props.onClose()}
              >
                {intl.get("UNIDADES_REDES.FORM_ADD_USER.BOTAO_CANCELAR")}
              </a>
              <a
                className="vg-btn-success vg-success-redes"
                onClick={this.userFormClick}
              >
                {intl.get(
                  `UNIDADES_REDES.FORM_ADD_USER.BOTAO_${
                    funcionarioID ? "EDIT" : "ADD"
                  }`,
                )}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserRedesForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  addUser: PropTypes.func.isRequired,
  atualizaUser: PropTypes.func.isRequired,
  funcionarioID: PropTypes.number,
  funcionarioNome: PropTypes.string,
  funcionarioIdentidade: PropTypes.string,
  funcionarioEmail: PropTypes.string,
  funcionarioPerfil: PropTypes.string,
  rede: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ addUser, atualizaUser }, dispatch),
)(UserRedesForm);
