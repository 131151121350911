// Action Types
const PHASES = "services/PHASES";
const PHASES_SUCCESS = "services/PHASES_SUCCESS";
const PHASES_FAIL = "services/PHASES_FAIL";
const PHASE_POST = "services/PHASE_POST";
const PHASE_POST_SUCCESS = "services/PHASE_POST_SUCCESS";
const PHASE_POST_FAIL = "services/PHASE_POST_FAIL";
const PHASE_UPDATE = "services/PHASE_UPDATE";
const PHASE_UPDATE_SUCCESS = "services/PHASE_UPDATE_SUCCESS";
const PHASE_UPDATE_FAIL = "services/PHASE_UPDATE_FAIL";
const PHASE_REMOVE = "services/PHASE_REMOVE";
const PHASE_REMOVE_SUCCESS = "services/PHASE_REMOVE_SUCCESS";
const PHASE_REMOVE_FAIL = "services/PHASE_REMOVE_FAIL";
const UPDATE_PHASES_LIST = "services/UPDATE_PHASES_LIST";

// InitialState
const initialState = {
  loading: false,
  loaded: false,
};

// Reducers
export default function Phases(state = initialState, action = {}) {
  switch (action.type) {
    case PHASES:
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case PHASES_SUCCESS:
      return {
        ...state,
        data: action.result,
        loading: false,
        loaded: true,
      };
    case PHASES_FAIL:
      return {
        loading: false,
        loaded: false,
        error: action.error,
      };
    case PHASE_POST:
      return {
        ...state,
        postResult: {
          loading: true,
          loaded: false,
        },
      };
    case PHASE_POST_SUCCESS:
      return {
        ...state,
        postResult: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case PHASE_POST_FAIL:
      return {
        postResult: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case PHASE_UPDATE:
      return {
        ...state,
        updateResult: {
          loading: true,
          loaded: false,
        },
      };
    case PHASE_UPDATE_SUCCESS:
      return {
        ...state,
        updateResult: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case PHASE_UPDATE_FAIL:
      return {
        updateResult: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case PHASE_REMOVE:
      return {
        ...state,
        removeResult: {
          loading: true,
          loaded: false,
        },
      };
    case PHASE_REMOVE_SUCCESS:
      return {
        ...state,
        removeResult: {
          data: action.result,
          loading: false,
          loaded: true,
        },
      };
    case PHASE_REMOVE_FAIL:
      return {
        ...state,
        removeResult: {
          error: action.error,
          loading: false,
          loaded: false,
        },
      };
    case UPDATE_PHASES_LIST:
      return {
        ...state,
        data: action.updatedStore,
      };
    default:
      return state;
  }
}

// Actions
export function load(path) {
  return {
    types: [PHASES, PHASES_SUCCESS, PHASES_FAIL],
    promise: (client) => client.get(path),
  };
}

export function post(path, data) {
  return {
    types: [PHASE_POST, PHASE_POST_SUCCESS, PHASE_POST_FAIL],
    promise: (client) => client.post(path, { data }),
  };
}

export function update(path, data) {
  return {
    types: [PHASE_UPDATE, PHASE_UPDATE_SUCCESS, PHASE_UPDATE_FAIL],
    promise: (client) => client.put(path, { data }),
  };
}

export function remove(path, data) {
  return {
    types: [PHASE_REMOVE, PHASE_REMOVE_SUCCESS, PHASE_REMOVE_FAIL],
    promise: (client) => client.del(path, { data }),
  };
}

export function updateList(updatedStore) {
  return {
    type: UPDATE_PHASES_LIST,
    updatedStore,
  };
}

function shouldFetch(state) {
  return state.Phases.loading ? false : state.Phases.data ? false : true;
}

// Método responsável pelo dispatch das actions de requisição
export function receive(path) {
  return (dispatch, getState) => {
    if (shouldFetch(getState())) {
      return dispatch(load(path));
    }
  };
}
