// Action Types
const LOAD_RETURN = "services/LOAD_RETURN";
const LOAD_RETURN_SUCCESS = "services/LOAD_RETURN_SUCCESS";
const LOAD_RETURN_FAIL = "services/LOAD_RETURN_FAIL";

// InitialState
const initialState = {
  loaded: false,
  loading: false,
  status: false,
};

// Reducer
export default function ReturnASP(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_RETURN:
      return {
        ...state,
        loading: true,
      };
    case LOAD_RETURN_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        status: true,
      };
    case LOAD_RETURN_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export function load(path) {
  return {
    types: [LOAD_RETURN, LOAD_RETURN_SUCCESS, LOAD_RETURN_FAIL],
    promise: (client) => client.put(path),
  };
}
