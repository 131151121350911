import React, { useState, useEffect } from "react";
import { string } from "prop-types";
import { Icon } from "../../index";

const WarningTitle = ({ text, error }) => {
  const [classError, setError] = useState("");

  useEffect(() => {
    error.length ? setError(error) : setError("");
  }, [error]);
  return (
    <div className={`vg-warning-title ${classError}`}>
      <Icon iconKey="warning" />
      <p>{text}</p>
    </div>
  );
};

WarningTitle.defaultProps = {
  text: "",
  error: "",
};

WarningTitle.propTypes = {
  text: string.isRequired,
  error: string.isRequired,
};

export default WarningTitle;
