import "./EditPhases.scss";

import {
  Button,
  CollapseDrag,
  EditPhasesActionModal,
  EditPhasesActionModalRemove,
  EditPhasesContent,
  EditPhasesHeader,
  EditPhasesModal,
  EditPhasesModalRemove,
  Icon,
  Toast,
} from "../../index";
import React, { Component } from "react";
import { filter, map } from "lodash";
import {
  post,
  receive,
  remove,
  update,
  updateList,
} from "../../../store/modules/Phases";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import intl from "react-intl-universal";
import propTypes from "prop-types";
import { setTimeout } from "core-js";
import { toast } from "react-toastify";

const mapStateToProps = (state) => ({
  phasesList: state.Phases,
  loading: state.Phases.loading,
  loaded: state.Phases.loaded,
  error: state.Phases.error,
});

class EditPhases extends Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
      isOpenRemoveModal: false,
      isOpenActionModal: false,
      isOpenActionModalRemove: false,
      phasesList: {
        loading: true,
        loaded: false,
        data: [],
      },
      currentItem: {
        nome: "",
        sigla: "",
        cor_sigla: "000000",
        data_hora: "-1",
      },
      formState: {
        nome: "",
        sigla: "",
        cor_sigla: "000000",
        data_hora: "-1",
      },
      justOneTime: false,
      isCreating: false,
      selectState: 0,
      currentAction: {},
    };

    this.createPhase = this.createPhase.bind(this);
    this.loadPhases = this.loadPhases.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.showNotifications = this.showNotifications.bind(this);
    this.reorder = this.reorder.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.editHandler = this.editHandler.bind(this);
    this.removeHandler = this.removeHandler.bind(this);
    this.updateList = this.updateList.bind(this);
    this.removeChange = this.removeChange.bind(this);
    this.removePhase = this.removePhase.bind(this);
    this.toggleActionModal = this.toggleActionModal.bind(this);
    this.handleOnChangeSelect = this.handleOnChangeSelect.bind(this);
    this.closeActionModal = this.closeActionModal.bind(this);
    this.updateFormState = this.updateFormState.bind(this);
  }

  // Método utilizado para exibir os feedbacks dos usuários
  showNotifications(message, type = "success") {
    toast(<Toast message={message} type={type} />, {
      position: "bottom-right",
      autoClose: 4000,
      closeOnClick: true,
      pauseOnHover: true,
      className: `sr-toast vg-toast-${type}`,
    });
  }

  componentDidMount() {
    // Carrega as fases
    this.loadPhases();

    // Adiciona evento de keyup para fechar o modal
    document.addEventListener("keyup", (e) => {
      if (e.code === "Escape") {
        this.setState(
          {
            isOpen: false,
            isOpenRemoveModal: false,
            isOpenActionModalRemove: false,
            isOpenActionModal: false,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.remove("vg-no-scroll")
        );
      }
    });
  }

  // Remove o eventListener do document
  componentWillUnmount() {
    document.removeEventListener("keyup", () =>
      document.getElementsByTagName("body")[0].classList.remove("vg-no-scroll")
    );
  }

  updateList(success, formState) {
    this.setState(
      (prevState) => ({
        phasesList: {
          ...prevState.phasesList,
          data: [...prevState.phasesList.data, success],
        },
      }),
      () => {
        // Notificando o usuário do sucesso da criação
        this.showNotifications(
          intl.getHTML("FASES.MENSAGENS.FASE_CRIADA", { fase: formState.nome }),
          "success"
        );
        // Fechando o modal
        this.toggleModal();
      }
    );
  }

  updateFormState(formState) {
    this.setState({ formState: formState });
  }

  // Método para criar ou atualizar uma fase
  createPhase(e, formState) {
    e && e.preventDefault();
    const { isCreating } = this.state;
    if (isCreating) {
      this.props.post("fases", { ...formState }).then(
        (success) => {
          this.updateList(success, formState);
        },
        () => {
          // Caso dê algum erro, o usuário é notificado.
          this.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_FASE"),
            "error"
          );
        }
      );
    } else {
      this.props.update(`fases/${formState.id}`, { ...formState }).then(
        (success) => {
          this.setState(
            (prevState) => {
              const updatedPhasesList = prevState.phasesList.data.map((phase) =>
                phase.id === success.id ? { ...success } : phase
              );

              return {
                phasesList: {
                  ...prevState.phasesList,
                  data: updatedPhasesList,
                },
              };
            },
            () => {
              // Notificando o usuário do sucesso da criação
              this.showNotifications(
                intl.getHTML("FASES.MENSAGENS.FASE_ATUALIZADA", {
                  fase: formState.nome,
                }),
                "success"
              );
              // Fechando o modal
              this.toggleModal();
            }
          );
        },
        () => {
          // Caso dê algum erro, o usuário é notificado.
          this.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_CRIAR_FASE", {
              fase: formState.nome,
            }),
            "error"
          );
        }
      );
    }
  }

  // Método que atualiza o state para remoção de fase
  removeChange(e) {
    const inputValue = e.target.value;

    this.setState({
      selectState: inputValue,
    });
  }

  // Método para abrir e fechar o <Modal />
  toggleModal(el, creating = false, type = "create-update") {
    el && el.preventDefault();

    if (type === "create-update") {
      if (creating) {
        this.setState(
          {
            isCreating: creating,
            isOpen: !this.state.isOpen,
            formState: {
              nome: "",
              sigla: "",
              cor_sigla: "000000",
              data_hora: "-1",
            },
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.toggle("vg-no-scroll")
        );
      } else {
        this.setState(
          {
            isCreating: creating,
            isOpen: !this.state.isOpen,
          },
          () =>
            document
              .getElementsByTagName("body")[0]
              .classList.toggle("vg-no-scroll")
        );
      }
    } else {
      this.setState(
        {
          isOpenRemoveModal: !this.state.isOpenRemoveModal,
          selectState: 0,
        },
        () =>
          document
            .getElementsByTagName("body")[0]
            .classList.toggle("vg-no-scroll")
      );
    }
  }

  // Método para carregar a lista de fases
  loadPhases() {
    this.props.receive("fases");
  }

  // a little function to help us with reordering the result
  reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  // Método que é disparado quando o evento de Drag`n drop é finalizado
  onDragEnd(result) {
    const { phasesList } = this.state;

    if (!result.destination) {
      return;
    }

    const items = this.reorder(
      phasesList.data,
      result.source.index,
      result.destination.index
    );

    this.setState(
      (prevState) => ({
        phasesList: {
          ...prevState.phasesList,
          data: items,
        },
      }),
      () => {
        document.querySelectorAll(".vg-collapse-space")[
          // eslint-disable-next-line no-unexpected-multiline
          result.destination.index
        ].classList.add("vg-changed-item");

        setTimeout(() => {
          document.querySelectorAll(".vg-collapse-space")[
            // eslint-disable-next-line no-unexpected-multiline
            result.destination.index
          ].classList.remove("vg-changed-item");
        }, 1000);

        // Retorna um array com a lista de fases ordenadas
        this.props
          .post("fases/ordenar", { ordem: map(items, (item) => item.id) })
          .catch(() => {
            // Caso dê algum erro, o usuário é notificado.
            this.showNotifications(
              intl.getHTML("FASES.MENSAGENS.ERRO_AO_ORDENAR"),
              "error"
            );
          });

        this.props.updateList(items);
      }
    );
  }

  // Método para atualizar o estado quando é editado uma fase
  editHandler(e, item) {
    e && e.preventDefault();

    this.setState(
      {
        formState: { ...item, data_hora: item.data_hora.toString() },
      },
      () => {
        this.toggleModal();
      }
    );
  }

  // Trata o estado com a fase selecionada para remoção
  removeHandler(e, item) {
    e && e.preventDefault();

    this.setState(
      {
        formState: { ...item },
        selectState: 0,
      },
      () => {
        this.toggleModal(null, null, "remove");
      }
    );
  }

  // Faz a requisição de remoção da fase
  removePhase(e) {
    e && e.preventDefault();

    this.props
      .remove(`fases/${this.state.formState.id}`, {
        fase_substituta_id: this.state.selectState,
      })
      .then(
        (success) => {
          this.setState(
            (prevState) => ({
              phasesList: {
                ...prevState.phasesList,
                data: filter(
                  prevState.phasesList.data,
                  (item) => item.id != success.id
                ),
              },
            }),
            () => {
              // Notificando o usuário do sucesso da criação
              this.showNotifications(
                intl.getHTML("FASES.MENSAGENS.SUCESSO_AO_APAGAR_FASE", {
                  fase: this.state.formState.nome,
                }),
                "success"
              );

              // Fechando o modal
              this.toggleModal(null, false, "remover");
            }
          );
        },
        () => {
          // Caso dê algum erro, o usuário é notificado.
          this.showNotifications(
            intl.getHTML("FASES.MENSAGENS.ERRO_AO_APAGAR_FASE", {
              fase: this.state.formState.nome,
            }),
            "error"
          );
        }
      );
  }

  handleOnChangeSelect(event) {
    this.setState({ selectPhase: event.target.value });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.phasesList.loaded && !state.justOneTime) {
      return {
        phasesList: props.phasesList,
        justOneTime: true,
      };
    }

    return null;
  }

  toggleActionModal(e, item, currentAction = {}, type = "create") {
    e && e.preventDefault();

    this.setState((prevState) => ({
      currentAction: currentAction,
      currentItem: item
        ? item
        : {
            nome: "",
            sigla: "",
            cor_sigla: "000000",
            data_hora: "-1",
          },
      isOpenActionModalRemove:
        type === "remove" ? !prevState.isOpenActionModalRemove : false,
      isOpenActionModal:
        type === "remove" ? false : !prevState.isOpenActionModal,
    }));
  }

  closeActionModal(e) {
    e && e.preventDefault();

    this.setState({
      currentAction: {},
      currentItem: {
        nome: "",
        sigla: "",
        cor_sigla: "000000",
        data_hora: "-1",
      },
      isOpenActionModalRemove: false,
      isOpenActionModal: false,
    });
  }

  render() {
    const {
      successAction,
      phasesList,
      isCreating,
      isOpen,
      isOpenRemoveModal,
      selectState,
      isOpenActionModal,
      isOpenActionModalRemove,
    } = this.state;
    const { loading, loaded, error } = this.props;

    return loading && !loaded ? (
      <div className="vg-container vg-phases-wrapper">
        <div className="vg-phases-page">
          <header>
            <div className="vg-title-loader">
              <Icon iconKey="titleLoader" />
            </div>
          </header>
          <ul className="vg-fast-links vg-fast-placeholder">
            <li>
              <Icon iconKey="fastLinksLoader" />
            </li>
            <li>
              <Icon iconKey="fastLinksLoader" />
            </li>
            <li>
              <Icon iconKey="fastLinksLoader" />
            </li>
          </ul>
          <div className="vg-collapse-list">
            <div className="vg-placeholder">
              <Icon iconKey="collapse-list-loader" />
            </div>
            <div className="vg-placeholder">
              <Icon iconKey="collapse-list-loader" />
            </div>
          </div>
        </div>
      </div>
    ) : error && !loaded ? (
      <div className="vg-container vg-phases-wrapper">
        <div className="vg-phases-page">
          <header>
            <h1 className="vg-title-1 vg-title-mt vg-title-no-mb">
              {intl.get("FASES.TITULO_PAGINA")}
            </h1>
            <h2 className="vg-sub-title">
              {intl.get("FASES.SUBTITULO_PAGINA")}
            </h2>
          </header>
        </div>
      </div>
    ) : (
      <div className="vg-container vg-phases-wrapper">
        <div className="vg-phases-page">
          <header>
            <h1 className="vg-title-1 vg-title-mt vg-title-no-mb">
              {intl.get("FASES.TITULO_PAGINA")}
            </h1>
            <h2 className="vg-sub-title">
              {intl.get("FASES.SUBTITULO_PAGINA")}
            </h2>
          </header>
          <a
            href="#"
            onClick={(el) => this.toggleModal(el, true)}
            className="vg-rounded-link"
          >
            <Icon iconKey="rounded-plus" />
            {intl.get("FASES.CRIAR_FASE")}
          </a>
          <CollapseDrag
            errorMessage={intl.get(
              "MENSAGEM_DE_ERRO.EDITAR_FASES_NAO_CARREGADA"
            )}
            retryFetch={this.loadPhases}
            headerContent={<EditPhasesHeader />}
            bodyContent={
              <EditPhasesContent
                editHandler={this.editHandler}
                removeHandler={this.removeHandler}
                toggleActionModal={this.toggleActionModal}
                toggleModal={this.toggleModal}
                successAction={successAction}
              />
            }
            restResult={phasesList}
            onDragEnd={this.onDragEnd}
          />
          <Button
            onClick={(el) => this.toggleModal(el, true)}
            className="vg-create-phase"
          >
            <Icon iconKey="rounded-plus" />
            {intl.get("FASES.ADICIONAR_FASE")}
          </Button>

          <EditPhasesModal
            isOpen={isOpen}
            toggleModal={this.toggleModal}
            createPhase={this.createPhase}
            editPhase={this.editPhase}
            title={
              isCreating
                ? intl.get("FASES.MODAL.CONTEUDO.TITULO_CRIAR")
                : intl.get("FASES.MODAL.CONTEUDO.TITULO_EDITAR")
            }
            subTitle={intl.get("FASES.MODAL.CONTEUDO.SUBTITULO")}
            isCreating={isCreating}
            formState={this.state.formState}
            phasesList={phasesList.data}
            updateFormState={this.updateFormState}
          />

          <EditPhasesModalRemove
            isOpen={isOpenRemoveModal}
            toggleModal={this.toggleModal}
            formChange={this.removeChange}
            selectState={selectState}
            removePhase={this.removePhase}
            phasesList={phasesList.data}
            formState={this.state.formState}
          />

          <EditPhasesActionModal
            isOpen={isOpenActionModal}
            currentItem={this.state.currentItem}
            showNotifications={this.showNotifications}
            currentAction={this.state.currentAction}
            closeActionModal={this.closeActionModal}
            phasesList={phasesList.data}
          />

          <EditPhasesActionModalRemove
            isOpen={isOpenActionModalRemove}
            currentItem={this.state.currentItem}
            showNotifications={this.showNotifications}
            currentAction={this.state.currentAction}
            closeActionModal={this.closeActionModal}
          />
        </div>
      </div>
    );
  }
}

EditPhases.defaultProps = {
  phasesList: {
    loaded: false,
  },
};

EditPhases.propTypes = {
  phasesList: propTypes.object.isRequired,
  updateList: propTypes.func.isRequired,
  receive: propTypes.func.isRequired,
  post: propTypes.func.isRequired,
  update: propTypes.func.isRequired,
  remove: propTypes.func.isRequired,
  loading: propTypes.bool.isRequired,
  loaded: propTypes.bool.isRequired,
  error: propTypes.object,
};

export default connect(mapStateToProps, (dispatch) =>
  bindActionCreators({ receive, post, update, remove, updateList }, dispatch)
)(EditPhases);
