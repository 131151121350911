import { Button, Link, Modal } from "../../index";
import React, { Component } from "react";
import { difference, filter, toLower } from "lodash";

import intl from "react-intl-universal";
import propTypes from "prop-types";

export class EditPhasesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isTyping: false,
      nomeDuplicated: false,
      siglaDuplicated: false,
    };

    this.formChange = this.formChange.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.createHandler = this.createHandler.bind(this);
  }

  // Método que atualiza o state quando o form de criação/edição é alterado
  formChange(e, type) {
    const targetValue = e.target.value;
    const inputValue =
      type === "sigla" ? targetValue.toUpperCase() : targetValue;
    const { formState } = this.props;
    const { isCreating, phasesList } = this.props;

    function isEqual(value) {
      const checkingEqual = toLower(value[type]) === toLower(inputValue);

      if (checkingEqual) {
        return value;
      }
    }

    const duplicateQuestion = filter(phasesList, isEqual);

    if (isCreating) {
      this.setState({
        [`${type}Duplicated`]: duplicateQuestion.length ? true : false,
        isTyping: true,
      });
      this.props.updateFormState({
        ...this.props.formState,
        [type]: inputValue,
      });
    } else {
      const isTheSame = filter(duplicateQuestion, { id: formState.id });

      this.setState({
        [`${type}Duplicated`]:
          duplicateQuestion.length > 0 &&
          difference(duplicateQuestion, isTheSame).length > 0
            ? true
            : false,
        isTyping: true,
      });
      this.props.updateFormState({
        ...this.props.formState,
        [type]: inputValue,
      });
    }
  }

  closeModal() {
    this.setState({
      isTyping: false,
      nomeDuplicated: false,
      siglaDuplicated: false,
    });
    this.props.toggleModal();
  }

  createHandler(e) {
    this.setState({
      isTyping: false,
      nomeDuplicated: false,
      siglaDuplicated: false,
    });
    this.props.createPhase(e, this.props.formState);
  }

  render() {
    const { isOpen, title, subTitle, isCreating, formState } = this.props;

    const { nomeDuplicated, siglaDuplicated } = this.state;
    return (
      <Modal show={isOpen} onClose={this.closeModal}>
        <div className="vg-phases-modal">
          <div className="vg-phases-modal-content">
            <h1>{title}</h1>
            <p>{subTitle}</p>
            <div className="vg-form">
              <label
                className={`vg-label ${nomeDuplicated && "vg-label-error"}`}
              >
                <span className="vg-label-text">
                  {intl.get("FASES.MODAL.LABELS.NOME")}
                </span>
                <input
                  type="text"
                  name="phase-name"
                  id="phase-name"
                  className="vg-input-text"
                  value={formState.nome}
                  onChange={(el) => this.formChange(el, "nome")}
                  autoComplete="off"
                  maxLength="50"
                />
                <span className="vg-label-helper-error">
                  {intl.get("FASES.MODAL.LABELS.NOME_ERRO")}
                </span>
              </label>
              <label
                className={`vg-label ${siglaDuplicated && "vg-label-error"}`}
              >
                <span className="vg-label-text">
                  {intl.get("FASES.MODAL.LABELS.SIGLA")}
                </span>
                <span className="vg-label-helper">
                  {intl.get("FASES.MODAL.LABELS.SIGLA_EXPLICACAO")}
                </span>
                <input
                  type="text"
                  name="phase-initials"
                  id="phase-initials"
                  className="vg-input-text vg-uppercase"
                  value={formState.sigla}
                  onChange={(el) => this.formChange(el, "sigla")}
                  autoComplete="off"
                  maxLength="7"
                />
                <span className="vg-label-helper-error">
                  {intl.get("FASES.MODAL.LABELS.SIGLA_ERRO")}
                </span>
              </label>
              <label className="vg-label">
                <span className="vg-label-text">
                  {intl.get("FASES.MODAL.LABELS.INFORMACAO")}
                </span>
              </label>
              <div className="vg-custom-radio">
                <label className="vg-label">
                  <input
                    type="radio"
                    name="phase-info"
                    id="phase-info-1"
                    value="-1"
                    checked={formState.data_hora == -1}
                    onChange={(el) => this.formChange(el, "data_hora")}
                  />
                  <span className="vg-label-text">
                    {intl.get("FASES.MODAL.LABELS.INFORMACAO1")}
                  </span>
                </label>
                <label className="vg-label">
                  <input
                    type="radio"
                    name="phase-info"
                    id="phase-info-2"
                    value="1"
                    checked={formState.data_hora == 1}
                    onChange={(el) => this.formChange(el, "data_hora")}
                  />
                  <span className="vg-label-text">
                    {intl.get("FASES.MODAL.LABELS.INFORMACAO2")}
                  </span>
                </label>
                <label className="vg-label">
                  <input
                    type="radio"
                    name="phase-info"
                    id="phase-info-3"
                    value="2"
                    checked={formState.data_hora == 2}
                    onChange={(el) => this.formChange(el, "data_hora")}
                  />
                  <span className="vg-label-text">
                    {intl.get("FASES.MODAL.LABELS.INFORMACAO3")}
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="vg-wrap-buttons">
            <Link onClick={this.closeModal} className="vg-btn-link">
              {intl.get("FASES.MODAL.BOTAO_2")}
            </Link>
            <Button
              onClick={(e) => this.createHandler(e)}
              disabled={
                formState.nome == "" ||
                formState.sigla == "" ||
                nomeDuplicated ||
                siglaDuplicated
              }
              className="vg-btn-success"
            >
              {isCreating
                ? intl.get("FASES.MODAL.BOTAO_1")
                : intl.get("FASES.MODAL.BOTAO_3")}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

EditPhasesModal.defaultProps = {
  isOpen: false,
  phasesList: [],
};

EditPhasesModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  isCreating: propTypes.bool.isRequired,
  toggleModal: propTypes.func.isRequired,
  createPhase: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  subTitle: propTypes.string.isRequired,
  phasesList: propTypes.array.isRequired,
  formState: propTypes.object,
  updateFormState: propTypes.func.isRequired,
};

export default EditPhasesModal;
