import React, { Component } from "react";
import PropTypes from "prop-types";
import { Icon } from "../../index";

import "./Panel.scss";

export class Panel extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { iconKey, children, className } = this.props;

    return (
      <div className={`vg-panel ${className}`}>
        <div className="contentIcon">
          <Icon iconKey={iconKey} />
        </div>
        {children}
      </div>
    );
  }
}

Panel.propTypes = {
  iconKey: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Panel.defaultProps = {
  className: "",
};

export default Panel;
