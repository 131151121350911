import { CollapseDragContent, CollapseDragHeader } from "../../index";
import React, { Component } from "react";

import propTypes from "prop-types";

class CollapseDragItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      opened: false,
      isActive: false,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(e) {
    e.preventDefault();
    this.setState((prevState) => ({
      opened: !prevState.opened,
    }));
  }

  render() {
    const { opened } = this.state;
    const { bodyContent, headerContent, index, item } = this.props;

    return (
      <div className={`vg-collapse-item ${opened && "vg-active"}`}>
        <CollapseDragHeader
          toggleCollapse={this.toggleCollapse}
          index={index}
          item={item}
        >
          {headerContent}
        </CollapseDragHeader>
        <CollapseDragContent opened={opened} item={item}>
          {bodyContent}
        </CollapseDragContent>
      </div>
    );
  }
}

CollapseDragItem.propTypes = {
  headerContent: propTypes.node.isRequired,
  bodyContent: propTypes.node.isRequired,
  item: propTypes.object.isRequired,
  index: propTypes.number.isRequired,
};

export default CollapseDragItem;
