import React, { useEffect } from "react";
import propTypes from "prop-types";
import { GraphSla, GraphBce, GraphCandidate, Icon } from "../../index";
import "./Graphs.scss";
import { connect } from "react-redux";
import { receive } from "../../../store/modules/Professionals";

const mapStateToProps = (state) => ({
  professionals: state.Professionals.data.acessos,
  loaded: state.Professionals.loaded,
  loading: state.Professionals.loading,
});

/*
Caso seja necessário carregar os dados do Painel, que indicará os componentes dinâmicos.

const mapStateToProps = state => ({
  slaGraphComponentName: state.Painel.painel.data ? state.Painel.painel.data.features.graficos.sla : 'GraphSla',
  loaded: state.Painel.painel.loaded,
});
 */

// Para carregamento dinâmico dos componentes,
// utilizamos o artigo abaixo como referência:
// https://medium.com/@Carmichaelize/dynamic-tag-names-in-react-and-jsx-17e366a684e9
const Graphs = ({ receive, professionals, loaded, loading }) => {
  useEffect(() => {
    receive();
  }, []);
  /*
  constructor() {
    super();

    this.components = {
      graph_sla: GraphSla,
      new_graph_sla_creditos: NewGraphSlaCreditos,
    };
  }
  */
  const bce =
    professionals &&
    professionals.banco_de_talentos &&
    professionals.banco_de_talentos.acesso
      ? "vg-boxes-with-bte"
      : "vg-boxes-graphs";
  // Necessário somente para uso de componentes dinâmicos
  // const loaded = this.props.loaded;
  // const SlaGraphComponentName = this.components[this.props.slaGraphComponentName];
  return loading && !loaded ? (
    <div className={`vg-graphs ${bce}`}>
      <div className="vg-col-xs-12 vg-col-md">
        <div className="vg-graphs-placeholder">
          <Icon iconKey="graphRounded" />
        </div>
      </div>
      <div className="vg-col-xs-12 vg-col-md">
        <div className="vg-graphs-placeholder vg-graphs-line-placeholder">
          <Icon iconKey="graphLine" />
        </div>
      </div>
      <div className="vg-col-xs-12 vg-col-md">
        <div className="vg-graphs-placeholder">
          <Icon iconKey="graphRounded" />
        </div>
      </div>
    </div>
  ) : !loading && loaded ? (
    <div className={`vg-graphs ${bce}`}>
      <div className="vg-col-xs-12 vg-col-md">
        <GraphSla />
      </div>
      <div className="vg-col-xs-12 vg-col-md">
        <GraphCandidate />
      </div>
      {bce != "vg-boxes-with-bte" && (
        <div className="vg-col-xs-12 vg-col-md">
          <GraphBce />
        </div>
      )}
    </div>
  ) : null;
};

/*
Necessário somente para uso de componentes dinâmicos

Graphs.defaultProps = {
  slaGraphComponentName: 'GraphSla'
};


export default connect(
  mapStateToProps
)(Graphs);
*/

Graphs.propTypes = {
  receive: propTypes.func.isRequired,
  professionals: propTypes.object.isRequired,
  loaded: propTypes.bool.isRequired,
  loading: propTypes.bool.isRequired,
};

export default connect(mapStateToProps, { receive })(Graphs);
